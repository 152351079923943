/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlNumerico from '../../Controles/CtrlNumerico';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';
import CtrlAutoComplete from '../../Controles/CtrlAutoComplete';

import { Autocomplete } from 'devextreme-react/autocomplete';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

export default class LoteMinimoFiltros extends React.Component {
  constructor(props) {
    super(props);

    this.onBtnBuscar = this.onBtnBuscar.bind(this);
    this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    this.onChange = this.onChange.bind(this);

    this.state = {
      dataTipoFecha: [
        { Clave: 1, Nombre: 'Fecha Creación Lote' },
        { Clave: 2, Nombre: 'Fecha Lote Completo' },
        { Clave: 3, Nombre: 'Fecha Cierre Lote' },
        { Clave: 4, Nombre: 'Fecha Cancelación Lote' },
      ],
    };
  }

  onBtnBuscar() {
    this.props.getLotesMinimos();
  }

  onBtnLimpiar() {
    this.props.onLimpiar();
  }

  componentDidMount() {}

  onChange(obj) {
    const Valor = '';
    if (obj.component._changedValue === '') {
      const object = { selectedItem: null };
      this.props.onChangeCmbSubFamilias(object);
    }
  }

  render() {
    const spinner =
      this.props.showSpin === true ? (
        <Loader type="Puff" color="#00BFFF" height={40} width={40} />
      ) : (
        <></>
      );

    return (
      <div className="content">
        <Row>
          <Col md={12} style={{ padding: 0, margin: 0 }}>
            <Card>
              <CardHeader>
                <CardTitle>Lotes Mínimos - Mallas y Alambres Producto</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <CtrlComboBox
                          etiqueta="Ubicación Lote"
                          onChange={this.props.onChangeCmbUbicacion}
                          data={this.props.ubicacionesData}
                          value={this.props.filtros.claUbicacion}
                          reference={this.props.refCmbUbicacion}
                        />
                      </Col>
                      <Col>
                        <CtrlComboBox
                          etiqueta="Ubicación Pide"
                          onChange={this.props.onChangeCmbUbicacionPide}
                          data={this.props.ubicacionesData}
                          value={this.props.filtros.claUbicacionPide}
                          reference={this.props.refCmbUbicacionPide}
                        />
                      </Col>
                      <Col>
                        <CtrlComboBox
                          etiqueta="Familia"
                          onChange={this.props.onChangeCmbFamilias}
                          value={this.props.filtros.claFamilia}
                          data={this.props.familiasData}
                          reference={this.props.refCmbFamilia}
                        />
                      </Col>
                      <Col>
                        <CtrlComboBox
                          etiqueta="Filtrar por Fecha"
                          onChange={this.props.onChangeCmbTipoFecha}
                          data={this.state.dataTipoFecha}
                          value={this.props.filtros.tipoFecha}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CtrlNumerico
                          etiqueta="Número Pedido"
                          onChange={this.props.onChangeClaPedido}
                          value={this.props.filtros.claPedido}
                        />
                      </Col>
                      <Col>
                        {/* <CtrlComboBox
                                                    etiqueta="SubFamilia"
                                                    onChange={this.props.onChangeCmbSubFamilias}
                                                    value={this.props.filtros.claSubFamilia}
                                                    data={this.props.subFamiliasData}
                                                    reference={this.props.refCmbSubFamilia}
                                                /> */}
                        <Row style={{ height: '24px' }}>
                          <Col md={{ size: 12 }}>
                            <div className="pl-xl-2 subtitle-1">{'SubFamilia'}</div>
                          </Col>
                        </Row>
                        <Row style={{ height: '32px' }}>
                          <Col md={{ size: 12 }}>
                            <Autocomplete
                              ref={this.props.refNumSubFamilia}
                              dataSource={this.props.subFamiliasData}
                              placeholder={'Clave o Nombre...'}
                              valueExpr={'Nombre'}
                              displayExpr={'Nombre'}
                              keyValue={'Clave'}
                              showClearButton={true}
                              minSearchLength={3}
                              searchTimeout={500}
                              onSelectionChanged={this.props.onChangeCmbSubFamilias}
                              onInput={this.onChange}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <CtrlDatePicker
                              etiqueta="Inicio"
                              defaultValue={this.now}
                              onChange={this.props.onChangeFechaInicio}
                              value={this.props.filtros.fechaInicio}
                            />
                          </Col>
                          <Col>
                            <CtrlDatePicker
                              etiqueta="Fin"
                              defaultValue={this.now}
                              onChange={this.props.onChangeFechaFin}
                              value={this.props.filtros.fechaFin}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CtrlNumerico
                          etiqueta="Número Lote"
                          onChange={this.props.onChangeIdLote}
                          value={this.props.filtros.idLote}
                        />
                      </Col>
                      <Col>
                        {/* <CtrlComboBox
                                                    etiqueta="Productos"
                                                    onChange={this.props.onChangeCmbProductos}
                                                    data={this.props.productosData}
                                                    value={this.props.filtros.claProducto}
                                                    reference={this.props.refCmbProductos}
                                                /> */}
                        <CtrlAutoComplete
                          etiqueta="Producto"
                          placeholder="Clave o Nombre del producto..."
                          valueExpr="Nombre"
                          displayExpr="Nombre"
                          keyValue="ClaCliente"
                          claFamilia={this.props.claFamilia}
                          claSubfamilia={this.props.claSubfamilia}
                          claProducto={25}
                          idEntidad={3}
                          onChange={this.props.onChangeCmbProductos}
                          value={this.props.claProducto}
                          reference={this.props.refNumProducto}
                        />
                      </Col>
                      <Col>
                        <CtrlComboBox
                          etiqueta="Estatus"
                          onChange={this.props.onChangeCmbEstatus}
                          data={this.props.estatusData}
                          value={this.props.filtros.estatusLote}
                          reference={this.props.refCmbEstatus}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Row>
                      <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                        Buscar
                      </Button>
                      {spinner}
                      <Button type="button" onClick={this.onBtnLimpiar}>
                        Limpiar
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
