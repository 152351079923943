import { LoadIndicator } from 'devextreme-react';
import React from 'react';

/**
 * Control que muestra una pantalla negra-transparente de Boostrap para Cargas de Datos
 * @component
 */
class LoaderScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    // Propieda de del Icono de Carga
    const widthLoader = 20; // pixeles
    const marginLeft = this.props.width / 2 - widthLoader;
    const marginTop = this.props.height / 2 - widthLoader;
    return (
      <div
        className={`loadscreen noselect ${this.props.show ? '' : 'd-none'}`}
        style={this.props.style}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadIndicator
            id="medium-indicator"
            height={widthLoader}
            width={widthLoader}
            visible={true}
          />
        </div>
      </div>
    );
  }
}

export default LoaderScreen;
