import React, { useState } from "react";
import { Row, Col, Popover, PopoverHeader, PopoverBody } from "reactstrap";

import "../../../assets/css/popover.css";

const TracePopupOverUbic = props => {
  const { data } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const idBtn = `datosUbic`;
  const clase = "mb-0 fa fa-info-circle ";

  if (data === null || data === undefined) {
    return (<></>);
  }

  const motivos = data.split(",");

  const trace = motivos.map((item, index) => {
    const id = `datosUbicId${index}`;
    return (
      <Row key={id}>
        <Col className="col-12">
          {item}
        </Col>
      </Row>
    );
  });

  const titulo = "Detalle Ubicacion Asignada";
  const header = (data.length > 0 ? <i id={idBtn} className={clase} style={{ cursor: "pointer" }}></i> : <i id={idBtn} className={clase}></i>);

  return (
    <span className="ml-1">
      {header}
      <Popover
        placement="left"
        isOpen={popoverOpen}
        target={idBtn}
        toggle={toggle}
        className="my-custom-popover"
      >
        <PopoverHeader>{titulo}</PopoverHeader>
        <PopoverBody>
          {trace}
        </PopoverBody>
      </Popover>
    </span>
  );
};



export default TracePopupOverUbic;