import React, { Component } from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { config } from '../../utils/config';
import { callApi, showNotify, notifyType, notifyPosition, callKrakenApi } from '../../utils/utils';

class UsuarioAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buscar: false,
      objUsuario: {
        ClaveEmpleado: '',
        claRol: 0,
        NombreEmpleado: '',
        ClaveUsuario: 0,
      },
    };

    this.btnBuscarHandleSubmit = this.btnBuscarHandleSubmit.bind(this);
    this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
    this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
    this.refForm = React.createRef();
  }

  /**
   * Regresa el objeto del formulario
   */
  get formUser() {
    return this.refForm.current ? this.refForm.current.instance : null;
  }

  /**
   * Funcion que se ejecuta al dar clic en el boton Buscar
   */
  btnBuscarHandleSubmit() {
    const dataForm = this.formUser.option('formData');
    const claEmpleado = dataForm.ClaveEmpleado;

    if (claEmpleado === '') {
      showNotify(
        'Favor de capturar una Clave de Empleado',
        notifyPosition.centerTop,
        notifyType.error
      );
      return;
    }

    const urlApiService = `${process.env.REACT_APP_URL_API}usuario/obtener/${claEmpleado}`;

    callApi(urlApiService, 'GET', null, (res) => {
      if (res.data.length > 0) {
        this.setState({
          objUsuario: {
            ClaveEmpleado: claEmpleado,
            NombreEmpleado: res.data[0].nombre,
            ClaveUsuario: res.data[0].claUsuario,
          },
        });
      } else {
        showNotify('No existe clave de empleado', notifyPosition.centerTop, notifyType.error);
      }
    });
  }

  /**
   * Funcion que se ejecuta al dar clic en el boton Guardar
   */
  btnGuardarHandleSubmit() {
    const dataForm = this.formUser.option('formData');

    if (!dataForm.ClaveEmpleado || !dataForm.claRol) {
      showNotify(
        'Favor de capturar una Clave de Empleado y Rol',
        notifyPosition.centerTop,
        notifyType.error
      );
      return;
    }

    const urlApiService = `${process.env.REACT_APP_URL_API}usuario/agregar`;

    const body = {
      ClaUsuario: dataForm.ClaveUsuario,
      BajaLogica: 0,
      ClaRol: dataForm.claRol,
    };

    callApi(urlApiService, 'POST', body, (res) => {
      showNotify('Usuario guardado correctamente', notifyPosition.centerTop, notifyType.success);
      this.props.handler(false);
      window.location.reload();
    });
  }

  /**
   * Función del botón para cancelar la operación y ocultar el modal
   */
  btnCancelarHandle() {
    this.props.handler(false);
  }

  render() {
    return (
      <div>
        <Card>
          <CardBody>
            <Form
              ref={this.refForm}
              key="formUser"
              formData={this.state.objUsuario}
              colCount={1}
              showColonAfterLabel={true}
              className="formUser"
              id="FormAltaUsuario"
            >
              <Item dataField="ClaveUsuario" visible={false} />
              <GroupItem colSpan={2} colCount={2}>
                <Item
                  dataField="ClaveEmpleado"
                  editorType="dxNumberBox"
                  editorOptions={{
                    placeholder: 'Ej. 909090',
                    onEnterKey: this.btnBuscarHandleSubmit,
                  }}
                />
                <ButtonItem
                  itemType="button"
                  horizontalAlignment="left"
                  buttonOptions={{
                    hint: 'Buscar',
                    icon: 'search',
                    type: 'default',
                    onClick: this.btnBuscarHandleSubmit,
                  }}
                />
              </GroupItem>
              <Item
                dataField="NombreEmpleado"
                editorOptions={{
                  placeholder: 'Se obtiene al buscar una Clave Empleado válida',
                  disabled: true,
                }}
              />

              <Item
                dataField="claRol"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: this.props.roles,
                  searchEnabled: true,
                  valueExpr: 'claRol',
                  displayExpr: 'nombre',
                  placeholder: 'Selecciona un rol',
                }}
              />
            </Form>
            <FormGroup className="text-right">
              <Button
                id="btnUsersGuardar"
                text="Guardar"
                onClick={this.btnGuardarHandleSubmit}
                className="btn btn-primary btn-round"
              />
              <Button
                id="btnUsersCancelar"
                text="Cancelar"
                onClick={this.btnCancelarHandle}
                className="btn btn-primary btn-round"
              />
            </FormGroup>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default UsuarioAlta;
