/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Format, Paging, Scrolling  } from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';

export default class PedidosGridDet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            dataDetalle:[]
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getProduccionData = this.getProduccionData.bind(this);
        this.onGridPage = this.onGridPage.bind(this);

        this.getProduccionData();
    }

    getProduccionData() {
        const filtrosPedidos = {            
            fechaCalculo: this.props.fechaCalculo,
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claPedido: this.props.claPedido,
            modoVista: 5
        };

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;

        callApi(urlApiService, 'POST', filtrosPedidos, (result) => {
            this.setState({
                dataDetalle: result[0]
            });
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.onGridPage(e.value);
        }
    }
    
    render() {
        const gridContent = (
            <div>
                <DataGrid
                    dataSource={this.state.dataDetalle}
                    height={600}
                    width="100%"
                    selection={{ mode: 'single' }}
                    showBorders={false}
                    columnHidingEnabled
                    columnAutoWidth={false}
                    showColumnHeaders
                    showColumnLines={false}
                    showRowLines
                    noDataText="Sin Registros"
                    wordWrapEnabled
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    >
                           
                    <Column dataField="NomAreaProduccion" caption="Área Producción" width={150}/>
                    <Column dataField="NomGrupoMaquina" caption="Gpo Máquinas" width={150}/>             
                    <Column dataField="claPedido" caption="Pedido" width={100}/>
                    <Column dataField="idRenglon" caption="Renglon" width={100}/>
                    <Column dataField="claProductoAto" caption="Producto" width={200} />
                    <Column dataField="claArticuloComp" caption="Producto Composición" width={200} />
                    <Column dataField="claPeriodo" caption="Periodo" width={100} />    
                    <Column dataField="nivelComposicion" caption="Nivel" width={100} dataType={"number"} alignment={"left"} />
                        
                    <Column dataField="deudaOriginalComposicion" caption="Deuda Original Composicion" width={100} dataType={"number"} alignment={"left"}>
                        <Format
                            type="fixedPoint"
                            precision={2}
                        />
                    </Column>
                    <Column dataField="capacidadDisponible" caption="Capacidad Disponible" width={100} dataType={"number"} alignment={"left"}>
                        <Format
                            type="fixedPoint"
                            precision={2}
                        />
                    </Column>
                    <Column dataField="horasReservados" caption="Horas Reservados" width={100} dataType={"number"} alignment={"left"}>
                        <Format
                            type="fixedPoint"
                            precision={2}
                        />
                    </Column>
                    <Column dataField="horasDisponibles" caption="Horas Disponibles" width={70} dataType={"number"} alignment={"left"}>
                        <Format
                            type="fixedPoint"
                            precision={0}
                        />
                    </Column>               
                    <Column dataField="deudaPendiente" caption="Deuda Pendiente" width={70} dataType={"number"} alignment={"left"}>
                        <Format
                            type="fixedPoint"
                            precision={2}
                        />
                    </Column>    

                    <Paging
                        pageSize={30}
                        pageIndex={this.props.pageIndex}
                    />
                    
                    <Scrolling columnRenderingMode="virtual" />
                </DataGrid>
            </div>
        );

        return (
            <>
            {gridContent}
            </>
        );
    }
}