/*eslint-disable*/
import React from 'react';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, Scrolling, FilterRow, HeaderFilter, Summary, TotalItem} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';

export default class ReportePedidosSinFechaGrid extends React.Component {
    constructor(props) {
        super(props);
        
        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);   
        this.onExporting= this.onExporting.bind(this);
        this.onExported = this.onExported.bind(this);
        this.customizeDateText = this.customizeDateText.bind(this);
        this.customizeText = this.customizeText.bind(this);
    }

    customizeText(cellElement){
        if(cellElement.value === 0){
            return ''
        }
        return cellElement.value
    }

    customizeDateText(cellElement){
        if(cellElement.value == '0001-01-01T00:00:00'){
            return '';
        }
        return cellElement.value
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const dateNow = new Date();


        e.component.columnOption("ClaPedido", "caption", "ClaPedido" );
        e.component.columnOption("ClaCliente", "caption", "ClaCliente" );
        e.component.columnOption("NombreUbicacion", "caption", "NombreUbicacion" );
        e.component.columnOption("Producto", "caption", "Producto" );
        e.component.columnOption("Motivo", "caption", "Motivo" );
        e.component.columnOption("SubMotivo", "caption", "SubMotivo" );
        e.component.columnOption("NombreGrupoEstadistico1", "caption", "NombreGrupoEstadistico1" );
        e.component.columnOption("NombreGrupoEstadistico2", "caption", "NombreGrupoEstadistico2" );
        e.component.columnOption("NombreGrupoEstadistico3", "caption", "NombreGrupoEstadistico3" );
        e.component.columnOption("NombrePlantaTE", "caption", "PlantaTE" );
        e.component.columnOption("UbicacionProduccion", "caption", "UbicacionProduccion" );


        e.component.beginUpdate(); 
        e.component.columnOption("Direccion", "visible", true);  
        e.component.columnOption("Subdireccion", "visible", true);  
        e.component.columnOption("ClaCliente", "visible", true);  
        e.component.columnOption("EsTraspaso", "visible", true);  
        e.component.columnOption("TipoTraspaso", "visible", true);  
        e.component.columnOption("ClaPedido", "visible", true);  
        e.component.columnOption("ClaUbicacionDestino", "visible", true);  
        e.component.columnOption("NomUbicacionDestino", "visible", true);  
        e.component.columnOption("FechaPedido", "visible", true);  
        e.component.columnOption("DiaPedido", "visible", true);  
        e.component.columnOption("MesPedido", "visible", true);  
        e.component.columnOption("AnioPedido", "visible", true);  
        e.component.columnOption("FechaDeseadaCliente", "visible", true);  
        e.component.columnOption("FechaEstimadaActual", "visible", true);  
        e.component.columnOption("DiaEstimadaActual", "visible", true);  
        e.component.columnOption("SemanaEstimadaActual", "visible", true);  
        e.component.columnOption("MesEstimadaActual", "visible", true);  
        e.component.columnOption("AnioEstimadaActual", "visible", true);  
        e.component.columnOption("Familia", "visible", true);  
        e.component.columnOption("SubFamilia", "visible", true);  
        e.component.columnOption("ClaveProducto", "visible", true);  
        e.component.columnOption("Producto", "visible", true);  
        e.component.columnOption("NombreGrupoEstadistico1", "visible", true);  
        e.component.columnOption("NombreGrupoEstadistico2", "visible", true);  
        e.component.columnOption("NombreGrupoEstadistico3", "visible", true);  
        e.component.columnOption("motivo", "visible", true);  
        e.component.columnOption("SubMotivo", "visible", true);  
        e.component.columnOption("NombrePlantaTE", "visible", true);  
        e.component.columnOption("FechaRecibe", "visible", true);  
        e.component.columnOption("CantidadPendienteSurtir", "visible", true);  
        e.component.columnOption("ToneladasSurtir", "visible", true);  
        e.component.columnOption("UbicacionProduccion", "visible", true);  
        e.component.columnOption("GrupoMaquina", "visible", true);  
        e.component.columnOption("CapacidadProduccion", "visible", true);  
        e.component.columnOption("NomClienteUnico", "visible", true);  
        e.component.columnOption("FechaEmision", "visible", true);
        e.component.columnOption("DiaEmision", "visible", true);  
        e.component.columnOption("SemanaEmision", "visible", true);  
        e.component.columnOption("MesEmision", "visible", true);  
        e.component.columnOption("AnioEmision", "visible", true);  
        e.component.columnOption("Vejez", "visible", true);  
        e.component.columnOption("Mayor15", "visible", true);  
        e.component.columnOption("Responsable", "visible", true);  

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            autoFilterEnabled: true,
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportePedidosSinFecha_'+dateNow.getDate()+'-'+(dateNow.getMonth()+1)+'-'+dateNow.getFullYear()+'.xlsx');
                });
        });
        
        e.cancel = true;
    }

    onExported(e){


        e.component.columnOption("ClaPedido", "caption", "Pedido" );
        e.component.columnOption("ClaCliente", "caption", "Cliente" );
        e.component.columnOption("NombreUbicacion", "caption", "Ubicación" );
        e.component.columnOption("Producto", "caption", "Producto" );
        e.component.columnOption("Motivo", "caption", "Motivo" );
        e.component.columnOption("SubMotivo", "caption", "SubMotivo" );
        e.component.columnOption("NombreGrupoEstadistico1", "caption", "Gpo Estadistico 1" );
        e.component.columnOption("NombreGrupoEstadistico2", "caption", "Gpo Estadistico 2" );
        e.component.columnOption("NombreGrupoEstadistico3", "caption", "Gpo Estadistico 3" );
        e.component.columnOption("NombrePlantaTE", "caption", "PlantaTE" );
        e.component.columnOption("UbicacionProduccion", "caption", "UbicacionProduccion" );


        e.component.columnOption("Direccion", "visible", false);  
        e.component.columnOption("SubDireccion", "visible", false);  
        e.component.columnOption("ClaCliente", "visible", false);  
        e.component.columnOption("EsTraspaso", "visible", false);  
        e.component.columnOption("TipoTraspaso", "visible", false);  
        e.component.columnOption("ClaPedido", "visible", false);  
        e.component.columnOption("ClaUbicacionDestino", "visible", false);  
        e.component.columnOption("NomUbicacionDestino", "visible", false);  
        e.component.columnOption("FechaPedido", "visible", false);  
        e.component.columnOption("DiaPedido", "visible", false);  
        e.component.columnOption("MesPedido", "visible", false);  
        e.component.columnOption("AnioPedido", "visible", false);  
        e.component.columnOption("FechaDeseadaCliente", "visible", false);  
        e.component.columnOption("FechaEstimadaActual", "visible", false);  
        e.component.columnOption("DiaEstimadaActual", "visible", false);  
        e.component.columnOption("SemanaEstimadaActual", "visible", false);  
        e.component.columnOption("MesEstimadaActual", "visible", false);  
        e.component.columnOption("AnioEstimadaActual", "visible", false);  
        e.component.columnOption("Familia", "visible", false);  
        e.component.columnOption("SubFamilia", "visible", false);  
        e.component.columnOption("ClaveProducto", "visible", false);  
        e.component.columnOption("producto", "visible", false);  
        e.component.columnOption("NombreGrupoEstadistico1", "visible", false);  
        e.component.columnOption("NombreGrupoEstadistico2", "visible", false);  
        e.component.columnOption("NombreGrupoEstadistico3", "visible", false);  
        e.component.columnOption("motivo", "visible", false);  
        e.component.columnOption("SubMotivo", "visible", false);  
        e.component.columnOption("NombrePlantaTE", "visible", false);  
        e.component.columnOption("FechaRecibe", "visible", false);  
        e.component.columnOption("CantidadPendienteSurtir", "visible", false);  
        e.component.columnOption("ToneladasSurtir", "visible", false);  
        e.component.columnOption("UbicacionProduccion", "visible", false);  
        e.component.columnOption("GrupoMaquina", "visible", false);  
        e.component.columnOption("CapacidadProduccion", "visible", false);  
        e.component.columnOption("NomClienteUnico", "visible", false);  
        e.component.columnOption("FechaEmision", "visible", false);
        e.component.columnOption("DiaEmision", "visible", false);  
        e.component.columnOption("SemanaEmision", "visible", false);  
        e.component.columnOption("MesEmision", "visible", false);  
        e.component.columnOption("AnioEmision", "visible", false);  
        e.component.columnOption("Vejez", "visible", false);  
        e.component.columnOption("Mayor15", "visible", false);  
        e.component.columnOption("Responsable", "visible", false);  
        
        e.component.endUpdate();
    }

    render() {
        return (
            <div className='content'>
                <DataGrid
                    id="gridProduccion"
                    dataSource={this.props.dataSource}
                    keyExpr="ClaPedido"
                    width="100%"
                    height={600}
                    selection={{ mode: 'single' }}
                    // columnWidth={100}
                    showBorders={false}
                    columnAutoWidth={true}
                    showColumnHeaders
                    showColumnLines={false}
                    showRowLines
                    noDataText="Sin Registros"
                    wordWrapEnabled
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    onExporting={this.onExporting}
                >
                    <FilterRow
                        visible={true}
                        applyFilter={true}
                    />
                    <HeaderFilter visible={true} width={"400"} /> 

                    <Column dataField="ClaPedido" caption="Pedido"/>
                    <Column dataField="NombreCliente" caption="NombreCliente"/>
                    <Column dataField="NombreUbicacion" caption="Ubicación"/>
                    <Column dataField="Producto" caption="Producto"/>
                    <Column dataField="Motivo" caption="Motivo"/>
                    <Column dataField="SubMotivo" caption="SubMotivo"/>
                    <Column dataField="NombreGrupoEstadistico1" caption="Gpo Estadistico 1"/>
                    <Column dataField="NombreGrupoEstadistico2" caption="Gpo Estadistico 2"/>
                    <Column dataField="NombreGrupoEstadistico3" caption="Gpo Estadistico 3"/>
                    <Column dataField="NombrePlantaTE" caption="PlantaTE"/>
                    <Column dataField="UbicacionProduccion" caption="Ubicación Elabora"/>



                    {/* <Column dataField="NombreUbicacion" caption="NombreUbicacion" visible={false}/> */}
                    <Column dataField="Direccion" caption="Direccion" visible={false}/>
                    <Column dataField="Subdireccion" caption="Subdireccion" visible={false}/>
                    <Column dataField="ClaCliente" caption="ClaCliente" visible={false}/>
                    <Column dataField="EsTraspaso" caption="EsTraspaso" visible={false}/>
                    <Column dataField="TipoTraspaso" caption="TipoTraspaso" visible={false}/>
                    {/* <Column dataField="ClaPedido" caption="ClaPedido" visible={false}/> */}
                    <Column dataField="ClaUbicacionDestino" caption="ClaUbicacionDestino" visible={false}/>
                    <Column dataField="NomUbicacionDestino" caption="NomUbicacionDestino" visible={false}/>
                    <Column dataField="FechaPedido" caption="FechaPedido" visible={false}/>
                    <Column dataField="DiaPedido" caption="DiaFechaPedido" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="SemanaPedido" caption="SemanaFechaPedido" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="MesPedido" caption="MesFechaPedido" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="AnioPedido" caption="AñoFechaPedido" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="FechaDeseadaCliente" caption="FechaDeseadaCliente" visible={false}
                        dataType={"date"} 
                        format={"dd/MM/yyyy"}
                    ></Column>
                    <Column dataField="FechaEstimadaActual" caption="FechaEstimadaActual" visible={false}/>
                    <Column dataField="DiaEstimadaActual" caption="DiaFechaEstimadaActual" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="SemanaEstimadaActual" caption="SemanaFechaEstimadaActual" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="MesEstimadaActual" caption="MesFechaEstimadaActual" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="AnioEstimadaActual" caption="AñoFechaEstimadaActual" cellRender={this.customizeText}  visible={false}/>
                    <Column dataField="Familia" caption="Familia"  visible={false}/>
                    <Column dataField="SubFamilia" caption="SubFamilia" visible={false}/>
                    <Column dataField="ClaveProducto" caption="ClaveProducto" visible={false}/>
                    {/* <Column dataField="producto" caption="Producto" visible={false}/> */}
                    {/* <Column dataField="NombreGrupoEstadistico1" caption="NombreGrupoEstadistico1" visible={false}/>
                    <Column dataField="NombreGrupoEstadistico2" caption="NombreGrupoEstadistico2" visible={false}/> */}
                    {/* <Column dataField="motivo" caption="Motivo" visible={false}/>
                    <Column dataField="SubMotivo" caption="SubMotivo" visible={false}/> */}
                    {/* <Column dataField="NombrePlantaTE" caption="PlantaTE" visible={false}/> */}
                    <Column dataField="FechaRecibe" caption="FechaRecibe" cellRender={this.customizeDateText} visible={false} />
                    <Column dataField="CantidadPendienteSurtir" caption="CantidadPendienteSurtir" visible={false}/>
                    <Column dataField="ToneladasSurtir" caption="ToneladasSurtir" visible={false}/>
                    {/* <Column dataField="UbicacionProduccion" caption="UbicacionProduccion" visible={false}/> */}
                    <Column dataField="GrupoMaquina" caption="GrupoMaquina" visible={false}/>
                    <Column dataField="CapacidadProduccion" caption="CapacidadProduccion" visible={false}/>
                    <Column dataField="NomClienteUnico" caption="ClaClienteUnico" visible={false}/>
                    <Column dataField="FechaEmision" caption="FechaEmisión" visible={false}/>
                    <Column dataField="DiaEmision" caption="DíaEmisión" visible={false}/>
                    <Column dataField="SemanaEmision" caption="SemanaEmisión" visible={false}/>
                    <Column dataField="MesEmision" caption="MesEmisión" visible={false}/>
                    <Column dataField="AnioEmision" caption="AñoEmisión" visible={false}/>
                    <Column dataField="Vejez" caption="VejesVSFechaPedido" visible={false}/>
                    <Column dataField="Mayor15" caption="Mayores a 15 días" visible={false}/>
                    <Column dataField="Responsable" caption="Responsable" visible={false}/>

                    <Summary calculateCustomSummary={this.countFechaEmbarque}>
                        <TotalItem column="ClaPedido" summaryType="count" displayFormat="Total: {0}" />
                    </Summary>
                    
                    <Paging
                        pageSize={30}
                        pageIndex={this.props.pageIndex}
                    />

                    <Scrolling columnRenderingMode="virtual" />
                    <Export enabled={true} allowExportSelectedData={false} />
                </DataGrid>
            </div>
        );
    }
}