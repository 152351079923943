/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Paging, Format } from 'devextreme-react/data-grid';
import { Button, Col, Row, Spinner } from 'reactstrap';
import CtrlComboBox from 'views/Controles/CtrlComboBox';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';
import ModalProduccionMyAPed from './ModalProduccionMyAPed';

import 'assets/css/popup.css';
import ModalProduccionMyADetPartidas from './ModalProduccionMyADetPartidas';
import { LoadIndicator } from 'devextreme-react';
import LoaderScreen from 'views/Controles/LoaderScreen';

export default class ProduccionGridDet extends React.Component {
  constructor(props) {
    super(props);

    const TimerTiempo = 2000;
    const TimerId = 0;
    this.gridRef = React.createRef();

    this.state = {
      data: [],
      enc: { NomAreaProduccion: null, gpo: null, direccion: null },
      partidas: [],
      claSegmentacion: this.props.paramService.claDireccion,
      datasegmentacion: [],
      openModal: false,
      openModal2: false,
      paramService: {},
      isProcessing: true,
    };

    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.onChangeCmbSegmentacion = this.onChangeCmbSegmentacion.bind(this);
    this.onTimer = this.onTimer.bind(this);
    this.onClickModal = this.onClickModal.bind(this);
    this.onClickModal2 = this.onClickModal2.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onClosePopup2 = this.onClosePopup2.bind(this);
  }

  getProduccionData = () => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Detalle`;
    console.log('url', urlApiService);

    const p = this.props.paramService;
    const params = {
      claUbicacion: p.claUbicacion,
      claArea: p.claArea,
      claGrupoMaquina: p.claGrupoMaquina,
      claDireccion: this.state.claSegmentacion,
      claPeriodo: p.claPeriodo,
      fechaCalculo: p.fechaCalculo,
      claPedido: p.claPedido,
    };

    callApi(urlApiService, 'POST', params, (result) => {
      this.setState({ data: result.detalle, enc: result.encabezado[0], partidas: result.partidas });
      this.TimerId = setInterval(this.onTimer, 2000);
    });
  };

  getProduccionDataPartidas = (ClaPeriodo, onResult) => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Detalle`;
    console.log('url partidas', urlApiService);

    const p = this.props.paramService;
    const params = {
      claUbicacion: p.claUbicacion,
      claArea: p.claArea,
      claGrupoMaquina: p.claGrupoMaquina,
      claDireccion: this.state.claSegmentacion,
      claPeriodo: ClaPeriodo ? ClaPeriodo : p.claPeriodo,
      fechaCalculo: p.fechaCalculo,
      claPedido: p.claPedido,
    };

    callApi(urlApiService, 'POST', params, (result) => {
      onResult(result);
    });
  };

  getSegmentacionData = () => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Segmentacion`;
    console.log('url', urlApiService);

    const p = this.props.paramService;
    const params = {
      claUbicacion: p.claUbicacion,
      claArea: p.claArea,
      claGrupoMaquina: p.claGrupoMaquina,
      fechaCalculo: p.fechaCalculo,
    };

    callApi(urlApiService, 'POST', params, (result) => {
      this.setState({ datasegmentacion: result[0], isProcessing: false });

      this.getProduccionData();
    });
  };
  onCellPrepared(e) {
    if (e.rowType == 'data' && e.data.TotalHrs === 0) {
      e.cellElement.style.color = 'red';
    }

    if (e.rowType == 'data' && e.columnIndex === 5 && e.data.DisponibleHrs === 0) {
      e.cellElement.style.color = 'red';
    }
  }

  onRowPrepared(e) {
    if (e.rowType == 'data' && e.data.ClaPeriodo === this.props.paramService.claPeriodo) {
      e.rowElement.classList.add('rowSelected');
    }
  }

  onChangeCmbSegmentacion(selectedItem) {
    this.setState({ claSegmentacion: selectedItem.value }, () => {
      this.getProduccionData();
    });
  }

  onClickModal() {
    const p = this.props.paramService;
    const params = {
      claPedido: p.claPedido,
      claUbicacion: p.claUbicacion,
      claArea: p.claArea,
      claGrupoMaquina: p.claGrupoMaquina,
      fechaCalculo: p.fechaCalculo,
      claDireccion: this.state.claSegmentacion,
    };

    this.setState({ openModal: true, paramService: params });
  }

  onClickModal2(ClaPeriodo) {
    const p = this.props.paramService;
    const params = {
      claPedido: p.claPedido,
      claUbicacion: p.claUbicacion,
      claArea: p.claArea,
      claGrupoMaquina: p.claGrupoMaquina,
      fechaCalculo: p.fechaCalculo,
      claDireccion: this.state.claSegmentacion,
      claPeriodo: ClaPeriodo,
    };

    this.getProduccionDataPartidas(ClaPeriodo, (result) => {
      this.setState({
        openModal2: true,
        paramService: params,
        data: result.detalle,
        enc: result.encabezado[0],
        partidas: result.partidas,
      });
    });
  }

  onClosePopup() {
    this.setState({ openModal: false });
  }
  onClosePopup2() {
    this.setState({ openModal2: false });
  }
  onTimer() {
    clearInterval(this.TimerId);

    if (this.gridRef.current === null) return;

    if (this.gridRef.current.instance === null) return;

    this.gridRef.current.instance.navigateToRow(this.props.paramService.claPeriodo);
  }

  cellVerDetalleReservadoPedido = (data) => {
    const link = (
      <div>
        <a
          title={data.data.ReservadoPedido}
          style={{
            color: 'blue',
            textAlign: 'center',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            this.onClickModal2(data.data.ClaPeriodo);
          }}
        >
          {data.data.ReservadoPedido}
        </a>
      </div>
    );

    return link;
  };

  componentDidMount() {
    //this.getProduccionData();
    this.getSegmentacionData();
  }

  render() {
    const gridContent = (
      <DataGrid
        id="gridPedidos"
        keyExpr="ClaPeriodo"
        ref={this.gridRef}
        dataSource={this.state.data}
        height={400}
        width="95%"
        selection={{ mode: 'single' }}
        showBorders={false}
        columnHidingEnabled
        columnAutoWidth={false}
        showColumnHeaders
        showColumnLines={false}
        showRowLines
        noDataText="Sin Registros"
        wordWrapEnabled
        rowAlternationEnabled={false}
        scrollByContent={true}
        onOptionChanged={this.handleOptionChange}
        onCellPrepared={this.onCellPrepared}
        onRowPrepared={this.onRowPrepared}
        className="connect-datagrid-default"
      >
        <Column dataField="ClaPeriodo" caption="Periodo" width={42} alignment="center" />
        <Column
          dataField="Porcentaje"
          caption="Seg."
          width={25}
          format="#0'%'"
          alignment="center"
        />
        <Column dataField="TotalHrs" caption="Total" width={30} format="#0.#" alignment="center" />
        <Column dataField="ReservadoHrs" caption="Reservado" width={45} alignment="center" />
        <Column
          dataField="ReservadoPedido"
          caption="Reservado Pedido"
          alignment="center"
          cellRender={this.cellVerDetalleReservadoPedido}
        />
        <Column dataField="DisponibleHrs" caption="Disp." width={35} alignment="center" />
        <Paging pageSize={60} />
      </DataGrid>
    );

    return (
      <div className="col-12">
        <LoaderScreen
          style={{ width: '511px', height: '511px', margin: '-20px', left: '0px' }}
          show={this.state.isProcessing}
        />
        <Row className="mb-0 pb-0 mt--3">
          <Col className="col-11 m-0 p-0">
            Area Producción: <b>{this.state.enc.NomAreaProduccion}</b>
          </Col>
          <Col className="col-1 m-0 p-0">
            <Button
              className="mb-0"
              style={{
                cursor: 'pointer',
                right: '80px',
                width: '120px',
                fontSize: '12px',
                backgroundColor: '#f6701b',
                color: 'white',
              }}
              onClick={() => {
                this.onClickModal();
              }}
            >
              Ver Detalle
            </Button>
          </Col>
          <Col className="col-12 m-0 p-0">
            Gpo Maquinas: <b>{this.state.enc.NomGrupoMaquina}</b>
          </Col>
          <Col className="col-2 m-0 p-0">Dirección:</Col>
          <Col className="col-10 m-0 p-0">
            <CtrlComboBox
              etiqueta="Segmentación"
              valueExpr="ClaDireccion"
              displayExpr="NomDireccion"
              onChange={this.onChangeCmbSegmentacion}
              data={this.state.datasegmentacion}
              value={this.state.claSegmentacion}
              tipo={2}
            />
          </Col>
        </Row>
        <Row className="mb-0 pb-0 mt--1">
          <Col className="col-12 m-0 p-0">{gridContent}</Col>
        </Row>
        <Row>
          <Col>
            <ModalProduccionMyAPed
              visible={this.state.openModal}
              onClosePopup={this.onClosePopup}
              paramService={this.state.paramService}
            ></ModalProduccionMyAPed>
          </Col>
          <Col>
            <ModalProduccionMyADetPartidas
              visible={this.state.openModal2}
              onClosePopup={this.onClosePopup2}
              paramService={this.state.paramService}
              partidas={this.state.partidas}
              encabezado={this.state.enc}
            ></ModalProduccionMyADetPartidas>
          </Col>
        </Row>
      </div>
    );
  }
}
