import React from "react";
import { Row, Col } from "reactstrap";

const TraceTraspasoTE = props => {
  const { data, idRenglon, claUbicacion } = props;

  const lista = data.map((item, index) => {
      const id = `TraspasoTeId-${index}`;
      return (
        <Row key={id}>
          <Col className="col-12">
            <Row>
              <Col>
                Traspaso:<b>{item.ClaPedidoTraspaso}</b>
              </Col>
              <Col>
                Estatus: <b>{item.Estatus}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                Fecha Embarque:<b>{item.Fecha}</b>
              </Col>
              <Col>
                Creado: <b>{item.FechaPedido}</b>
              </Col>
            </Row>
            <Row className="d-flex align-items-start">
              <Col>
                Destino:
              </Col>
              <Col>
                Origen: <b></b>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">&nbsp;</Col>
            </Row>
          </Col>
        </Row>
      );
    });

  return lista;
};


export default TraceTraspasoTE;