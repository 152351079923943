/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import ModalProduccionMyA from 'views/Produccion/ModalCapacidad/ModalProduccionMyA';
import FactorConversionModal from 'views/Produccion/FactorConversion/FactorConversionModal';

const TraceProduccionMyA = (props) => {
  const { data, idRenglon, claUbicacion } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverOpenFactor, setPopoverOpenFactor] = useState(false);
  const [paramService, setparamService] = useState({});

  if (data === undefined) return <></>;

  const listaProd = data.filter(
    (item) => item.IdRenglon === idRenglon && item.ClaUbicacion === claUbicacion
  );

  if (listaProd.length === 0) return <></>;

  const onClosePopup = () => {
    setPopoverOpen(false);
  };

  const onClosePopupFactor = () => {
    setPopoverOpenFactor(false);
  };

  const onClick = (
    claPedido,
    claUbicacion,
    claAreaProduccion,
    claGrupoMaquina,
    claDireccion,
    claPeriodo,
    fechaCalculo
  ) => {
    const params = {
      claPedido,
      claUbicacion,
      claArea: claAreaProduccion,
      claGrupoMaquina,
      claDireccion,
      claPeriodo,
      fechaCalculo,
    };

    setparamService(params);
    setPopoverOpen(true);
  };

  const onClickFactor = (
    claPedido,
    claUbicacion,
    claAreaProduccion,
    claGrupoMaquina,
    claProducto,
    claDireccion,
    claPeriodo,
    fechaCalculo
  ) => {
    const params = {
      claPedido,
      claUbicacion,
      claArea: claAreaProduccion,
      claGrupoMaquina,
      claProducto,
      claDireccion,
      claPeriodo,
      fechaCalculo,
    };

    setparamService(params);
    setPopoverOpenFactor(true);
  };

  const ubicacion = listaProd[0].NomUbicacion;
  const deudaPedido = listaProd[0].DeudaPedidoOriginal;
  const diasAdicionales = listaProd[0].DiasAdicionales;
  const deudaHrs = listaProd[0].DeudaOriginalHrs;
  const embarque = listaProd[0].FechaEmbarque;
  const nomDireccion = listaProd[0].NomDireccion;

  let tmpClaArticuloComp;
  let tmpClaAreaProduccion;
  let tmpNivelComposicion;

  const modalFactor =
    popoverOpenFactor === false ? (
      <></>
    ) : (
      <FactorConversionModal
        visible={popoverOpenFactor}
        onClosePopup={onClosePopupFactor}
        paramService={paramService}
      ></FactorConversionModal>
    );

  const lista = listaProd.map((item, index) => {
    const id = `ProduccionMyAId-${index}`;

    if (
      tmpClaArticuloComp === item.ClaArticuloComp &&
      tmpClaAreaProduccion === item.ClaAreaProduccion &&
      tmpNivelComposicion === item.NivelComposicion
    )
      return <></>;

    let direccionStyle = {};
    if (item.ClaDireccion === 99) {
      direccionStyle = {
        color: 'blue',
      };
    }

    const msgError =
      item.ClaTipoDist === 0 ? (
        <></>
      ) : (
        <Row>
          <Col className="col-12">
            <span className="fontAlert">{item.NomTipoDist}</span>
          </Col>
        </Row>
      );

    const result = (
      <Row key={id} className="mb--1" style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <Col className="col-12">
          {/* <Row>
              <Col className="col-12">
                <b>Direccion:{item.NomDireccion}</b>
              </Col>
            </Row> */}
          <Row>
            <Col className="col-12">
              <b>{item.Producto}</b>
            </Col>
          </Row>
          <Row className="mt--1">
            <Col className="col-4">
              <b style={direccionStyle}>Dirección:{item.NomDireccion}</b>
            </Col>
          </Row>
          <Row className="mt--1">
            <Col className="col-4">Area:{item.NomAreaProduccion}</Col>
            <Col className="col-4 p-0">Grupo:{item.NomGrupoMaquina}</Col>
            <Col
              className="col-3 p-0"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                onClickFactor(
                  item.ClaPedido,
                  item.ClaUbicacion,
                  item.ClaAreaProduccion,
                  item.ClaGrupoMaquina,
                  item.ClaArticuloComp,
                  item.ClaDireccion,
                  item.ClaPeriodo,
                  item.FechaCalculo
                );
              }}
            >
              Factor {item.FactorHrs}
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <TracePartidaXFecha
                data={listaProd}
                ClaArticuloComp={item.ClaArticuloComp}
                ClaAreaProduccion={item.ClaAreaProduccion}
                ClaGrupoMaquina={item.ClaGrupoMaquina}
                NivelComposicion={item.NivelComposicion}
                onClickEvt={onClick}
              ></TracePartidaXFecha>
            </Col>
          </Row>
          {msgError}
        </Col>
      </Row>
    );

    tmpClaArticuloComp = item.ClaArticuloComp;
    tmpClaAreaProduccion = item.ClaAreaProduccion;
    tmpNivelComposicion = item.NivelComposicion;

    return result;
  });

  return (
    <Row>
      <Col className="col-12">
        <Row>
          <Col>
            <b>{ubicacion}</b>
          </Col>
          <Col>
            Deuda: <b>{deudaPedido.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}</b>
          </Col>
          <Col>
            Horas: <b>{deudaHrs.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})}</b>
          </Col>
        </Row>
        <Row>
          <Col className="col-4">
            <b>Dias Adicionales:{diasAdicionales}</b>
          </Col>
          <Col className="col-4 p-0">
            <b>Produccion:{embarque}</b>
          </Col>
        </Row>
        <Row style={{ overflowY: 'scroll', position: 'relative', maxHeight: '500px' }}>
          <Col>{lista}</Col>
        </Row>
        <Row>
          <Col>
            <ModalProduccionMyA
              visible={popoverOpen}
              onClosePopup={onClosePopup}
              paramService={paramService}
              // eslint-disable-next-line react/jsx-closing-tag-location
            ></ModalProduccionMyA>
          </Col>
        </Row>
        <Row>
          <Col>{modalFactor}</Col>
        </Row>
      </Col>
    </Row>
  );
};

const TracePartidaXFecha = (props) => {
  const {
    data,
    ClaArticuloComp,
    ClaAreaProduccion,
    ClaGrupoMaquina,
    NivelComposicion,
    onClickEvt,
  } = props;

  if (data === undefined) return <></>;

  let lastClaGrupoMaquina = ClaGrupoMaquina;
  const listaProd = data.filter(
    (item) =>
      item.ClaArticuloComp === ClaArticuloComp &&
      item.ClaAreaProduccion === ClaAreaProduccion &&
      // && item.ClaGrupoMaquina === ClaGrupoMaquina
      item.NivelComposicion === NivelComposicion
  );

  if (listaProd.length === 0) return <></>;

  const result = listaProd.map((item, index) => {
    const id = `MyARen-${index}`;
    const estilo = item.DeudaPendiente > 0 ? 'fontAlert' : '';

    const gpomaq =
      item.ClaGrupoMaquina !== lastClaGrupoMaquina ? (
        <Row className="mt--1">
          <Col className="col-4"></Col>
          <Col className="col-5 p-0">Grupo:{item.NomGrupoMaquina}</Col>
          <Col className="col-3 p-0">Factor:{item.FactorHrs}</Col>
        </Row>
      ) : (
        <></>
      );
      
    const result0 = (
      <Row key={id} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <Col className="col-12 m-0">
          {gpomaq}
          <Row>
            <Col className="col-4">Composición: {item.PorcComposicion.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})} %</Col>
            <Col className="col-2 p-0">Hrs: {item.Deuda.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})} </Col>
            <Col className="col-3 p-0">Asignada:{item.DeudaAsignada.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})} </Col>
            <Col className="col-3 p-0">Pendiente:{item.DeudaPendiente.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})} </Col>
          </Row>
          <Row>
            <Col className="col-4"></Col>
            <Col className="col-2 p-0">Kgs: {item.DeudaInicialUnidades.toLocaleString(undefined, {minimumFractionDigits:2,maximumFractionDigits:2})} </Col>
            <Col className="col-3 p-0">Asignada:{item.DeudaAsignadaUnidades.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})}</Col>
            <Col className="col-3 p-0">
              <span className={estilo}>Pendiente:{item.DeudaPendienteUnidades.toLocaleString(undefined, {maximumFractionDigits:2})} </span>
            </Col>
          </Row>
          <Row>
            <Col
              className="col-4"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                onClickEvt(
                  item.ClaPedido,
                  item.ClaUbicacion,
                  item.ClaAreaProduccion,
                  item.ClaGrupoMaquina,
                  item.ClaDireccion,
                  item.ClaPeriodo,
                  item.FechaCalculo
                );
              }}
            >
              {' '}
              Periodo:{item.ClaPeriodo}
            </Col>
            <Col className="col-3 p-0">Fecha:{item.Fecha}</Col>
            <Col>Nivel Composición: {item.NivelComposicion} </Col>
          </Row>
        </Col>
      </Row>
    );

    lastClaGrupoMaquina = item.ClaGrupoMaquina;
    return result0;
  });

  return result;
};

export default TraceProduccionMyA;
