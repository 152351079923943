import React from "react";
import { Popup } from 'devextreme-react/popup';

import 'assets/css/popup.css';
import { Row, Col } from "reactstrap";
import ProduccionGridDetPartidas from "./ProduccionGridDetPartidas";

const ModalProduccionMyADetPartidas = props => {
  const { visible, onClosePopup, paramService,encabezado,partidas } = props;
  
  if(visible===false)
    return (<></>);

  const onContentReady = (args) => {
    // const html = args.component.content();  
    const html = args.component;  
    // $(html).css("padding", "0");
    // html.ElementAttr('padding', '0');
  }

  const titulo=`Capacidad Producción - (Partidas x Pedido)`;

  return (
    <Popup
      visible={visible}
      resizeEnabled={true}
      closeOnOutsideClick={false}
      onHiding={onClosePopup}
      onContentReady={onContentReady}
      showCloseButton={true}
      showTitle={true}
      title={titulo}
      container=".dx-viewport"
      width={500}
      height={580}
    >
      <Row>
        <ProduccionGridDetPartidas partidas={partidas} encabezado={encabezado} paramService={paramService}></ProduccionGridDetPartidas>
      </Row>
      
    </Popup>      
  );
};


export default ModalProduccionMyADetPartidas;