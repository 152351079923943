import React from 'react';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import PedidoMasterGrid from './PedidoMasterGrid';
import PedidoReleaseGrid from './PedidoReleaseGrid';
import PedidoReleaseDetGrid from './PedidoReleaseDetGrid';

export default class PedidoReleaseDetalle extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
        dataEnc: { ClaPedido: null, NomCliente: "", NomUbicacion: "", FechaCaptura: "", Direccion: "", SubDireccion: "", Zona: "", FechaDeseada: "", KilosSolicitados: "", KilosSurtidos: "" },
        data: [],
        dataDet: [],
        pageIndex: 0,
        claPedido: 0
      }

      this.onGridPage = this.onGridPage.bind(this);
      this.onRowReleaseClick = this.onRowReleaseClick.bind(this);
    }

    getPedido(claPedido){
      const params = {
        ClaPedido: claPedido,
      };

      const urlService = `${process.env.REACT_APP_URL_API}Pedido/DetalleRelease`;
      
      callApi(urlService, "POST", params, (res) => {
        const encabezado = { 
          ClaPedido: res[0][0].ClaPedido, 
          NomCliente: res[0][0].NomCliente, 
          NomUbicacion: res[0][0].NomUbicacion, 
          FechaCaptura: res[0][0].FechaCaptura, 
          Direccion: res[0][0].Direccion, 
          SubDireccion: res[0][0].SubDireccion, 
          FechaDeseada: res[0][0].FechaDeseada ,
          KilosSolicitados: res[0][0].KilosSolicitados,
          KilosSurtidos: res[0][0].KilosSurtidos,
          Zona: res[0][0].Zona
        }
        this.setState({ 
          data: res[1] ,
          dataEnc: encabezado,
          dataDet: res[2],
          claPedido
        });
      });
    }

    onRowReleaseClick(row){
      this.getPedido(row.data.ClaPedido);
    }

    onGridPage(pageIndex) {
      this.setState({ pageIndex });
    }

    componentDidMount(){
      this.getPedido(this.props.claPedido)
    }

    componentDidUpdate(){
    }

    render() {
        return (
          <>
            <Row>
              <Col md={{ size: 12 }}>
                <PedidoMasterGrid 
                  data={this.state.dataEnc}
                  onBack={this.props.onBack}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 12 }}>
                <PedidoReleaseGrid
                  data={this.state.data}
                  onPage={this.onGridPage} 
                  pageIndex={this.state.pageIndex}
                  onRowReleaseClick={this.onRowReleaseClick}
                  onClick={this.props.onClickPedido}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ size: 12 }}>
                <PedidoReleaseDetGrid 
                  data={this.state.dataDet}
                  claPedido={this.state.claPedido}
                />
              </Col>
            </Row>
          </>
        );
    }  
}