/*eslint-disable*/
import React from 'react';
import { Row, Col } from 'reactstrap';
import TraceProduccionMyA from './TraceProduccionMyA';
import TraceProduccionAceria from './TraceProduccionAceria';

const TraceProduccion = (props) => {
  const { data, idRenglon, claUbicacion } = props;
  console.log('dentro de trace produccion', props, props.showSegementacion);
  if (data === undefined) return <></>;

  return (
    <Row>
      <Col className="col-12">
        <TraceProduccionMyA
          data={props.data.mallasalambres}
          idRenglon={idRenglon}
          claUbicacion={claUbicacion}
        ></TraceProduccionMyA>

        <TraceProduccionAceria
          data={props.data.acerias}
          idRenglon={idRenglon}
          claUbicacion={claUbicacion}
          showSegementacion={props.showSegementacion}
        ></TraceProduccionAceria>
      </Col>
    </Row>
  );
};

export default TraceProduccion;
