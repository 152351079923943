/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export } from 'devextreme-react/data-grid';
import { config } from 'utils/config';
import { callApi, callKrakenApi } from 'utils/utils';
import ContratosMasterDetail from './ContratosMasterDetail';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';

export default class ProduccionGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;
        this.state = {
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);        
        this.onExporting= this.onExporting.bind(this);
    }

    getDetalle() {
        const urlService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaContrato`;

        callApi(urlService, 'POST', this.props.filtros, (response) => {
            this.Exporting(response[1])
        });
    }

    Exporting(detalle) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = this.dataGrid.instance;
        const masterRows = [];
        const items = [];
    
        worksheet.columns = [
          { width: 20 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }
        ];
        exportDataGrid({
            component: grid,
            worksheet,
            keepColumnWidths: false,
            topLeftCell: { row: 1, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'data') {
                // Valida que no se repita la llave
                if(items.indexOf(gridCell.data.idContrato)<0){
                    items.push(gridCell.data.idContrato);
                    masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
                }
            }

            // Formato a la celdas si es Group Row
            /* if (gridCell.rowType === 'group') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
            }
            */

            // Formato a la celda de Datos
            if (gridCell.rowType === 'data') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
                excelCell.font =  { bold: true };
            }

            // Formato a la celdas si es Total Footer Row
            if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                excelCell.font.italic = true;
            }
            }
    
        })
        .then((cellRange) => {



          // GENERAR UNA SECCION DE DETALLE
            const encabezados = [
                "Contrato",
                "Artículo",
                "Capacidad"
            ];
            const columns = [                
                "idContrato",
                "claArticulo",
                "capacidad"
            ];
            const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            const columnIndex = cellRange.from.column;

            const insertRow = function (index, offset, outlineLevel) {
                const currentIndex = index + offset;
                const row = worksheet.insertRow(currentIndex, [], 'n');

                for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
                    worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
                }

                row.outlineLevel = outlineLevel;
                return row;
            }

            let totalOffset = 0;

            for (let i = 0; i < masterRows.length; i++) {
                let offset = 0;
                offset = totalOffset;
                
                // ** Cambio: Llave encabezado
                // const { ClaArticulo } = masterRows[i].data;
                const { fechaCalculo, idContrato } = masterRows[i].data;
                // Agrega un Row con los Encabezados del Detalle...
                const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                console.log(row)
                encabezados.forEach((header, currentColumnIndex) => {
                    Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                    value: header,
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } },
                    font: { bold: true },
                    border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                    alignment: { horizontal: 'center' }
                    });
                });

                // ** Cambio : Filtro Detalle
                // const pedidoPartidas = detalle.filter((partida) => partida.ClaProductoOrig == ClaArticulo);
                const pedidoPartidas = detalle.filter((partida) => partida.idContrato == idContrato);
                pedidoPartidas.forEach((partida) => {
                    const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

                    columns.forEach((columnName, currentColumnIndex) => {
                    const alinear = currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';
                        // Imprime cada celda del detalle..
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                        value: partida[columnName],
                        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F9FE' } },
                        border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                        alignment: { horizontal: alinear }
                        });
                    });
                });
                offset--;
                totalOffset = offset;
            }
    
        })
        .then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Contratos.xlsx');
            });
        });
        grid.cancel = true;
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        this.getDetalle();
        e.cancel = true;
    }

    componentDidMount() {
    }

    render(){
        const gridContent = (
            <DataGrid
                id="gridContratos"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.data[0]}
                // width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={true}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                onExporting={this.onExporting}
            >                
                <MasterDetail 
                enabled={true} 
                component={ContratosMasterDetail} 
                />

                <Column dataField="fechaCalculo" caption="Fecha Calculo" alignment="center" 
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                    visible={false}
                    
                ></Column>
                <Column dataField="claCliente" caption="Cliente" />
                <Column dataField="claUbicacion" caption="Ubicacion" visible={false}/>
                <Column dataField="nomUbicacion" caption="Ubicacion" />
                <Column dataField="capacidad" caption="Capacidad" dataType="number" alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>
                <Column dataField="idContrato" caption="Contrato" />
                <Column dataField="idVersion" caption="Versión" />
                <Column dataField="periodo" caption="Periodo" />
                <Column dataField="fechaInicio" caption="Fecha Inicio" 
                dataType={"date"} 
                format={"dd/MM/yyyy"}
                />
                <Column dataField="fechaFin" caption="Fecha Fin" 
                dataType={"date"} 
                format={"dd/MM/yyyy"}
                />

                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        );

        return (
        <>
            {gridContent}
        </>
        );
    };
}