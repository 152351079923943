import React from 'react';
import { Column, DataGrid, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import { callApi, semaforoColor } from '../../../utils/utils';
import { config } from '../../../utils/config';
import 'assets/css/semaforo.css';

class GridArticulos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ArticulosData: null,
    };

    this.cellIconoRender = this.cellIconoRender.bind(this);
  }

  getArticulos() {
    const urlService = `${process.env.REACT_APP_URL_API}Semaforo/Detalle`;

    const params = {
      ClaPedido: this.props.ClaPedido,
    };

    callApi(urlService, 'POST', params, (response) => {
      this.setState({
        ArticulosData: response.lista,
      });
    });
  }

  cellIconoRender(data) {

    const iconoColor = semaforoColor(data.data.claSemaforo);

    const icono =
      (
        <i
          className={`mb-0 icon icon-shape text-white rounded-circle shadow ${iconoColor}`}
          title={data.data.nomSemaforoEsatstus}
          style={{ width: 15, height: 15, padding: "5px", cursor: "pointer" }}
        // onClick={this.descargaDirecta.bind(this, data.ClaTipoDocumento, idDocumento, tipoArchivo)}
        >

        </i>
      );

    return icono;
  }


  render() {
    const ancho = window.innerWidth;
    let adaptive = false;

    let rowNumWidth = '50px';
    if (ancho < 1024) {
      rowNumWidth = '40px';
      adaptive = true;
    }

    return (
      <DataGrid dataSource={this.state.ArticulosData} showBorders={true} className="connect-datagrid-default">
        <Paging defaultPageSize={50} />

        <Column dataField="claSemaforoEstatus" caption="" width={70} cellRender={this.cellIconoRender} />
        <Column dataField="idRenglon" caption="#" width="50px" />
        <Column dataField="producto" caption="Articulo" width="50%" />
        <Column dataField="unidad" caption="Unidad" alignment="center" />
        <Column dataField="deuda" caption="Pedida" alignment="center" />
        <Column dataField="surtido" caption="Surtido" alignment="center" />
        <Column dataField="fechaEmbarque" caption="Embarque" alignment="center" />
        <Column dataField="nomSemaforoEstatus" caption="Motivo" alignment="center" width="40%" />

        <Summary>
          <TotalItem column="producto" summaryType="count" />
        </Summary>
      </DataGrid>
    );
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.getArticulos();
    }
  }

  componentDidMount() {
    this.getArticulos();
  }
}

export default GridArticulos;
