/*eslint-disable*/
import React from 'react';
import DataGrid, { MasterDetail, Column, Paging } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class LoteMinimoGridDet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 0,
      dataDetalle: [],
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.getLoteMinimoData = this.getLoteMinimoData.bind(this);
    this.onGridPage = this.onGridPage.bind(this);
  }

  getLoteMinimoData() {
    const filtros = {
      claUbicacion: null,
      claFamilia: null,
      claSubFamilia: null,
      claProducto: null,
      claPedido: null,
      idLote: this.props.idLote,
      estatusLote: 0,
      tipoLote: 1,
    };

    const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/LoteMinimoBusqueda`;

    callApi(urlApiService, 'POST', filtros, (result) => {
      console.log(result);
      this.setState({
        dataDetalle: result[1],
      });
    });
  }

  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.onGridPage(e.value);
    }
  }

  componentDidMount() {
    this.getLoteMinimoData();
  }

  render() {
    const gridContent = (
      <div>
        <DataGrid
          dataSource={this.state.dataDetalle}
          height={600}
          width="100%"
          selection={{ mode: 'single' }}
          showBorders={false}
          columnHidingEnabled
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onOptionChanged={this.handleOptionChange}
        >
          <Column dataField="IdLote" caption="Lote"/>
          <Column dataField="ClaPedido" caption="Pedido"/>
          <Column dataField="IdRenglon" caption="Renglón"/>
          <Column dataField="CantidadDeuda" caption="Deuda"/>
          <Column dataField="Estatus" caption="Esatus de la Partida"/>
          <Column dataField="ClaUbicacion" caption="Ubicación" visible={false}/>
          <Column dataField="ClaUbicacionElabora" caption="Ubicación Elabora" visible={false}/>
          <Column dataField="NomUbicacionElabora" caption="Ubicación Elabora" />
          <Column dataField="NomUbicacion" caption="Ubicación"/>
          <Column dataField="ClaArticulo" caption="Artículo" visible={false}/>
          <Column dataField="NomArticulo" caption="Artículo" />

          <Paging pageSize={30} pageIndex={this.props.pageIndex} />
        </DataGrid>
      </div>
    );

    return <>{gridContent}</>;
  }
}
