/*eslint-disable*/
import React from 'react';
import AppContext from "../../../context/AppContext";
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { locale } from "devextreme/localization";
import ReportePedidosSinFechaGrid from './ReportePedidosSinFechaGrid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import Filtros from './Filtros/Filtros';

export default class ReportePedidosSinFecha extends React.Component {
    constructor(props) {
        super(props);
    
        const filtros = {
            claUbicacion: null,
            claUbicacionElabora: null,
            claPlantaTE: null, 
            fechaInicio: new Date(),
            fechaFin: new Date(),
            claMotivo: null,
            claGpoEstadistico: null,
            selectedTipoFecha: 0,
            claPedido: null
        }

        this.state = {
            showSpin: false,
            pageIndex: 0,
            data: [],
            filtros,
            fechaDisabled:true,
        };

        this.onGridPage = this.onGridPage.bind(this);
        this.getReporteData = this.getReporteData.bind(this);
        this.onChangeNumPedido = this.onChangeNumPedido.bind(this);
        this.onChangeCmbTipoFecha = this.onChangeCmbTipoFecha.bind(this);
        this.onChangeFechaInicio = this.onChangeFechaInicio.bind(this);
        this.onChangeFechaFin = this.onChangeFechaFin.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeCmbUbicacionElabora = this.onChangeCmbUbicacionElabora.bind(this);
        this.onChangeCmbPlantaTE = this.onChangeCmbPlantaTE.bind(this);
        this.onChangeCmbMotivo = this.onChangeCmbMotivo.bind(this);
        this.onChangeCmbGpoEstadistico1 = this.onChangeCmbGpoEstadistico1.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
        this.onBtnBuscar = this.onBtnBuscar.bind(this);
    }

    getReporteData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Pedido/ReportePedidosSinFecha`;

        this.setState({ showSpin: true });

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            this.setState({
                data: result,
                showSpin: false
            });
        });
    }

    onChangeNumPedido(selectedItem){
        const numPedido = selectedItem.value;
        let newFiltros = this.state.filtros;
        newFiltros.claPedido = numPedido;


        this.setState({
            filtros: newFiltros,
        })
    }

    onChangeCmbTipoFecha(selectedItem) {
        const tipoFecha = selectedItem.value;
        let newFiltros = this.state.filtros;
        newFiltros.selectedTipoFecha = tipoFecha;

        const disabled = tipoFecha === 0 ? true : false;

        this.setState({
            filtros: newFiltros,
            fechaDisabled: disabled
        })
    }

    onChangeFechaInicio(object) {
        const fechaInicio = object.value;
        let newFiltros = this.state.filtros;
        newFiltros.fechaInicio = fechaInicio;

        this.setState({
            filtros: newFiltros
        })
    }

    onChangeFechaFin(object) {
        const fechaFin = object.value;
        let newFiltros = this.state.filtros;
        newFiltros.fechaFin = fechaFin;
        
        this.setState({
            filtros: newFiltros
        })
    }

    onChangeCmbUbicacion(selectedItem) {
        const claUbicacion = selectedItem.value
        let newFiltros = this.state.filtros;
        newFiltros.claUbicacion = claUbicacion;

        this.setState({
            filtros: newFiltros
        })
    }

    onChangeCmbUbicacionElabora(selectedItem) {
        const claUbicacion = selectedItem.value
        let newFiltros = this.state.filtros;
        newFiltros.claUbicacionElabora = claUbicacion;

        this.setState({
            filtros: newFiltros
        })
    }

    onChangeCmbPlantaTE(selectedItem) {
        const claUbicacion = selectedItem.value
        let newFiltros = this.state.filtros;
        newFiltros.claPlantaTE = claUbicacion;

        this.setState({
            filtros: newFiltros
        })
    }

    onChangeCmbMotivo(selectedItem){
        const claMotvo = selectedItem.value
        let newFiltros = this.state.filtros;
        newFiltros.claMotivo = claMotvo;
        
        this.setState({
            filtros: newFiltros
        })
    }

    onChangeCmbGpoEstadistico1(selectedItem){
        const claGpoEstadistico1 = selectedItem.value
        let newFiltros = this.state.filtros;
        newFiltros.claGpoEstadistico = claGpoEstadistico1;

        this.setState({
            filtros: newFiltros
        })
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onBtnBuscar() {
        this.getReporteData();
    }

    onBtnLimpiar(){
        const filtros = {
            claUbicacion: null,
            claUbicacionElabora: null,
            claPlantaTE: null, 
            fechaInicio: new Date(),
            fechaFin: new Date(),
            claMotivo: null,
            claGpoEstadistico: null,
            selectedTipoFecha: 1
        }

        this.setState({ filtros });
    }

    componentDidMount(){
    }

    render() {
        return (
            <div className="content">
                <Row>                                    
                    <Col>                           
                        <Filtros 
                            filtros={this.state.filtros}
                            onChangeCmbTipoFecha={this.onChangeCmbTipoFecha}
                            onChangeFechaInicio={this.onChangeFechaInicio}
                            onChangeFechaFin={this.onChangeFechaFin}
                            onChangeCmbMotivo={this.onChangeCmbMotivo}
                            onChangeCmbGpoEstadistico1={this.onChangeCmbGpoEstadistico1}
                            onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                            onChangeCmbPlantaTE={this.onChangeCmbPlantaTE}
                            onChangeCmbUbicacionElabora={this.onChangeCmbUbicacionElabora}
                            onChangeNumPedido={this.onChangeNumPedido}
                            onBtnBuscar={this.onBtnBuscar}
                            onBtnLimpiar={this.onBtnLimpiar}
                            showSpin={this.state.showSpin}
                            fechaDisabled={this.state.fechaDisabled}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 12, offset: 0 }}>         
                        <ReportePedidosSinFechaGrid
                            dataSource={this.state.data}
                            onPage={this.onGridPage} 
                            pageIndex={this.state.pageIndex}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}