/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from 'views/Controles/CtrlComboBox';
import CtrlDatePicker from 'views/Controles/CtrlDatePicker';
import CtrlNumerico from 'views/Controles/CtrlNumerico';

export default class ContratosFiltros extends React.Component {
    constructor(props) {
        super(props);

        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    }

    onBtnBuscar() {
        this.props.getProduccionData();
    }

    onBtnLimpiar() {
        this.props.onLimpiar();
    }

    render(){
        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return(
            <div className="content"  style={{ width: "100%"}}>
                <Row>
                    <Col md={12} style={{ padding: 0, margin: 0 }}>
                        <Card>
                            <CardHeader>
                            <CardTitle>Contratos</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <CtrlDatePicker
                                                    etiqueta="Fecha Cálculo"
                                                    defaultValue={this.now}
                                                    onChange={this.props.onChangeFechaCalculo}
                                                    value={this.props.fechaCalculoValue}
                                                />
                                            </Col>    
                                            <Col>
                                                <CtrlComboBox
                                                    etiqueta="Ubicación"
                                                    onChange={this.props.onChangeCmbUbicacion}
                                                    data={this.props.ubicacionesData}
                                                    value={this.props.claUbicacion}
                                                    reference={this.props.refCmbUbicacion}
                                                />
                                            </Col>                           
                                            <Col>
                                                <CtrlNumerico 
                                                    etiqueta="Número Contrato"
                                                    onChange={this.props.onChangeIdContrato}
                                                    value={this.props.idContrato}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>                                              
                                            <Col md={{size:4}}>
                                                <CtrlComboBox
                                                    etiqueta="Periodo Inicio"
                                                    onChange={this.props.onChangeCmbPeriodoInicio}
                                                    data={this.props.periodosData}
                                                    value={this.props.periodoInicio}
                                                    reference={this.props.refCmbPeriodoInicio}
                                                />
                                            </Col>       
                                            
                                            <Col md={{size:4}}>
                                                <CtrlComboBox
                                                    etiqueta="Periodo Fin"
                                                    onChange={this.props.onChangeCmbPeriodoFin}
                                                    data={this.props.periodosData}
                                                    value={this.props.periodoFin}
                                                    reference={this.props.refCmbPeriodoFin}
                                                />
                                            </Col>    
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                                Buscar
                                            </Button>
                                            {spinner}
                                            <Button type="button" onClick={this.onBtnLimpiar}>
                                                Limpiar
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    };
}