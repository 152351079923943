/*eslint-disable*/
import React from 'react';

import { locale } from 'devextreme/localization';
import Tabs from 'devextreme-react/tabs';

import LoteMinimoMyA from './MyA/LoteMinimoMyA';
import LoteMinimoAP from './AceriasProducto/LoteMinimoAceriasProd';
import LoteMinimoAC from './AceriasCalibre/LoteMinimoAceriasCal';

export default class LoteMinimo extends React.Component {
  constructor(props) {
    super(props);

    locale('en');

    const vistas = [
      {
        id: 0,
        text: 'Lote Minimo Mallas y Alambres',
        content: 'LoteMinimoMyA',
      },
      {
        id: 1,
        text: 'Lote Minimo Acerias Producto',
        content: 'LoteMinimoAP',
      },
      {
        id: 2,
        text: 'Lote Minimo Acerias Calibre',
        content: 'LoteMinimoAC',
      },
    ];

    this.state = {
      filtroLoteMinimoMyAHidden: false,
      filtroLoteMinimoAceriaProdHidden: true,
      filtroLoteMinimoAceriaCalHidden: true,
      vistas,
      loteMinimoModo: 0,
      numPedido: null,
    };

    this.loteMinimoModoValueChanged = this.loteMinimoModoValueChanged.bind(this);
  }

  onChangeNumPedido(e) {
    var newFiltro = this.state.filtrosPedidos;
    newFiltro.claPedido = e.value;

    this.setState({
      filtrosPedidos: newFiltro,
    });
  }

  loteMinimoModoValueChanged = (e) => {
    if (e.name === 'selectedIndex') {
      if (e.value == 0) {
        this.setState({
          filtroLoteMinimoMyAHidden: false,
          filtroLoteMinimoAceriaProdHidden: true,
          filtroLoteMinimoAceriaCalHidden: true,
          loteMinimoModo: e.value,
        });
      } else if (e.value == 1) {
        this.setState({
          filtroLoteMinimoMyAHidden: true,
          filtroLoteMinimoAceriaProdHidden: false,
          filtroLoteMinimoAceriaCalHidden: true,
          loteMinimoModo: e.value,
        });
      } else if (e.value == 2) {
        this.setState({
          filtroLoteMinimoMyAHidden: true,
          filtroLoteMinimoAceriaProdHidden: true,
          filtroLoteMinimoAceriaCalHidden: false,
          loteMinimoModo: e.value,
        });
      }
    }
  };

  componentDidMount() {}

  render() {
    let filtro = <></>;
    let grid = <></>;
    let content = null;

    // if (this.state.filtroLoteMinimoMyAHidden === false) {
      content = <LoteMinimoMyA />;
    // }

    // if (this.state.filtroLoteMinimoAceriaProdHidden === false) {
    //   content = <LoteMinimoAP />;
    // }

    // if (this.state.filtroLoteMinimoAceriaCalHidden === false) {
    //   content = <LoteMinimoAC />;
    // }

    return (
      <>
        {/* <Tabs
          dataSource={this.state.vistas}
          selectedIndex={this.state.loteMinimoModo}
          onOptionChanged={this.loteMinimoModoValueChanged}
        /> */}
        <div className="content">{content}</div>
      </>
    );
  }
}
