/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column, Format, Paging, GroupPanel, Scrolling } from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';

export default class ContratosTabProductos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            dataDetalle:[]
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getProduccionData = this.getProduccionData.bind(this);
        this.onCellRenderIcono= this.onCellRenderIcono.bind(this);

        this.getProduccionData();
    }

    getProduccionData() {
        const filtrosPedidos = {            
            fechaCalculo: this.props.fechaCalculo,
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claPedido: this.props.claPedido,
            modoVista: 6
        };

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;

        callApi(urlApiService, 'POST', filtrosPedidos, (result) => {
            this.setState({
                dataDetalle: result[0]
            });
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.onGridPage(e.value);
        }
    }

    onCellRenderIcono(data) {
    
        const claSemaforo=data.data.estatus;
        const iconoColor =  
        data.data.claSemaforo === 0 ? "semaforoGris" :
        claSemaforo === 1 ? "bg-success" :
        claSemaforo === 2 ? "semaforoAmarillo" :
        claSemaforo === 3 ? "bg-danger" : "";

        const icono =(
            <i
                className={`mb-0 icon icon-shape text-white rounded-circle shadow ${iconoColor}`}
                title={data.data.nomSemaforoEstatus}
                style={{ width: 20, height: 20, padding: "5px", cursor: "pointer" }}
            >
            </i>
        );

    return icono;
    }
    
    render() {
        const gridContent = (
            <div>
                <DataGrid
                dataSource={this.state.dataDetalle}
                height={600}
                width="100%"
                selection={{ mode: 'single' }}
                noDataText="Sin Registros"
                onOptionChanged={this.handleOptionChange}
                >
                <Column dataField="idContrato" caption="Id Contrato" width={50}/>
                <Column dataField="claveArticulo" caption="Clave" width={50}/>
                <Column dataField="nomArticulo" caption="Producto Composición" width={200} />
                <Column dataField="claPeriodoIni" caption="Inicio" width={40} />
                <Column dataField="claPeriodoFin" caption="Fin" width={40} />
                <Column dataField="claPeriodoMax" caption="Maximo" width={40} />
                <Column dataField="estatus" caption="Estatus" width={40}  />
                <Column dataField="estatus" caption="Estatus" width={40} cellRender={this.onCellRenderIcono} />
                 
                <Paging
                    enabled={false} 
                    pageSize={400}
                    pageIndex={this.props.pageIndex}
                />
                <Scrolling columnRenderingMode="virtual" />
                </DataGrid>
            </div>
        );

        return (
            <>
            {gridContent}
            </>
        );
    }
}