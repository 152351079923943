/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class ProduccionGrid extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            pageIndex: 0,
            dataDetalle:[],
            showSpin: false
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getProduccionData = this.getProduccionData.bind(this);
        this.onGridPage = this.onGridPage.bind(this);

    }

    getProduccionData() {
        const filtros = {            
            fechaCalculo: this.props.data.fechaCalculo,
            claUbicacion: this.props.data.claUbicacion,
            periodoInicio: this.props.data.periodoInicio,
            periodoFin: this.props.data.periodoFin,
            idContrato: this.props.data.idContrato
        };
        this.setState({ showSpin: true });
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaContrato`;
        
        callApi(urlApiService, 'POST', filtros, (result) => {
            
            this.setState({
                dataDetalle: result[1],
                showSpin: false
            })
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.onGridPage(e.value);
        }
    }

    componentDidMount(){
        this.getProduccionData();
    }

    render() {
        const spinner =
        this.state.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        const gridContent = (
            <div>
                <DataGrid
                dataSource={this.state.dataDetalle}
                height={600}
                width={"100%"}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                >
                
                <FilterRow
                    visible={true}
                    applyFilter={true}
                />

                <HeaderFilter visible={true}  width={"400"}/> 

                <Column dataField="idContrato" caption="Contrato" width={100} visible={false}/>
                <Column dataField="claArticulo" caption="Artículo" width={100}/>
                <Column dataField="periodo" caption="Periodo" width={100} alignment={"left"}/>
                <Column dataField="capacidad" caption="Capacidad" width={100} dataType="number" alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>

                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                </DataGrid>
            </div>
        );

        return (
            <>
                <Row>
                    <Col>
                        {spinner}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {gridContent}
                    </Col>
                </Row>
            </>
        );
    }
}