/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Paging, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';

import { config } from 'utils/config';
import { callApi, callKrakenApi } from 'utils/utils';

export default class FaltantesGridDet extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            pageIndex: 0,
            dataDetalle:[]
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getProduccionData = this.getProduccionData.bind(this);
        this.onGridPage = this.onGridPage.bind(this);
        this.customizeText = this.customizeText.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);

        this.getProduccionData();
    }

    getProduccionData() {

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/FaltantesDet`;
        callApi(urlApiService, 'POST', this.props.paramService, (result) => {
            this.setState({
                dataDetalle: result[0]
            })
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.onGridPage(e.value);
        }
    }

    customizeText(cellElement){
      return cellElement.rowIndex+1;
    }

    onCellPrepared(e){
      if (e.rowType == 'data' && e.data.Nivel===6 && e.data.NomAreaProduccion===null){
        e.cellElement.style.color ="red"
      }
    }

    render() {
        const gridContent = (
          <DataGrid
            dataSource={this.state.dataDetalle}
            height={500}
            width="99%"
            selection={{ mode: 'single' }}
            showBorders={false}
            columnHidingEnabled
            columnAutoWidth={false}
            showColumnHeaders
            showColumnLines={false}
            showRowLines
            noDataText="Sin Registros"
            wordWrapEnabled
            rowAlternationEnabled={false}
            onCellPrepared={this.onCellPrepared} 
            >
            
            <Column dataField="Preferencia" caption="#" width={30} cellRender={this.customizeText} />
            <Column dataField="NomProducto" caption="Componente" width={150} />
            <Column dataField="PorcComposicion" caption="Comp" width={50} format="#0.##'%'"/>
            <Column dataField="Nivel" caption="Niv" width={40}/>
            <Column dataField="NomAreaProduccion" caption="Area" width={100} alignment="center"/>
            <Column dataField="NomGrupoMaquina" caption="Grupo" width={100} alignment="center"/>
            <Column dataField="Preferencia" caption="Pref." width={10} alignment="center"/>
            
            <Paging pageSize={60}/>
            <Summary>
             <TotalItem column="NomProducto" summaryType="count" displayFormat="Total: {0}" />
            </Summary>
         </DataGrid>
        );

        return (
          <Col className='col-12 mt--3 p-0'>
            {gridContent}
          </Col>
        );
    }
}