/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from '../../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../../Controles/CtrlDatePicker';
import CtrlAutoComplete from '../../../Controles/CtrlAutoComplete';

export default class ContratosFiltro extends React.Component {
    constructor(props) {
        super(props);

        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    }

    onBtnBuscar() {
        this.props.getProduccionPedidosData();
    }

    onBtnLimpiar() {
        this.props.onLimpiar();
    }

    render() {
        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return (        
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <CtrlDatePicker
                                    etiqueta="Fecha Cálculo"
                                    defaultValue={this.now}
                                    onChange={this.props.onChangeFechaCalculo}
                                    value={this.props.fechaCalculoValue}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Periodo Inicio"
                                    onChange={this.props.onChangeCmbPeriodoInicio}
                                    data={this.props.periodosData}
                                    value={this.props.periodoInicio}
                                    reference={this.props.refCmbPeriodoInicio}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Periodo Fin"
                                    onChange={this.props.onChangeCmbPeriodoFin}
                                    data={this.props.periodosData}
                                    value={this.props.periodoFin}
                                    reference={this.props.refCmbPeriodoFin}
                                />
                            </Col>
                            <Col md={6}>
                                <CtrlComboBox
                                    etiqueta="Dirección"
                                    onChange={this.props.onChangeCmbDireccion}
                                    data={this.props.direccionesData}
                                    value={this.props.direccion}
                                    reference={this.props.refCmbDireccion}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Ubicacion"
                                    onChange={this.props.onChangeCmbUbicacion}
                                    data={this.props.ubicacionesData}
                                    value={this.props.claUbicacion}
                                    reference={this.props.refCmbUbicacion}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="SubDirección"
                                    onChange={this.props.onChangeCmbSubDireccion}
                                    data={this.props.subDireccionesData}
                                    value={this.props.subDireccion}
                                    reference={this.props.refCmbSubDireccion}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Área"
                                    onChange={this.props.onChangeCmbAreas}
                                    data={this.props.areasData}
                                    value={this.props.claArea}
                                    reference={this.props.refCmbAreas}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Tipo Producto"
                                    onChange={this.props.onChangeCmbTipoProducto}
                                    data={this.props.tipoProductoData}
                                    value={this.props.tipoProducto}
                                    reference={this.props.refCmbTipoProducto}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Grupo Máquina"
                                    onChange={this.props.onChangeCmbGpoMaquinas}
                                    data={this.props.gpoMaquinasData}
                                    value={this.props.claGrupoMaquina}
                                    reference={this.props.refCmbGpoMaquinas}
                                />
                            </Col>
                            <Col>
                                {/* <CtrlComboBox
                                    etiqueta="Producto"
                                    onChange={this.props.onChangeCmbProducto}
                                    data={this.props.productoData}
                                    value={this.props.producto}
                                    reference={this.props.refCmbProducto}
                                /> */}
                                <CtrlAutoComplete
                                    etiqueta="Producto"
                                    placeholder="Clave o Nombre del producto..."
                                    valueExpr="Nombre"
                                    displayExpr="Nombre"
                                    keyValue="Clave"
                                    claFamilia={undefined}
                                    claSubfamilia={undefined}
                                    claProducto={25}
                                    idEntidad={3}
                                    onChange={this.props.onProductoSelected}
                                    value={this.props.producto}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                Buscar
                            </Button>
                            {spinner}
                            <Button type="button" onClick={this.onBtnLimpiar}>
                                Limpiar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}