/*eslint-disable*/
import React from 'react';
import { Popup } from 'devextreme-react/popup';
import DataGrid, { Column, Paging, Lookup, RowDragging, Scrolling, FilterRow, Editing, Form, Export, HeaderFilter  } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import {  Card, CardHeader, CardBody, Row, Col, CardTitle, FormGroup } from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { Button } from 'devextreme-react/button';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';

export default class Template4Grid extends React.Component {
    constructor(props) {
        super(props);
        
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
          },
          {
            key: 'onClick',
            name: 'On Button Click'
        }];

        this.state = {
            showSpin: false,
            pageIndex: 0,            
            currentFilter: this.applyFilterTypes[0].key,
            ubicacionesData: [],
            popupVisible: false,
            deletingData: {},
        }

        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onGridPage = this.onGridPage.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.validateRemove = this.validateRemove.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.hidePopUp = this.hidePopUp.bind(this);
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onAdd(e) {
        this.props.addConfiguracion(e.itemData);
    }

    hidePopUp() {
        this.setState({
          popupVisible: false,
        });
    }

    validateRemove(e){
        if(e.data.TieneLoteEnCuarentenaAbierto == 1 || e.data.TieneLoteEnCuarentenaAbierto == 3)
        {
            this.setState({
                deletingData: e,
                popupVisible: true,
            });

            e.cancel = true;
        }
        else{
            this.props.validateRemove(e);
        }
    }

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Configuración.xlsx');
                });
        });
        e.cancel = true;
    }
    
    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    btnGuardarHandleSubmit(){
        this.props.validateRemove(this.state.deletingData);
        this.setState({
            deletingData: {},
            popupVisible: false,
        });
    }

    btnCancelarHandle(){
        this.setState({
            deletingData: {},
            popupVisible: false,
        });
    }

    componentDidMount(){
        this.getUbicaciones();
    }

    componentDidUpdate(){

    }

    render(){

        const rowData = this.state.deletingData.data;
        const idLote = rowData == undefined ? "" : rowData.IdLote
        const estatusLote = rowData == undefined ? "" : rowData.Descripcion;

        const gridContent = (
            <>
                <DataGrid
                    id="gridConfiguracion"
                    ref={(ref) => this.dataGrid = ref}
                    dataSource={this.props.dataSource}
                    key={'IdCfgConfiguracionGeneral'}
                    width="100%"
                    height={600}
                    selection={{ mode: 'single' }}
                    showBorders={false}
                    columnHidingEnabled
                    columnAutoWidth={false}
                    showColumnHeaders
                    showColumnLines={false}
                    showRowLines
                    noDataText="Sin Registros"
                    wordWrapEnabled
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    remoteOperations={true}
                    repaintChangesOnly={true}
                    onRowRemoving={this.validateRemove}   
                    onExporting={this.onExporting}   
                >
                    <FilterRow visible={true} 
                        applyFilter={this.state.currentFilter}
                    />
                    <HeaderFilter visible={true} width={"400"} /> 

                    <Editing
                        mode="popup"
                        allowDeleting={true}
                        allowAdding={false}
                    >
                    </Editing>
                    <Column dataField="IdCfgConfiguracionGeneral" caption="IdCfgConfiguracionGeneral" width={100} visible={false}/>
                    <Column dataField="IdConfiguracion" caption="IdConfiguracion" width={150} visible={false}/>
                    <Column dataField="NomUbicacion" caption="Ubicación" width={100} visible={true} />
                    <Column dataField="NomProducto" caption="Producto" width={150} visible={this.props.idConfiguracion !== 338} alignment={"left"}/>
                    <Column dataField="NomCalibre" caption="Calibre" width={150} visible={this.props.idConfiguracion === 338} alignment={"left"}/>
                    <Column dataField="Lote" caption="Lote Mínimo" width={70} visible={true} alignment={"left"}/>
                    <Column dataField="Descripcion" caption="Estatus" width={100} visible={true} />
                    <Column dataField="TieneLoteEnCuarentenaAbierto" caption="cuarentenaAbierto" visible={false} />
                    <Column dataField="IdLote" caption="idlote" visible={false} />
                    <Paging
                        pageSize={30}
                        pageIndex={this.props.pageIndex}
                    />
                    
                    <Export enabled={true} allowExportSelectedData={false} />
                </DataGrid>
                <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    width={470}
                    height={300}
                    >
                    <Row>
                        <Col>
                            <div>Esta configuración tiene el lote: <b>{idLote}</b> </div>
                            <div>con el estatus <b>{estatusLote}</b> ¿desea eliminarlo?</div>
                            <div>Al eliminar la configuración se cierra el lote. Y los pedidos serán recalculados.</div>
                        </Col>
                    </Row>
                    <FormGroup className="text-right">
                        <Button
                            id="btnUsersGuardar"
                            text="Si"
                            onClick={this.btnGuardarHandleSubmit}
                            className="btn btn-primary btn-round"
                        />
                        <Button
                            id="btnUsersCancelar"
                            text="No"
                            onClick={this.btnCancelarHandle}
                            className="btn btn-primary btn-round"
                        />
                    </FormGroup>
                    {/* <Row>
                        <Col>
                            <Button
                                id="btnUsersGuardar"
                                text="Si"
                                onClick={this.btnGuardarHandleSubmit}
                                className="btn btn-primary btn-round"
                            />
                        </Col>
                        <Col>
                            <Button
                                id="btnUsersCancelar"
                                text="No"
                                onClick={this.btnCancelarHandle}
                                className="btn btn-primary btn-round"
                            />
                        </Col>
                    </Row> */}
                </Popup>
            </>
        );

        return(
            <div className="content mb-5">
                {gridContent}
            </div>
        )
    }
}