/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';
import CtrlNumerico from '../../Controles/CtrlNumerico';

export default class CapacidadFiltros extends React.Component {
    constructor(props) {
        super(props);
        
        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    }
    
    onBtnBuscar() {
        this.props.getEmbarques();
    }

    onBtnLimpiar() {
        this.props.onLimpiar();
    }
    
    componentDidMount() { 
    }
    
    render() {
        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return (        
            <div className="content">
                <Row>
                    <Col md={12} style={{ padding: 0, margin: 0 }}>
                        <Card>
                            <CardHeader>
                            <CardTitle>Saturación de Embarque</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <CtrlDatePicker
                                                    etiqueta="Fecha Embarque"
                                                    defaultValue={this.now}
                                                    onChange={this.props.onChangeFechaEmbarque}
                                                    value={this.props.filtros.fechaEmbarque}
                                                />
                                            </Col>
                                            <Col>
                                                <CtrlComboBox
                                                    etiqueta="Ubicacion"
                                                    onChange={this.props.onChangeCmbUbicacion}
                                                    data={this.props.ubicacionesData}
                                                    value={this.props.filtros.claUbicacion}
                                                    reference={this.props.refCmbUbicacion}
                                                    isRequired={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={{offset:7}}>
                                                <div style={{"visibility": this.props.requiredMessage, "color": "red"}}>
                                                    ¡Ubicación Requerida!
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                                Buscar
                                            </Button>
                                            {spinner}
                                            <Button type="button" onClick={this.onBtnLimpiar}>
                                                Limpiar
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>          
            </div>
        );
    }
}