/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from '../../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../../Controles/CtrlDatePicker';
import ModalProduccionMyA from '../../ModalCapacidad/ModalProduccionMyA';

export default class CapacidadFiltros extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showModal:false,
            paramService:{ 
                claUbicacion:null
                ,claArea:null
                ,claGrupoMaquina:null
                ,claDireccion: null
                ,claPeriodo: null
                ,fechaCalculo:new Date()
            },
            alertaToggle: "hidden",
        };

        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
        this.onBtnVerPedidos = this.onBtnVerPedidos.bind(this);
        this.onClosePopup = this.onClosePopup.bind(this);
    }

    onBtnBuscar() {
        this.props.getProduccionData();
    }

    onBtnLimpiar() {
        this.props.onLimpiar();
    }

    onClosePopup(){
        this.setState({ showModal:false });
    }

    onBtnVerPedidos(){

        if(
            this.props.filtros.claUbicacion === null 
            || this.props.filtros.claArea === null 
            || this.props.filtros.claGrupoMaquina === null
            || this.props.filtros.fechaCalculo == null
        ){
            this.setState({
                alertaToggle:  "visible"
            })
        }
        else{
            this.setState({ 
                showModal:true,
                paramService:{ 
                    claUbicacion:this.props.filtros.claUbicacion
                    ,claArea:this.props.filtros.claArea
                    ,claGrupoMaquina:this.props.filtros.claGrupoMaquina
                    ,claDireccion: null
                    ,fechaCalculo:this.props.filtros.fechaCalculo
                },                
                alertaToggle:  "hidden"
            });
        }

    }

    componentDidMount() { 
    }

    render() {
        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return (        
            <>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <CtrlDatePicker
                                    etiqueta="Fecha Cálculo"
                                    defaultValue={this.now}
                                    onChange={this.props.onChangeFechaCalculo}
                                    value={this.props.fechaCalculoValue}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Ubicacion"
                                    onChange={this.props.onChangeCmbUbicacion}
                                    data={this.props.ubicacionesData}
                                    value={this.props.claUbicacion}
                                    reference={this.props.refCmbUbicacion}
                                />
                            </Col>        
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Periodo Inicio"
                                    onChange={this.props.onChangeCmbPeriodoInicio}
                                    data={this.props.periodosData}
                                    value={this.props.periodoInicio}
                                    reference={this.props.refCmbPeriodoInicio}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Periodo Fin"
                                    onChange={this.props.onChangeCmbPeriodoFin}
                                    data={this.props.periodosData}
                                    value={this.props.periodoFin}
                                    reference={this.props.refCmbPeriodoFin}
                                />
                            </Col>                                                
                        </Row>
                        <Row>
                            <Col md={6}>
                                <CtrlComboBox
                                    etiqueta="Área"
                                    onChange={this.props.onChangeCmbAreas}
                                    data={this.props.areasData}
                                    value={this.props.claArea}
                                    reference={this.props.refCmbAreas}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Grupo Máquina"
                                    onChange={this.props.onChangeCmbGpoMaquinas}
                                    data={this.props.gpoMaquinasData}
                                    value={this.props.claGrupoMaquina}
                                    reference={this.props.refCmbGpoMaquinas}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                Buscar
                            </Button>
                            {spinner}
                            <Button type="button" onClick={this.onBtnLimpiar}>
                                Limpiar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}