import React from 'react';
import { Card, CardBody, Row, Col, CardHeader, CardTitle } from 'reactstrap';
import CtrlComboBox from '../Controles/CtrlComboBox';
import CtrlAutoComplete from '../Controles/CtrlAutoComplete';
import { callApi, callKrakenApi } from '../../utils/utils';
import CtrlDatePicker from '../Controles/CtrlDatePicker';
import CtrlCheckbox from '../Controles/CtrlCheckbox';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';

export default class JeimmyPrueba extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getUbicaciones() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 231, params, 1, (res) => {
    this.setState({ data: res.Result0 });
    });
  }

  componentDidMount() {
    this.getUbicaciones();
  }


  render() {

    return (
      <>
        <Row>
          <Col className="m-1"> 
            <Card className="m-1">
              <CardHeader>
                <CardTitle>Pedidos</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Direccion"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Familia"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Filtrar por Fecha"
                      data={this.state.data}
                    />
                  </Col>

                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Subdirección"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Subfamilia"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <Row>
                      <Col>
                        <CtrlDatePicker
                          etiqueta="Inicio"
                          defaultValue={this.now}
                          onChange={this.props.onChangeFechaFin}
                          value={this.props.fechaFin}
                        />
                      </Col>

                      <Col>
                        <CtrlDatePicker
                          etiqueta="Fin"
                          defaultValue={this.now}
                          onChange={this.props.onChangeFechaFin}
                          value={this.props.fechaFin}
                        />
                      </Col>
                    </Row>
                  </Col>

                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Zona"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Producto"
                      data={this.state.data}
                    />
                  </Col>

                  <Col>
                    <CtrlCheckbox
                      etiqueta="Pendientes de Fecha Promesa de Embarque"
                      data={this.state.data}
                    />
                  </Col>

                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Agente"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <CtrlAutoComplete
                      etiqueta="Cliente"
                      placeholder="Clave o Nombre del cliente..."
                      valueExpr="Nombre"
                      displayExpr="Nombre"
                    />
                  </Col>

                  <Col>
                    <CtrlCheckbox
                      etiqueta="Cancelados/Surtidos"
                      data={this.state.data}
                    />
                  </Col>

                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Planta Asignada"
                      data={this.state.data}
                    />
                  </Col>

                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Número Pedido"
                      data={this.state.data}
                    />
                  </Col>

                  <Col>
                    <CtrlCheckbox
                      etiqueta="Con Pedidos Vencidos"
                      data={this.state.data}
                    />
                  </Col>

                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        
        <Row>
          <Col>
            <DataGrid
              id="gridPedidos"
              dataSource={this.props.data}
              keyExpr="claPedido"
              height={600}
              selection={{ mode: 'single' }}
              showBorders={false}
              columnHidingEnabled
              columnAutoWidth={false}
              showColumnHeaders
              showColumnLines={false}
              showRowLines
              noDataText="Sin Registros"
              wordWrapEnabled
              rowAlternationEnabled={false}
              onContentReady={this.onContentReady}
              onOptionChanged={this.handleOptionChange}
            >
              <Column dataField="claPedido" caption="Pedido" width={100} alignment="center" cellRender={this.cellDocumentoRender} />
              <Column dataField="estatus" caption="Estatus" width={150} />
              <Column dataField="nomCliente" caption="Cliente" width={300} />
              <Column dataField="nomUbicacion" caption="Planta Asignada" width={200} />
              <Column dataField="fechaCaptura" caption="Fecha Pedido" width={150} />
              <Column dataField="fechaEstimadaEmbarque" caption="Embarque" width={130} />
              <Column dataField="fechaEmbarqueAG" caption="Embarque AG" width={130} />
              <Column dataField="fechaEstimadaEntrega" caption="Entrega" width={130} />
              <Column dataField="fechaCreacion" caption="Creacion" width={180} />
              <Paging
                pageSize={30}
                pageIndex={this.props.pageIndex}
              />
            </DataGrid>
          </Col>
        </Row>
      </>
    );
  }
}
