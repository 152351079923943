import React from 'react';

import DataGrid, { MasterDetail, Column, Paging, Export, FilterRow, HeaderFilter, Summary, TotalItem } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

import GridTabs from './GridTabs';
import { config } from '../../../utils/config';
import { callApi, semaforoColor } from 'utils/utils';
import 'assets/css/semaforo.css';

class GridPedidos extends React.Component {
  constructor(props) {
    super(props);

    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately'
    },
    {
      key: 'onClick',
      name: 'On Button Click'
    }];

    this.state = {
      showFilterRow: true,
      showHeaderFilter: true,
      currentFilter: this.applyFilterTypes[0].key
    };

    this.dataGrid = null;

    this.onOrderHeaderFilter = this.onOrderHeaderFilter.bind(this);
    this.onShowFilterRowChanged = this.onShowFilterRowChanged.bind(this);
    this.onShowHeaderFilterChanged = this.onShowHeaderFilterChanged.bind(this);
    this.onCurrentFilterChanged = this.onCurrentFilterChanged.bind(this);

    this.onCellRenderIcono = this.onCellRenderIcono.bind(this);
    this.cellLinkRender = this.cellLinkRender.bind(this);
    this.onClickPedido = this.onClickPedido.bind(this);
    this.onExporting = this.onExporting.bind(this);
    this.Exporting = this.Exporting.bind(this);


  }

  getDetallePedidos() {
    const urlService = `${process.env.REACT_APP_URL_API}Semaforo/BusquedaDet`;

    const { filtros } = this.props;

    const params = {
      ClaDireccion: filtros.claDireccion,
      ClaSubdireccion: filtros.claSubdireccion,
      ClaZona: filtros.claZona,
      ClaAgente: filtros.claAgente,
      ClaUbicacion: filtros.claPlanta,
      ClaFamilia: filtros.claFamilia,
      ClaSubfamilia: filtros.claSubfamilia,
      ClaProducto: filtros.claProducto,
      ClaCliente: filtros.claCliente,
      ClaPedido: filtros.numPedido !== null ? filtros.numPedido : -1,
      TipoFecha: filtros.tipoFecha,
      FechaInicio: filtros.fechaInicio,
      FechaFin: filtros.fechaFin,
      PendFechaPromesa: filtros.pendienteFechaPromesa === true ? 1 : 0,
      CanceladoSurtido: filtros.canceladosSurtidos === true ? 1 : 0,
      PedidoVencidos: filtros.pedidoVencidos === true ? 1 : 0
    };

    callApi(urlService, 'POST', params, (response) => {
      this.Exporting(response)
    });
    // const data = callApiSinc(urlService, 'POST', params);
  }

  onCellRenderIcono(data) {

    const iconoColor = semaforoColor(data.data.claSemaforo);

    const icono =
      (
        <i
          className={`mb-0 icon icon-shape text-white rounded-circle shadow ${iconoColor}`}
          title={data.data.nomSemaforoEstatus}
          style={{ width: 20, height: 20, padding: "5px", cursor: "pointer" }}
        // onClick={this.descargaDirecta.bind(this, data.ClaTipoDocumento, idDocumento, tipoArchivo)}
        >

        </i>
      );

    return icono;
  }

  cellLinkRender(cellData) {
    return (
      <div
        className="link"
        onClick={this.onClickPedido.bind(this, cellData.data.claPedido)}
      >
        {cellData.text}
      </div>
    );
  }

  onClickPedido(ClaPedido) {
    this.props.onClick(ClaPedido);
  }

  onExporting(e) {
    this.getDetallePedidos();
    e.cancel = true;
  }

  Exporting(detalle) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const e = this.dataGrid.instance;
    const masterRows = [];

    worksheet.columns = [
      { width: 5 }, { width: 15 }, { width: 20 }, { width: 60 }, { width: 25 }, { width: 40 }, { width: 15 }, { width: 25 }, { width: 80 }
    ];
    exportDataGrid({
      component: e,
      worksheet,
      keepColumnWidths: false,
      topLeftCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.column.dataField === 'claSemaforoEstatus' && gridCell.rowType === 'data') {
          masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
        }

        if (gridCell.rowType === 'header') {
          if (gridCell.column.dataField === 'claSemaforoEstatus') {
            excelCell.value = "Semaforo";
          }
        }

        if (gridCell.rowType === 'data') {
          if (gridCell.column.dataField === 'claSemaforoEstatus') {
            const texto = gridCell.data.claSemaforo === 10 ? "verde" : gridCell.data.claSemaforo === 20 ? "amarillo" : "rojo";
            const color = gridCell.data.claSemaforo === 10 ? "34b53a" : gridCell.data.claSemaforo === 20 ? "ffb200" : "ff3a29";

            excelCell.value = { text: texto, hyperlink: texto };
            excelCell.font = { color: { argb: color }, underline: true };
            excelCell.alignment = { horizontal: 'left' };
          }
        }
        if (gridCell.rowType === 'group') {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } };
        }
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
      }

    }).then((cellRange) => {
      const encabezados = ["Semaforo", "Articulo", "Unidad", "Pedida", "Surtido", "Embarque", "Motivo"];
      const columns = ["claSemaforo", "producto", "unidad", "deuda", "surtido", "fechaEmbarque", "nomSemaforoEstatus"];
      const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
      const columnIndex = cellRange.from.column + 1;

      const insertRow = function (index, offset, outlineLevel) {
        const currentIndex = index + offset;
        const row = worksheet.insertRow(currentIndex, [], 'n');

        for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
          worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
        }

        row.outlineLevel = outlineLevel;
        return row;
      }

      let totalOffset = 0;

      for (let i = 0; i < masterRows.length; i++) {
        let offset = 0;
        offset = totalOffset;

        const { claPedido } = masterRows[i].data;
        const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
        encabezados.forEach((header, currentColumnIndex) => {
          Object.assign(row.getCell(columnIndex + currentColumnIndex), {
            value: header,
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
            font: { bold: true },
            border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
            alignment: { horizontal: 'center' }
          });
        });

        const pedidoPartidas = detalle.filter((partida) => partida.claPedido === claPedido);
        pedidoPartidas.forEach((partida) => {
          const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

          columns.forEach((columnName, currentColumnIndex) => {
            const alinear = currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';

            if (currentColumnIndex === 0) {
              const texto = partida[columnName] === 10 ? "verde" : partida[columnName] === 20 ? "amarillo" : "rojo";
              const color = partida[columnName] === 10 ? "34b53a" : partida[columnName] === 20 ? "ffb200" : "ff3a29";
              Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                value: { text: texto, hyperlink: texto },
                font: { color: { argb: color }, underline: true },
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
                border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                alignment: { horizontal: alinear }
              });

            }
            else {
              Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                value: partida[columnName],
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } },
                border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                alignment: { horizontal: alinear }
              });

            }
          });
        });
        offset--;
        totalOffset = offset;
      }

    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Semaforo.xlsx');
      });
    });
    e.cancel = true;
  }

  onCalculateFilterExpression(value, selectedFilterOperations, target) {
    const column = this;
    if (target === 'headerFilter' && value === 'Verde') {
      return [[getClaSemaforoValue, '=', 10]];
    }

    if (target === 'headerFilter' && value === 'Amarillo') {
      return [[getClaSemaforoValue, '=', 20]];
    }

    if (target === 'headerFilter' && value === 'Rojo') {
      return [[getClaSemaforoValue, '=', 30]];
    }

    /// return column.defaultCalculateFilterExpression.apply(this, arguments);
    return column.defaultCalculateFilterExpression.apply(this, [target, selectedFilterOperations, value]);
  }

  onOrderHeaderFilter(data) {
    data.dataSource.postProcess = (results) => {
      results.push({
        text: 'Verde',
        value: 'Verde'
      }, {
        text: 'Amarillo',
        value: 'Amarillo'
      }, {
        text: 'Rojo',
        value: 'Rojo'
      });
      return results;
    };
  }

  onShowFilterRowChanged(e) {
    this.setState({
      showFilterRow: e.value
    });
    this.clearFilter();
  }

  onShowHeaderFilterChanged(e) {
    this.setState({
      showHeaderFilter: e.value
    });
    this.clearFilter();
  }

  onCurrentFilterChanged(e) {
    this.setState({
      currentFilter: e.value
    });
  }

  clearFilter() {
    this.dataGrid.instance.clearFilter();
  }

  render() {

    return (
      <>
        <DataGrid
          id="gridPedidos"
          ref={(ref) => this.dataGrid = ref}
          keyExpr="claPedido"
          key="claPedido"
          dataSource={this.props.data}
          height={1000}
          selection={{ mode: 'single' }}
          showBorders={false}
          columnHidingEnabled
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onExporting={this.onExporting}
        >
          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />

          <MasterDetail enabled={true} component={GridTabs} />

          <Column dataField="claSemaforoEstatus" caption="" width={40} cellRender={this.onCellRenderIcono} calculateFilterExpression={this.onCalculateFilterExpression}>
            <HeaderFilter dataSource={this.onOrderHeaderFilter} />
          </Column>
          <Column dataField="claPedido" caption="Pedido" width={100} alignment="center" cellRender={this.cellLinkRender} />
          <Column dataField="nomCliente" caption="Cliente" width="30%" />
          <Column dataField="nomUbicacion" caption="Planta Asignada" width={100} />
          <Column dataField="fechaCaptura" caption="Fecha Pedido" width={100} />
          <Column dataField="fechaEstimadaEmbarque" caption="Embarque" width={100} />
          <Column dataField="fechaEstimadaEntrega" caption="Entrega" width={100} />

          <Export enabled={true} allowExportSelectedData={true} />

          <Summary>
            <TotalItem column="claPedido" summaryType="count" />
          </Summary>

          <Paging
            pageSize={30}
          /// pageIndex={this.props.pageIndex}
          />
        </DataGrid>
      </>
    );
  }

}

function getClaSemaforoValue(rowData) {
  return rowData.claSemaforo;
}
export default GridPedidos;