import React from 'react';
import DataGrid, { Column, Paging, Summary, TotalItem, Export, Scrolling, HeaderFilter } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import 'assets/css/semaforo.css';
import { config } from 'utils/config';
import { callApi, fechaStr,  } from 'utils/utils';
import CtrlCheckbox from 'views/Controles/CtrlCheckbox';


export default class PedidoGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claPermiso: null
    };


    this.cellLinkRender = this.cellLinkRender.bind(this);
    this.onClickPedido = this.onClickPedido.bind(this);
    this.onContentReady = this.onContentReady.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.cellDateFormatRender = this.cellDateFormatRender.bind(this);
    this.cellCheckBoxRender = this.cellCheckBoxRender.bind(this);
    this.onExporting = this.onExporting.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
    
  }


  onClickPedido(ClaPedido) {
    this.props.onClick(ClaPedido);
  }

  cellLinkRender(cellData) {

    return (
      <div
        className="link"
        onClick={this.onClickPedido.bind(this, cellData.data.ClaPedido)}
      >
        {cellData.text}
      </div>
    );    
    
    
  }

  onContentReady(e) {

    const selectedRows = e.component.getSelectedRowsData();

    // Selecciona el primer row del grid.
    if (this.props.claPedido === 0 || this.props.claPedido === null)
      return;

    if (selectedRows.length === 0) {
      const listaKeys = [];
      if (this.props.data.length > 0) {
        listaKeys.push(this.props.claPedido);
        e.component.selectRows(listaKeys, true);

      }
    }
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.props.onPage(e.value);
    }

    if (e.fullName === 'columns[5].sortOrder') {
      this.props.onSort(e.value);
    }
  }

  cellDateFormatRender(data, index) {
    const fechaobj= data.values[data.columnIndex];
    const fecha=fechaStr(fechaobj);
    const fechaJsx =
      (
        <span>{fecha}</span>
      );

    return fechaJsx;
  }

  cellCheckBoxRender(cellData) {
    const value= cellData.data.EsAceptaPedidoAntes === 1;

    const ctrl=  <CtrlCheckbox etiqueta="" value={value}></CtrlCheckbox>;
    return ctrl;
  }
  
  countFechaEmbarque(options) {

    switch(options.summaryProcess) {
      case "start":
        // Initializing "totalValue" here
        options.totalValue = 0;
        break;
      case "calculate":
          // Modifying "totalValue" here
          if (options.value.FechaEstimadaEmbarque!==null && options.name === 'FechaEstimadaEmbarque') {
            options.totalValue += 1;
          }
          if (options.value.fechaEmbarqueAG!==null && options.name === 'fechaEmbarqueAG') {
            options.totalValue += 1;
          }
          if (options.value.FechaEstimadaEmbarque!==null && options.name === 'fechasKayak' && options.value.esFechaKayak===1) {
            options.totalValue += 1;
          }
          break;
      default:
      case "finalize":
          // Assigning the final value to "totalValue" here
          break;
    }
  
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PedidosRecalcular.xlsx');
      });
    });
    e.cancel = true;
  }

  onCellPrepared(e){

    if (e.rowType === 'data' && e.columnIndex===3 && e.data.esFechaKayak===1){
      e.cellElement.style.color ="green";
      e.cellElement.style.fontWeight ="bold";
      return;
    }

    if (e.rowType === 'data' && e.columnIndex===4 && e.data.esFechaKayak===0){
      e.cellElement.style.color ="green";
      e.cellElement.style.fontWeight ="bold";
    }

  }

  componentDidMount() {
   
  }

  render() {

    const gridContent = (
      <div>
        <DataGrid
          id="gridPedidos"
          dataSource={this.props.data}
          keyExpr="ClaPedido"
          height={600}
          columnWidth={100}
          selection={{ mode: 'single' }}
          showBorders={false}
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onContentReady={this.onContentReady}
          onOptionChanged={this.handleOptionChange}
          onCellPrepared={this.onCellPrepared} 
          onExporting={this.onExporting}
        >
          <Column dataField="ClaPedido" caption="Pedido" width={100} alignment="center" cellRender={this.cellLinkRender} />
          <Column dataField="NomUbicacion" caption="Planta Asignada" width={200} />
          <Column dataField="NomCliente" caption="Cliente" width={300} />
          <Column dataField="TipoPedido" caption="Tipo" width={150} />
          <Column dataField="FechaDesea" caption="Desea" dataType="date" width={130} cellRender={this.cellDateFormatRender} sortOrder={this.props.sortorder.col5} />
          <Column dataField="EsAceptaPedidoAntes" caption="Acepta Antes" width={100} cellRender={this.cellCheckBoxRender} />
          <Column dataField="FechaEstimadaEmbarque" caption="Embarque Actual" dataType="date" format="dd-MM-yyyy" width={130} cellRender={this.cellDateFormatRender} />
          <Column dataField="FechaFinal" caption="Calculada" dataType="date" format="dd-MM-yyyy" width={130} cellRender={this.cellDateFormatRender} />
          <Column dataField="DiferenciaDias" caption="Dias" dataType="int" width={90} />

          <Column dataField="FechaDetMax" caption="Max Detalle" width={150} dataType="date" format="dd-MM-yyyy" cellRender={this.cellDateFormatRender} />
          <Column dataField="FechaSaturacion" caption="Saturacion Fecha" width={150} dataType="date" format="dd-MM-yyyy" cellRender={this.cellDateFormatRender} />
          <Column dataField="DiasSaturacion" caption="Saturacion Días" dataType="int" width={80} />
          <Column dataField="FechaFrecuenciaCte" caption="Cte Fecha Frec" width={150} dataType="date" format="dd-MM-yyyy" cellRender={this.cellDateFormatRender} />
          <Column dataField="DiasFrecuencia" caption="Frecuencia Días" dataType="int" width={80} />

          <Summary calculateCustomSummary={this.countFechaEmbarque}>
            <TotalItem column="ClaPedido" summaryType="count" displayFormat="Total: {0}" />
          </Summary>
          <Paging
            enabled={false} 
            pageSize={30}
            pageIndex={this.props.pageIndex}
          />
          <Export enabled={true} />
          <Scrolling columnRenderingMode="virtual" />
          <HeaderFilter visible={true} />
        </DataGrid>
      </div>
    );


    return (
      <>
        {gridContent}
      </>
    );
  }
}
