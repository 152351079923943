import React from 'react';
import AppContext from "../../context/AppContext";

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { locale } from "devextreme/localization";

import Filtros from './Filtros/Filtros';
import PedidoGrid from './PedidoGrid';
import PedidoDetalle from './Detalle/PedidoDetalle';
import PedidoReleaseDetalle from './DetalleRelease/PedidoReleaseDetalle'
import Semaforo from './Semaforo/Semaforo';

class Pedidos extends React.Component {
  constructor(props) {
    super(props);

    locale('en');

    const filtros = {
      claDireccion: -1,
      claSubdireccion: -1,
      claZona: -1,
      claAgente: -1,
      claPlanta: -1,
      claFamilia: -1,
      claSubfamilia: -1,
      claProducto: -1,
      claCliente: -1,
      numPedido: null,
      tipoFecha: 1,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      pendienteFechaPromesa: false,
      canceladosSurtidos: false,
      pedidoVencidos: false,
      claProceso:null
    };

    this.state = {
      buscador: true,
      releaseState: false,
      buscadorTipo: 1,
      dataPedidos: [],
      filtros,
      claPedidoOriginal: 0,
      claPedido: 0,
      pageIndex: 0,
      sortorder: {col5:'desc', col6:'desc', col7:'desc'},
      fechaCalculo: null
    };

    this.onChangePedidos = this.onChangePedidos.bind(this);
    this.onClickPedido = this.onClickPedido.bind(this);
    this.onClickPedidoSemaforo = this.onClickPedidoSemaforo.bind(this);

    this.onClickSemaforo = this.onClickSemaforo.bind(this);

    this.onGridPage = this.onGridPage.bind(this);
    this.onGridSort = this.onGridSort.bind(this);
    this.onBackDetalle = this.onBackDetalle.bind(this);
    this.toggleReleaseState = this.toggleReleaseState.bind(this);

  }

  /// Evento al buscar pedidos
  onChangePedidos(data, filtros) {
    this.setState({ dataPedidos: data, filtros, buscadorTipo: 1 });
  }

  /// Evento Click en el numero de pedido en el Grid
  /// para mostrar el detalle del pedido
  onClickPedido(ClaPedido) {
    this.setState({ buscador: false, releaseState: false, claPedido: ClaPedido, buscadorTipo: 1, fechaCalculo: null });
  }

  /// Evento Click en el numero de pedido en el Grid
  /// para mostrar el detalle del pedido
  onClickPedidoSemaforo(ClaPedido) {
    const fecha = new Date()
    const pad = function (num) { return (`0${num}`.slice(-2)); };
    const fechastr = `${fecha.getFullYear()}-${pad(fecha.getMonth() + 1)}-${pad(fecha.getDate())}`;

    this.setState({ buscador: false, claPedido: ClaPedido, buscadorTipo: 3, fechaCalculo: fechastr });
  }

  /// Evento para buscar Semaforo
  onClickSemaforo(filtros) {
    this.setState({ filtros, buscador: true, buscadorTipo: 2 });
  }

  /// Evento para manejar la pagina del grid previamente utilizada.
  /// Al regresar del back del detalle, muestre la pagina que habia seleccionado.
  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }

  onGridSort(sort){
    const value=this.state.sortorder;
    value.col5=sort;
    this.setState({ value });
  }

  /// Evento back desde el Detalle del Pedido..
  onBackDetalle() {
    this.setState({ buscador: true, releaseState: false });
  }

  toggleReleaseState()  {
    this.setState({ releaseState: true });
  }

  componentDidMount() {
  }

  render() {

    const { state } = this.context;
    const idPedidoParam = state.idPedido;
    let idPedido = this.state.claPedido;

    if (idPedidoParam !== null)
      idPedido = parseInt(idPedidoParam, 10);

    const buscadorDetalle = this.state.buscadorTipo === 1 ?
      (
        <PedidoGrid 
          data={this.state.dataPedidos} 
          onClick={this.onClickPedido} 
          onPage={this.onGridPage} 
          pageIndex={this.state.pageIndex} 
          onSort={this.onGridSort} 
          sortorder={this.state.sortorder} 
          idPedido={idPedido} 
        />
      )
      : <Semaforo filtros={this.state.filtros} onClick={this.onClickPedidoSemaforo}></Semaforo>;

    const buscadorContent = (
      <>
        <Row>
          <Col md={12}>
            <Filtros onChange={this.onChangePedidos} onSemaforo={this.onClickSemaforo} filtros={this.state.filtros}></Filtros>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12, offset: 0 }}>
            {buscadorDetalle}
          </Col>
        </Row>
      </>
    );

    const detalleContent = this.state.releaseState === false ? (
      <>
        <PedidoDetalle claPedido={idPedido} fechaCalculo={this.state.fechaCalculo} onBack={this.onBackDetalle} releaseState={this.state.releaseState} toggleReleaseState={this.toggleReleaseState}>
        </PedidoDetalle>
      </>
    ):    
    (
      <>
        <PedidoReleaseDetalle 
          claPedido={idPedido} 
          onBack={this.onBackDetalle} 
          releaseState={this.state.releaseState} 
          onClickPedido={this.onClickPedido} 
        />
      </>
    )
    ;


    const contenido = this.state.buscador === true && idPedidoParam === null ? buscadorContent : detalleContent;

    return (
      <>
        <div className="content mb-5">
          {contenido}
        </div>
      </>
    );
  }
}

Pedidos.contextType = AppContext;
export default Pedidos;