/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class Template6Alta extends React.Component {
    constructor(props) {
        super(props);
        
        const objTemplate6 = {
            claUbicacion: null,
            Lote: 0,
        }

        this.state = {
            objTemplate6,
            ubicacionesData: [],
            areaProduccionData: [],
            gpoProduccionData: [],
        }

        this.onFieldDataChanged = this.onFieldDataChanged.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getAreaProduccion = this.getAreaProduccion.bind(this);
        this.getGpoProduccion = this.getGpoProduccion.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);

        this.refForm = React.createRef();
    }

    get formUser() {
        return this.refForm.current ? this.refForm.current.instance : null;
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    getAreaProduccion(claUbicacion){
        const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetAreaProduccion`;

        callApi(urlApiService, 'POST', claUbicacion, (result) => {
            this.setState({
                areaProduccionData: result[0],
            })
        });
    }

    getGpoProduccion(claUbicacion){
        const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetGpoProduccion`;

        callApi(urlApiService, 'POST', claUbicacion, (result) => {
            this.setState({
                gpoProduccionData: result[0],
            })
        });
    }

    onFieldDataChanged(e){
        if(e.dataField == "claUbicacion"){
            this.getGpoProduccion(e.value);
            this.getAreaProduccion(e.value);
        }
    }

    btnCancelarHandle() {
        this.props.handler(false);
    }

    btnGuardarHandleSubmit() {
        const dataForm = this.formUser.option('formData');
    
        if (!dataForm.claUbicacion ) {
        //   showNotify(
        //     'Favor de capturar una Clave de Ubicacion y Producto',
        //     notifyPosition.centerTop,
        //     notifyType.error
        //   );
          return;
        }
    
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/InsertTemplate6`;
    
        const body = {
            idConfiguracion: this.props.idConfiguracion,
            claUbicacion: dataForm.claUbicacion,
            valorEntero1: dataForm.claAreaProduccion,
            valorEntero2: dataForm.claGpoProduccion
        };
    
        callApi(urlApiService, 'POST', body, (res) => {
        //   showNotify('Usuario guardado correctamente', notifyPosition.centerTop, notifyType.success);
          this.props.handler(false);
          window.location.reload();
        });
    }

    componentDidMount(){
        this.getUbicaciones();
        // this.getAreaProduccion();
    }

    render(){
        return(
            <div>
                <Form
                    ref={this.refForm}
                    key="formUser"
                    formData={this.state.objTemplate6}
                    colCount={1}
                    showColonAfterLabel={true}
                    className="formUser"
                    id="FormAltaUsuario"
                    onFieldDataChanged={this.onFieldDataChanged}
                >
                <Item
                    dataField="claUbicacion"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.ubicacionesData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
                <Item
                    dataField="claAreaProduccion"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.areaProduccionData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
                <Item
                    dataField="claGpoProduccion"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.gpoProduccionData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
            </Form>
            <FormGroup className="text-right">
                <Button
                    id="btnUsersGuardar"
                    text="Guardar"
                    onClick={this.btnGuardarHandleSubmit}
                    className="btn btn-primary btn-round"
                />
                <Button
                    id="btnUsersCancelar"
                    text="Cancelar"
                    onClick={this.btnCancelarHandle}
                    className="btn btn-primary btn-round"
                />
            </FormGroup>
            </div>
        )
    }
}