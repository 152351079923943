/*eslint-disable*/
import React from 'react';
import AppContext from "../../../context/AppContext";

import { Popup } from 'devextreme-react/popup';
import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

import Template7Grid from './Template7Grid';
import Template7Alta from './Template7Alta';

export default class Template7 extends React.Component{
    constructor(props) {
        super(props);    

        const filtros = {
            claUbicaion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            idConfiguracion: null,
            claTemplate: 7
        };

        this.state = {
            showSpin: false,
            filtros,  
            pageIndex: 0,
            popupVisible: false,
        }

        this.getConfiguraciones = this.getConfiguraciones.bind(this);
        this.getArtUnidades = this.getArtUnidades.bind(this);
        this.onGridPage = this.onGridPage.bind(this);  
        this.validateRemove = this.validateRemove.bind(this);
        this.hidePopUp = this.hidePopUp.bind(this);
        this.handler = this.handler.bind(this);
        this.onClickAgregar = this.onClickAgregar.bind(this);
       
    }

    validateRemove(e){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/DeleteTemplate7`;

        var params = {
            idCfgConfiguracionGeneral: e.data.IdCfgConfiguracionGeneral,
            idConfiguracion: e.data.IdConfiguracion,
            claUnidad: parseInt(e.data.ClaUnidad),
        }

        callApi(urlApiService, 'POST', params, (result) => {
            this.getConfiguraciones();
        });
    }

    getConfiguraciones(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {

            this.setState({
                configData: result,
                showSpin: false
            });
        });
    }

    getArtUnidades(){        
        const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetArtUnidades`;

        callApi(urlApiService, 'POST', this.props.filtros, (result) => {
            // var _result = [];
            // result.forEach((element, index) => {
            //     _result.push({"Clave": element.clave, "Nombre": element.nombre});
            // });

            this.setState({
                artUnidadesData: result,
            });
        });
    }

///////// POPUP
    handler(val) {
        this.setState({
        popupVisible: val,
        });
    }

    hidePopUp() {
        this.setState({
        popupVisible: false,
        });
    }
////////

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }
    
    onClickAgregar(e) {
        this.setState({
          popupVisible: true,
        });
    
        e.stopPropagation();
    }

    componentDidMount(){
        let newfiltros = this.state.filtros;
        newfiltros.idConfiguracion = this.props.configuracionValue
        this.setState({
            filtros: newfiltros
        })
        this.getConfiguraciones();
        this.getArtUnidades();
    }

    componentDidUpdate(){        
        if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        {
            let newfiltros = this.state.filtros;
            newfiltros.idConfiguracion = this.props.configuracionValue
            this.state.filtros = newfiltros;
            this.getConfiguraciones();
        }
    }

    render(){
        const content = (
            <>
                <Row>
                    <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                        <Button
                            id="btnNuevo"
                            onClick={this.onClickAgregar}
                            size="md"
                            className="btn btn-primary btn-round btn-icon btn-custom mb-1"
                        >
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Template7Grid 
                            configData={this.state.configData}
                            pageIndex={this.state.pageIndex}
                            onPage={this.onGridPage}
                            validateRemove={this.validateRemove}
                        />
                    </Col>
                </Row>
                
                    <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Configuración"
                    width={470}
                    height={400}
                    >
                    <Template7Alta 
                        idConfiguracion={this.props.configuracionValue}
                        handler={this.handler}
                        artUnidadesData={this.state.artUnidadesData}
                        getConfiguraciones={this.getConfiguraciones}
                    />
                </Popup>
            </>
        );

        return(
            <div className="content mb-5">
                {content}
            </div>
        )
    }
}