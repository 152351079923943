/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Scrolling, Paging, Export } from 'devextreme-react/data-grid';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { callApi, fechaStr } from '../../../utils/utils';

export default class PedidoReleaseGrid extends React.Component {
    constructor(props) {
        super(props);

        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);   
        this.cellLinkRender = this.cellLinkRender.bind(this);
        this.onClickPedido = this.onClickPedido.bind(this);
        this.onExporting = this.onExporting.bind(this);
    }
    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }
    
    cellDateFormatRender(data, index) {
        const fechaobj= data.values[data.columnIndex];
        const fecha=fechaStr(fechaobj);
        const fechaJsx =
          (
            <span>{fecha}</span>
          );
    
        return fechaJsx;
    }
    
    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: this.dataGrid.instance,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PedidosRelease.xlsx');
                });
        });
        e.cancel = true;
    }

    onClickPedido(ClaPedido) {
        this.props.onClick(ClaPedido);
    }

    cellLinkRender(cellData) {
        return (
          <div
            className="link"
            onClick={this.onClickPedido.bind(this, cellData.data.ClaPedido)}
          >
            {cellData.text}
          </div>
        );
    }

    render() {
        const gridContent = (
            <div>
              <DataGrid
                id="gridPedidos"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.data}
                keyExpr="ClaPedido"
                height={400}
                width="100%"
                selection={{ mode: 'single' }}
                showBorders={false}
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                onCellPrepared={this.onCellPrepared} 
                onExporting={this.onExporting}
                onRowClick={this.props.onRowReleaseClick}
              >
                <Column dataField="ClaPedido" caption="Pedido" width={100} alignment="center" cellRender={this.cellLinkRender} />
                <Column dataField="NomUbicacion" caption="Planta Asignada" width={100} />
                <Column dataField="FechaCaptura" caption="Fecha Pedido" width={100} />
                <Column dataField="NomCliente" caption="Cliente" width={100} />
                <Column dataField="FechaEstimadaEmbarque" caption="Embarque" width={70} />
                <Column dataField="FechaEstimadaEntrega" caption="Entrega" width={70}  />
                <Column dataField="FechaDeseada" caption="Deseada" width={150}  />

                <Paging
                  enabled={false} 
                  pageSize={30}
                  pageIndex={this.props.pageIndex}
                />

                <Export enabled={true} allowExportSelectedData={true} />
                <Scrolling columnRenderingMode="virtual" />
              </DataGrid>
            </div>
        );

        return (
          <>
             <Row>
                <Col md={{ size: 12 }}>
                    <Card>
                        <CardHeader>
                            <CardTitle>PEDIDOS RELEASE</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    {gridContent}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          </>
        )
    }
}