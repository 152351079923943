/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';

export default class Template1Filtros extends React.Component {
    constructor(props) {
        super(props);

        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    }

    onBtnBuscar() {
        this.props.getConfiguraciones();
    }

    onBtnLimpiar() {
        this.props.onLimpiar();
    }

    componentDidMount(){
    }

    render(){
        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return(
            <div className="content">
                <Row>
                    <Col md={12} style={{ padding: 0, margin: 0 }}>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col>
                                                <CtrlComboBox
                                                    etiqueta="Familia"
                                                    onChange={this.props.onChangeClaFamilia}
                                                    value={this.props.familiaValue}
                                                    data={this.props.familiaData}
                                                    reference={this.props.refCmbFamilia}
                                                />
                                            </Col>
                                            <Col>
                                                <CtrlComboBox
                                                    etiqueta="SubFamilia"
                                                    onChange={this.props.onChangeClaSubFamilia}
                                                    value={this.props.subFamiliaValue}
                                                    data={this.props.subFamiliaData}
                                                    reference={this.props.refCmbSubFamilia}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <CtrlComboBox
                                                    etiqueta="Ubicacion"
                                                    onChange={this.props.onChangeCmbUbicacion}
                                                    data={this.props.ubicacionesData}
                                                    value={this.props.ubicacionValue}
                                                    reference={this.props.refCmbUbicacion}
                                                />
                                            </Col>
                                            <Col>
                                                <CtrlComboBox
                                                    etiqueta="Productos"
                                                    onChange={this.props.onChangeCmbProductos}
                                                    data={this.props.productosData}
                                                    value={this.props.productosValue}
                                                    reference={this.props.refCmbProductos}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                                Buscar
                                            </Button>
                                            {spinner}
                                            <Button type="button" onClick={this.onBtnLimpiar}>
                                                Limpiar
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    };
}