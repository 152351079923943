/*eslint-disable*/
import React from 'react';
import { Popup } from 'devextreme-react/popup';
import ProduccionAceGridDet from './ProduccionAceGridDet';
import 'assets/css/popup.css';
import { Row, Col } from 'reactstrap';

const ModalProduccionAce = (props) => {
  const { visible, onClosePopup, paramService } = props;

  if (visible === false) return <></>;

  const onContentReady = (args) => {
    const html = args.component;
  };

  const replaceJSX = (str, find, replace) => {
    const parts = str.toString().split(find);
    const result = [];
    for (let i = 0; i < parts.length; i++) {
      result.push(parts[i]);
      result.push(replace);
      break;
    }
    return result;
  };

  const titulo = `Capacidad Producción ${replaceJSX(paramService.fechaCalculo, 'T00:00:00', '')}`;

  return (
    <Popup
      visible={visible}
      resizeEnabled={true}
      closeOnOutsideClick={false}
      onHiding={onClosePopup}
      onContentReady={onContentReady}
      showCloseButton={true}
      showTitle={true}
      title={titulo}
      container=".dx-viewport"
      width={500}
      height={580}
    >
      <Row>
        <ProduccionAceGridDet
          paramService={paramService}
          showSegementacion={props.showSegementacion}
        ></ProduccionAceGridDet>
      </Row>
    </Popup>
  );
};

export default ModalProduccionAce;
