/*eslint-disable*/
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import ModalProduccionAce from 'views/Produccion/ModalCapacidad/ModalProduccionAce';

const TraceProduccionAceria = (props) => {
  const { data, idRenglon, claUbicacion, showSegementacion } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [paramService, setparamService] = useState({});

  if (data === undefined) return <></>;

  const onClosePopup = () => {
    setPopoverOpen(false);
  };

  const onClick = (
    claUbicacion,
    claGrupoProduccion,
    claCalibre,
    fechaCalculo,
    fechaEstimadaEmbarque,
    claDireccion
  ) => {
    const params = {
      claUbicacion,
      claGrupoProduccion,
      claCalibre,
      fechaCalculo,
      fechaEstimadaEmbarque,
      claDireccion,
    };
    setparamService(params);
    setPopoverOpen(true);
  };

  const listaProd = data.filter(
    (item) => item.IdRenglon === idRenglon && item.ClaUbicacion === claUbicacion
  );

  if (listaProd.length === 0) return <></>;

  const ubicacion = listaProd[0].NomUbicacion;
  let tmpGrupoProduccion;
  let tmpClaCalibre;

  const lista = listaProd.map((item, index) => {
    const id = `ProduccionAceriaId-${index}`;

    if (tmpGrupoProduccion === item.GrupoProduccion && tmpClaCalibre === item.ClaCalibre)
      return <></>;

    const result = (
      <Row key={id}>
        <Col className="col-12 m-0">
          <Row>
            <Col className="col-12 m-0">
              Producto interno: <b>{item.Producto}</b>
            </Col>
          </Row>
          <Row>
            <Col className="col-12 m-0">
              Grupo de producción: <b>{item.GrupoProduccion}</b>
            </Col>
          </Row>
          <Row>
            <Col className="col-4 m-0 ">
              Calibre: <b> {item.NomCalibre}</b>
            </Col>
            <Col className="col-8 m-0">
              Molino: <b> {item.DescMolino}</b>
            </Col>
          </Row>
          <Row>
            <Col>
              <TracePartidaXFecha
                data={listaProd}
                ClaGrupoProduccion={item.ClaGrupoProduccion}
                ClaCalibre={item.ClaCalibre}
                onClickEvt={onClick}
                showSegementacion={showSegementacion}
              ></TracePartidaXFecha>
            </Col>
          </Row>
          <Row>
            <Col className="col-4 m-0 ">
              Dias Adicionales: <b> {item.DiasAdicionales}</b>
            </Col>
            <Col className="col-4 m-0 ">
              Embarque: <b> {item.FechaEmbarque}</b>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    tmpGrupoProduccion = item.GrupoProduccion;
    tmpClaCalibre = item.ClaCalibre;
    return result;
  });

  return (
    <Row>
      <Col className="col-12">
        <Row>
          <Col>
            <h5 className="mb-2">Capacidad de producción de acerias</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>{ubicacion}</b>
          </Col>
        </Row>
        <Row>
          <Col>{lista}</Col>
        </Row>
        <Row>
          <Col>
            <ModalProduccionAce
              visible={popoverOpen}
              onClosePopup={onClosePopup}
              paramService={paramService}
              showSegementacion={showSegementacion}
            ></ModalProduccionAce>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const TracePartidaXFecha = (props) => {
  const { data, ClaGrupoProduccion, ClaCalibre, onClickEvt } = props;

  if (data === undefined) return <></>;

  const listaProd = data.filter(
    (item) => item.ClaGrupoProduccion === ClaGrupoProduccion && item.ClaCalibre === ClaCalibre
  );

  if (listaProd.length === 0) return <></>;

  const result = listaProd.map((item, index) => {
    const id = `ProduccionAceriaId-${index}`;
    return (
      <Row key={id} style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}>
        <Col className="col-12 m-0">
          <Row>
            <Col className="col-4 m-0">
              <b>&nbsp;</b>
            </Col>
            <Col className="col-4 m-0">
              <b>Capacidad</b>
            </Col>
            <Col className="col-4 m-0">
              <b>&nbsp;</b>
            </Col>
            {
              <Col
                className="col-4 m-0"
                style={{ visibility: props.showSegementacion == 0 ? 'hidden' : 'visible' }}
              >
                Direccion: <b>{item.NomDireccion}</b>
              </Col>
            }
          </Row>
          <Row>
            <Col className="col-4 m-0">
              Máxima: <b>{item.CapacidadMaxima}</b>
            </Col>
            <Col className="col-4 m-0">
              Disponible: <b>{item.CapacidadDisponible}</b>
            </Col>
            <Col className="col-4 m-0 ">
              Asignada: <b>{item.DeudaAsignada}</b>
            </Col>
          </Row>
          <Row>
            <Col
              className="col-4 m-0 "
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                onClickEvt(
                  item.ClaUbicacion,
                  item.ClaGrupoProduccion,
                  item.ClaCalibre,
                  item.FechaCalculo,
                  item.FechaEstimadaEmbarque,
                  item.ClaDireccion
                );
              }}
            >
              Fecha: <b>{item.FechaProduccion}</b>
            </Col>
            <Col className="col-4 m-0">
              Prioridad: <b>{item.Prioridad}</b>
            </Col>
            <Col className="col-4 m-0">
              Deuda pendiente: <b>{item.DeudaPendiente}</b>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  });

  return result;
};

export default TraceProduccionAceria;
