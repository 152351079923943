/*eslint-disable*/
import React from 'react';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { Row, Col } from 'reactstrap';
import LoteMinimoFiltros from './LoteMinimoFiltros';
import LoteMinimoGridCalibre from './LoteMinimoGrid';
import { config } from '../../../utils/config';

export default class LoteMinimo extends React.Component {
  constructor(props) {
    super(props);

    const filtros = {
      claUbicacion: null,
      claUbicacionPide: null,
      claFamilia: null,
      claSubFamilia: null,
      claProducto: null,
      claPedido: null,
      idLote: null,
      estatusLote: null,
      tipoFecha: 1,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      tipoLote: 2,
    };

    this.state = {
      filtros,
      showSpin: false,
      ubicacionesData: [],
      familiasData: [],
      subFamiliasData: [],
      productosData: [],
      estatusData: [],
      pageIndex: 0,
      loteMinimoData: [],
      calibresData: []
    };

    this.getLotesMinimos = this.getLotesMinimos.bind(this);
    this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    this.onChangeCmbUbicacionPide = this.onChangeCmbUbicacionPide.bind(this);
    this.onChangeCmbFamilias = this.onChangeCmbFamilias.bind(this);
    this.onChangeCmbSubFamilias = this.onChangeCmbSubFamilias.bind(this);
    this.onChangeCmbProductos = this.onChangeCmbProductos.bind(this);
    this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    this.onLimpiar = this.onLimpiar.bind(this);
    this.onChangeClaPedido = this.onChangeClaPedido.bind(this);
    this.onChangeIdLote = this.onChangeIdLote.bind(this);
    this.onGridPage = this.onGridPage.bind(this);
    this.onChangeCmbTipoFecha = this.onChangeCmbTipoFecha.bind(this);
    this.onChangeFechaInicio = this.onChangeFechaInicio.bind(this);
    this.onChangeFechaFin = this.onChangeFechaFin.bind(this);
    this.onChangeCmbEstatus = this.onChangeCmbEstatus.bind(this);

    this.getUbicaciones = this.getUbicaciones.bind(this);
    this.getFamilias = this.getFamilias.bind(this);
    this.getSubfamilias = this.getSubfamilias.bind(this);
    this.getProductos = this.getProductos.bind(this);
    this.getEstatus = this.getEstatus.bind(this);

    this.refCmbUbicacion = React.createRef();
    this.refCmbUbicacionPide = React.createRef();
    this.refCmbFamilia = React.createRef();
    this.refCmbSubFamilia = React.createRef();
    this.refCmbProducto = React.createRef();
    this.refCmbEstatus = React.createRef();
    this.refNumSubFamilia = React.createRef();
  }

  getLotesMinimos() {
    const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/LoteMinimoBusqueda`;

    callApi(urlApiService, 'POST', this.state.filtros, (result) => {

      var resultCalibre = [];
      resultCalibre.push(result[0].filter(function(x){ return x.CalibreCantidadloteMinimo !== null}))
      
      this.setState({
        loteMinimoData: resultCalibre,
      });
    });
  }

  getUbicaciones() {
    var params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 258, params, 1, (res) => {
        
        let acerias = res.Result0.filter(u => u.Nombre.includes('Acería'));

        this.setState({
            ubicacionesData: acerias
        })
    });
  }

  getFamilias() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 1,
      IncluirTodosSN: 0,
      ClaNivel: 20,
    };

    callKrakenApi(25, 1, params, 1, (res) => {
      // return res.Result0;
      this.setState({
        familiasData: res.Result0,
      });
    });
  }

  getSubfamilias() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetFamiliasSubFamilias`;

    callApi(urlApiService, 'POST', null, (result) => {
      this.setState({
        subFamiliasData: result[1],
      });
    });
  }

  getProductos(claFamilia, claSubfamilia) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 1,
      IncluirTodosSN: 0,
      ClaFamilia: claFamilia,
      ClaSubFamilia: claSubfamilia,
    };

    callKrakenApi(25, 3, params, 1, (res) => {
      const data = res.Result0;

      this.setState({
        productosData: data,
      });
    });
  }

  getEstatus() {
    const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/GetEstatusList`;

    callApi(urlApiService, 'POST', this.state.filtros, (result) => {
      this.setState({
        estatusData: result,
      });
    });
  }

  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }
  
  getCalibres(claUbicacion) {
    const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/GetCatCalibresAceriaLoteminimo?ClaUbicacion=${claUbicacion}`;

    callApi(urlApiService, 'GET', null, (result) => {
        // console.log(result);
        this.setState({
            calibresData: result
        })
    });
  }

  onLimpiar() {
    const filtros = {
      claUbicacion: null,
      claFamilia: null,
      claSubFamilia: null,
      claProducto: null,
      claPedido: null,
      idLote: null,
      estatusLote: null,
      tipoFecha: 1,
      fechaInicio: new Date(),
      fechaFin: new Date(),
    };

    this.setState({
      filtros,
      subFamiliasData: [],
      productosData: [],
    });
  }

  onChangeCmbFamilias(selectedItem) {
    var newFiltro = this.state.filtros;
    newFiltro.claFamilia = selectedItem.value;

    this.setState({
      filtros: newFiltro,
    });
    this.getSubfamilias(selectedItem.value);
  }

  onChangeCmbSubFamilias(selectedItem) {
    var newFiltro = this.state.filtros;
    newFiltro.claSubFamilia =
      selectedItem.selectedItem !== null ? selectedItem.selectedItem.Clave : null;

    this.setState({
      filtros: newFiltro,
    });

    // this.getProductos(this.state.filtros.claFamilia, selectedItem.value);
  }

  onChangeCmbProductos(selectedItem) {
    var newFiltro = this.state.filtros;
    newFiltro.claProducto =
      selectedItem.selectedItem !== null ? selectedItem.selectedItem.Clave : null;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeCmbUbicacion(selectedItem) {
    if(selectedItem.value !== null){    
      var newFiltro = this.state.filtros;
      newFiltro.claUbicacion = selectedItem.value;

      this.getCalibres(selectedItem.value)

      this.setState({
        filtros: newFiltro,
      });
    }
    else{
        this.limpiar();
    }
    
  }

  onChangeCmbUbicacionPide(selectedItem) {
    var newFiltro = this.state.filtros;
    newFiltro.claUbicacionPide = selectedItem.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeClaPedido(e) {
    var newFiltro = this.state.filtros;
    newFiltro.claPedido = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeIdLote(e) {
    var newFiltro = this.state.filtros;
    newFiltro.idLote = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeCmbTipoFecha(e) {
    var newFiltro = this.state.filtros;
    newFiltro.tipoFecha = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeFechaInicio(e) {
    var newFiltro = this.state.filtros;
    newFiltro.fechaInicio = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeFechaFin(e) {
    var newFiltro = this.state.filtros;
    newFiltro.fechaFin = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeCmbEstatus(e) {
    var newFiltro = this.state.filtros;
    newFiltro.estatusLote = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  componentDidMount() {
    this.getFamilias();
    this.getSubfamilias();
    this.getUbicaciones();
    this.getEstatus();
  }

  render() {
    return (
      <div className="content">
        <Row>
          <Col>
            <LoteMinimoFiltros
              onChangeCmbUbicacion={this.onChangeCmbUbicacion}
              onChangeCmbUbicacionPide={this.onChangeCmbUbicacionPide}
              onChangeCmbFamilias={this.onChangeCmbFamilias}
              onChangeCmbSubFamilias={this.onChangeCmbSubFamilias}
              onChangeCmbProductos={this.onChangeCmbProductos}
              onChangeClaPedido={this.onChangeClaPedido}
              onChangeIdLote={this.onChangeIdLote}
              getLotesMinimos={this.getLotesMinimos}
              onChangeCmbTipoFecha={this.onChangeCmbTipoFecha}
              onChangeFechaInicio={this.onChangeFechaInicio}
              onChangeFechaFin={this.onChangeFechaFin}
              onChangeCmbEstatus={this.onChangeCmbEstatus}
              onLimpiar={this.onLimpiar}
              ubicacionesData={this.state.ubicacionesData}
              familiasData={this.state.familiasData}
              subFamiliasData={this.state.subFamiliasData}
              productosData={this.state.productosData}
              estatusData={this.state.estatusData}
              refNumSubFamilia={this.refNumSubFamilia}
              filtros={this.state.filtros}
              calibresData={this.state.calibresData}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <LoteMinimoGridCalibre
              dataSource={this.state.loteMinimoData}
              onPage={this.onGridPage}
              pageIndex={this.state.pageIndex}
              filtros={this.state.filtros}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
