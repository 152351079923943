import React from "react";
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

import PedidoGrid from './PedidoGrid';
import PedidoDetalle from 'views/Pedidos/Detalle/PedidoDetalle';
import CtrlComboBox from 'views/Controles/CtrlComboBox';

import { config } from 'utils/config';
import { callApi, callKrakenApi } from 'utils/utils';

class Pedidos extends React.Component {
    constructor(props) {
      super(props);
  
        this.state = {
        claPlanta: null,
        dataUbicaciones:[],
        dataPedidos: [],
        showDetalle:false,
        claPedido:null,
        fechaCalculo:null,
        
        sortorder: {col5:'desc', col6:'desc', col7:'desc'},
        showSpin:false
      };
  
      
      this.onChangeCmbPlanta = this.onChangeCmbPlanta.bind(this);
      this.onBtnBuscar = this.onBtnBuscar.bind(this);
      this.onClickPedido = this.onClickPedido.bind(this);
      this.onGridSort = this.onGridSort.bind(this);
      this.onBackDetalle = this.onBackDetalle.bind(this);
    }

    getUbicaciones() {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
          Valor: '',
          Tipo: 1,
          IncluirTodosSN: 0,
        };
    
        callKrakenApi(8, 258, params, 1, (res) => {
          this.setState({ dataUbicaciones: res.Result0 });
        });
    }

    getPedidos() {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const urlService = `${process.env.REACT_APP_URL_API}Pedido/ReportePedidosRecalcular`;
   
        const pad = function (num) { return (`0${num}`.slice(-2)); };
        const fechaFormat = function (fecha) {
          return (`${fecha.getFullYear()}-${pad(fecha.getMonth() + 1)}-${pad(fecha.getDate())
            }T00:00:00.000Z`);
        };
    
        const params = {
          ClaUbicacion: this.state.claPlanta,
        };
    
        this.setState({ showSpin: true });
        callApi(urlService, "POST", params, (res) => {
          const data = res;
          this.setState({ showSpin: false, dataPedidos:data });
        });
    }

    onChangeCmbPlanta(selectedItem) {
        const claPlanta = selectedItem.value != null ? selectedItem.value : -1;
    
        this.setState({ claPlanta });
    }

    onBtnBuscar() {
        this.getPedidos();
    }
 
      /// Evento back desde el Detalle del Pedido..
    onBackDetalle() {
        this.setState({ showDetalle: false });
  }

    /// Evento Click en el numero de pedido en el Grid
    /// para mostrar el detalle del pedido
    onClickPedido(ClaPedido) {
        const fecha = new Date()
        const pad = function (num) { return (`0${num}`.slice(-2)); };
        const fechastr = `${fecha.getFullYear()}-${pad(fecha.getMonth() + 1)}-${pad(fecha.getDate())}`;

        this.setState({ showDetalle:true,claPedido: ClaPedido, fechaCalculo: fechastr });
    }
 
    onGridSort(sort){
        const value=this.state.sortorder;
        value.col5=sort;
        this.setState({ value });
      }
    
    componentDidMount() {
        this.getUbicaciones();
    }
  
    render() {
  
        const spinner =
        this.state.showSpin === true ? (
          <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        const grid =
        (
          <PedidoGrid 
            data={this.state.dataPedidos} 
            onSort={this.onGridSort} 
            onClick={this.onClickPedido} 
            sortorder={this.state.sortorder}
            claPedido={this.state.claPedido} 
          />
        )
   
        const detalleContent = (
          <>
            <div className="content mb-5">
              <PedidoDetalle claPedido={this.state.claPedido} fechaCalculo={this.state.fechaCalculo} onBack={this.onBackDetalle}>
              </PedidoDetalle>
            </div>
          </>
        );

        if(this.state.showDetalle===true)
            return detalleContent;

        return (
          <>
            <div className="content mb-5">
              <Row>
                <Col md={12} style={{ padding: 0, margin: 0 }}>
                  <Card>
                    <CardHeader>
                      <CardTitle>Pedidos por ReCalcular</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginleft: 0, marginright: 0 }}>
                        <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                          <CtrlComboBox
                            etiqueta="Planta Asignada"
                            onChange={this.onChangeCmbPlanta}
                            data={this.state.dataUbicaciones}
                            value={this.state.claPlanta}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row>
                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                              Buscar
                            </Button>
                            {spinner}
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              {grid}
            </div>
          </>
        );

    }
  }
  
  export default Pedidos;  