/*eslint-disable*/
import React from 'react';
import AppContext from "../../../context/AppContext";

import { Popup } from 'devextreme-react/popup';
import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

import Template_UbiCalAceLoteGrid from './Template_UbiCalAceLoteGrid';
import Template_UbiCalAceLoteAlta from './Template_UbiCalAceLoteAlta';

export default class Template_UbiCalAceLote extends React.Component {
    constructor(props) {
        super(props);

        const filtros = {
            claUbicacion: null,
            claCalibre: null,
            idConfiguracion: this.props.configuracionValue,
            claTemplate: 11
        };
        
        this.state = {
            showSpin: false,
            pageIndex: 0,
            filtros,            
            ubicacionesData: [],
            calibresData: [],
               
            filtrosUbicacionesData: [],
            filtrosCalibresData: [],

            popupVisible: false,
        }
        
        this.onDeleting = this.onDeleting.bind(this);
        this.onGridPage = this.onGridPage.bind(this); 
        this.hidePopUp = this.hidePopUp.bind(this);
        this.handler = this.handler.bind(this);
        this.onChangeCmbCalibre = this.onChangeCmbCalibre.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);

        this.getConfiguraciones = this.getConfiguraciones.bind(this);
        this.onLimpiar = this.onLimpiar.bind(this);
        this.onDeleting = this.onDeleting.bind(this);

        this.onClickAgregar = this.onClickAgregar.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getCalibres = this.getCalibres.bind(this);

        this.refCmbUbicacion = React.createRef();
        this.refCmbCalibre = React.createRef();
    }
    
    getConfiguraciones(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            this.setState({
                configData: result,
            });
        });
    }

    getUbicaciones() {
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    getCalibres(claUbicacion) {
        const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/GetCatCalibresAceriaLoteminimo?ClaUbicacion=${claUbicacion}`;

        callApi(urlApiService, 'GET', null, (result) => {
            // console.log(result);
            this.setState({
                calibresDataData: result
            })
        });
    }
///////// POPUP
    handler(val) {
        this.setState({
        popupVisible: val,
        });
    }

    hidePopUp() {
        this.setState({
        popupVisible: false,
        });
    }
////////
    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onDeleting(e){
        e.cancel = true;
        
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneralDelete`;
        this.setState({ showSpin: true });

        const data = {
            idConfiguracionGeneral: e.data.IdCfgConfiguracionGeneral
        }
        callApi(urlApiService, 'POST', data, (result) => {
        });

        this.getConfiguraciones();
    }

    
    onChangeCmbCalibre(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claCalibre = selectedItem.value;

        this.setState({
            filtros: newFiltro
        })
    }

    onChangeCmbUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;

        this.setState({
            filtros: newFiltro
        })
    }

    onRefreshData(){
        this.getConfiguraciones();
    }

    onClickAgregar(e) {
        this.setState({
          popupVisible: true,
        });
    
        e.stopPropagation();
    }

    onLimpiar() {
        const filtros = {
            claUbicacion: null,
            claCalibre: null,
            claConfiguracion: 310
        };

        this.setState({
            filtros,
            calibresData: [],
               
            filtrosCalibresData: [],
        })
    }

    componentDidMount(){
        this.getConfiguraciones();

        this.getUbicaciones();
    }

    componentDidUpdate(){
        if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        {
            this.getConfiguraciones();
        }
    }

    render(){
        return(
            <div className="content">
                <Row>
                    <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                        <Button
                            id="btnNuevo"
                            onClick={this.onClickAgregar}
                            size="md"
                            className="btn btn-primary btn-round btn-icon btn-custom mb-1"
                        >
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Template_UbiCalAceLoteGrid 
                            data={this.state.configData}
                            onDeleting={this.onDeleting}
                            onPage={this.onGridPage} 
                            pageIndex={this.state.pageIndex}
                        />   
                    </Col>
                </Row>
                <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Configuración"
                    // width={470}
                    height={600}
                    >
                    <Template_UbiCalAceLoteAlta 
                        idConfiguracion={this.props.configuracionValue}
                        handler={this.handler}
                        getConfiguraciones={this.getConfiguraciones}
                    />
                </Popup>
            </div>
        );
    }

}
