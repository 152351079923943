/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class Template5Alta extends React.Component {
    constructor(props) {
        super(props);
        
        const objTemplate5 = {
            Ubicacion : null,
            DiaCorte: null,
        }
        const diasNombres = [
            {"Clave": 1, "Nombre": "Domingo"},
            {"Clave": 2, "Nombre": "Lunes"},
            {"Clave": 3, "Nombre": "Martes"},
            {"Clave": 4, "Nombre": "Miércoles"},
            {"Clave": 5, "Nombre": "Jueves"},
            {"Clave": 6, "Nombre": "Viernes"},
            {"Clave": 7, "Nombre": "Sábado"}
        ]

        this.state = {
            diasNombres,
            objTemplate5,
            ubicacionesData: [],
            DiasCorteData: [],
        }

        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
        this.onChangeDiaCorte = this.onChangeDiaCorte.bind(this);

        this.refForm = React.createRef();
    }

    get formUser() {
        return this.refForm.current ? this.refForm.current.instance : null;
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    btnCancelarHandle() {
        this.props.handler(false);
    }

    btnGuardarHandleSubmit() {
        const dataForm = this.formUser.option('formData');
    
        if (dataForm.Ubicacion === null || dataForm.DiaCorte === null) {
        //   showNotify(
        //     'Favor de capturar una Clave de Ubicacion y Producto',
        //     notifyPosition.centerTop,
        //     notifyType.error
        //   );
          return;
        }
    
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/InsertTemplate5`;
    
        const body = {
            idConfiguracion: this.props.idConfiguracion,
            claUbicacion: dataForm.Ubicacion,
            valorString1: this.state.diasNombres[dataForm.DiaCorte -1].Nombre,
            valorEntero1: dataForm.DiaCorte,
        };
    
        callApi(urlApiService, 'POST', body, (res) => {
        //   showNotify('Usuario guardado correctamente', notifyPosition.centerTop, notifyType.success);
          this.props.handler(false);
        //   window.location.reload();
        });
    }

    onChangeDiaCorte(e){
        this.setState({
            selectedDia: e.value,
            // selectedDiaNom: name
        })
    }

    componentDidMount(){
        this.getUbicaciones();
    }

    render(){
        return(
            <div>
                <Form
                    ref={this.refForm}
                    key="formUser"
                    formData={this.state.objTemplate5}
                    colCount={1}
                    showColonAfterLabel={true}
                    className="formUser"
                    id="FormAltaUsuario"
                    onFieldDataChanged={this.onFieldDataChanged}
                >
                <Item
                    dataField="Ubicacion"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.ubicacionesData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
                <Item
                    dataField="DiaCorte"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.diasNombres,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
            </Form>
            <FormGroup className="text-right">
                <Button
                    id="btnUsersGuardar"
                    text="Guardar"
                    onClick={this.btnGuardarHandleSubmit}
                    className="btn btn-primary btn-round"
                />
                <Button
                    id="btnUsersCancelar"
                    text="Cancelar"
                    onClick={this.btnCancelarHandle}
                    className="btn btn-primary btn-round"
                />
            </FormGroup>
            </div>
        )
    }
}