/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button  } from 'reactstrap';

export default class PedidoMasterGrid extends React.Component {
    constructor(props) {
        super(props);

        this.onBack = this.onBack.bind(this);
    }

    onBack() {
        this.props.onBack();
    }

    render() {
        return (
          <>
            <Row>
                <Col md={{ size: 12 }}>
                    <Card>
                    <CardHeader>
                        <CardTitle>PEDIDO MASTER</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row style={{ marginleft: 0, marginright: 0 }}>
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Pedido Master: {this.props.data.ClaPedido}</h5>
                            </Col>
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Cliente: {this.props.data.NomCliente}</h5>
                            </Col>                    
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Ubicación: {this.props.data.NomUbicacion}</h5>
                            </Col> 
                        </Row>
                        <Row>                            
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Fecha Pedido: {this.props.data.FechaCaptura}</h5>
                            </Col>
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Fecha Desea: {this.props.data.FechaDeseada}</h5>
                            </Col>
                        </Row>
                        <Row>                        
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Direccion: {this.props.data.Direccion}</h5>
                            </Col>                 
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>SubDireccion: {this.props.data.SubDireccion}</h5>
                            </Col>              
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Zona: {this.props.data.Zona}</h5>
                            </Col>                
                            {/* <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <h5>Agente: {this.props.data.Agente}</h5>
                            </Col> */}
                        </Row>
                        <Row>   
                        </Row>
                        <Row style={{ marginTop: 10 }}>
                            <Col md={{ size: 3 }} style={{ margin: 0 }}>
                                <Button type="button" className="btn btn-primario" onClick={this.onBack}>
                                Regresar
                                </Button>
                            </Col>                                                     
                            <Col md={{ size: 4 }} style={{ margin: 0 }}>
                                <h5>Kilos Solicitados: {this.props.data.KilosSolicitados}</h5>
                            </Col>
                            <Col md={{ size: 4 }} style={{ margin: 0 }}>
                                <h5>Kilos Surtidos: {this.props.data.KilosSurtidos}</h5>
                            </Col>
                        </Row>
                    </CardBody>
                    </Card>
                </Col>
            </Row>
          </>
        )
    }
}