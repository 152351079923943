/*eslint-disable*/
import React from 'react';
import DataGrid, { MasterDetail, Column, Paging, Scrolling } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class AceriasGridDet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 0,
      dataDetalle: [],
      showSegementacion: 0,
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.getProduccion = this.getProduccion.bind(this);
    this.onGridPage = this.onGridPage.bind(this);
    this.getConfiguracion = this.getConfiguracion.bind(this);
    this.getConfiguracion();
  }
  getConfiguracion() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/ConfiguracionSegmentacion`;

    callApi(urlApiService, 'POST', this.state.filtroSeg, (result) => {
      this.setState({
        showSegementacion: result[0][0].SegmentacionActivada,
      });
    });
  }
  getProduccion() {
    const filtros = {
      fechaProduccionIni: new Date(),
      fechaProduccionFin: new Date(),
      claUbicacion: this.props.claUbicacion,
      claCalibre: null,
      claGpoProduccion: null,
      idProduccion: this.props.idProduccion,
      IdProduccionDet: this.props.IdProduccionDet,
    };

    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaAceria`;

    this.setState({ showSpin: true });

    this.setState({ showSpin: true });

    callApi(urlApiService, 'POST', filtros, (result) => {
      this.setState({
        dataDetalle: result[3],
      });
    });
  }

  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.onGridPage(e.value);
    }
  }

  componentDidMount() {
    this.getProduccion();
  }

  render() {
    const gridContent = (
      <div>
        <DataGrid
          dataSource={this.state.dataDetalle}
          height={600}
          width="100%"
          selection={{ mode: 'single' }}
          showBorders={false}
          columnHidingEnabled
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onOptionChanged={this.handleOptionChange}
        >
          <Column dataField="IdProduccion" caption="Id Producción" width={100} visible={false} />
          <Column dataField="IdRenglon" caption="Renglón" width={70} />
          <Column dataField="ClaPedido" caption="Pedido" width={70} />
          <Column
            dataField="NombreDireccion"
            caption="Direccion"
            width={100}
            visible={this.state.showSegementacion}
          />
          <Column dataField="NomArticulo" caption="Artículo" width={100} />
          <Column dataField="NomProductoAceria" caption="Producto Acería" width={100} />
          <Column
            dataField="KilosDisponibles"
            caption="Kgs Disponibles"
            width={70}
            dataType="string"
            format="#,###.##"
          />
          <Column
            dataField="KilosReservados"
            caption="Kgs Reservados"
            width={70}
            dataType="string"
            format="#,###.##"
          />
          <Column dataField="NomMolino" caption="Molino" width={100} />

          <Paging pageSize={30} pageIndex={this.props.pageIndex} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </div>
    );

    return <>{gridContent}</>;
  }
}
