import swal from 'sweetalert';
import notify from 'devextreme/ui/notify';
import { config } from './config';

require('./prototypes');

/**
 * Realiza una peticion a una URL especificada.
 *,,
 * @param {String} url Dirección donde se realizara la peticioón
 * @param {String} method Tipo de peticion a ejecutar (POST, GET, PUT, DELETE)
 * @param {JSON} [data={}] Objeto que se adjuntará al body de la petición
 * @returns
 */ const kraken_api_key = process.env.REACT_APP_API_KEY;
async function callApi(url, method, data = {}, callBack, multipart = false) {
  method = method.toUpperCase();

  const headers = {
    'Content-Type': 'application/json',
    'x-api-key': kraken_api_key,
    'x-access-token': getSessionItem('Token', ''),
  };

  let statusReponse = 0;

  fetch(url, {
    method,
    headers,
    dataType: 'json',
    body: method === 'GET' ? null : JSON.stringify(data),
  })
    .then(async (response) => {
      const res = await response.json();

      statusReponse = response.status;

      if (response.status === 200) {
        callBack(res);
      } else {
        const errX = new Error();
        errX.message = res.message ?? res.mensaje;
        errX.name = response.status;
        errX.description = res.message;
        errX.status = response.status;

        throw errX;
      }
    })
    .catch((err) => {
      console.log(
        `Url: ${url}`,
        err.message,
        method === 'GET' ? null : `JSON:${JSON.stringify(data)}`
      );

      swal('Error', err.message, 'error', {
        buttons: {
          confirm: {
            text: 'Aceptar',
            className: 'animation-on-hover btn btn-success',
          },
        },
      });
    })
    .finally(() => {
      if (statusReponse === 401) {
        document.location.href = '/Login';
      }
    });
}
/**
 * Función para realizar la petición a Kraken por medio de su servicio en Node js
 * @param {Number} claProducto número de el producto, dueño de la entidad
 * @param {Number} idEntidad número de la entidad que se desea consultar
 * @param {Object} paramsSP parametros que necesita la entidad
 * @param {Object} tipoEstructura número
 * @param {Function} callback función de callback para manejo del resultado
 */
async function callKrakenApi(claProducto, idEntidad, paramsSP, tipoEstructura, callback) {
  const method = 'POST';

  const params = {
    parameters: JSON.stringify(paramsSP),
    tipoEstructura,
  };

  const url = `${process.env.REACT_APP_URL_KRAKEN}/${claProducto}/${idEntidad}`;

  await callApi(url, method, params, callback);
}

/**
 * Guarda en el localStorage cualquier valor que se mande en un objeto
 *
 * @param {object} params objeto donde cada propiedad se va a guardar
 */
function setSessionData(params) {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];

      if (typeof element === 'object') {
        localStorage.setItem(key, JSON.stringify(element));
      } else {
        localStorage.setItem(key, element);
      }
    }
  }
}

/**
 * Regresa del localStorage cualquier valor que se pase como primer parametro,
 * en caso de no encontrarlo, regresa el valor por default del segundo parametro
 *
 * @param {string} key
 * @param {any} def
 */
function getSessionItem(key, def) {
  let value;

  try {
    value = JSON.parse(localStorage.getItem(key));
  } catch (error) {
    value = localStorage.getItem(key);
  }

  if (!value) {
    return def;
  }
  return value;
}

/**
 * Regresa del sessionStorage el NumUsuario
 *
 * @returns {Number} NumUsuario
 */
function getCliente() {
  return localStorage.getItem('NumUsuario');
}

/**
 * Remueve del localStorage el JWT token y el nombre del usuario
 *
 */
function logOut() {
  localStorage.clear();
}

/**
 * Hace el decode del JWT token
 *
 * @param {string} token
 * @returns {object} payload del token
 */
function decodeToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

/**
 * Valida la si existe un token guardado en localStorage y en caso de existir
 * se valida su expiracion
 *
 * @returns {boolean} retorna si el token es valido o no
 */
function sessionAlive() {
  const jwtToken = localStorage.getItem('Token');
  let resp = false;

  if (jwtToken) {
    const { exp } = decodeToken(jwtToken);

    if (Date.now() < exp * 1000) {
      resp = true;
    }
  }

  if (!resp) {
    logOut();
  }

  return resp;
}

/**
 * Tipo de la notificacion de DevExtreme
 */
const notifyType = {
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};

/**
 * Posicion la notificacion de DevExtreme
 * 'bottom' | 'center' | 'left' | 'left bottom' | 'left top' | 'right' | 'right bottom' | 'right top' | 'top'
 */
const notifyPosition = {
  centerBottom: 'center bottom',
  centerTop: 'center top',
  rightBottom: 'right bottom',
  rightTop: 'right top',
};

/**
 * Manda Un mensaje Generico con el Notify de DevExtreme
 *
 * @param {string} message mensaje de la alerta
 * @param {notifyPosition} notifyPosition posicion de la alerta
 * @param {notifyType} notifyType tipo de notificacion
 */
function showNotify(message, notifyPosition, notifyType) {
  notify(
    {
      message,
      position: {
        my: notifyPosition,
        at: notifyPosition,
      },
      width: '400px',
      closeOnSwipe: true,
    },
    notifyType,
    3000
  );
}

function showSweetAlert(title, message, notifyType) {
  swal(title, message, notifyType, {
    buttons: {
      confirm: {
        text: 'Aceptar',
        className: 'animation-on-hover btn btn-success',
      },
    },
  });
}

function semaforoColor(claSemaforo) {
  const iconoColor =
    claSemaforo === 5
      ? 'semaforoGris'
      : claSemaforo === 10
      ? 'bg-success'
      : claSemaforo === 20
      ? 'semaforoAmarillo'
      : claSemaforo === 30
      ? 'bg-danger'
      : '';

  return iconoColor;
}

function fechaStr(fechaobj) {
  const pad = function (num) {
    return `0${num}`.slice(-2);
  };
  let mes = '';
  let fecha = '';

  if (fechaobj === null || fechaobj === undefined) return '';

  switch (fechaobj.getMonth()) {
    case 0:
      mes = 'Ene';
      break;
    case 1:
      mes = 'Feb';
      break;
    case 2:
      mes = 'Mar';
      break;
    case 3:
      mes = 'Abr';
      break;
    case 4:
      mes = 'May';
      break;
    case 5:
      mes = 'Jun';
      break;
    case 6:
      mes = 'Jul';
      break;
    case 7:
      mes = 'Ago';
      break;
    case 8:
      mes = 'Sep';
      break;
    case 9:
      mes = 'Oct';
      break;
    case 10:
      mes = 'Nov';
      break;
    case 11:
      mes = 'Dic';
      break;
    default:
      mes = 'Desc';
      break;
  }

  fecha = `${pad(fechaobj.getDate())}-${mes}-${fechaobj.getFullYear()}`;
  return fecha;
}

export {
  callApi,
  callKrakenApi,
  setSessionData,
  getSessionItem,
  getCliente,
  logOut,
  decodeToken,
  sessionAlive,
  notifyType,
  notifyPosition,
  showNotify,
  showSweetAlert,
  semaforoColor,
  fechaStr,
};
