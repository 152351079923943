/*eslint-disable*/
import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import LoteMinimoGridDet from './LoteMinimoGridDet';

export default class LoteMinimoMasterDetail extends React.Component {
    constructor(props) {
        super(props);

        this.renderDetalleTab = this.renderDetalleTab.bind(this);
    }

    componentDidMount() { 
    }

    renderDetalleTab() {
        return <LoteMinimoGridDet 
                idLote={this.props.data.data.IdLote} 
            />;
    }

    render() {
        return (
        <TabPanel>
            <Item title="Detalle" render={this.renderDetalleTab} />
        </TabPanel>
        );
    }
}