/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';

export default class PedidosPlanoGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;
        this.state = {
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);             
        this.onExporting= this.onExporting.bind(this);   
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Producción.xlsx');
                });
        });
        
        e.cancel = true;
    }
    
    render() {
        const gridContent = (
            <DataGrid
                id="gridProduccion"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.data}
                key={'idPlan'}
                width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                onExporting={this.onExporting}
            >
                <FilterRow
                    visible={true}
                    applyFilter={true}
                />
                <HeaderFilter visible={true} /> 

                <Column dataField="claUbicacion" caption="Ubicación" width={100}/>
                <Column dataField="NomAreaProduccion" caption="Área Producción" width={150}/>
                <Column dataField="NomGrupoMaquina" caption="Gpo Máquinas" width={150}/> 
                <Column dataField="claPedido" caption="Pedido" width={100}/>
                <Column dataField="idRenglon" caption="Renglon" width={70}/>
                <Column dataField="claProductoAto" caption="Producto" width={150} />
                <Column dataField="claArticuloComp" caption="Composición" width={200} />
                <Column dataField="claPeriodo" caption="Periodo" width={100} />
                <Column dataField="nivelComposicion" caption="Nivel" width={100}/>                
                
                <Column dataField="deudaOriginalComposicion" caption="Deuda Original Composicion" width={100} dataType={"number"} alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>
                <Column dataField="capacidadDisponible" caption="Capacidad Disponible" width={100} dataType={"number"} alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>
                <Column dataField="horasReservados" caption="Horas Reservados" width={100} dataType={"number"} alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>
                <Column dataField="horasDisponibles" caption="Horas Disponibles" width={70} dataType={"number"} alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={0}
                    />
                </Column>               
                <Column dataField="deudaPendiente" caption="Deuda Pendiente" width={70} dataType={"number"} alignment={"left"}>
                    <Format
                        type="fixedPoint"
                        precision={2}
                    />
                </Column>       

                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        );

        return (
        <>
            {gridContent}
        </>
        );
    }
}
