/*eslint-disable*/
import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import Template9Grid from './Template9Grid';
import Template9Alta from './Template9Alta';

export default class Template6 extends React.Component{
    constructor(props) {
        super(props);

        const filtros = {
            claUbicaion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            idConfiguracion: null,
            claTemplate: 9
        };

        this.state = {
            filtros,
            configuracionData: [],
            addedConfig: [],
            pageIndex: 0,
            popupVisible: false,
        }

        this.onClickAgregar = this.onClickAgregar.bind(this);
        this.hidePopUp = this.hidePopUp.bind(this);
        this.getConfiguracionData = this.getConfiguracionData.bind(this);
        this.addConfiguracion = this.addConfiguracion.bind(this);
        this.onGridPage = this.onGridPage.bind(this);  
        this.validateRemove = this.validateRemove.bind(this);
        this.handler = this.handler.bind(this);
    }

    getConfiguracionData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            this.setState({
                configuracionData: result,
                showSpin: false
            });
        });
    }

    validateRemove(e){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/DeleteTemplate9`;

        var params = {
            idConfiguracionGeneral: e.data.IdCfgConfiguracionGeneral,
        }

        callApi(urlApiService, 'POST', params, (result) => {
            this.getConfiguracionData();
        });
    }

    handler(val) {
        this.setState({
          popupVisible: val,
        });
        this.getConfiguracionData();
    }

    hidePopUp() {
        this.setState({
          popupVisible: false,
        });
        this.getConfiguracionData();
    }

    addConfiguracion(item){
        // this.state.addedConfig.push(item);
        
        this.state.configuracionData.push(item);
    }

    onClickAgregar(e) {
        this.setState({
          popupVisible: true,
        });
    
        e.stopPropagation();
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }
    
    componentDidMount(){
        let newfiltros = this.state.filtros;
        newfiltros.idConfiguracion = this.props.configuracionValue
        this.setState({
            filtros: newfiltros
        })
        this.getConfiguracionData();        
    }

    componentDidUpdate(){
        // if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        // {
        //     let newfiltros = this.state.filtros;
        //     newfiltros.idConfiguracion = this.props.configuracionValue
        //     this.state.filtros = newfiltros;
        //     this.getConfiguraciones();
        // }
    }
    
    render(){
        return(
            <div className="content">
                <Row>
                    <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                        <Button
                            id="btnNuevo"
                            onClick={this.onClickAgregar}
                            size="md"
                            className="btn btn-primary btn-round btn-icon btn-custom mb-1"
                        >
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Template9Grid
                            dataSource={this.state.configuracionData}
                            addConfiguracion={this.addConfiguracion}
                            addedConfig={this.state.addedConfig}
                            pageIndex={this.state.pageIndex}
                            onPage={this.onGridPage}
                            validateRemove={this.validateRemove}
                        />
                    </Col>
                </Row>
                <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Configuración"
                    width={470}
                    height={600}
                    >
                    <Template9Alta 
                        idConfiguracion={this.props.configuracionValue}
                        handler={this.handler}
                    />
                </Popup>
            </div>
        );
    }
}