/*eslint-disable*/
import React from 'react';
import AppContext from '../../../context/AppContext';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  Button,
  Spinner,
  Label,
} from 'reactstrap';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import AceriasFiltros from './AceriasFiltros';
import AceriasGrid from './AceriasGrid';
import AceriasCharts from './AceriasCharts';

export default class Acerias extends React.Component {
  constructor(props) {
    super(props);

    const filtros = {
      fechaProduccionIni: new Date(),
      fechaProduccionFin: new Date(),
      claUbicacion: null,
      claCalibre: null,
      claGpoProduccion: null,
      idProduccion: null,
    };
    const filtroSeg = { ClaUbicacion: 7 };

    this.state = {
      produccionData: [],
      chartData: [],
      columnChartData: [],
      detalleProduccionData: [],

      ubicacionesData: [],
      calibreData: [],
      gpoProduccionData: [],
      showSpin: false,
      showSegementacion: 0,
      filtros,
      filtroSeg,
      pageIndex: 0,
    };

    this.getUbicaciones = this.getUbicaciones.bind(this);
    this.getConfiguracion = this.getConfiguracion.bind(this);
    this.getProduccion = this.getProduccion.bind(this);
    this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    this.onChangeFechaProduccionIni = this.onChangeFechaProduccionIni.bind(this);
    this.onChangeFechaProduccionFin = this.onChangeFechaProduccionFin.bind(this);
    this.onChangeCmbGpoProduccion = this.onChangeCmbGpoProduccion.bind(this);
    this.onChangeCmbCalibre = this.onChangeCmbCalibre.bind(this);
    this.onLimpiar = this.onLimpiar.bind(this);
    this.onGridPage = this.onGridPage.bind(this);
    this.getCalibres = this.getCalibres.bind(this);
    this.getGpoPoduccion = this.getGpoPoduccion.bind(this);

    this.refCmbUbicacion = React.createRef();
    this.refCmbGpoProduccion = React.createRef();
    this.refCmbCalibre = React.createRef();

    this.getConfiguracion();
  }
  getConfiguracion() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/ConfiguracionSegmentacion`;

    callApi(urlApiService, 'POST', this.state.filtroSeg, (result) => {
      this.setState({
        showSegementacion: result[0][0].SegmentacionActivada,
      });
    });
  }
  getProduccion() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaAceria`;

    this.setState({ showSpin: true });

    callApi(urlApiService, 'POST', this.state.filtros, (result) => {
      this.setState({
        showSpin: false,
        produccionData: result[2],
        chartData: result[1],
        columnChartData: result[0],
      });
    });
  }

  getUbicaciones() {
    var params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 258, params, 1, (res) => {
      this.setState({
        ubicacionesData: res.Result0,
      });
    });
  }

  getCalibres() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetCalibre`;
    console.log('urlApiService', urlApiService);

    callApi(urlApiService, 'POST', this.state.filtros.claUbicacion, (result) => {
      this.setState({
        calibreData: result[0],
      });
    });
  }

  getGpoPoduccion() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetGpoProduccion`;
    console.log('urlApiService', urlApiService);

    callApi(urlApiService, 'POST', this.state.filtros.claUbicacion, (result) => {
      this.setState({
        gpoProduccionData: result[0],
      });
    });
  }

  onChangeFechaProduccionIni(e) {
    var newFiltro = this.state.filtros;
    newFiltro.fechaProduccionIni = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeFechaProduccionFin(e) {
    var newFiltro = this.state.filtros;
    newFiltro.fechaProduccionFin = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeCmbUbicacion(e) {
    var newFiltro = this.state.filtros;
    newFiltro.claUbicacion = e.value;
    newFiltro.claCalibre = null;
    newFiltro.claGpoProduccion = null;

    this.setState({
      filtros: newFiltro,
      calibreData: [],
      gpoProduccionData: [],
    });

    this.getCalibres();
    this.getGpoPoduccion();
  }

  onChangeCmbGpoProduccion(e) {
    var newFiltro = this.state.filtros;
    newFiltro.claGpoProduccion = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onChangeCmbCalibre(e) {
    var newFiltro = this.state.filtros;
    newFiltro.claCalibre = e.value;

    this.setState({
      filtros: newFiltro,
    });
  }

  onLimpiar() {
    const filtros = {
      fechaProduccionIni: new Date(),
      fechaProduccionFin: new Date(),
      claUbicacion: null,
      claCalibre: null,
      claGpoProduccion: null,
      idProduccion: null,
    };

    this.setState({
      filtros,
    });
  }

  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }

  componentDidMount() {
    this.getUbicaciones();
  }

  render() {
    var chartContent = null;
    if (this.state.filtros.claCalibre !== null) {
      chartContent = (
        <AceriasCharts dataSource={this.state.chartData} columns={this.state.columnChartData} />
      );
    }
    return (
      <>
        <div className="content">
          <Row>
            <Col>
              <AceriasFiltros
                onLimpiar={this.onLimpiar}
                getProduccion={this.getProduccion}
                ubicacionesData={this.state.ubicacionesData}
                calibreData={this.state.calibreData}
                gpoProduccionData={this.state.gpoProduccionData}
                onChangeFechaProduccionIni={this.onChangeFechaProduccionIni}
                onChangeFechaProduccionFin={this.onChangeFechaProduccionFin}
                onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                onChangeCmbGpoProduccion={this.onChangeCmbGpoProduccion}
                onChangeCmbCalibre={this.onChangeCmbCalibre}
                refCmbUbicacion={this.refCmbUbicacion}
                refCmbGpoProduccion={this.refCmbGpoProduccion}
                refCmbCalibre={this.refCmbCalibre}
                filtros={this.state.filtros}
                getConfiguracion={this.getConfiguracion}
                showSpin={this.state.showSpin}
              />
            </Col>
          </Row>
          <Row>
            <Col>{chartContent}</Col>
            {/* <Col md={8}>
                            <AceriasGrid
                                dataSource={this.state.produccionData}
                                onPage={this.onGridPage} 
                                pageIndex={this.state.pageIndex}
                                filtros={this.state.filtros}
                            />
                        </Col>
                        <Col md={4}>
                        </Col> */}
          </Row>
          <Row>
            <Col>
              <AceriasGrid
                dataSource={this.state.produccionData}
                onPage={this.onGridPage}
                pageIndex={this.state.pageIndex}
                filtros={this.state.filtros}
                showSegementacion={this.state.showSegementacion}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
