/*eslint-disable*/
import React from 'react';
import AppContext from '../../../context/AppContext';

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from 'devextreme/localization';

import Filtros from './FactorConversionFiltros';
import FactorConversionGrid from './FactorConversionGrid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class FactorConversion extends React.Component {
  constructor(props) {
    super(props);

    locale('en');

    const filtros = {
      fechaCalculo: new Date(),
      claUbicacion: null,
      claProducto: null,
      claArea: null,
      claGrupoMaquina: null,
    };

    this.state = {
      buscador: true,
      dataFactorConversion: [],
      filtros,
      fechaCalculo: new Date(),
      pageIndex: 0,
      ubicacionesData: [],
      productosData: [],
      areasData: [],
      gruposMaquinas: [],
      showSpin: false,
    };

    this.getFactorConversionData = this.getFactorConversionData.bind(this);
    this.onGridPage = this.onGridPage.bind(this);

    this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    this.onChangeCmbProductos = this.onChangeCmbProductos.bind(this);
    this.onChangeCmbGpoMaquinas = this.onChangeCmbGpoMaquinas.bind(this);
    this.onChangeCmbAreas = this.onChangeCmbAreas.bind(this);

    this.onLimpiar = this.onLimpiar.bind(this);

    this.refCmbUbicacion = React.createRef();
    this.refCmbProductos = React.createRef();
    this.refCmbAreas = React.createRef();
    this.refCmbGpoMaquinas = React.createRef();

    var params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 258, params, 1, (res) => {
      this.setState({
        ubicacionesData: res.Result0,
      });
    });

    params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(25, 3, params, 1, (res) => {
      this.setState({
        productosData: res.Result0,
      });
    });
  }

  getFactorConversionData = () => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaFactor`;

    this.setState({ showSpin: true });

    callApi(urlApiService, 'POST', this.state.filtros, (result) => {
      console.log(result);
      this.setState({
        dataFactorConversion: result,
        showSpin: false,
      });
    });
  };

  getDetellePedidos() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const e = this.dataGrid.instance;

    exportDataGrid({
      component: e,
      worksheet,
      keepColumnWidths: false,
      topLeftCell: { row: 2, column: 2 },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Semaforo.xlsx');
      });
    });
  }

  // onExporting(e) {
  //     this.getDetellePedidos();
  //     e.cancel = true;
  // }

  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }

  onChangeFechaCalculo = (selectedDate) => {
    var newFiltro = this.state.filtros;
    newFiltro.fechaCalculo = selectedDate.value;

    this.setState({
      filtros: newFiltro,
      fechaCalculo: selectedDate.value,
    });
  };

  onChangeCmbUbicacion = (selectedItem) => {
    var newFiltro = this.state.filtros;
    newFiltro.claUbicacion = selectedItem.value;

    this.setState({
      filtros: newFiltro,
    });

    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

    callApi(urlApiService, 'POST', this.state.filtros, (result) => {
      var areasArr = [];

      result.areasProduccion.forEach((e) => {
        areasArr.push({ Clave: e.ClaAreaProduccion, Nombre: e.NomAreaProduccion });
      });

      this.setState({
        areasData: areasArr,
      });
    });
  };

  onChangeCmbProductos = (selectedItem) => {
    var newFiltro = this.state.filtros;
    newFiltro.claProducto = selectedItem.value;

    this.setState({
      filtros: newFiltro,
    });
  };

  onChangeCmbAreas = (selectedItem) => {
    var newFiltro = this.state.filtros;
    newFiltro.claArea = selectedItem.value;

    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

    callApi(urlApiService, 'POST', this.state.filtros, (result) => {
      var maqArr = [];

      result.gpoMaquinas.forEach((e) => {
        maqArr.push({ Clave: e.ClaGrupoMaquina, Nombre: e.NomGrupoMaquina });
      });

      this.setState({
        gruposMaquinas: maqArr,
      });
    });

    this.setState({
      filtros: newFiltro,
    });
  };

  onChangeCmbGpoMaquinas = (selectedItem) => {
    var newFiltro = this.state.filtros;
    newFiltro.claGrupoMaquina = selectedItem.value;

    this.setState({
      filtros: newFiltro,
    });
  };

  onLimpiar() {
    const filtros = {
      fechaCalculo: new Date(),
      claUbicacion: null,
      claArea: null,
      claGrupoMaquina: null,
      periodoInicio: null,
      periodoFin: null,
    };

    this.setState({
      filtros: filtros,
    });
  }

  render() {
    return (
      <div className="content mb-5">
        <Row>
          <Col>
            <Filtros
              onLimpiar={this.onLimpiar}
              getFactorConversionData={this.getFactorConversionData}
              onChangeFechaCalculo={this.onChangeFechaCalculo}
              fechaCalculoValue={this.state.filtros.fechaCalculo}
              ubicacionesData={this.state.ubicacionesData}
              onChangeCmbUbicacion={this.onChangeCmbUbicacion}
              refCmbUbicacion={this.refCmbUbicacion}
              claUbicacion={this.state.filtros.claUbicacion}
              productosData={this.state.productosData}
              onChangeCmbProductos={this.onChangeCmbProductos}
              refCmbProductos={this.refCmbProductos}
              claProducto={this.state.filtros.claProducto}
              areasData={this.state.areasData}
              onChangeCmbAreas={this.onChangeCmbAreas}
              refCmbAreas={this.refCmbAreas}
              claArea={this.state.filtros.claArea}
              gpoMaquinasData={this.state.gruposMaquinas}
              onChangeCmbGpoMaquinas={this.onChangeCmbGpoMaquinas}
              refCmbGpoMaquinas={this.refCmbGpoMaquinas}
              claGrupoMaquina={this.state.filtros.claGrupoMaquina}
              showSpin={this.state.showSpin}
            ></Filtros>
          </Col>
        </Row>
        <Row>
          <Col>
            <FactorConversionGrid
              data={this.state.dataFactorConversion}
              onPage={this.onGridPage}
              pageIndex={this.state.pageIndex}
              // onExporting={this.onExporting}
            ></FactorConversionGrid>
          </Col>
        </Row>
      </div>
    );
  }
}
