/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Paging, Format, Summary, TotalItem } from 'devextreme-react/data-grid';
import { Button, Col, Row } from 'reactstrap';

import 'assets/css/popup.css';

export default class ProduccionGridDetPartidas extends React.Component {
  constructor(props) {
    super(props);

    this.gridRef = React.createRef();

    this.state = {
      data: this.props.partidas,
      enc: this.props.encabezado,
      paramService: {},
    };
  }

  componentDidMount() {}

  calculaTotal = (options) => {
    if (options.name === 'Total') {
      if (options.summaryProcess === 'start') {
        options.totalValue = 0;
      }
      if (options.summaryProcess === 'calculate') {
        options.totalValue += options.value.HorasReservados;
      }
    }
  };

  render() {
    const gridContent = (
      <DataGrid
        id="gridPartidas"
        keyExpr="ClaPeriodo"
        ref={this.gridRef}
        dataSource={this.state.data}
        height={400}
        width="95%"
        selection={{ mode: 'single' }}
        showBorders={false}
        columnHidingEnabled
        columnAutoWidth={false}
        showColumnHeaders
        showColumnLines={false}
        showRowLines
        noDataText="Sin Registros"
        wordWrapEnabled
        rowAlternationEnabled={false}
        scrollByContent={true}
        onOptionChanged={this.handleOptionChange}
        onCellPrepared={this.onCellPrepared}
        onRowPrepared={this.onRowPrepared}
        className="connect-datagrid-default"
      >
        <Column dataField="ClaPeriodo" caption="Periodo" width={40} alignment="center" />
        <Column dataField="ClaArticulo" caption="Articulo" width={25} alignment="center" />
        <Column dataField="IdRenglon" caption="Renglon" width={30} alignment="center" />
        <Column dataField="HorasReservados" caption="Reservado" width={40} alignment="center" format={"#0.##"} />
        <Summary calculateCustomSummary={this.calculaTotal}>
          <TotalItem
            name="Total"
            summaryType="custom"
            showInColumn="HorasReservados"
            valueFormat="#0.##"
            displayFormat="Total Reserva: {0}"
          />
        </Summary>
        <Paging pageSize={60} />
      </DataGrid>
    );

    return (
      <div className="col-12">
        <Row className="mb-0 pb-0 mt--3">
          <Col className="col-11 m-0 p-0">
            Area Producción: <b>{this.state.enc.NomAreaProduccion}</b>
          </Col>
          <Col className="col-12 m-0 p-0">
            Gpo Maquinas: <b>{this.state.enc.NomGrupoMaquina}</b>
          </Col>
          <Col className="col-12 m-0 p-0">
            Pedido: <b>{this.props.paramService.claPedido}</b>
          </Col>
        </Row>
        <Row className="mb-0 pb-0 mt--1">
          <Col className="col-12 m-0 p-0">{gridContent}</Col>
        </Row>
      </div>
    );
  }
}
