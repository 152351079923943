import React from "react";
import { Row, Col } from "reactstrap";

const TraceViaje = props => {
  const { data, idRenglon } = props;

  const lista = data
    .filter(item => item.IdRenglon === idRenglon)
    .map((item, index) => {
      const id = `ViajeId-${index}`;
      return (
        <Row key={id}>
          <Col className="col-12">
            <Row>
              <Col>
                Viaje: <b>{item.IdViaje}</b>
              </Col>
              <Col>
                Fecha recepción: <b>{item.FechaRecepcion}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                Cantidad Reservada:{item.Reservada}
              </Col>
              <Col>
                Cantidad Asignada: {item.Total}
              </Col>
            </Row>
            <Row className="d-flex align-items-start">
              <Col>
                Destino:{item.Destino}
              </Col>
              <Col>
                Origen:<b> {item.Origen}</b>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">&nbsp;</Col>
            </Row>
          </Col>
        </Row>
      );
    });

  return lista;
};


export default TraceViaje;