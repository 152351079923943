/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default class CapacidadCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: this.getChart(),
        }

        this.getChart = this.getChart.bind(this);
    }
    
    getChart(){
        var chartData = []
        for(var i = 0; i < this.props.dataSource.length; i++)
        {            
            var chartSerie = this.props.dataSource[i]
            if(chartSerie.name == "Disponible"){
                chartSerie.color = Highcharts.getOptions().colors[0]
            }
            if(chartSerie.name == "Reservado"){
                chartSerie.color = Highcharts.getOptions().colors[1]
            }
            chartData.push(chartSerie);
        }
        console.log(chartData);
        const options = {
                chart: {
                  type: 'column',
                },      
                title: {
                  text: 'Capacidad de Producción',
                  style: {
                    fontSize: '24px'
                  }
                },      
                // tooltip: {
                //   enabled: false,
                // },
                xAxis: {
                    categories: this.props.columns,
                    title: {
                        text: 'Grpo Producción'
                    }
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: ''
                  },
                },
                legend:{
                    enabled: true,
                },
                plotOptions: {
                    column: {
                      stacking: 'percent'
                    }
                },      
                series: chartData,
                tooltip: {
                  formatter: function(){
                    var stackName = this.series.userOptions.stack;
                    // console.log(stackName);
                    return '<span style="color:'+this.series.color+'">'+stackName+'</span>: <b>'+this.point.y+' Toneladas '+this.series.name+'</b><br/>'
                  },
                //   pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} Toneladas</b> ({point.percentage:.0f}%)<br/>',
                //   shared: true
                },
                credits: { enabled: false },
            };
        
            return options;
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){        
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {            
            // console.log(this.props.capacidadData)
            this.setState({                
                options: this.getChart()
            })
        }
    }

    render() {
        return (
            <div className="content">
                <Row>
                    <Col>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.options}
                            allowChartUpdate={true}
                            containerProps={{ style: { width: '100%', height: '600px' } }}
                        >                            
                        </HighchartsReact>
                    </Col>
                </Row>
            </div>
        );
    }
}