/*eslint-disable*/
import React from 'react';
import { Card, CardBody, CardTitle, CardHeader, Row, Col } from 'reactstrap';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import CtrlCheckbox from 'views/Controles/CtrlCheckbox';

export default class HistorialReclaculos extends React.Component{
    constructor(props){
        super(props);

        this.state = {
            data: [],            
            pageIndex: 0,
            popupVisible: false,
        }

        this.onGridPage = this.onGridPage.bind(this); 
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.cellRenderCheckbox = this.cellRenderCheckbox.bind(this);
        
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    cellRenderCheckbox(cellData) {
        
      const value= cellData.data.EsMejoraFecha === 1;

      const ctrl=  <CtrlCheckbox etiqueta="" value={value}></CtrlCheckbox>;
      return ctrl;

    }
    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }
    
    componentDidMount(){
    }

    render(){
        const gridContent = (
            <DataGrid
                id="gridHistorial"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.data}
                width="99%"
                height="100%"
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={true}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                remoteOperations={true}
            >
                
                <Column dataField="FechaRecibe" caption="Recibe" width={70} 
                    dataType={"date"} 
                    format={"dd/MM/yyyy HH:mm:ss"}
                ></Column>
                <Column dataField="FechaCalculo" caption="Fecha Cálculo" width={70} 
                    dataType={"date"} 
                    format={"dd/MM/yyyy HH:mm:ss"}
                ></Column>
                <Column dataField="Tipo" caption="Tipo" width={70}/>
                <Column dataField="FechaEmbarqueNueva" caption="Fecha Calculada" width={80} 
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                <Column dataField="FechaEmbarqueAnterior" caption="Fecha Embarque Anterior" width={80} 
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                
                <Column dataField="EsMejoraFecha" caption="Mejora" width={50} cellRender={this.cellRenderCheckbox} alignment="center"/>
                <Column dataField="FechaClienteAnterior" caption="Fecha Cliente Anterior" width={80} 
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                <Column dataField="FechaClienteNueva" caption="Fecha Cliente Nueva" width={80} 
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                <Column dataField="NomUsuario" caption="Usuario" width={80}/>
                <Column dataField="Comentarios" caption="Comentarios" width={80}/>

                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
            </DataGrid>
        );

        return(
            <>                                          
                {gridContent}
            </>
        );
    }
}