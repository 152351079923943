/*eslint-disable*/
import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';

import DetalleTab from './DetalleTab'

class ContratosMasterDetail extends React.Component {
    constructor(props) {
        super(props);

        this.renderDetalleTab = this.renderDetalleTab.bind(this);
        this.dataDetalle = [];
    }

    componentDidMount() { 
    }

    renderDetalleTab() {
      return <DetalleTab 
        data={this.props.data.data}
        />;
    }

    render() {
        return (
          <TabPanel>
            <Item title="Detalle" render={this.renderDetalleTab} />
          </TabPanel>
        );
    }
}

export default ContratosMasterDetail;