/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export } from 'devextreme-react/data-grid';

export default class CapacidadGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);   
    }
    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }
    
    render() {
        const gridContent = (
            <Row>
                <Col>
                    <Row>    
                        <Col>
                            <DataGrid
                                id="gridProduccion"
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.props.capacidadData}
                                key={'idPlan'}
                                width="100%"
                                height={600}
                                selection={{ mode: 'single' }}
                                showBorders={false}
                                columnHidingEnabled
                                columnAutoWidth={false}
                                showColumnHeaders
                                showColumnLines={false}
                                showRowLines
                                noDataText="Sin Registros"
                                wordWrapEnabled
                                rowAlternationEnabled={false}
                                onOptionChanged={this.handleOptionChange}
                            >

                                <Column dataField="FechaValidaEmbarque" caption="Fecha Embarque" width={80} alignment="center" 
                                    dataType={"date"} 
                                    format={"dd/MM/yyyy"}
                                />
                                <Column dataField="Ubicacion" caption="Ubicación" width={100}/>
                                <Column dataField="NombreZona" caption="Zona" width={100}/>
                                <Column dataField="ToneladasDiarias" caption="Toneladas Diarias" width={100}/>
                                <Column dataField="ToneladasReservadas" caption="Toneladas Reservadas" width={100}/>
                                <Column dataField="ToneladasDisponibles" caption="Toneladas Disponibles" width={100}/>

                                <Paging
                                    pageSize={30}
                                    pageIndex={this.props.pageIndex}
                                />
                            </DataGrid>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

        return (
        <>
            {gridContent}
        </>
        );
    }
}