/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class DeudaGridDet extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            pageIndex: 0,
            dataDetalle:[]
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getDeudaData = this.getDeudaData.bind(this);
        this.onGridPage = this.onGridPage.bind(this);
    }

    getDeudaData() {
        const filtros = {            
            fechaEmbarque: this.props.fechaCalculo,
            claPedido: this.props.claPedido,
        };

        const urlApiService = `${process.env.REACT_APP_URL_API}Embarque/BusquedaDeuda`;
        
        callApi(urlApiService, 'POST', filtros, (result) => {
            console.log(result);
            this.setState({
                dataDetalle: result[1]
            })
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.onGridPage(e.value);
        }
    }

    componentDidMount(){
        this.getDeudaData();
    }

    render() {
        const gridContent = (
            <div>
                <DataGrid
                dataSource={this.state.dataDetalle}
                height={600}
                width="100%"
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                >
                    
                <Column dataField="FechaCalculo" caption="Fecha Cálculo" width={100}
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                <Column dataField="FechaPromesaEmbarque" caption="Fecha Embarque" width={100}
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                <Column dataField="NomUbicacion" caption="Ubicación" width={100}/>
                <Column dataField="NomArticulo" caption="Artículo" width={100} />
                <Column dataField="CantidadDeudaTons" caption="Deuda Tons" width={70} />
                <Column dataField="CantidadCapacidadReservada" caption="Reserva Tons" width={70} />
                <Column dataField="CantidadDeudaPendienteTons" caption="Deuda Pendiente Tons" width={70} />
                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                </DataGrid>
            </div>
        );

        return (
            <>
            {gridContent}
            </>
        );
    }
}