/*eslint-disable*/
import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import AppContext from "../../../context/AppContext";
import { callApi, callKrakenApi } from '../../../utils/utils';
import Tabs from 'devextreme-react/tabs';
import DeudaFiltros from './DeudaFiltros';
import DeudaGrid from './DeudaGrid';
import { config } from '../../../utils/config';

export default class Deuda extends React.Component {
    constructor(props) {
        super(props);

        const filtros = {
            claUbicacion: null,
            claArea: null,
            claPedido: null,
            fechaEmbarque: new Date(),
        }

        this.state = {
            ubicacionesData: [],
            deudaData: [],
            filtros,
            showSpin: false,
            requiredMessage: "hidden"
        };

        this.getEmbarques = this.getEmbarques.bind(this);
        
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeFechaCalculo = this.onChangeFechaCalculo.bind(this);
        this.onChangeNumPedido = this.onChangeNumPedido.bind(this);

        this.onGridPage = this.onGridPage.bind(this);
        this.onLimpiar = this.onLimpiar.bind(this);

        this.refCmbUbicacion = React.createRef();
    }
    
    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    getEmbarques(){
        if(this.state.filtros.claUbicacion === null){
            this.setState({
                requiredMessage: "visible"
            })
        }
        else{
            const urlApiService = `${process.env.REACT_APP_URL_API}Embarque/BusquedaDeuda`;
            
            this.setState({ showSpin: true });

            callApi(urlApiService, 'POST', this.state.filtros, (result) => {
                console.log(result);
                this.setState({
                    deudaData: result,
                    showSpin: false,
                    requiredMessage: "hidden"
                })
            });
        }
    }
    
    onChangeCmbUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro,
            requiredMessage: "hidden"
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onChangeNumPedido(e){
        const numPedido = e.value;
        
        var newFiltro = this.state.filtros;
        newFiltro.claPedido = numPedido;

        this.setState({ 
            filtros: newFiltro
        });
    }

    onChangeCmbFamilias(e){
        var newFiltro = this.state.filtros;
        newFiltro.claFamilia = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro
        });

    }
    
    onChangeFechaCalculo(selectedDate){
        var newFiltro = this.state.filtros;
        newFiltro.fechaEmbarque = selectedDate.value;

        this.setState({
            filtros: newFiltro,
        })
    }

    onLimpiar(){        
        const filtros = {
            claUbicacion: null,
            claArea: null,
            claPedido: null,
            claFamilia: null,
            fechaEmbarque: new Date(),
        }
        
        this.setState({
            filtros: filtros
        })
    }

    componentDidMount(){  
        this.getUbicaciones();     
    }

    render() {
        return (
            <div className="content mb-5">
                <Row>
                    <Col>
                        <DeudaFiltros
                            onChangeNumPedido={this.onChangeNumPedido}
                            onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                            onChangeFechaCalculo={this.onChangeFechaCalculo}

                            getEmbarques={this.getEmbarques}
                            onLimpiar={this.onLimpiar}

                            ubicacionesData={this.state.ubicacionesData}
                            zonasData={this.state.zonasData}

                            refCmbUbicacion={this.refCmbUbicacion}
                            refCmbZonas={this.refCmbZonas}

                            filtros={this.state.filtros}
                            showSpin={this.state.showSpin}
                            requiredMessage={this.state.requiredMessage}
                        >
                        </DeudaFiltros>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <DeudaGrid 
                            filtros={this.state.filtros}
                            dataSource={this.state.deudaData[0]}
                            onPage={this.onGridPage} 
                            pageIndex={this.state.pageIndex}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}