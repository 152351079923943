import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import FiltroOrganizacion from './FiltroOrganizacion';
import FiltroFamilias from './FiltroFamilias';
import FiltroFechas from './FiltroFechas';

export default class Filtros extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataOrganizacion: { direccion: [], subdireccion: [], zona: [], agente: [], planta: [] },
      dataFamilia: { familia: [], subfamilia: [], producto: [], claCliente: null, numPedido: null },
      claDireccion: props.filtros.claDireccion,
      claSubdireccion: props.filtros.claSubdireccion,
      claZona: props.filtros.claZona,
      claAgente: props.filtros.claAgente,
      claPlanta: props.filtros.claPlanta,
      claFamilia: props.filtros.claFamilia,
      claSubfamilia: props.filtros.claSubfamilia,
      claProducto: props.filtros.claProducto,
      claCliente: props.filtros.claCliente,
      numPedido: props.filtros.numPedido,
      tipoFecha: props.filtros.tipoFecha,
      fechaInicio: props.filtros.fechaInicio,
      fechaFin: props.filtros.fechaFin,
      pendienteFechaPromesa: props.filtros.pendienteFechaPromesa,
      canceladosSurtidos: props.filtros.canceladosSurtidos,
      pedidoVencidos: props.filtros.pedidoVencidos,
      claProceso:props.filtros.claProceso,
      showSpin: false,
    };


    this.onChangeCmbDireccion = this.onChangeCmbDireccion.bind(this);
    this.onChangeCmbSubdireccion = this.onChangeCmbSubdireccion.bind(this);
    this.onChangeCmbZona = this.onChangeCmbZona.bind(this);
    this.onChangeCmbAgente = this.onChangeCmbAgente.bind(this);
    this.onChangeCmbPlanta = this.onChangeCmbPlanta.bind(this);

    this.onChangeCmbFamilia = this.onChangeCmbFamilia.bind(this);
    this.onChangeCmbSubfamilia = this.onChangeCmbSubfamilia.bind(this);
    this.onChangeCmbProducto = this.onChangeCmbProducto.bind(this);
    this.onCUCSelected = this.onCUCSelected.bind(this);
    this.onProductoSelected = this.onProductoSelected.bind(this);
    this.onChangeNumPedido = this.onChangeNumPedido.bind(this);

    this.onChangeCmbTipoFecha = this.onChangeCmbTipoFecha.bind(this);
    this.onChangeFechaInicio = this.onChangeFechaInicio.bind(this);
    this.onChangeFechaFin = this.onChangeFechaFin.bind(this);
    this.onChangePendienteFechaPromesa = this.onChangePendienteFechaPromesa.bind(this);
    this.onChangeCanceladosSurtidos = this.onChangeCanceladosSurtidos.bind(this);
    this.onChangePedidosVencidos = this.onChangePedidosVencidos.bind(this);
    this.onChangeProceso = this.onChangeProceso.bind(this);

    this.onDataOrganizacion = this.onDataOrganizacion.bind(this);
    this.onDataFamilia = this.onDataFamilia.bind(this);

    this.onBtnBuscar = this.onBtnBuscar.bind(this);
    this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    this.onBtnSemaforo = this.onBtnSemaforo.bind(this);

    this.refCmbDireccion = React.createRef();
    this.refCmbPlanta = React.createRef();
    this.refCmbFamilia = React.createRef();
    this.refNumCliente = React.createRef();
    this.refNumProducto = React.createRef();
  }

  getPedidos() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const urlService = `${process.env.REACT_APP_URL_API}Pedido/Busqueda`;
    let params;

    const pad = function (num) { return (`0${num}`.slice(-2)); };
    const fechaFormat = function (fecha) {
      return (`${fecha.getFullYear()}-${pad(fecha.getMonth() + 1)}-${pad(fecha.getDate())
        }T00:00:00.000Z`);
    };

    const params1 = {
      ClaDireccion: this.state.claDireccion,
      ClaSubdireccion: this.state.claSubdireccion,
      ClaZona: this.state.claZona,
      ClaAgente: this.state.claAgente,
      ClaUbicacion: this.state.claPlanta,
      ClaFamilia: this.state.claFamilia,
      ClaSubfamilia: this.state.claSubfamilia,
      ClaProducto: this.state.claProducto,
      ClaCliente: this.state.claCliente,
      ClaPedido: this.state.numPedido !== null ? this.state.numPedido : -1,
      TipoFecha: this.state.tipoFecha,
      FechaInicio: fechaFormat(this.state.fechaInicio),
      FechaFin: fechaFormat(this.state.fechaFin),
      PendFechaPromesa: this.state.pendienteFechaPromesa === true ? 1 : 0,
      CanceladoSurtido: this.state.canceladosSurtidos === true ? 1 : 0,
      PedidoVencidos: this.state.pedidoVencidos === true ? 1 : 0,
      claProceso: this.state.claProceso===null?-1:this.state.claProceso
    };
    const params2 = {
      ClaDireccion: this.state.claDireccion,
      ClaSubdireccion: this.state.claSubdireccion,
      ClaZona: this.state.claZona,
      ClaAgente: this.state.claAgente,
      ClaUbicacion: this.state.claPlanta,
      ClaFamilia: this.state.claFamilia,
      ClaSubfamilia: this.state.claSubfamilia,
      ClaProducto: this.state.claProducto,
      ClaCliente: this.state.claCliente,
      ClaPedido: this.state.numPedido !== null ? this.state.numPedido : -1,
      TipoFecha: this.state.tipoFecha,
      PendFechaPromesa: this.state.pendienteFechaPromesa === true ? 1 : 0,
      CanceladoSurtido: this.state.canceladosSurtidos === true ? 1 : 0,
      PedidoVencidos: this.state.pedidoVencidos === true ? 1 : 0,
      claProceso: this.state.claProceso===null?-1:this.state.claProceso
    };

    if (this.state.fechaInicio != null && this.state.fechaFin !== null)
      params = params1;
    else params = params2;

    this.setState({ showSpin: true });

    callApi(urlService, "POST", params, (res) => {
      const data = res;
      this.setState({ showSpin: false });

      const filtros = this.getFiltros();
      this.props.onChange(data, filtros);
    });
  }

  getFiltros() {
    const filtros = {
      claDireccion: this.state.claDireccion,
      claSubdireccion: this.state.claSubdireccion,
      claZona: this.state.claZona,
      claAgente: this.state.claAgente,
      claPlanta: this.state.claPlanta,
      claFamilia: this.state.claFamilia,
      claSubfamilia: this.state.claSubfamilia,
      claProducto: this.state.claProducto,
      claCliente: this.state.claCliente,
      numPedido: this.state.numPedido,
      tipoFecha: this.state.tipoFecha,
      fechaInicio: this.state.fechaInicio,
      fechaFin: this.state.fechaFin,
      pendienteFechaPromesa: this.state.pendienteFechaPromesa,
      canceladosSurtidos: this.state.canceladosSurtidos,
      pedidoVencidos: this.state.pedidoVencidos,
      claProceso: this.state.claProceso
    };

    return filtros;
  }

  onDataOrganizacion(data) {

    const dataOrganizacion = {
      direccion: data.direccion,
      subdireccion: data.subdireccion,
      zona: data.zona,
      agente: data.agente,
      planta: data.planta
    };

    this.setState({ dataOrganizacion });

  }

  onDataFamilia(data) {

    const dataFamilia = {
      familia: data.familia,
      subfamilia: data.subfamilia,
      producto: data.producto
    };

    this.setState({ dataFamilia });
  }

  onChangeCmbDireccion(claDireccion) {

    this.setState({ claDireccion, claSubdireccion: -1, claZona: -1, claAgente: -1 });
  }

  onChangeCmbSubdireccion(claSubdireccion) {
    this.setState({ claSubdireccion, claZona: -1, claAgente: -1 });
  }

  onChangeCmbZona(claZona) {
    this.setState({ claZona, claAgente: -1 });
  }

  onChangeCmbAgente(selectedItem) {
    const claAgente = selectedItem.value != null ? selectedItem.value : -1;

    this.setState({ claAgente });
  }

  onChangeCmbPlanta(selectedItem) {
    const claPlanta = selectedItem.value != null ? selectedItem.value : -1;

    this.setState({ claPlanta });
  }

  onChangeCmbFamilia(claFamilia) {
    this.setState({ claFamilia, claSubfamilia: -1, claProducto: -1 });
  }

  onChangeCmbSubfamilia(claSubfamilia) {
    this.setState({ claSubfamilia, claProducto: -1 });
  }

  onChangeCmbProducto(selectedItem) {
    const claProducto = selectedItem.value != null ? selectedItem.value : -1;

    this.setState({ claProducto });
  }

  onCUCSelected(object) {
    const claCliente = object.selectedItem !== null ? object.selectedItem.Clave : -1;

    this.setState({ claCliente });
  }

  onProductoSelected  (object) {
    const claProducto = object.selectedItem !== null ? object.selectedItem.Clave : -1;

    this.setState({ claProducto },()=>{ const filtros = this.getFiltros();
      this.props.onChange([], filtros);});
  }

  onChangeNumPedido(object) {
    const numPedido = object.value;

    this.setState({ numPedido });
  }

  onChangeFiltroFamilia(data) {
    this.setState({
      claFamilia: data.claFamilia,
      claSubfamilia: data.claSubfamilia,
      claProducto: data.claProducto,
      claCliente: data.claCliente,
      numPedido: data.numPedido,
    });
  }

  onChangeCmbTipoFecha(selectedItem) {
    const tipoFecha = selectedItem.value;
    this.setState({ tipoFecha });
  }

  onChangeFechaInicio(object) {
    const fechaInicio = object.value;
    this.setState({ fechaInicio });
  }

  onChangeFechaFin(object) {
    const fechaFin = object.value;
    this.setState({ fechaFin });
  }

  onChangePendienteFechaPromesa(object) {
    const pendienteFechaPromesa = object.value;
    this.setState({ pendienteFechaPromesa });
  }

  onChangeCanceladosSurtidos(object) {
    const canceladosSurtidos = object.value;
    this.setState({ canceladosSurtidos });
  }

  onChangePedidosVencidos(object) {
    const pedidoVencidos = object.value;
    this.setState({ pedidoVencidos });
  }
  
  onChangeProceso(object) {
    const claProceso = object.value;
    this.setState({ claProceso });
  }
  
  onBtnLimpiar() {

    const filtros = {
      claDireccion: -1,
      claSubdireccion: -1,
      claZona: -1,
      claAgente: -1,
      claPlanta: -1,
      claFamilia: -1,
      claSubfamilia: -1,
      claProducto: -1,
      claCliente: -1,
      numPedido: null,
      tipoFecha: 1,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      pendienteFechaPromesa: true,
      canceladosSurtidos: false,
      pedidoVencidos: false,
      claProceso: null
    };
    this.props.onChange([], filtros);


    const dataOrganizacion = {
      direccion: this.state.dataOrganizacion.direccion,
      subdireccion: [],
      zona: [],
      agente: [],
      planta: this.state.dataOrganizacion.planta
    };

    const dataFamilia = {
      familia: this.state.dataFamilia.familia,
      subfamilia: [],
      producto: [],
      claCliente: -1,
      numPedido: null
    };

    this.setState({
      dataOrganizacion,
      dataFamilia,
      claDireccion: -1,
      claSubdireccion: -1,
      claZona: -1,
      claAgente: -1,
      claPlanta: -1,
      claFamilia: -1,
      claSubfamilia: -1,
      claProducto: -1,
      claCliente: -1,
      numPedido: null,
      tipoFecha: 1,
      fechaInicio: new Date(),
      fechaFin: new Date(),
      pendienteFechaPromesa: false,
      canceladosSurtidos: false,
      pedidoVencidos: false,
      claProceso: null
    });

    const cmbDireccion = this.refCmbDireccion.current.instance;
    cmbDireccion.reset();

    const cmbPlanta = this.refCmbPlanta.current.instance;
    cmbPlanta.reset();

    const cmbFamilia = this.refCmbFamilia.current.instance;
    cmbFamilia.reset();

    const ctrlCliente = this.refNumCliente.current.instance;
    ctrlCliente.reset();

  }

  onBtnBuscar() {
    this.getPedidos();
  }

  onBtnSemaforo() {

    const filtros = this.getFiltros();
    this.props.onSemaforo(filtros);
  }

  componentDidMount() { }


  render() {
    const spinner =
      this.state.showSpin === true ? (
        <Loader type="Puff" color="#00BFFF" height={40} width={40} />
      ) : (<></>);

    return (
      <>
        <div className="content">
          <Row>
            <Col md={12} style={{ padding: 0, margin: 0 }}>
              <Card>
                <CardHeader>
                  <CardTitle>Pedidos</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginleft: 0, marginright: 0 }}>
                    <Col md={{ size: 3 }} style={{ margin: 0 }}>
                      <FiltroOrganizacion
                        etiqueta="Dirección"
                        onData={this.onDataOrganizacion}
                        data={this.state.dataOrganizacion}
                        claDireccion={this.state.claDireccion}
                        claSubdireccion={this.state.claSubdireccion}
                        claZona={this.state.claZona}
                        claAgente={this.state.claAgente}
                        claPlanta={this.state.claPlanta}
                        onChangeCmbDireccion={this.onChangeCmbDireccion}
                        onChangeCmbSubdireccion={this.onChangeCmbSubdireccion}
                        onChangeCmbZona={this.onChangeCmbZona}
                        onChangeCmbAgente={this.onChangeCmbAgente}
                        onChangeCmbPlanta={this.onChangeCmbPlanta}
                        refCmbDireccion={this.refCmbDireccion}
                        refCmbPlanta={this.refCmbPlanta}
                      />
                    </Col>
                    <Col md={{ size: 3 }} style={{ margin: 0 }}>
                      <FiltroFamilias
                        etiqueta="Dirección"
                        onData={this.onDataFamilia}
                        data={this.state.dataFamilia}
                        claFamilia={this.state.claFamilia}
                        claSubfamilia={this.state.claSubfamilia}
                        claProducto={this.state.claProducto}
                        claCliente={this.state.claCliente}
                        numPedido={this.state.numPedido}
                        refCmbFamilia={this.refCmbFamilia}
                        onChangeCmbFamilia={this.onChangeCmbFamilia}
                        onChangeCmbSubfamilia={this.onChangeCmbSubfamilia}
                        onChangeCmbProducto={this.onChangeCmbProducto}
                        onCUCSelected={this.onCUCSelected}
                        onProductoSelected={this.onProductoSelected}
                        onChangeNumPedido={this.onChangeNumPedido}
                        refNumCliente={this.refNumCliente}
                        refNumProducto={this.refNumProducto}
                      />
                    </Col>
                    <Col md={{ size: 4 }} style={{ margin: 0 }} className="align-self-start">
                      <FiltroFechas
                        etiqueta="Dirección"
                        tipoFecha={this.state.tipoFecha}
                        fechaInicio={this.state.fechaInicio}
                        fechaFin={this.state.fechaFin}
                        pendienteFechaPromesa={this.state.pendienteFechaPromesa}
                        canceladosSurtidos={this.state.canceladosSurtidos}
                        pedidoVencidos={this.state.pedidoVencidos}
                        claProceso={this.state.claProceso}
                        onChangeCmbTipoFecha={this.onChangeCmbTipoFecha}
                        onChangeFechaInicio={this.onChangeFechaInicio}
                        onChangeFechaFin={this.onChangeFechaFin}
                        onChangePendienteFechaPromesa={this.onChangePendienteFechaPromesa}
                        onChangeCanceladosSurtidos={this.onChangeCanceladosSurtidos}
                        onChangePedidosVencidos={this.onChangePedidosVencidos}
                        onChangeProceso={this.onChangeProceso}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                          Buscar
                        </Button>
                        {spinner}
                        <Button
                          type="button"
                          className="btn btn-primario"
                          onClick={this.onBtnLimpiar}
                        >
                          Limpiar
                        </Button>
                        <Button
                          type="button"
                          className="btn btn-primario"
                          onClick={this.onBtnSemaforo}
                        >
                          Semaforo
                        </Button>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
