import Usuarios from './views/Usuarios/Usuarios.js';
import Pedidos from './views/Pedidos/Pedidos.js';
import MaqSaturadas from './views/Produccion/MaquinasSaturada/MaquinasSaturada.js';
import LuisPrueba from './views/Pruebas/LuisPrueba.js';
import JeimmyPrueba from './views/Pruebas/JeimmyPrueba.js';
import FcoPrueba from './views/Pruebas/FcoPrueba.js';
import Produccion from './views/Produccion/Produccion/Produccion.js';
import FactorConversion from './views/Produccion/FactorConversion/FactorConversion.js';
import Faltantes from './views/Produccion/Faltantes/Faltantes.js';
import Configuracion from './views/Configuracion/Configuracion.js';
import Contratos from './views/Produccion/Contratos/Contratos.js';
import Vencimientos from './views/Vencimientos/Vencimientos.js';
import Embarques from './views/Embarque/Embarque.js';
import LoteMinimo from './views/LoteMinimo/LoteMinimo.js';
import Acerias from './views/Produccion/Acerias/Acerias.js';
import ReportePedidosSinFecha from './views/Pedidos/PedidosSinFecha/ReportePedidosSinFecha.js';
import PedidosPorRecalcular from './views/Pedidos/PedidosPorRecalcular/Pedidos.js';
import TraspHorizonte from './views/Traspasos/Horizonte/Horizonte.js';

const routes = [
  
  {
    path: '/Pedidos',
    name: 'Pedidos',
    icon: 'fas fa-boxes deacero-text-primary',
    component: Pedidos,
    layout: '/layout',
    active: true,
  },  
  {
    collapse: true,
    name: 'Producción',
    icon: 'fas fa-industry deacero-text-primary',
    state: false,
    views: [
      {
        path: '/Produccion',
        name: 'Capacidad Producción',
        icon: 'fas fa-pallet deacero-text-primary',
        component: Produccion,
        layout: '/layout',
        parent: 'Produccion'
      },
      {
        path: '/Acerias',
        name: 'Capacidad Producción Acerías',
        icon: 'fas fa-pallet deacero-text-primary',
        component: Acerias,
        layout: '/layout',
        parent: 'Produccion'
      },
      {
        path: '/FactorConversion',
        name: 'Factor de Conversión',
        icon: 'fas fa-square-root-alt deacero-text-primary',
        component: FactorConversion,
        layout: '/layout',
        parent: 'Produccion'
      },
      {
        path: '/MaqSaturadas',
        name: 'Gpo. Maquinas Saturadas',
        icon: 'fas fa-chart-line deacero-text-primary',
        component: MaqSaturadas,
        layout: '/layout',
        parent: 'Produccion'
      },  
      {
        path: '/Contratos',
        name: 'Contratos',
        icon: 'fas fa-copy deacero-text-primary',
        component: Contratos,
        layout: '/layout',
        parent: 'Produccion'
      },
      {    
        path: '/LoteMinimo',
        name: 'Lote Mínimo',
        icon: 'fas fa-dolly-flatbed deacero-text-primary',
        component: LoteMinimo,
        layout: '/layout',
        parent: 'Produccion'
      },
      {
        path: '/Faltantes',
        name: 'Faltantes',
        icon: 'fas fa-clipboard-check deacero-text-primary',
        component: Faltantes,
        layout: '/layout',
        parent: 'Produccion'
      },
    ]
  },  
  {
    path: '/Vencimientos',
    name: 'Vencimientos',
    icon: 'fas fa-history deacero-text-primary',
    component: Vencimientos,
    layout: '/layout',
    active: false,
  },
  {
    path: '/Embarques',
    name: 'Embarques',
    icon: 'fas fa-warehouse deacero-text-primary',
    component: Embarques,
    layout: '/layout',
    active: false,
  },
  {
    path: '/Usuarios',
    name: 'Usuarios',
    icon: 'fas fa-users deacero-text-primary',
    component: Usuarios,
    layout: '/layout',
    active: false,
  },
  {
    path: '/Configuracion',
    name: 'Configuración',
    icon: 'fas fa-cogs deacero-text-primary',
    component: Configuracion,
    layout: '/layout',
    active: false,
  },
  {
    collapse: true,
    name: 'Reportes',
    icon: 'fas fa-file-excel deacero-text-primary',
    state: false,
    views: [      
      {
        collapse: true,
        name: 'Calidad de Fecha',
        icon: 'fas fa-certificate deacero-text-primary',
        state: false,
        views: [  
          {
            path: '/ReportePedidosSinFecha',
            name: 'Pedidos sin Fecha',
            icon: 'far fa-calendar-times deacero-text-primary',
            component: ReportePedidosSinFecha,
            layout: '/layout',
            active: false,
          } ,
          {
            path: '/PedidosPorRecalcular',
            name: 'Pedidos por ReCalcular',
            icon: 'far fa-calendar-times deacero-text-primary',
            component: PedidosPorRecalcular,
            layout: '/layout',
            active: false,
          }   
        ]
      }
    ]
  },
  {
    collapse: true,
    name: 'Traspasos',
    icon: 'fas fa-file-excel deacero-text-primary',
    state: false,
    views: [      
      {
        path: '/Horizontes',
        name: 'Horizontes',
        icon: 'far fa-calendar-times deacero-text-primary',
        component: TraspHorizonte,
        layout: '/layout',
        active: false,
      }   
    ]
  },
];

export default routes;
