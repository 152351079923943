/*eslint-disable*/
import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';

import { config } from '../../../../utils/config';
import CapacidadGridDet from './CapacidadGridDet'

class CapaidadMasterDetail extends React.Component {
    constructor(props) {
        super(props);

        this.renderDetalleTab = this.renderDetalleTab.bind(this);
        this.dataDetalle = [];
    }

    componentDidMount() { 
    }

    renderDetalleTab() {
      console.log(this.props.data.data.idPlan)
      return <CapacidadGridDet idPlan={this.props.data.data.idPlan} fechaCalculo={this.props.data.data.fechaCalculo} />;
    }

    render() {
        return (
          <TabPanel>
            <Item title="Detalle" render={this.renderDetalleTab} />
          </TabPanel>
        );
    }
}

export default CapaidadMasterDetail;
