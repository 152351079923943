/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column, Format, Paging, GroupPanel, Scrolling } from 'devextreme-react/data-grid';
import { Form, Item, Label } from 'devextreme-react/form';
import { config } from 'utils/config';
import { callApi} from 'utils/utils';
import CtrlComboBox from 'views/Controles/CtrlComboBox';

export default class ContratosTabReservaCap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndex: 0,
            dataDetalle:[],
            dataProds:[],
            claProducto:null
        }
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.getProduccionData = this.getProduccionData.bind(this);
        this.onGridPage = this.onGridPage.bind(this);
        this.onCellPrepared= this.onCellPrepared.bind(this);
        this.onChangeCmbProducto=this.onChangeCmbProducto.bind(this);
        this.renderSelectBox=this.renderSelectBox.bind(this);
        this.renderGrid=this.renderGrid.bind(this);
        
        

        this.getProduccionData();
    }

    getProduccionData() {
        const filtrosPedidos = {            
            fechaCalculo: this.props.fechaCalculo,
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claPedido: this.props.claPedido,
            modoVista: 7
        };

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;

        callApi(urlApiService, 'POST', filtrosPedidos, (result) => {
            this.setState({
                dataDetalle: result[0], dataProds:result[1]
            });
        });
    }

    onCellPrepared(e){

      if (e.column.type == 'detailExpand'  || e.column.type == 'groupExpand'){
         e.column.width=10;
      }
      else 
      e.column.width=10;
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.onGridPage(e.value);
        }
    }

    onChangeCmbProducto(selectedItem){
        const claProducto = selectedItem.value;
        this.setState({ claProducto });
    }

    renderSelectBox() {
        return  <CtrlComboBox
            etiqueta="Producto"
            valueExpr="claArticulo"
            displayExpr="nomArticulo"
            onChange={this.onChangeCmbProducto}
            data={this.state.dataProds}
            value={this.state.claProducto}
            tipo={2}
        />
    }

    renderGrid() {
        
    let data=this.state.dataDetalle;
    
    if(this.state.claProducto!==null){
        data=this.state.dataDetalle.filter((item) =>this.state.claProducto == item.claArticulo)
    }
        

        return  (<DataGrid
            dataSource={data}
            height={600}
            width="100%"
            selection={{ mode: 'single' }}
            noDataText="Sin Registros"
            onOptionChanged={this.handleOptionChange}
            >
            <Column dataField="claPeriodo" caption="Periodo" width={30} />
            <Column dataField="claProductoAto" caption="Producto" width="30"/>
            <Column dataField="claArticuloComp" caption="Producto Composición" width={100} />
            <Column dataField="nivelComposicion" caption="Nivel" width={25} alignment="center"/>
            <Column dataField="nomGrupoMaquina" caption="Grupo Maquina" width={45} alignment="center"/>
            
            <Column dataField="capacidadMaxima" caption="Maxima" width={30} dataType="number">
                <Format type="fixedPoint" precision={2}/>
            </Column>
            <Column dataField="capacidadDisponible" caption="Disponible" width={50} dataType="number">
                <Format type="fixedPoint" precision={2}/>
            </Column>


            <Column dataField="deudaOriginalComposicion" caption="Deuda" width={40} dataType="number">
                <Format type="fixedPoint" precision={3}/>
            </Column>               
            <Column dataField="deudaActual" caption="Deuda Actual" width={40} dataType="number">
                <Format type="fixedPoint" precision={3}/>
            </Column>               
            
            <Column dataField="horasReservados" caption="Reservado" width={40}  dataType="number">
                <Format type="fixedPoint" precision={2}/>
            </Column>
            <Column dataField="deudaPendiente" caption="Pendiente" width={30} dataType="number">
                <Format type="fixedPoint" precision={2}/>
            </Column>

            <Column dataField="horasDisponibles" caption="Restante" width={40} dataType="number">
                <Format type="fixedPoint" precision={3}/>
            </Column>               

            <Paging
                enabled={false} 
                pageSize={400}
                pageIndex={this.props.pageIndex}
            />
            <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
        );
    }

    render() {

        return (
        <>
            <Form labelLocation="top" className="form-container">
                <Item render={this.renderSelectBox} colCount={2}>
                    <Label text="Producto" />
                </Item>
               <Item render={this.renderGrid}>
                    <Label text="Detalle Reserva" />
                </Item>
            </Form>
        
        </>
        );

    }
}