/*eslint-disable*/
import React from 'react';
import DataGrid, {
  MasterDetail,
  Column,
  Paging,
  Format,
  Export,
  Scrolling,
} from 'devextreme-react/data-grid';
import { Row, Col } from 'reactstrap';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import LoteMinimoMasterDetail from './LoteMinimoMasterDetail';

export default class LoteMinimoGrid extends React.Component {
  constructor(props) {
    super(props);

    this.dataGrid = null;

    this.state = {
      detGridClass: 'd-none',
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.onExporting = this.onExporting.bind(this);
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.props.onPage(e.value);
    }
  }

  onExporting(e) {
    this.setState({
      detGridClass: '',
    });
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    exportDataGrid({
      component: this.dataGridDet.instance,
      worksheet: worksheet,
      customizeCell: function (options) {
        const excelCell = options;
        excelCell.font = { name: 'Arial', size: 12 };
        excelCell.alignment = { horizontal: 'left' };
      },
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'LoteMínimo.xlsx');
      });
    });
    e.cancel = true;
    this.setState({
      detGridClass: 'd-none',
    });
    // this.getDetalle();
    // e.cancel = true;
  }

  render() {
    const gridContent = (
      <Row>
        <Col>
          <Row>
            <Col>
              <DataGrid
                id="gridProduccion"
                ref={(ref) => (this.dataGrid = ref)}
                dataSource={this.props.dataSource[0]}
                key={'idPlan'}
                width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                onExporting={this.onExporting}
                onCellPrepared={this.onCellPrepared}
              >
                <MasterDetail enabled={true} component={LoteMinimoMasterDetail} />

                <Column dataField="IdLote" caption="Número de Lote" width={40} />
                <Column
                  dataField="ClaUbicacionLote"
                  caption="Ubicación"
                  width={120}
                  visible={false}
                />
                <Column dataField="NomUbicacion" caption="Ubicación" width={80} />
                <Column dataField="ClaArticulo" caption="Artículo" width={120} visible={false} />
                <Column dataField="NomArticulo" caption="Artículo" width={80} />
                <Column
                  dataField="FechaCreacionLote"
                  caption="Fecha Creación de Lote"
                  dataType={'date'}
                  format={'dd/MM/yyyy'}
                ></Column>
                <Column
                  dataField="FechaCompletoLote"
                  caption="Fecha de Lote Completo"
                  dataType={'date'}
                  format={'dd/MM/yyyy'}
                ></Column>
                <Column
                  dataField="FechaCierreLote"
                  caption="Fecha Cierre de Lote"
                  dataType={'date'}
                  format={'dd/MM/yyyy'}
                ></Column>
                <Column dataField="CantidadloteMinimo" caption="Cantidad de Lote" width={40} />
                <Column dataField="CantidadDeuda" caption="Deuda del Lote" width={40} />
                <Column dataField="ClaEstatusLote" caption="Estatus" width={120} visible={false} />
                <Column dataField="NomEstatus" caption="Estatus" width={120} />

                <Paging pageSize={30} pageIndex={this.props.pageIndex} />

                <Scrolling columnRenderingMode="virtual" />

                <Export enabled={true} allowExportSelectedData={false} />
              </DataGrid>
            </Col>
          </Row>
          <Row className={this.state.detGridClass}>
            <Col>
              <DataGrid
                ref={(ref) => (this.dataGridDet = ref)}
                dataSource={this.props.dataSource[1]}
                height={600}
                width="100%"
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
              >
                <Column dataField="IdLote" caption="Lote" width={100} />
                <Column dataField="ClaPedido" caption="Pedido" width={100} />
                <Column dataField="ClaUbicacion" caption="Ubicación" width={100} visible={false} />
                <Column dataField="NomUbicacion" caption="Ubicación" width={120} />
                <Column
                  dataField="ClaUbicacionElabora"
                  caption="Ubicación Elabora"
                  width={100}
                  visible={false}
                />
                <Column dataField="NomUbicacionElabora" caption="Ubicación Elabora" width={120} />
                <Column dataField="IdRenglon" caption="Renglón" width={100} />
                <Column dataField="ClaArticulo" caption="Artículo" width={100} visible={false} />
                <Column dataField="NomArticulo" caption="Artículo" width={120} />
                <Column dataField="CantidadDeuda" caption="Deuda" width={100} />
                <Column
                  dataField="FechaCreacionLote"
                  caption="Fecha Creación de Lote"
                  dataType={'date'}
                  format={'dd/MM/yyyy'}
                ></Column>
                <Column
                  dataField="FechaCompletoLote"
                  caption="Fecha de Lote Completo"
                  dataType={'date'}
                  format={'dd/MM/yyyy'}
                ></Column>
                <Column
                  dataField="FechaCierreLote"
                  caption="Fecha Cierre de Lote"
                  dataType={'date'}
                  format={'dd/MM/yyyy'}
                ></Column>
                <Column dataField="CantidadloteMinimo" caption="Cantidad de Lote" width={40} />
                <Column dataField="NomEstatus" caption="Estatus" width={120} />
              </DataGrid>
            </Col>
          </Row>
        </Col>
      </Row>
    );

    return <>{gridContent}</>;
  }
}
