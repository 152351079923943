/*eslint-disable*/
import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';

import { config } from '../../../utils/config';
import AceriasGridDet from './AceriasGridDet';

export default class AceriasMasterDetail extends React.Component {
  constructor(props) {
    super(props);

    this.renderDetalleTab = this.renderDetalleTab.bind(this);
    this.dataDetalle = [];
  }

  renderDetalleTab() {
    return (
      <AceriasGridDet
        idProduccion={this.props.data.data.IdProduccion}
        IdProduccionDet={this.props.data.data.IdProduccionDet}
        fechaProduccion={this.props.data.data.FechaPlanCampana}
        claUbicacion={this.props.data.data.ClaUbicacion}
      />
    );
  }

  render() {
    return (
      <TabPanel>
        <Item title="Detalle" render={this.renderDetalleTab} />
      </TabPanel>
    );
  }
}
