/*eslint-disable*/
import React, { useState } from 'react';
import { Row, Col, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import TraceInventario from './TraceInventario';
import TraceInventarioAceria from './TraceInventarioAceria';
import TraceTraspaso from './TraceTraspaso';
import TraceTraspasoTE from './TraceTraspasoTE';
import TraceViaje from './TraceViaje';
import TraceProduccion from './TraceProduccion';

import '../../../assets/css/popover.css';

const TracePopupOver = (props) => {
  const { value, data, tipo, idRenglon, claUbicacion } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  console.log('dentro de tracepopupover', props.showSegementacion, props);
  const toggle = () => setPopoverOpen(!popoverOpen);

  const idBtn = `Popover${tipo}${claUbicacion}`;
  const claseBase = tipo !== 5 ? 'mb-0 icon icon-shape text-white rounded-circle shadow ' : '';
  const clase =
    claseBase + (value > 0 && tipo !== 5 ? 'bg-warning' : tipo !== 5 ? 'bg-info' : 'mb-0 link');

  let esAceria = false;

  if (tipo === 1 && props.data.inventarios.length > 0)
    esAceria = props.data.inventarios[0].ArticuloAceria !== null;

  const trace =
    tipo === 1 && esAceria === false ? (
      <TraceInventario
        data={props.data.inventarios}
        idRenglon={idRenglon}
        claUbicacion={claUbicacion}
      ></TraceInventario>
    ) : tipo === 1 && esAceria === true ? (
      <TraceInventarioAceria
        data={props.data.inventarios}
        idRenglon={idRenglon}
        claUbicacion={claUbicacion}
      ></TraceInventarioAceria>
    ) : tipo === 2 ? (
      <TraceTraspaso
        data={props.data.traspasos}
        idRenglon={idRenglon}
        claUbicacion={claUbicacion}
      ></TraceTraspaso>
    ) : tipo === 3 ? (
      <TraceViaje
        data={props.data.viajes}
        idRenglon={idRenglon}
        claUbicacion={claUbicacion}
      ></TraceViaje>
    ) : tipo === 4 ? (
      <TraceProduccion
        data={props.data.produccion}
        idRenglon={idRenglon}
        claUbicacion={claUbicacion}
        showSegementacion={props.showSegementacion}
      ></TraceProduccion>
    ) : tipo === 5 ? (
      <TraceTraspasoTE
        data={props.data}
        idRenglon={idRenglon}
        claUbicacion={claUbicacion}
      ></TraceTraspasoTE>
    ) : (
      <></>
    );

  let titulo =
    tipo === 1
      ? 'Detalle Inventario '
      : tipo === 2
      ? 'Detalle traspasos'
      : tipo === 3
      ? 'Detalle viajes'
      : tipo === 4
      ? 'Capacidad Producción'
      : tipo === 5
      ? 'Traspaso TE'
      : '';

  titulo = `${titulo} - renglon ${idRenglon}`;

  const header = (value > 0 ? <h5 id={idBtn} className={clase} style={{ cursor: "pointer" }}> <u>{value.toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits:2})} </u></h5> : <h5 id={idBtn} className={clase}> {value}</h5>);

  return (
    <span>
      {header}
      <Popover placement="left" isOpen={popoverOpen} target={idBtn} toggle={toggle}>
        <PopoverHeader>{titulo}</PopoverHeader>
        <PopoverBody>{trace}</PopoverBody>
      </Popover>
    </span>
  );
};

export default TracePopupOver;
