/*eslint-disable*/
import React from 'react';
import AppContext from "../../../context/AppContext";

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

import Template2Grid from './Template2Grid';

export default class Template2 extends React.Component {
    constructor(props) {
        super(props);
        
        const filtros = {
            claUbicaion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            idConfiguracion: null,
            claTemplate: 2
        };

        this.state = {
            showSpin: false,
            filtros,            
            ubicacionesData: [],
            listSelectedUbicaciones: []
        }

        this.getConfiguraciones = this.getConfiguraciones.bind(this);
        this.guardarConfiguracion = this.guardarConfiguracion.bind(this);
        this.limpiaCambios = this.limpiaCambios.bind(this);
    }

    guardarConfiguracion(data){
        data.idConfiguracion = this.props.configuracionValue
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/UpdateTemplate2`;
        callApi(urlApiService, 'POST', data , (result) => {
        });
    }

    limpiaCambios(){
        // this.setState({
        //     selectedUbicaciones: this.state.listSelectedUbicaciones
        // })
        this.getConfiguraciones();
    }

    getConfiguraciones(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;
        
        var _result = null
        callApi(urlApiService, 'POST', this.state.filtros , (result) => {
            _result = result[1][0].ValorString1.split(',');

            var formatedData = [];
            var _selectedUbicaciones = [];

            result[0].forEach((element, index) => {
                var obj = null;

                if(_result.findIndex((x) => x==element.clave) > -1){
                    obj = {
                        'id': index,
                        'nombre': element.nombre,
                        'clave': element.clave,
                        'selected': true
                    }
                    _selectedUbicaciones.push({'nombre': element.nombre});
                }
                else{
                    obj = {
                        'id': index,
                        'nombre': element.nombre,
                        'clave': element.clave,
                        'selected': false
                    }
                } 

                formatedData.push(obj);
            });

            this.setState({
                ubicacionesData: formatedData,
                listSelectedUbicaciones : _selectedUbicaciones
            })
        });
    }

    componentDidMount(){
        let newfiltros = this.state.filtros;
        newfiltros.idConfiguracion = this.props.configuracionValue
        this.setState({
            filtros: newfiltros
        })
        this.getConfiguraciones();
    }

    componentDidUpdate(){
        if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        {
            let newfiltros = this.state.filtros;
            newfiltros.idConfiguracion = this.props.configuracionValue
            this.state.filtros = newfiltros;
            this.getConfiguraciones();
        }
    }

    render(){
        return(
            <div className="content">
                <Row>
                    <Col>
                        <Template2Grid 
                            ubicacionesData={this.state.ubicacionesData}
                            selectedUbicaciones={this.state.listSelectedUbicaciones}
                            configuracionValue={this.state.configuracionValue}
                            guardarConfiguracion={this.guardarConfiguracion}
                            limpiaCambios={this.limpiaCambios}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}