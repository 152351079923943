/*eslint-disable*/
import React from 'react';
import { Popup } from 'devextreme-react/popup';
import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { locale } from "devextreme/localization";
import CtrlComboBox from '../../Controles/CtrlComboBox';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import Template4Grid from './Template4Grid';
import Template4Alta from './Template4Alta';

export default class Template4 extends React.Component{
    constructor(props) {
        super(props);

        const filtros = {
            claUbicaion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            idConfiguracion: null,
            claTemplate: 4
        };
      
        this.state = {
            filtros,
            configuracionData: [],
            addedConfig:[],
            pageIndex: 0,
            popupVisible: false,
        }
        
        this.onChangeUbicacion = this.onChangeUbicacion.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.onClickAgregar = this.onClickAgregar.bind(this);
        this.hidePopUp = this.hidePopUp.bind(this);
        this.getProductos = this.getProductos.bind(this);
        this.getConfiguracionData = this.getConfiguracionData.bind(this);
        this.addConfiguracion = this.addConfiguracion.bind(this);
        this.onGridPage = this.onGridPage.bind(this);  
        this.validateRemove = this.validateRemove.bind(this);
        this.handler = this.handler.bind(this);
    }

    getProductos(){
        this.setState({
            productosData: []
        });
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            let MyA = res.Result0.filter(u => !u.Nombre.includes('Acería'));

            this.setState({
                ubicacionesData: MyA
            })
        });
    }

    getConfiguracionData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            this.setState({
                configuracionData: result,
                showSpin: false
            });
        });
    }

    validateRemove(e){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/DeleteTemplate4`;

        var params = {
            idCfgConfiguracionGeneral: e.data.IdCfgConfiguracionGeneral,
        }

        callApi(urlApiService, 'POST', params, (result) => {
            this.getConfiguracionData();
        });
    }

    handler(val) {
        this.setState({
          popupVisible: val,
        });
    }

    hidePopUp() {
        this.setState({
          popupVisible: false,
        });
    }

    addConfiguracion(item){
        // this.state.addedConfig.push(item);
        
        this.state.configuracionData.push(item);
    }

    onChangeUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;

        this.setState({
            filtros: newFiltro
        });
        
        this.getConfiguracionData();
    }

    onClickAgregar(e) {
        this.setState({
          popupVisible: true,
        });
    
        e.stopPropagation();
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }
    
    componentDidMount(){
        let newfiltros = this.state.filtros;
        newfiltros.idConfiguracion = this.props.configuracionValue
        this.setState({
            filtros: newfiltros
        })
        this.getConfiguracionData();  
        this.getUbicaciones();      
    }
    
    componentDidUpdate(){
        if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        {
            let newfiltros = this.state.filtros;
            newfiltros.idConfiguracion = this.props.configuracionValue
            this.state.filtros = newfiltros;
            this.getConfiguracionData();  
            this.getUbicaciones();      
        }
    }

    render(){
        return(
            <div className="content">
                <Row>
                    <Col md={{ size: 5 }}>
                        <CtrlComboBox
                            etiqueta="Ubicación"
                            onChange={this.onChangeUbicacion}
                            data={this.state.ubicacionesData}
                            value={this.state.filtros.claUbicacion}
                            reference={this.props.refCmbUbicacion}                                        
                        />
                    </Col>
                    <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                        <Button
                            id="btnNuevo"
                            onClick={this.onClickAgregar}
                            size="md"
                            className="btn btn-primary btn-round btn-icon btn-custom mb-1"
                        >
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Template4Grid
                            dataSource={this.state.configuracionData}
                            addConfiguracion={this.addConfiguracion}
                            idConfiguracion={this.props.configuracionValue}
                            type={2}
                            addedConfig={this.state.addedConfig}
                            pageIndex={this.state.pageIndex}
                            onPage={this.onGridPage}
                            validateRemove={this.validateRemove}
                        />
                    </Col>
                </Row>
                <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Configuración"
                    width={470}
                    height={600}
                    >
                    <Template4Alta 
                        idConfiguracion={this.props.configuracionValue}
                        handler={this.handler}
                    />
                </Popup>
            </div>
        );
    }
}