/*eslint-disable*/
import React from 'react';
import AppContext from "../../../context/AppContext";

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";
import { Switch } from "devextreme-react/switch";
import RadioGroup from 'devextreme-react/radio-group';
import Tabs from 'devextreme-react/tabs';

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import Pedidos from './Pedidos/Pedidos';
import Capacidad from './Capacidad/Capacidad';
import Contratos from './Contratos/Contratos';

export default class Produccion extends React.Component {
    constructor(props) {
        super(props);

        locale('en');

        const vistas = [
            {
                id: 0,
                text: 'Producción',
                content: 'Producción',
            },
            {
                id: 1,
                text: 'Pedidos',
                content: 'Pedidos',
            },
            {
                id: 2,
                text: 'Contratos',
                content: 'Contratos',
            }
        ]

        this.state = {
            filtroProduccionHidden: false,
            filtroPedidosHidden:true,
            filtroContratosHidden:true,
            vistas,
            produccionModo: 0,
            numPedido: null
        };

        this.produccionModoValueChanged  = this.produccionModoValueChanged.bind(this);
        
    }

    onChangeNumPedido(e){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.claPedido = e.value;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        });
    }

    produccionModoValueChanged = e => {
        if (e.name === 'selectedIndex') {
            if(e.value == 0){
                this.setState({
                    filtroProduccionHidden: false,
                    filtroPedidosHidden: true,
                    filtroContratosHidden: true,
                    produccionModo: e.value
                })
            }
            else if (e.value == 1){
                this.setState({
                    filtroProduccionHidden: true,
                    filtroPedidosHidden: false,
                    filtroContratosHidden: true,
                    produccionModo: e.value,
                })
            }
            else if(e.value == 2){
                this.setState({
                    filtroProduccionHidden: true,
                    filtroPedidosHidden: true,
                    filtroContratosHidden: false,
                    produccionModo: e.value,
                })
            }
        }
    }
    
    componentDidMount(){
    }

    render(){

        let filtro =<></>
        let grid =<></>
        let content = null;

        if(this.state.filtroProduccionHidden===false){
            content = (<Capacidad />);
        }

        if(this.state.filtroPedidosHidden===false){
            content = (<Pedidos />);
        }

        if(this.state.filtroContratosHidden===false){
            content = (<Contratos />)
        }

        return(
            <>
                <Tabs
                    dataSource={this.state.vistas}
                    selectedIndex={this.state.produccionModo}
                    onOptionChanged={this.produccionModoValueChanged}
                />                
                <div className="content"> 
                    {content}
                </div>
            </>
        )
    };
}
