/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Scrolling, Export } from 'devextreme-react/data-grid';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';

export default class PedidoReleaseDetGrid extends React.Component {
    constructor(props) {
        super(props);

        this.dataGrid = null;
        
        this.onExporting = this.onExporting.bind(this);
    }
    
    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: this.dataGrid.instance,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Detalle.xlsx');
                });
        });
        e.cancel = true;
    }

    render() {
        const gridContent = (
            <div>
              <DataGrid
                id="gridDetalle"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.data}
                keyExpr="IdRenglon"
                height={600}
                width="100%"
                selection={{ mode: 'single' }}
                showBorders={false}
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onContentReady={this.onContentReady}
                onSelectionChanged={this.onSelectionChanged}
                onExporting={this.onExporting}
              >
                <Column dataField="IdRenglon" caption="#" width={30} alignment="right" />
                <Column dataField="Producto" caption="Producto" width={200} />
                <Column dataField="Deuda" caption="Cant" width={50} format="#,##0" />
                <Column dataField="Unidad" caption="UM" width={50} />
                <Column dataField="KilosSolicitados" caption="Cant Kgs" width={50} format="#,##0" />
                <Column dataField="KilosSurtidos" caption="Surt. Kgs" width={50} format="#,##0" />
                <Column dataField="Estatus" caption="Estatus" width={70} />
                <Column dataField="FechaEmbarque" caption="Fecha Embarque" width={120} />
                <Scrolling columnRenderingMode="virtual" />
                
                <Export enabled={true} allowExportSelectedData={true} />
              </DataGrid>
            </div>
        );

        return (
          <>
            <Row>
                <Col md={{ size: 12 }}>
                    <Card>
                        <CardHeader>
                            <CardTitle>DETALLE DEL PEDIDO {this.props.claPedido}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    {gridContent}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          </>
        )
    }
}