import React from "react";
import { Row, Col } from "reactstrap";

const TraceTraspaso = props => {
  const { data, idRenglon, claUbicacion } = props;

  const lista = data
    .filter((item) => item.IdRenglon === idRenglon && item.ClaUbicacion === claUbicacion)
    .map((item, index) => {
      const id = `TraspasoId-${index}`;
      return (
        <Row key={id}>
          <Col className="col-12">
            <Row>
              <Col>
                Recepción: <b>{item.FechaRecepcion}</b>
              </Col>
              <Col>
                Traspaso:<b>{item.IdTraspaso}</b>
              </Col>
            </Row>
            <Row>
              <Col>
                Cantidad Asignada:{item.Reservada}
              </Col>
              <Col>
                Cantidad Reservada: {item.Total}
              </Col>
            </Row>
            <Row className="d-flex align-items-start">
              <Col>
                Destino:{item.Destino}
              </Col>
              <Col>
                Origen: <b>{item.Origen}</b>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">&nbsp;</Col>
            </Row>
          </Col>
        </Row>
      );
    });

  return lista;
};


export default TraceTraspaso;