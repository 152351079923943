import React from 'react';
import { Row, Col } from 'reactstrap';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlNumerico from '../../Controles/CtrlNumerico';
import CtrlAutoComplete from '../../Controles/CtrlAutoComplete';
import { callKrakenApi } from '../../../utils/utils';

export default class FiltroFamilias extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFamilia: [],
      dataSubfamilia: [],
      dataProducto: [],
      dataAgente: [],
      dataUbicaciones: [],
    };

    this.onChangeCmbFamilia = this.onChangeCmbFamilia.bind(this);
    this.onChangeCmbSubfamilia = this.onChangeCmbSubfamilia.bind(this);
  }

  getFamilia() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 1,
      IncluirTodosSN: 0,
      ClaNivel: 20,
    };

    callKrakenApi(25, 1, params, 1, (res) => {
      const data = res.Result0;
      if (this.props.claSubfamilia !== -1) {
        this.getSubfamilia(this.props.claFamilia);
      }

      this.dataLoad(data, this.props.data.subfamilia, this.props.data.producto);
    });
  }

  getSubfamilia(claFamilia) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 1,
      IncluirTodosSN: 0,
      ClaFamilia: claFamilia,
    };

    callKrakenApi(25, 2, params, 1, (res) => {
      const data = res.Result0;
      if (this.props.claSubfamilia !== -1) {
        this.getProductos(this.props.claFamilia, this.props.claSubfamilia);
      }

      this.dataLoad(this.props.data.familia, res.Result0, this.props.data.producto);
    });
  }

  getProductos(claFamilia, claSubfamilia) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 1,
      IncluirTodosSN: 0,
      ClaFamilia: claFamilia,
      ClaSubFamilia: claSubfamilia,
    };

    callKrakenApi(25, 3, params, 1, (res) => {
      const data = res.Result0;
      if (data.length > 0) {
        this.dataLoad(this.props.data.familia, this.props.data.subfamilia, res.Result0);
      } else {
        this.dataLoad(this.props.data.familia, this.props.data.subfamilia, []);
      }

    });
  }

  dataLoad(familia, subfamilia, producto) {

    const dataOrganizacion = {
      familia,
      subfamilia,
      producto,
    };

    this.props.onData(dataOrganizacion);
  }

  onChangeCmbFamilia(selectedItem) {
    const claFamilia = selectedItem.value != null ? selectedItem.value : -1;

    if (claFamilia !== -1) {
      this.getSubfamilia(claFamilia);
    }

    this.props.onChangeCmbFamilia(claFamilia);
  }

  onChangeCmbSubfamilia(selectedItem) {
    const claSubfamilia = selectedItem.value != null ? selectedItem.value : -1;

    if (claSubfamilia !== -1) {
      this.getProductos(this.props.claFamilia, claSubfamilia);
    }

    this.props.onChangeCmbSubfamilia(claSubfamilia);
  }

  componentDidMount() {
    this.getFamilia();
  }

  render() {

    return (
      <>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Familia"
              onChange={this.onChangeCmbFamilia}
              data={this.props.data.familia}
              value={this.props.claFamilia}
              reference={this.props.refCmbFamilia}
              searchExpr={['Nombre', 'Clave2']}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Subfamilia"
              onChange={this.onChangeCmbSubfamilia}
              data={this.props.data.subfamilia}
              value={this.props.claSubfamilia}
              searchExpr={['Nombre', 'Clave2']}
            />
          </Col>
        </Row>
        <Row
          style={{ padding: 0, margin: 0, marginBottom: 1, alignItems: 'center' }}
          className="mb-1"
        >
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlAutoComplete
              etiqueta="Producto"
              placeholder="Clave o Nombre del producto..."
              valueExpr="Nombre"
              displayExpr="Nombre"
              keyValue="ClaCliente"
              claFamilia={this.props.claFamilia}
              claSubfamilia={this.props.claSubfamilia}
              claProducto={25}
              idEntidad={3}
              onChange={this.props.onProductoSelected}
              value={this.props.claProducto}
              reference={this.props.refNumProducto}
            />
          </Col>
        </Row>
        <Row
          style={{ padding: 0, margin: 0, marginBottom: 1, alignItems: 'center' }}
          className="mb-1"
        >
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlAutoComplete
              etiqueta="Cliente"
              placeholder="Clave o Nombre del cliente..."
              valueExpr="NomCliente"
              displayExpr="NomCliente"
              keyValue="ClaCliente"
              claProducto={23}
              idEntidad={17}
              onChange={this.props.onCUCSelected}
              value={this.props.claCliente}
              reference={this.props.refNumCliente}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <CtrlNumerico
              etiqueta="Número Pedido"
              onChange={this.props.onChangeNumPedido}
              value={this.props.numPedido}
            />
          </Col>
        </Row>
      </>
    );
  }
}
