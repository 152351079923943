import React from "react";
import { Row, Col } from "reactstrap";

const TraceInventario = props => {
  const { data, idRenglon, claUbicacion } = props;

  const lista = data
    .filter((item) => item.IdRenglon === idRenglon && item.ClaUbicacion === claUbicacion)
    .map((invent, index) => {
      const id = `Id-${index}`;

      const diaComprado = invent.DiasProductoComprado > 0 ? <Row><Col><b>Dias Producto Comprado:</b> {invent.DiasProductoComprado} </Col></Row> : <></>;
      const diasDesviacion = invent.DiasDesviacion > 0 ? <Row><Col><b>Dias Desviacición:</b> {invent.DiasDesviacion} </Col></Row> : <></>;

      return (
        <Row key={id}>
          <Col>
            <Row>
              <Col>
                Asignación a Inventario: {invent.Asignado}
              </Col>
              <Col>
                Inventario Total: {invent.Total}
              </Col>
            </Row>
            {diaComprado}
            {diasDesviacion}
          </Col>
        </Row>
      );
    });

  return lista;
};


export default TraceInventario;