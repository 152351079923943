/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, Editing, Lookup } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';
import TreeView from 'devextreme-react/tree-view';
import { element } from 'prop-types';
import DropDownBox from 'devextreme-react/drop-down-box';

export default class TemplateGrid2 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {            
        }

        this.treeView = null;
        this.selectedTreeView = null;

        this.onBtnGuardar = this.onBtnGuardar.bind(this);
        this.onBtnEliminarCambios = this.onBtnEliminarCambios.bind(this);
        this.treeViewRender = this.treeViewRender.bind(this);
        this.selectedTreeViewRender = this.selectedTreeViewRender.bind(this);
    }

    treeViewRender(){
        return(
            <TreeView
                ref={(ref) => this.treeView = ref}
                id="treeview"
                items={this.props.ubicacionesData}
                showCheckBoxesMode={"normal"}
                selectionMode={"multiple"}
                searchEnabled={true}
                searchMode="contains"
                displayExpr="nombre"
                keyExpr="id"
                itemRender={renderTreeViewItem}
            />
        );
    }

    selectedTreeViewRender(){
        return(
            <TreeView
                ref={(ref) => this.selectedTreeView = ref}
                items={this.props.selectedUbicaciones}
                showCheckBoxesMode={'none'}                
                selectionMode={"multiple"}
                searchMode="contains"
                displayExpr="nombre"
                keyExpr="id"
                itemRender={renderTreeViewItem}
            />
        );
    }

    onBtnGuardar(){
        var selectedUbicaciones = [];
        this.treeView.instance.getSelectedNodes().forEach((element, index) => {
            selectedUbicaciones.push(element.itemData.clave);            
        });

        const dto = {
            'idConfiguracion': null,
            'valorString1': selectedUbicaciones.toString()
        }

        this.props.guardarConfiguracion(dto);
    }

    onBtnEliminarCambios(){
        this.props.limpiaCambios();
    }

    componentDidMount(){
    }

    render(){
        const gridContent = (
            <Row>
                <Col md={4}>
                    <DropDownBox
                        value={"Seleccionados"}
                        dataSource={this.props.selectedUbicaciones}
                        contentRender={this.selectedTreeViewRender}
                    />
                </Col>
                <Col md={4} >
                    <DropDownBox
                        value={"Ubicaciones"}       
                        valueExpr="id"
                        displayExpr="Nombre"     
                        showClearButton={true}
                        dataSource={this.props.ubicacionesData}
                        // onValueChanged={this.syncTreeViewSelection}
                        contentRender={this.treeViewRender}
                        width="auto"
                    />
                </Col>
                <Col>
                    <Button 
                        type="button"
                        color="warning"
                        onClick={this.onBtnGuardar}
                    >
                        Guardar
                    </Button>
                </Col>
                <Col> 
                    <Button 
                        type="button"
                        onClick={this.onBtnEliminarCambios}
                    >
                        Eliminar Cambios
                    </Button>
                </Col>
            </Row>
        );

        return(
            <div className="content">
                {gridContent}
            </div>
        )
    }
}

function renderTreeViewItem(item) {
    return `${item.nombre}`;
}