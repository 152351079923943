/*eslint-disable*/
import React from 'react';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, Editing, Lookup, Popup } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { Col } from 'reactstrap';
import DeudaMasterDetail from './DeudaMasterDetail';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';


export default class Template1Grid extends React.Component {
    constructor(props) {
        super(props);

        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);   
        this.onExporting= this.onExporting.bind(this);
        this.getDetalle = this.getDetalle.bind(this);
    }

    getDetalle() {
        const urlService = `${process.env.REACT_APP_URL_API}Embarque/BusquedaDeuda`;

        callApi(urlService, 'POST', this.props.filtros, (response) => {
            this.Exporting(response[1])
        });
    }

    Exporting(detalle) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = this.dataGrid.instance;
        const masterRows = [];
        const items = [];
    
        worksheet.columns = [
          { width: 20 }, 
          { width: 15 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }, 
          { width: 20 }
        ];
        exportDataGrid({
            component: grid,
            worksheet,
            keepColumnWidths: false,
            topLeftCell: { row: 1, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'data') {
                // Valida que no se repita la llave
                if(items.indexOf(gridCell.data.ClaPedido)<0){
                    items.push(gridCell.data.ClaPedido);
                    masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
                }
            }

            // Formato a la celdas si es Group Row
            /* if (gridCell.rowType === 'group') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
            }
            */

            // Formato a la celda de Datos
            if (gridCell.rowType === 'data') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
                excelCell.font =  { bold: true };
            }

            // Formato a la celdas si es Total Footer Row
            if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                excelCell.font.italic = true;
            }
            }
    
        })
        .then((cellRange) => {
          // GENERAR UNA SECCION DE DETALLE
          
            const encabezados = [
                "Fecha Cálculo",
                "Ubicación",
                "Artículo",
                "Deuda Tons",
                "Reserva Tons",
                "Deuda Pendiente Tons"
            ];
            const columns = [
                "FechaCalculo",
                "NomUbicacion",
                "NomArticulo",
                "CantidadDeudaTons",
                "CantidadCapacidadReservada",
                "CantidadDeudaPendienteTons"
            ];
            const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            const columnIndex = cellRange.from.column;

            const insertRow = function (index, offset, outlineLevel) {
                const currentIndex = index + offset;
                const row = worksheet.insertRow(currentIndex, [], 'n');

                for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
                    worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
                }

                row.outlineLevel = outlineLevel;
                return row;
            }

            let totalOffset = 0;

            for (let i = 0; i < masterRows.length; i++) {
                let offset = 0;
                offset = totalOffset;

                // ** Cambio: Llave encabezado
                // const { ClaArticulo } = masterRows[i].data;
                const { ClaPedido } = masterRows[i].data;
                // Agrega un Row con los Encabezados del Detalle...
                const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                encabezados.forEach((header, currentColumnIndex) => {
                    Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                    value: header,
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } },
                    font: { bold: true },
                    border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                    alignment: { horizontal: 'center' }
                    });
                });

                // ** Cambio : Filtro Detalle
                // const pedidoPartidas = detalle.filter((partida) => partida.ClaProductoOrig == ClaArticulo);
                const pedidoPartidas = detalle.filter((partida) => partida.ClaPedido == ClaPedido);
                pedidoPartidas.forEach((partida) => {
                    const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

                    columns.forEach((columnName, currentColumnIndex) => {
                    const alinear = currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';
                        // Imprime cada celda del detalle..
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                        value: partida[columnName],
                        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F9FE' } },
                        border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                        alignment: { horizontal: alinear }
                        });
                    });
                });
                offset--;
                totalOffset = offset;
            }
    
        })
        .then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Embarques.xlsx');
            });
        });
        grid.cancel = true;
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }
    onExporting(e) {        
        this.getDetalle();
        e.cancel = true;
    }

    render(){
        const gridContent = (
            <DataGrid
                id="gridDeuda"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.dataSource}width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={true}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                remoteOperations={true}
                repaintChangesOnly={true}  
                onExporting={this.onExporting}
            >
                <MasterDetail 
                    enabled={true} 
                    component={DeudaMasterDetail} 
                />

                <Column dataField="FechaCalculo" caption="Fecha Cálculo" 
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                ></Column>
                <Column dataField="ClaPedido" caption="Pedido" />
                <Column dataField="CantidadDeudaTons" caption="Dueda Toneladas" />
                <Column dataField="CantidadCapacidadReservada" caption="Capacidad Toneladas" />
                <Column dataField="CantidadDeudaPendienteTons" caption="Dueda Pendiente Toneladas" />

                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        );

        return(
            <div className="content mb-5">
                {gridContent}
            </div>
        )
    }
}
