/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, Button, CardTitle } from 'reactstrap';
import DataGrid, { Column, Paging, Lookup, RowDragging, Scrolling, FilterRow, Editing, Popup, Form, Export } from 'devextreme-react/data-grid';
import { Label } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class Template5Grid extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {     
            nomUbicacion: this.props.nomUbicacion  ,
            selectedDiaNom: this.props.selectedDiaNom   ,
            selectedDia: this.props.selectedDia  ,
        }
        
        this.treeView = null;
        this.selectedTreeView = null;

    }

    componentDidMount(){
        console.log(this.props);
        this.setState({
            nomUbicacion: this.props.nomUbicacion,
            selectedDiaNom: this.props.selectedDiaNom,
            selectedDia: this.props.selectedDia,
        })
    }

    componentDidUpdate(){
        console.log(this.props);
        
    }


    render(){
        const gridContent = (
            <DataGrid
                id="gridConfiguracion"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.configData}
                key={'IdCfgConfiguracionGeneral'}
                width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                remoteOperations={true}
                repaintChangesOnly={true}
                onRowRemoving={this.props.validateRemove}         
                onRowInserted={this.validateInsert} 
                onExporting={this.onExporting}   
            >
                <FilterRow visible={true} 
                    applyFilter={this.state.currentFilter}
                />

                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowAdding={false}
                >
                </Editing>
                <Column dataField="IdCfgConfiguracionGeneral" caption="IdCfgConfiguracionGeneral" width={100} visible={false}/>
                <Column dataField="IdConfiguracion" caption="IdConfiguracion" width={150} visible={false}/>
                <Column dataField="NomUbicacion" caption="Ubicación" width={150} visible={true}/>
                <Column dataField="ValorString1" caption="Día de corte" width={150} visible={true}/>
            
                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        )
        
        return(
            <div className="content">
                {gridContent}
            </div>
        )
    }
}