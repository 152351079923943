/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import TracePopupOverFecha from './TracePopupOverFecha';
import TracePopupOverUbic from './TracePopupOverUbic';

export default class InfoCalculo extends React.Component {
  constructor(props) {
    super(props);

    const fecha = this.fechaFormat();

    this.state = {
      tipoFechaCalculo: this.props.fechaCalculo == null ? 0 : 1,
      fechaCalculo: fecha,
    };

    this.onClickFechacalculo = this.onClickFechacalculo.bind(this);
  }

  fechaFormat() {
    const fecha = new Date();
    const pad = function (num) {
      return `0${num}`.slice(-2);
    };
    return `${fecha.getFullYear()}-${pad(fecha.getMonth() + 1)}-${pad(fecha.getDate())}`;
  }

  onClickFechacalculo() {
    const fecha = this.state.tipoFechaCalculo === 0 ? this.state.fechaCalculo : null;
    this.props.onFechaCalculo(fecha);

    const tipo = this.state.tipoFechaCalculo === 1 ? 0 : 1;
    this.setState({ tipoFechaCalculo: tipo });
  }

  componentDidMount() {}

  render() {
    const clase =
      this.state.tipoFechaCalculo === 0
        ? 'mb-0 fa fa-hand-point-down'
        : 'mb-0 fa fa-hand-point-left';
    const claseRecalculo =
      this.props.data.nomTipoProceso === 'ReCalculo' ? 'font-weight-bold text-danger' : '';
    const claseAviso = 'bg-warning font-weight-bold text-white';
    let procMejora =
      this.props.data.claProceso > 1 ? (
        <h5 className={claseAviso}>{this.props.data.nomProceso}</h5>
      ) : (
        ''
      );
    procMejora =
      this.props.data.esFechaActualizadaConInventario === 1 ? (
        <h5 className={claseAviso}>Pedido con mejora de fecha con inventario disponible</h5>
      ) : (
        procMejora
      );

    let avisoFechaCapturaManual = '';
    avisoFechaCapturaManual =
      this.props.data.esCapturaFechaManual === 1 ? (
        <h5 className={claseAviso}>{this.props.data.motivoCambioFechaManual}</h5>
      ) : (
        ''
      );

    let avisoPedidoConLoteMinimo = '';
    avisoPedidoConLoteMinimo =
      this.props.data.esPedidoConLoteMinimo === 1 ? (
        <h5 className={claseAviso}>Pedido con producto configurado con lote mínimo.</h5>
      ) : (
        ''
      );

    return (
      <>
        <Row>
          <Col md={{ size: 12 }}>
            <Card>
              <CardHeader>
                <CardTitle>RESULTADO DEL CALCULO</CardTitle>
              </CardHeader>
              <CardBody>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <span>
                      <h5>
                        Planta seleccionada: {this.props.data.nomUbicacion}
                        <TracePopupOverUbic
                          data={this.props.data.ubicacionLog}
                        ></TracePopupOverUbic>
                      </h5>
                    </span>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>
                      Tipo de Cálculo:{' '}
                      <i className={claseRecalculo}> {this.props.data.nomTipoProceso}</i>
                    </h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>Fecha de Entrega: {this.props.data.fechaEstimadaEntrega}</h5>
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>
                      Horario del cálculo:{' '}
                      {this.state.tipoFechaCalculo === 0
                        ? this.props.data.horaCalculo
                        : this.state.fechaCalculo}{' '}
                      <i
                        className={clase}
                        onClick={this.onClickFechacalculo}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    </h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <Row>
                      <Col>
                        <h5>
                          Fecha de Embarque: {this.props.data.fechaEstimadaEmbarque}
                          <TracePopupOverFecha data={this.props.dataFecha}></TracePopupOverFecha>
                        </h5>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    {procMejora}
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    {this.props.data.esTraspasoDeCliente === 0 ? (
                      ''
                    ) : (
                      <h5>
                        Pedido(s) de cliente: <b> {this.props.data.claPedidoCliente}</b>{' '}
                      </h5>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    {this.props.data.esTraspasoOriginalCancelado === 0 ? (
                      ''
                    ) : (
                      <h5>
                        Traspaso original cancelado:
                        <b> {this.props.data.traspasoOriginalCancelado}</b>{' '}
                      </h5>
                    )}
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    {avisoPedidoConLoteMinimo}
                  </Col>
                  <Col md={{ size: 5 }} style={{ margin: 0 }}>
                    {this.props.data.esCapturaFechaManual === 0 ? (
                      ''
                    ) : (
                      <h5>
                        Usuario captura fecha manual:
                        <b> {this.props.data.nomUsuarioCambioFechaManual}</b>{' '}
                      </h5>
                    )}
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    {avisoFechaCapturaManual}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
