/*eslint-disable*/
import React from 'react';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { Card, CardHeader, CardBody, Row, Col, Button, CardTitle } from 'reactstrap';
import { Popup } from 'devextreme-react/popup';
import Template5Grid from '../Template5/Template5Grid';
import Template5Alta from './Template5Alta';
import CtrlComboBox from '../../Controles/CtrlComboBox';

export default class Template5 extends React.Component{
    constructor(props) {
        super(props);

        const filtros = {
            claUbicaion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            idConfiguracion: this.props.configuracionValue,
            claTemplate: 5
        };

        this.state ={
            filtros,
            ubicacionesData: [],
            selectedDia: 0,
            pageIndex: 0,
        }

        this.onChangeUbicacion = this.onChangeUbicacion.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getConfiguracionData = this.getConfiguracionData.bind(this);
        this.onChangeDiaCorte = this.onChangeDiaCorte.bind(this);
        this.onClickAgregar = this.onClickAgregar.bind(this);
        this.validateRemove = this.validateRemove.bind(this);
        this.handler = this.handler.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.hidePopUp = this.hidePopUp.bind(this);
        this.onGridPage = this.onGridPage.bind(this);  

        this.refCmbDia = React.createRef();
        this.refCmbUbicacion = React.createRef();
    }

    getConfiguracionData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {

            this.setState({
                configData: result,
                showSpin: false,
            });
        });
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    validateRemove(e){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/DeleteTemplate5`;

        var params = {
            idCfgConfiguracionGeneral: e.data.IdCfgConfiguracionGeneral,
        }

        callApi(urlApiService, 'POST', params, (result) => {
            this.getConfiguracionData();
        });
    }

    handler(val) {
        this.setState({
          popupVisible: val,
        });
        this.getConfiguracionData();
    }

    hidePopUp() {
        this.setState({
          popupVisible: false,
        });
        this.getConfiguracionData();
    }

    onClickAgregar(e) {
        this.setState({
          popupVisible: true,
        });
    
        e.stopPropagation();
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onChangeDiaCorte(e){
        this.setState({
            selectedDia: e.value,
        })
    }

    onChangeUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;

        this.setState({
            filtros: newFiltro
        });
        
        this.getConfiguracionData();
    }

    componentDidMount(){
        this.getConfiguracionData();
        this.getUbicaciones();
    }

    componentDidUpdate(){
        if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        {
            this.getConfiguracionData();
        }
    }

    render(){
        return(
            <div className="content">
                <Row>
                    <Col md={{ size: 5 }}>
                        <CtrlComboBox
                            etiqueta="Ubicación"
                            onChange={this.onChangeUbicacion}
                            data={this.state.ubicacionesData}
                            value={this.state.filtros.claUbicacion}
                            reference={this.props.refCmbUbicacion}                                        
                        />
                    </Col>
                    <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                        <Button
                            id="btnNuevo"
                            onClick={this.onClickAgregar}
                            size="md"
                            className="btn btn-primary btn-round btn-icon btn-custom mb-1"
                        >
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>                    
                        <Template5Grid 
                            configData={this.state.configData}
                            pageIndex={this.state.pageIndex}
                            onPage={this.onGridPage}
                            validateRemove={this.validateRemove}
                        />
                    </Col>
                </Row>
                <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Configuración"
                    width={470}
                    height={600}
                    >
                    <Template5Alta 
                        idConfiguracion={this.props.configuracionValue}
                        handler={this.handler}
                    />
                </Popup>
            </div>
        )
    }
}