/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import CtrlComboBox from '../../../Controles/CtrlComboBox';
export default class FiltroFechas extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <CtrlComboBox
                        etiqueta="GrupoEstadístico 1"
                        onChange={this.props.onChangeCmbGpoEstadistico1}
                        data={this.props.dataGpoEstadisticos1}
                        value={this.props.filtros.claGpoEstadistico}
                        reference={this.props.refCmbGpoEstadistico1}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <CtrlComboBox
                        etiqueta="Motivo"
                        onChange={this.props.onChangeCmbMotivo}
                        data={this.props.dataMotivos}
                        value={this.props.filtros.claMotivo}
                        reference={this.props.refCmbMotivo}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}