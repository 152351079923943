import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import drilldown from 'highcharts/modules/drilldown.js';

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import CtrlComboBox from 'views/Controles/CtrlComboBox';
import CtrlNumerico from 'views/Controles/CtrlNumerico';
import ModalProduccionMyA from 'views/Produccion/ModalCapacidad/ModalProduccionMyA';

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from 'utils/utils';

class MaquinasSaturada extends React.Component {
  constructor(props) {
    super(props);

    const TimerTiempo=50;
    const TimerId=0;
    this.state = {
      options: this.getChart(),
      data:[],
      dataGraf:[],
      dataUbicacion:[],
      dataAreaProduccion:[],
      dataGpoMaquinas:[],
      dataCategorias:[],
      dataCmbGpoMaquinasSel:[],
      claUbicacion:3,
      numGpoMaquinas:7,
      claAreaProduccion:null,
      claGrupoMaquina:null,
      grafTitle:null,
      showModal:false
    };

    drilldown(Highcharts);
    this.graficaRef = React.createRef();
    this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    this.onChangeNumGpoMaquinas = this.onChangeNumGpoMaquinas.bind(this);
    this.onChangeCmbAreaProduccion = this.onChangeCmbAreaProduccion.bind(this);
    this.onChangeCmbGpoMaquinas = this.onChangeCmbGpoMaquinas.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onDrillDown = this.onDrillDown.bind(this);
  }

  getUbicaciones() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 258, params, 1, (res) => {
      this.setState({ dataUbicacion: res.Result0 });
      // this.dataLoad(this.props.data.direccion, this.props.data.subdireccion, this.props.data.zona, this.props.data.agente, res.Result0);
    });
  }

  getInfoProdMyA() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const urlService = `${process.env.REACT_APP_URL_API}Produccion/GpoMaquinasSaturadas`;

    const params = {
      ClaUbicacion: this.state.claUbicacion,
      NumGpoMaquinas: this.state.numGpoMaquinas,
    };

    callApi(urlService, "POST", params, (res) => {
      const data=this.grafDatos(res.maqSaturadas);
      const drilldata=this.grafDatosDrillDown(res.maqSaturadasDet, res.maqSaturadas);
      // this.state.options.xAxis.categories=data.categorias;
      this.state.options.title=data.title;
      this.state.options.series=data.series;
      this.state.options.drilldown.series=drilldata;
      this.setState({
        dataMaqSaturadas:res.maqSaturadas,
        dataCategorias:res.maqSaturadasDet,
        dataPeriodos:res.periodos,
        dataAreaProduccion:res.areasProduccion,
        dataGpoMaquinas:res.gpoMaquinas,
        grafTitle:data.title
      },
      () => this.graficaRef.current.chart.hideLoading());
    });
  }

  grafDatos(data) {

    const series = { name:"", colorByPoint: true, data:[]};
    let claPeriodoIni = null;
    
    for (let i = 0; i < data.length; i += 1) {
      const name=`${data[i].NomGrupoMaquina}<br/>${data[i].ClaPeriodo}`;
      series.data.push({name, y:data[i].Semanas, drilldown:data[i].NomGrupoMaquina, fecha:data[i].NomPeriodo, area:data[i].NomAreaProduccion, gpoMaquinas:data[i].NomGrupoMaquina, periodo:data[i].ClaPeriodo, hrs:data[i].HorasTotales});

      if(claPeriodoIni===null)
      claPeriodoIni=data[i].ClaPeriodoMin;
    }

    const title =`Grupo Maquinas Saturadas Alambres Monterrey, Inicio:${claPeriodoIni} `
    this.graficaRef.current.chart.setTitle({ text:title });
    const result = {
      series,
      title
    };

    return result;
  }

  grafDatosDrillDown(data, maqSaturadasSeries) {
    const series =[];

    for (let i = 0; i < data.length;) {
      const serie = {
        name: data[i].NomGrupoMaquina,
        id:data[i].NomGrupoMaquina,
        data: []
      };

      const index = maqSaturadasSeries.findIndex(item => item.NomGrupoMaquina === data[i].NomGrupoMaquina)

      for (; i < data.length && serie.name===data[i].NomGrupoMaquina; i += 1) {
        
        const name =`${data[i].NomDireccion} ${data[i].PorcSegmentacion} % `;
        
        serie.data.push({ name, y:(data[i].TotalHrs)
          ,direccion:data[i].NomDireccion
          ,segmentacion:data[i].PorcSegmentacion
          ,fecha:data[i].NomPeriodo
          ,fechaMax:data[i].NomPeriodoMax
          ,area:data[i].NomAreaProduccion
          ,claAreaProduccion:data[i].ClaAreaProduccion
          ,claGrupoMaquina:data[i].ClaGrupoMaquina
          ,claPeriodo:data[i].ClaPeriodoMax
          ,claDireccion:data[i].ClaDireccion
          ,capacidad:data[i].TotalHrs
          ,valor:(data[i].TotalHrs-data[i].ReservadoHrs)
          ,valorporc:data[i].PorcDisponible
          ,status:"Disponible"
          ,serieIdx:index
        });

        // serie.data.push([data[i].NomDireccion,data[i].ReservadoHrs]);
        serie.data.push({name, y:data[i].ReservadoHrs, color:"red"
        ,direccion:data[i].NomDireccion
        ,segmentacion:data[i].PorcSegmentacion
        ,fecha:data[i].NomPeriodo
        ,fechaMax:data[i].NomPeriodoMax
        ,area:data[i].NomAreaProduccion
        ,claAreaProduccion:data[i].ClaAreaProduccion
        ,claGrupoMaquina:data[i].ClaGrupoMaquina
        ,claPeriodo:data[i].ClaPeriodoMax
        ,claDireccion:data[i].ClaDireccion
        ,capacidad:data[i].TotalHrs
        ,valor:data[i].ReservadoHrs
        ,valorporc:data[i].PorcReservado
        ,status:"Reservado"
        ,serieIdx:index
      });

      }
      series.push(serie);
    }

    return series;
  }
  
  grafDatosOld(data, dataCat) {

    const categorias = [];
    const series = [];
    const periodosSel = [];

    for (let i1 = 0; i1 < dataCat.length; i1 += 1) {
      categorias.push(dataCat[i1].NomPeriodo);
    }

    let lastClaPeriodo=0;

    for (let i = 0; i < data.length;) {
      const serie = {
        name: data[i].NomGrupoMaquina,
        data: [],
        stack: `${data[i].ClaGrupoMaquina}`
      };

      for (; i < data.length && serie.name===data[i].NomGrupoMaquina; i += 1) {
        serie.data.push({ y:data[i].PorcReservado, capacidad:data[i].TotalHrs
          ,direccion:data[i].NomDireccion
          ,segmentacion:data[i].PorcSegmentacion
          ,fecha:data[i].NomPeriodo
          ,area:data[i].NomAreaProduccion
        });

        if(data[i].ClaPeriodo>lastClaPeriodo){
          periodosSel.push(data[i].NomPeriodo);
          lastClaPeriodo=data[i].ClaPeriodo;
        }
      }
      series.push(serie);
    }

    const result ={
      categorias:periodosSel,
      series
    }
    return result;
  }

  getChart(){

    const config=
    {
      chart: {
        type: 'column',
        events: {
          load: (e) => { 
          },
          drilldown: (e) => {
            const text = `${e.point.area} - ${e.point.gpoMaquinas} ( ${e.point.hrs} Hrs x Semana )<br/>SEMANA:${e.point.fecha} (${e.point.periodo})`;
            this.graficaRef.current.chart.setTitle({ text });
            this.graficaRef.current.chart.yAxis[0].setTitle({ text: "Horas" });
          },
          drillup: async (e) => {
            await this.graficaRef.current.chart.setTitle({ text: this.state.grafTitle});
            await this.graficaRef.current.chart.setSubtitle({ text: null });
            await this.graficaRef.current.chart.yAxis[0].setTitle({ text: "Numero de Semanas" });
          },
        },
      },
      title: {
          text: 'Grupo Maquinas Saturadas Alambres Monterrey'
      },
      subtitle: {
          text: null
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
          min: 0,
          title: {
              text: 'Numero de Semanas',
              align: 'high'
          },
          labels: {
              overflow: 'justify'
          }
      },
      tooltip: {
          valueSuffix: ' %',
          formatter: this.onFormatTooltip
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
              enabled: true,
              format: '{point.y:.0f}'
          },
          point: {
            events: {
              click: (e)=> {
                  const p=e.point;
                  if(p.claAreaProduccion===undefined) return;
                  const paramService ={claUbicacion: this.state.claUbicacion,  claArea: p.claAreaProduccion,
                    claGrupoMaquina: p.claGrupoMaquina,
                    claDireccion: p.claDireccion,
                    claPeriodo: p.claPeriodo,
                    fechaCalculo: new Date()}
                  this.setState({ showModal:true, paramService, serieIdx:p.serieIdx }, () =>{ this.graficaRef.current.chart.drillUp(); this.TimerId=setInterval(this.onDrillDown, this.TimerTiempo);} );
              }
            }
          }
        }
      },
      legend: {
          enabled: false,
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'top',
          x: 0,
          y: 80,
          floating: false,
          borderWidth: 1,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
          shadow: true
      },
      credits: {
          enabled: false
      },
      series: [],
      drilldown: {
        breadcrumbs: {
          position: {
              align: 'right'
          }
        },
        series: []
      }
  }
    return config;
  }

  onFormatTooltip(tooltip, par2, par3, x = this.x, point = this.point) {
    let s = '<table>';

    if(point.options.direccion===undefined)
      s += `<tr><td style="color:${point.series.color};padding:0">Gpo Maquina:<b>${point.options.name}</b></td></tr>
      <br/><tr><td style="padding:0;">Area:<b>${point.area}</b></td></tr>
      <br/><tr><td style="padding:0;">Semana:<b>${point.fecha}</b></td></tr>
      <br/><tr><td style="padding:0;">Reservado:<b>${point.y} Semanas</b></td></tr>`;
    else
      s += `<tr><td style="color:${point.series.color};padding:0">${point.options.direccion}:</td></tr>
      <br/><tr><td style="padding:0;">Segmentacion:<b>${point.options.segmentacion}%</b></td></tr>
      <br/><tr><td style="padding:0;">Estatus:<b>${point.options.status}</b></td></tr>
      <br/><tr><td style="padding:0;">Saturada:<b>${point.options.fechaMax}</b></td></tr>
      <br/><tr><td style="padding:0;">Semana:<b>${point.options.claPeriodo}</b></td></tr>
      <br/><tr><td style="padding:0;"><b>${point.options.valor} Horas, ${point.options.valorporc} %</b></td></tr>
      `;
         
    s += '</table>';

    return s;
  }

  filterAreaProduccion() {
    const { dataMaqSaturadas, dataCategorias, dataGpoMaquinas, claAreaProduccion } = this.state;

    const dataCmbGpoMaquinasSel = dataGpoMaquinas.filter((gpoMaquinas)=>{
      return gpoMaquinas.ClaAreaProduccion === claAreaProduccion; // returns true or false
    });

    const dataGpoMaquinasSel = dataMaqSaturadas.filter((gpoMaquinas)=>{
      return gpoMaquinas.ClaAreaProduccion === claAreaProduccion && gpoMaquinas.Filtrar===0; // returns true or false
    });

    const data=this.grafDatos(dataGpoMaquinasSel, dataCategorias);
    this.state.options.xAxis.categories=data.categorias;
    this.state.options.series=data.series;

    this.setState({ dataCmbGpoMaquinasSel, claAreaProduccion });
  }

  filterGpoMaquina() {
    const { dataMaqSaturadas, dataCategorias, claAreaProduccion, claGrupoMaquina } = this.state;

    const dataGpoMaquinasSel = dataMaqSaturadas.filter((gpoMaquinas)=>{
      return gpoMaquinas.ClaAreaProduccion === claAreaProduccion && gpoMaquinas.ClaGrupoMaquina===claGrupoMaquina
        && gpoMaquinas.PorcReservado>0; // returns true or false
    });

    const data=this.grafDatos(dataGpoMaquinasSel, dataCategorias);
    this.state.options.xAxis.categories=data.categorias;
    this.state.options.series=data.series;

    this.setState({ claGrupoMaquina });
  }

  onChangeCmbUbicacion(selectedItem) {
    const claUbicacion = selectedItem.value != null ? selectedItem.value : -1;

    this.setState({ claUbicacion }, () => this.getInfoProdMyA());
  }

  onChangeCmbAreaProduccion(selectedItem) {
    const claAreaProduccion = selectedItem.value != null ? selectedItem.value : -1;

    this.setState({ claAreaProduccion }, () => this.filterAreaProduccion());
  }

  onChangeCmbGpoMaquinas(selectedItem) {
    const claGrupoMaquina = selectedItem.value != null ? selectedItem.value : -1;

    this.setState({ claGrupoMaquina }, () => this.filterGpoMaquina());
  }

  onChangeNumGpoMaquinas(object) {
    const numGpoMaquinas = object.value;

    this.setState({ numGpoMaquinas }, () => this.getInfoProdMyA());
  }
  
  onClosePopup(){
    this.setState({ showModal:false },
      () =>{ this.graficaRef.current.chart.drillUp(); 
            this.TimerId=setInterval(this.onDrillDown, this.TimerTiempo);});
     
  }

  async onDrillDown(serieIdx){

    clearInterval(this.TimerId)
    // alert('onDrillDown')
    this.graficaRef.current.chart.series[0].data[this.state.serieIdx].doDrilldown()
  }

  componentDidMount() {

    this.getUbicaciones();
    this.getInfoProdMyA();
  }

  render() {

    return (
      <>
        <div className="content mb-5">
          <Row>
            <Col className='col-4' style={{ padding: 0, margin: 0 }}>
              <CtrlComboBox
                etiqueta="Ubicacion"
                onChange={this.onChangeCmbUbicacion}
                data={this.state.dataUbicacion}
                value={this.state.claUbicacion}
                reference={this.props.refCmbPlanta}
              />
            </Col>
            <Col className='col-4' style={{ padding: 0, margin: 0 }}>
              <CtrlNumerico
                etiqueta="Número Grupo Maquinas"
                onChange={this.onChangeNumGpoMaquinas}
                value={this.state.numGpoMaquinas}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card className="m-1 border-top border-bottom border-right border-left border-dark">
                <CardBody className="p-1">
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                    containerProps={{ style: { width: '100%', height: '90%' } }}
                    ref={this.graficaRef}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className='col-12'>
              <ModalProduccionMyA visible={this.state.showModal} onClosePopup={this.onClosePopup} paramService={this.state.paramService}>
              </ModalProduccionMyA>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default MaquinasSaturada;