/*eslint-disable*/
import React from 'react';
import AppContext from "../../context/AppContext";
import { callApi, callKrakenApi } from '../../utils/utils';
import Tabs from 'devextreme-react/tabs';
import Partidas from './Partida/Partida';
import Pedidos from './Pedido/Pedido';
import Entregas from './Entrega/Entrega';
import { config } from '../../utils/config';

export default class Vencimientos extends React.Component {
    constructor(props) {
        super(props);

        const tabs = [
            {
                id: 0,
                text: 'Embarque Partida',
                content: 'Partida',
            },
            {
                id: 1,
                text: 'Embarque Pedido',
                content: 'Pedido',
            },
            {
                id: 2,
                text: 'Entrega',
                content: 'Vencimiento de Entrega',
            }
        ]

        this.state = {
            tabs,
            selectedIndex: 0,
            partidasVisibility: "",
            entregasVisibility: "none",
            pedidoVisibility: "none",
            ubicacionesData: [],
            gpoEstadisticoData: [],
            areasData: []
        }

        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
        
        this.getUbicaciones();
        this.getGpoEstadistico();
        this.getAreas();
    }

    getUbicaciones = () => {
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    getAreas = () => {
        const urlApiService = `${process.env.REACT_APP_URL_API}Vencimiento/GetAreas`;

        callApi(urlApiService, 'POST', null, (result) => {
            console.log(result);
            this.setState({
                areasData: result
            });
        });
    }

    getGpoEstadistico = () => {
        const urlApiService = `${process.env.REACT_APP_URL_API}Vencimiento/GetGpoEstadistico`;

        callApi(urlApiService, 'POST', null, (result) => {
            console.log(result);
            this.setState({
                gpoEstadisticoData: result
            });
        });
    }

    onTabsSelectionChanged(args){
        if (args.name === 'selectedIndex') {
            if(args.value === 0){             
                this.setState({
                    selectedIndex: args.value,
                    partidasVisibility: "",
                    entregasVisibility: "none",
                    pedidoVisibility: "none",
                });   
            }
            else if(args.value === 1){             
                this.setState({
                    selectedIndex: args.value,
                    partidasVisibility: "none",
                    entregasVisibility: "none",
                    pedidoVisibility: "",
                });   
            }
            else if(args.value === 2){              
                this.setState({
                    selectedIndex: args.value,
                    partidasVisibility: "none",
                    entregasVisibility: "",
                    pedidoVisibility: "none",
                });   
            }
        }
    }

    componentDidMount(){
    }

    render() {
        return (
            <>
                <Tabs
                    dataSource={this.state.tabs}
                    selectedIndex={this.state.selectedIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                />
                <div className="content mb-5">
                    <div style={{"display": this.state.partidasVisibility}}>
                        <Partidas 
                            ubicacionesData={this.state.ubicacionesData}
                            estadisticosData={this.state.gpoEstadisticoData}
                            areasData={this.state.areasData}
                        />
                    </div>
                    <div style={{"display": this.state.pedidoVisibility}}>
                        <Pedidos 
                            ubicacionesData={this.state.ubicacionesData}
                            estadisticosData={this.state.gpoEstadisticoData}
                            areasData={this.state.areasData}
                        />
                    </div>
                    <div style={{"display": this.state.entregasVisibility}}>
                        <Entregas 
                            ubicacionesData={this.state.ubicacionesData}
                            estadisticosData={this.state.gpoEstadisticoData}
                            areasData={this.state.areasData}
                        />
                    </div>
                </div>
            </>
        );
    }
}