/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import FiltrosFechas from './FiltrosFechas';
import FiltrosUbicaciones from './FiltrosUbicaciones';
import FiltrosOtros from './FiltrosOtros';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import { config } from '../../../../utils/config';

export default class Filtros extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataUbicaciones: [],
            dataGpoEstadisticos1: [],
            dataMotivos: [],
        }
      
        this.getGpoEstadistico = this.getGpoEstadistico.bind(this);

        this.refCmbUbicacion = React.createRef();
        this.refCmbPlantaTE = React.createRef();
        this.refCmbUbicacionElabora = React.createRef();
        this.refCmbGpoEstadistico1 = React.createRef();
        this.refCmbMotivo = React.createRef();
    }

    getUbicaciones() {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
          Valor: '',
          Tipo: 1,
          IncluirTodosSN: 0,
        };
    
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                dataUbicaciones: res.Result0
            });
        });
    }

    getGpoEstadistico(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Vencimiento/GetGpoEstadistico`;

        callApi(urlApiService, 'POST', null, (result) => {
            console.log(result);
            this.setState({
                dataGpoEstadisticos1: result
            });
        });
    }

    getMotivosVencimiento(){
        const filtros = {
            fechaEmbarqueIniValue: this.now,
            fechaEmbarqueFinValue: this.now,
            claEstadistico: null,
            claUbicacion: null,
            claMotivoVencimiento: null,
            claArea: null,
            idTab: 2
        }

        const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/GetMotivosCalculoFecha`;

        callApi(urlApiService, 'POST', filtros, (result) => {
            console.log(result);
            this.setState({
                dataMotivos: result
            });
        });
    }

    componentDidMount(){
        this.getUbicaciones();
        this.getGpoEstadistico();
        this.getMotivosVencimiento();
    }

    render() {
        const spinner =
            this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
            ) : (<></>);
    
        return (
            <div className="content">
                <Card>
                    <CardHeader>
                        <CardTitle>Reporte de Pedidos sin Fechas de Kayak</CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FiltrosFechas 
                                    onChangeCmbTipoFecha={this.props.onChangeCmbTipoFecha}
                                    onChangeFechaInicio={this.props.onChangeFechaInicio}
                                    onChangeNumPedido={this.props.onChangeNumPedido}
                                    onChangeFechaFin={this.props.onChangeFechaFin}
                                    filtros={this.props.filtros}
                                    disabled={this.props.fechaDisabled}
                                    // selectedTipoFecha={this.state.selectedTipoFecha}
                                />
                            </Col>
                            <Col>
                                <FiltrosUbicaciones 
                                    dataUbicaciones={this.state.dataUbicaciones}
                                    onChangeCmbUbicacion={this.props.onChangeCmbUbicacion}
                                    onChangeCmbPlantaTE={this.props.onChangeCmbPlantaTE}
                                    onChangeCmbUbicacionElabora={this.props.onChangeCmbUbicacionElabora}
                                    refCmbUbicacion={this.refCmbUbicacion}
                                    refCmbPlantaTE={this.refCmbPlantaTE}
                                    refCmbUbicacionElabora={this.refCmbUbicacionElabora}
                                    filtros={this.props.filtros}
                                />
                            </Col>
                            <Col>
                                <FiltrosOtros 
                                    dataGpoEstadisticos1={this.state.dataGpoEstadisticos1}
                                    dataMotivos={this.state.dataMotivos}
                                    dataUbicaciones={this.dataUbicaciones}
                                    onChangeCmbMotivo={this.props.onChangeCmbMotivo}
                                    onChangeCmbGpoEstadistico1={this.props.onChangeCmbGpoEstadistico1}
                                    refCmbGpoEstadistico1={this.refCmbGpoEstadistico1}
                                    refCmbMotivo={this.refCmbMotivo}
                                    filtros={this.props.filtros}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row>
                                    <Button type="button" color="warning" onClick={this.props.onBtnBuscar}>
                                    Buscar
                                    </Button>
                                    {spinner}
                                    <Button
                                    type="button"
                                    className="btn btn-primario"
                                    onClick={this.props.onBtnLimpiar}
                                    >
                                    Limpiar
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

