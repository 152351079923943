/*eslint-disable*/
import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  CardTitle,
  Button,
  Spinner,
  Label,
} from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';

export default class AceriasFiltros extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.onBtnBuscar = this.onBtnBuscar.bind(this);
    this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
  }

  onBtnBuscar() {
    this.props.getProduccion();
    this.props.getConfiguracion();
  }

  onBtnLimpiar() {
    this.props.onLimpiar();
  }

  componentDidMount() {}

  render() {
    const spinner =
      this.props.showSpin === true ? (
        <Loader type="Puff" color="#00BFFF" height={40} width={40} />
      ) : (
        <></>
      );

    return (
      <>
        <Card>
          <CardHeader>
            <CardTitle>Acerías</CardTitle>
          </CardHeader>
        </Card>
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col>
                  <CtrlDatePicker
                    etiqueta="Fecha Campaña Inicio"
                    defaultValue={this.now}
                    onChange={this.props.onChangeFechaProduccionIni}
                    value={this.props.filtros.fechaProduccionIni}
                  />
                </Col>
                <Col>
                  <CtrlDatePicker
                    etiqueta="Fecha Campaña Fin"
                    defaultValue={this.now}
                    onChange={this.props.onChangeFechaProduccionFin}
                    value={this.props.filtros.fechaProduccionFin}
                  />
                </Col>
                <Col>
                  <CtrlComboBox
                    etiqueta="Ubicacion"
                    onChange={this.props.onChangeCmbUbicacion}
                    data={this.props.ubicacionesData}
                    value={this.props.filtros.claUbicacion}
                    reference={this.props.refCmbUbicacion}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <CtrlComboBox
                    etiqueta="Calibre"
                    onChange={this.props.onChangeCmbCalibre}
                    data={this.props.calibreData}
                    value={this.props.filtros.claCalibre}
                    reference={this.props.refCmbCalibre}
                  />
                </Col>
                <Col>
                  <CtrlComboBox
                    etiqueta="Grupo Producción"
                    onChange={this.props.onChangeCmbGpoProduccion}
                    data={this.props.gpoProduccionData}
                    value={this.props.filtros.claGpoProduccion}
                    reference={this.props.refCmbGpoProduccion}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                  Buscar
                </Button>
                {spinner}
                <Button type="button" onClick={this.onBtnLimpiar}>
                  Limpiar
                </Button>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </>
    );
  }
}
