/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from '../Controles/CtrlComboBox';

export default class Filtros extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        return (        
            <div className="content">
                <Col md={12} style={{ padding: 0, margin: 0 }}>
                    <Card>
                        <CardHeader>
                        <CardTitle>Configuración</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <CtrlComboBox
                                        etiqueta="Configuración General"
                                        onChange={this.props.onChangeConfiguracion}
                                        data={this.props.configData}
                                        value={this.props.configuracion}
                                        reference={this.props.refCmbConfiguracion}                                        
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </div>
        )
    };
}