/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Paging, Lookup, RowDragging, Scrolling, FilterRow, Editing, Popup, Form, Export } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class Template9Grid extends React.Component {
    constructor(props) {
        super(props);
        
        this.applyFilterTypes = [{
            key: 'auto',
            name: 'Immediately'
          },
          {
            key: 'onClick',
            name: 'On Button Click'
        }];

        this.state = {
            showSpin: false,
            pageIndex: 0,            
            currentFilter: this.applyFilterTypes[0].key,
            ubicacionesData: [],
        }

        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onGridPage = this.onGridPage.bind(this);
    }

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Configuración.xlsx');
                });
        });
        e.cancel = true;
    }
    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    componentDidMount(){
    }

    componentDidUpdate(){

    }

    render(){
        const gridContent = (
            <DataGrid
                id="gridConfiguracion"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.dataSource}
                key={'IdCfgConfiguracionGeneral'}
                width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                remoteOperations={true}
                repaintChangesOnly={true}
                onRowRemoving={this.props.validateRemove}         
                onRowInserted={this.validateInsert} 
                onExporting={this.onExporting}   
            >
                <FilterRow visible={true} 
                    applyFilter={this.state.currentFilter}
                />

                <Editing
                    mode="popup"
                    allowDeleting={true}
                    allowAdding={false}
                >
                </Editing>
                <Column dataField="IdCfgConfiguracionGeneral" caption="IdCfgConfiguracionGeneral" width={100} visible={false}/>
                <Column dataField="IdConfiguracion" caption="IdConfiguracion" width={150} visible={false}/>
                <Column dataField="NomUbicacion" caption="Ubicación" width={150} visible={true}/>
                <Column dataField="NomFamilia" caption="Familia" width={150} visible={true} alignment="left"/>
                <Column dataField="NomSubFamilia" caption="SubFamilia" width={150} visible={true} alignment="left"/>
            
                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                
                <Export enabled={true} allowExportSelectedData={false} />
            </DataGrid>
        );

        return(
            <div className="content mb-5">
                {gridContent}
            </div>
        )
    }
}