/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Export, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';


export default class EntregaGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;
        this.state = {
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);        
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Motivos Vencimientos de Entrega.xlsx');
                });
        });
        e.cancel = true;
    }

    componentDidMount() {
    }
  
    render() {
        return (
            <>
                <DataGrid
                    id="gridProduccion"
                    ref={(ref) => this.dataGrid = ref}
                    dataSource={this.props.data}
                    key={'idPlan'}
                    width="100%"
                    height={600}
                    selection={{ mode: 'single' }}
                    showBorders={false}
                    columnHidingEnabled
                    columnAutoWidth={false}
                    showColumnHeaders
                    showColumnLines={false}
                    showRowLines
                    noDataText="Sin Registros"
                    wordWrapEnabled
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    onExporting={this.onExporting}
                >
                    <FilterRow
                        visible={true}
                        applyFilter={true}
                    />
                    <HeaderFilter visible={true} />             
                    
                    <Column alignment= "center" dataField="FechaEntrega" caption="Fecha Entrega"
                        dataType={"date"} 
                        format={"dd/MM/yyyy"}
                    ></Column>
                    <Column alignment= "center" width = '85' dataField="ClaPedido" caption="Pedido" allowResizing={false}/>
                    <Column alignment= "left" width = '85' dataField="NombreUbicacion" caption="Ubicacion" allowResizing={false}/>
                    <Column alignment= "left" width = '85' dataField="Motivo" caption="Motivo" allowResizing={false}/>
                    <Column alignment= "left" width = '85' dataField="NomArea" caption="Área" alowResizing={false}/>
                    <Column alignment= "left" width = '85' dataField="NombreGrupoEstadistico1" caption="Gpo Estadístico 1" alowResizing={false}/>
                    <Column alignment= "center" width = '85' dataField="Clasificacion" caption="Clasificación" alowResizing={false}/>
                    
                    <Paging
                        pageSize={30}
                        pageIndex={this.props.pageIndex}
                    />
                    
                    <Export enabled={true} allowExportSelectedData={false} />
                </DataGrid>
            </>
        );
    }
}