/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class Template8Alta extends React.Component {
    constructor(props) {
        super(props);
        
        const objTemplate1 = {
            ClaUbicacion: null,
        }

        this.state = {
            objTemplate1,
        }

        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);

        this.refForm = React.createRef();
    }

    get formUser() {
        return this.refForm.current ? this.refForm.current.instance : null;
    }

    btnCancelarHandle() {
        this.props.handler(false);
    }

    btnGuardarHandleSubmit() {
        const dataForm = this.formUser.option('formData');
    
        if (!dataForm.Ubicacion) {
        //   showNotify(
        //     'Favor de capturar una Clave de Ubicacion y Producto',
        //     notifyPosition.centerTop,
        //     notifyType.error
        //   );
          return;
        }
    
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/UpdateTemplate8`;
    
        const body = {
            idCfgConfiguracionGeneral: 0,
            idConfiguracion: this.props.idConfiguracion,
            claUbicacion: dataForm.Ubicacion,
        };
    
        callApi(urlApiService, 'POST', body, (res) => {
            this.props.handler(false);
            dataForm.Ubicacion = null;
            this.props.getConfiguraciones()
        });
    }

    componentDidMount(){
    }

    render(){
        return(
            <div>
                <Form
                    ref={this.refForm}
                    key="formUser"
                    formData={this.state.objTemplate4}
                    colCount={1}
                    showColonAfterLabel={true}
                    className="formUser"
                    id="FormAltaUsuario"
                    onFieldDataChanged={this.onFieldDataChanged}
                >
                <Item
                    dataField="Ubicacion"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.props.UbicacionData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
            </Form>
            <FormGroup className="text-right">
                <Button
                    id="btnUsersGuardar"
                    text="Guardar"
                    onClick={this.btnGuardarHandleSubmit}
                    className="btn btn-primary btn-round"
                />
                <Button
                    id="btnUsersCancelar"
                    text="Cancelar"
                    onClick={this.btnCancelarHandle}
                    className="btn btn-primary btn-round"
                />
            </FormGroup>
            </div>
        )
    }
}