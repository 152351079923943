/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';
import CtrlNumerico from '../../Controles/CtrlNumerico';
import CtrlCheckbox from '../../Controles/CtrlCheckbox';

export default class EntregaFiltros extends React.Component {
    constructor(props) {
        super(props);
        
        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    }

    onBtnBuscar() {
        this.props.getEntregaData();
    }

    onBtnLimpiar() {
        this.props.onLimpiarPartidas();
    }

    componentDidMount() { 
    }

    render() {
        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return (        
        <div className="content">
            <Row>
                <Col md={12} style={{ padding: 0, margin: 0 }}>
                    <Card>
                        <CardHeader>
                        <CardTitle>Vencimiento de Entrega</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <CtrlNumerico
                                                etiqueta="Número Pedido"
                                                onChange={this.props.onChangeNumPedido}
                                                value={this.props.claPedido}
                                            />
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <CtrlCheckbox 
                                                        etiqueta="Traspasos" 
                                                        defaultValue={false} 
                                                        onChange={this.props.onChangeEsTraspasos} 
                                                        value={this.props.esTraspasos}/>
                                                </Col>
                                                <Col>
                                                    <CtrlCheckbox 
                                                        etiqueta="Clientes" 
                                                        defaultValue={false} 
                                                        onChange={this.props.onChangeEsClientes} 
                                                        value={this.props.esClientes}/>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CtrlDatePicker
                                                etiqueta="Fecha Embarque Inicio"
                                                defaultValue={this.now}
                                                onChange={this.props.onChangeFechaEmbarqueIni}
                                                value={this.props.fechaEmbarqueIniValue}
                                            />
                                        </Col>
                                        <Col>
                                            <CtrlDatePicker
                                                etiqueta="Fecha Embarque Fin"
                                                defaultValue={this.now}
                                                onChange={this.props.onChangeFechaEmbarqueFin}
                                                value={this.props.fechaEmbarqueFinValue}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CtrlComboBox
                                                etiqueta="Ubicacion"
                                                onChange={this.props.onChangeCmbUbicacion}
                                                data={this.props.ubicacionesData}
                                                value={this.props.claUbicacion}
                                                reference={this.props.refCmbUbicacion}
                                            />
                                        </Col>   
                                        <Col>
                                            <CtrlComboBox
                                                etiqueta="Gpo. Estadístico"
                                                onChange={this.props.onChangeCmbEstadistico}
                                                data={this.props.estadisticosData}
                                                value={this.props.claEstadistico}
                                                reference={this.props.refCmbEstadistico}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CtrlComboBox
                                                etiqueta="Área"
                                                onChange={this.props.onChangeCmArea}
                                                data={this.props.areaData}
                                                value={this.props.claArea}
                                                reference={this.props.refCmbArea}
                                            />
                                        </Col>                             
                                        <Col>
                                            <CtrlComboBox
                                                etiqueta="Motivo Vencimiento"
                                                onChange={this.props.onChangeCmbMotivo}
                                                data={this.props.motivoData}
                                                value={this.props.claMotivo}
                                                reference={this.props.refCmbMotivo}
                                            />
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Row>
                                        <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                            Buscar
                                        </Button>
                                        {spinner}
                                        <Button type="button" onClick={this.onBtnLimpiar}>
                                            Limpiar
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          
        </div>
        );
    }
}