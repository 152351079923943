/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, FilterRow, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import CapaidadMasterDetail from './CapacidadMasterDetail';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';

export default class CapacidadGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onExporting = this.onExporting.bind(this);
    }

    getDetalle() {
        const urlService = `${process.env.REACT_APP_URL_API}Produccion/Busqueda`;

        callApi(urlService, 'POST', this.props.filtros, (response) => {
            this.Exporting(response[1])
        });
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
            this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        this.getDetalle();
        e.cancel = true;
    }

    Exporting(detalle) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = this.dataGrid.instance;
        const masterRows = [];
        const items = [];

        worksheet.columns = [
            { width: 20 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }
        ];
        exportDataGrid({
            component: grid,
            worksheet,
            keepColumnWidths: false,
            topLeftCell: { row: 1, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
                if (gridCell.rowType === 'data') {
                    // Valida que no se repita la llave
                    if (items.indexOf(gridCell.data.idPlan) < 0) {
                        items.push(gridCell.data.idPlan);
                        masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
                    }
                }

                // Formato a la celdas si es Group Row
                /* if (gridCell.rowType === 'group') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
                }
                */

                // Formato a la celda de Datos
                if (gridCell.rowType === 'data') {
                    excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
                    excelCell.font = { bold: true };
                }

                // Formato a la celdas si es Total Footer Row
                if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                    excelCell.font.italic = true;
                }
            }

        })
            .then((cellRange) => {
                // GENERAR UNA SECCION DE DETALLE
                const encabezados = [
                    // "Id Plan",
                    // "Id Plan Det",
                    // "Plan Enc",
                    // "Id Padre",
                    "Ubicacion",
                    "Periodo",
                    "Area Producción",
                    "Grupo Máquina",
                    "Segmentación",
                    "Nombre",
                    "Nivel",
                    "Porcentaje",
                    "Total Hrs",
                    "Horas Disponbles",
                    "Horas Reservadas"
                ];
                const columns = [
                    //  "idPlan",
                    //  "idPlanDet",
                    //  "idPlanEnc",
                    //  "idPadre",
                    "claUbicacion",
                    "claPeriodo",
                    "claAreaProduccion",
                    "claGrupoMaquinas",
                    "claCampo",
                    "nombreCampo",
                    "nivel",
                    "porcentaje",
                    "totalHrs",
                    "disponibleHrs",
                    "reservadoHrs"
                ];
                const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
                const columnIndex = cellRange.from.column;

                const insertRow = function (index, offset, outlineLevel) {
                    const currentIndex = index + offset;
                    const row = worksheet.insertRow(currentIndex, [], 'n');

                    for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
                        worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
                    }

                    row.outlineLevel = outlineLevel;
                    return row;
                }

                let totalOffset = 0;

                for (let i = 0; i < masterRows.length; i++) {
                    let offset = 0;
                    offset = totalOffset;

                    // ** Cambio: Llave encabezado
                    // const { ClaArticulo } = masterRows[i].data;
                    const { fechaCalculo, idPlan } = masterRows[i].data;
                    // Agrega un Row con los Encabezados del Detalle...
                    const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                    encabezados.forEach((header, currentColumnIndex) => {
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                            value: header,
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } },
                            font: { bold: true },
                            border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                            alignment: { horizontal: 'center' }
                        });
                    });

                    // ** Cambio : Filtro Detalle
                    // const pedidoPartidas = detalle.filter((partida) => partida.ClaProductoOrig == ClaArticulo);
                    const pedidoPartidas = detalle.filter((partida) => partida.fechaCalculo == fechaCalculo && partida.idPlanEnc == idPlan);
                    pedidoPartidas.forEach((partida) => {
                        const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

                        columns.forEach((columnName, currentColumnIndex) => {
                            const alinear = currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';
                            // Imprime cada celda del detalle..
                            Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                                value: partida[columnName],
                                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F9FE' } },
                                border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                                alignment: { horizontal: alinear }
                            });
                        });
                    });
                    offset--;
                    totalOffset = offset;
                }

            })
            .then(() => {
                workbook.xlsx.writeBuffer().then((buffer) => {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Produccion.xlsx');
                });
            });
        grid.cancel = true;
    }

    onCellPrepared(e) {
        if (e.rowType === 'data' && e.columnIndex === 8 && e.data.horasReales <= 0) {
            e.cellElement.style.color = "red";
            e.cellElement.style.fontWeight = "bold";
            //   return;
        }
    }

    componentDidMount() {
    }

    render() {
        const gridContent = (
            <div className='content'>
                <DataGrid
                    id="gridProduccion"
                    ref={(ref) => this.dataGrid = ref}
                    dataSource={this.props.data[0]}
                    key={'idPlan'}
                    width="100%"
                    height={600}
                    selection={{ mode: 'single' }}
                    showColumnHeaders
                    showRowLines
                    noDataText="Sin Registros"
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    onExporting={this.onExporting}
                    onCellPrepared={this.onCellPrepared}
                >

                    <FilterRow
                        visible={true}
                        applyFilter={true} />
                    <HeaderFilter visible={true} width={"400"} />

                    <MasterDetail
                        enabled={true}
                        component={CapaidadMasterDetail}
                    />

                    <Column dataField="idPlan" caption="Id Plan" visible={false} width={10}/>
                    <Column dataField="claUbicacion" caption="Ubicacion" width={100} />
                    <Column dataField="claAreaProduccion" caption="Area Producción" width={200} />
                    <Column dataField="claGrupoMaquinas" caption="Grupo Máquina" width={200} />
                    <Column dataField="horasTotales" caption="Horas Totales"  dataType="number" alignment="left" width={50}>
                        <Format
                            type="fixedPoint"
                            precision={0}
                            format="#0.##"
                        />

                    </Column>
                    <Column dataField="horasInhabiles" caption="Horas Inhabiles"  dataType={"number"} alignment="left" width={50}>
                        <Format
                            type="fixedPoint"
                            precision={0}
                            format="#0.##"
                        />
                    </Column>
                    <Column dataField="horasReales" caption="Horas Reales"  dataType={"number"} alignment="left" width={50}>
                        <Format
                            type="fixedPoint"
                            precision={0}
                            format="#0.##"
                        />
                    </Column>
                    <Column dataField="claPeriodo" caption="Periodo"  width={50}/>
                    
                    <Paging
                        pageSize={30}
                        pageIndex={this.props.pageIndex}
                    />
                    
                    <Export enabled={true} allowExportSelectedData={false} />
                    <Scrolling columnRenderingMode="virtual" />
                </DataGrid>

            </div>
        );

        return (
            <>
                {gridContent}
            </>
        );
    }
}
