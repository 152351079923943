import React from 'react';

import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { locale } from "devextreme/localization";
import { Popup } from 'devextreme-react/popup';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { config } from '../../../utils/config';
import ScrollView from 'devextreme-react/scroll-view';
import HorizonteGrid from './HorizonteGrid';
import HorizonteAlta from './HorizonteAlta';

class Horizonte extends React.Component {
  constructor(props) {
    super(props);

    locale('en');


    this.state = {
      popupVisible:false,
      claPermiso:null,
      dataUbicaciones: [],
      dataGpoEstadisticos1: [],
      dataTipoInventario: [
        { 'Clave': 1, 'Nombre': '1-MTS' },
        { 'Clave': 3, 'Nombre': '3-MTO' }
      ]
    };

    this.onClickAgregar = this.onClickAgregar.bind(this);
    this.hidePopUp = this.hidePopUp.bind(this);
    this.handler = this.handler.bind(this);
    this.getGpoEstadistico = this.getGpoEstadistico.bind(this);
    this.getUbicaciones = this.getUbicaciones.bind(this);
    this.getHorizonteCfg = this.getHorizonteCfg.bind(this);
    this.updateHorizonteCfg = this.updateHorizonteCfg.bind(this);
    this.deleteHorizonteCfg = this.deleteHorizonteCfg.bind(this);
  }

  /**
   * Funcion que se abre el popup para dar de alta un usuario
   * @param {Obj} e objeto del popup
   */
  onClickAgregar(e) {
    this.setState({
      popupVisible: true,
    });

    e.stopPropagation();
  }

  /**
   * Funcion que se oculta el popup de alta de usuario
   */
  hidePopUp() {
    this.setState({
      popupVisible: false,
    });
  }
  
  /**
   * Funcion que actualiza el estatus del popup de alta de usuario
   * @param {Boolean} val estatus del popop
   */
  handler(val) {
    this.setState({
      popupVisible: val,
    });
  }

  getUbicaciones(){
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 258, params, 1, (res) => {
        this.setState({
            dataUbicaciones: res.Result0
        });
    });
  }

  getPermiso(){
    callApi(`${process.env.REACT_APP_URL_API}usuario/permisos`, 'POST', {}, (result) => {
      this.setState({
        claPermiso: result.data
      })
    });

  }

  getGpoEstadistico(){
    const urlApiService = `${process.env.REACT_APP_URL_API}Vencimiento/GetGpoEstadistico`;

    callApi(urlApiService, 'POST', null, (result) => {
        console.log(result);
        this.setState({
            dataGpoEstadisticos1: result
        });
    });
  }

  getHorizonteCfg() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/Horizontes`;

    callApi(urlApiService, 'POST', null, (result) => {
      this.setState({
        data: result[0],
      });
    });
  }

  updateHorizonteCfg(body){
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/HorizontesUpd`;

    callApi(urlApiService, 'POST', body, (res) => {
      this.getHorizonteCfg();
    });
  }

  deleteHorizonteCfg(body){
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/HorizontesDel`;

    callApi(urlApiService, 'POST', body, (res) => {
      this.getHorizonteCfg();
    });
  }

  componentDidMount() {
    this.getPermiso();
    this.getUbicaciones();
    this.getGpoEstadistico();
    this.getHorizonteCfg();
  }

  render() {

    const btnAgregar = this.state.claPermiso === 1 || this.state.claPermiso === 2 ? (
      <>
        <Button
          id="btnNuevo"
          onClick={this.onClickAgregar}
          size="md"
          className="btn btn-primary btn-round btn-icon btn-custom mb-1"
        >
          <i className="fa fa-plus" />
        </Button>
      </>
      ): <></>;

    return (
      <>
        <div className="content mb-5">
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag="h4">Configuración de Horizontes</CardTitle>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                  {btnAgregar}
                </Col>
              </Row>
              <Row>
                <Col md={{ size: 12, offset: 0 }}>
                  <HorizonteGrid 
                    data={this.state.data} 
                    dataGpoEstadisticos1={this.state.dataGpoEstadisticos1}
                    updHorizonteCfg={this.updateHorizonteCfg}
                    delHorizonteCfg={this.deleteHorizonteCfg}
                    claPermiso={this.state.claPermiso}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <Popup
          key="popHorizontes"
          id="popHorizontes"
          visible={this.state.popupVisible}
          onHiding={this.hidePopUp}
          dragEnabled={false}
          closeOnOutsideClick={true}
          showTitle={true}
          title="Alta de Horizonte"
          width={470}
          height={570}
        >
          <ScrollView height="100%" width="100%">
            <HorizonteAlta 
              handler={this.handler} 
              dataUbicaciones={this.state.dataUbicaciones} 
              dataGpoEstadisticos1={this.state.dataGpoEstadisticos1} 
              dataTipoInventario={this.state.dataTipoInventario} 
              getHorizonteCfg={this.getHorizonteCfg}
            />
          </ScrollView>
        </Popup>
      </>
    );
  }
}


export default Horizonte;