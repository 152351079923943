/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Editing, Paging, Texts, Format, Export } from 'devextreme-react/data-grid';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class CapacidadConfigGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;

        this.state = {
            usuarios: [],
            roles: [],
            claPermiso:null,
          };

          this.onRowUpdated = this.onRowUpdated.bind(this);
          this.updateRegistro = this.updateRegistro.bind(this);
      
          this.handleOptionChange = this.handleOptionChange.bind(this);   
    }
    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndexTab1') {
          this.props.onPage(e.value);
        }
    }
    

  getPermiso(){
    callApi(`${process.env.REACT_APP_URL_API}usuario/permisos`, 'POST', {}, (result) => {
      this.setState({
        claPermiso: result.data
      })
    });

  }

  /**
   * Funcion que actualiza la capacidad disponible
   */
  onRowUpdated(objeto) {
    this.updateRegistro(objeto);
  }

  /**
   * Funcion que realiza la peticion al servicio
   */
  updateRegistro(objeto) {
    if (objeto.data) {
      const urlApiService = `${process.env.REACT_APP_URL_API}Embarque/ActualizaCapDispCarga`;

      // Parametros o cuerpo del servicio
      const body = {
        ClaUbicacion: objeto.data.ClaUbicacion,
        PctLunCapCarga: objeto.data.PctLunCapCarga,
        PctMarCapCarga: objeto.data.PctMarCapCarga,
        PctMieCapCarga: objeto.data.PctMieCapCarga,
        PctJueCapCarga: objeto.data.PctJueCapCarga,
        PctVieCapCarga: objeto.data.PctVieCapCarga,
        PctSabCapCarga: objeto.data.PctSabCapCarga,
        PctDomCapCarga: objeto.data.PctDomCapCarga,
      };

      callApi(urlApiService, 'POST', body, (res) => { });
  
    }
  }

  componentDidMount() {
    this.getPermiso();
  }

    render() {
        const permisos =this.state.claPermiso===1 || this.state.claPermiso===2;

        const gridContent = (
            <Row>
                <Col>
                    <Row>    
                        <Col>
                            <DataGrid
                                id="gridCapacidadConfig"
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.props.capacidadConfigData}
                                key={'ClaUbicacion'}
                                width="100%"
                                height={600}
                                selection={{ mode: 'single' }}
                                showBorders={false}
                                columnHidingEnabled
                                columnAutoWidth={true}
                                showColumnHeaders
                                showColumnLines={false}
                                showRowLines={true}
                                noDataText="Sin Registros"
                                wordWrapEnabled
                                rowAlternationEnabled={false}
                                onOptionChanged={this.handleOptionChange}
                                onRowUpdated={this.onRowUpdated}
                            >
 
                              <Editing
                                mode="row"
                                allowUpdating={permisos}
                                allowDeleting={false}
                                allowAdding={false}
                                width={100}
                                >
                            </Editing>   

                                <Column dataField="ClaUbicacion" caption="Clave Ubicación" allowEditing={false} visible={false} width={30}/>
                                <Column dataField="NomUbicacion" caption="Ubicación" allowEditing={false} width={120}/>
                                <Column dataField="PctLunCapCarga" caption="% Lun" allowEditing={true} width={30}/>
                                <Column dataField="PctMarCapCarga" caption="% Mar" allowEditing={true} width={30}/>
                                <Column dataField="PctMieCapCarga" caption="% Mié" allowEditing={true} width={30}/>
                                <Column dataField="PctJueCapCarga" caption="% Jue" allowEditing={true} width={30}/>
                                <Column dataField="PctVieCapCarga" caption="% Vie" allowEditing={true} width={30}/>
                                <Column dataField="PctSabCapCarga" caption="% Sáb" allowEditing={true} width={30}/>
                                <Column dataField="PctDomCapCarga" caption="% Dom" allowEditing={true} width={30}/>
                                <Column width={30}/>

                                <Paging
                                    pageSize={30}
                                    pageIndexTab1={this.props.pageIndexTab1}
                                />
                            </DataGrid>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

        const GridConfigCapacidadServicio = () => {
            return <div>{gridContent}</div>;
          };
      

    //    return (
    //     <>
    //         {gridContent}
    //     </>
    //     );

        return (
            <>
              <div className="content">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <CardTitle tag="h4">Configuración Capacidad % Carga Disponible</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <GridConfigCapacidadServicio claPermiso={this.state.claPermiso} />
                  </CardBody>
                </Card>
              </div>
            </>
          );
      
    }
}