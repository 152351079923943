import React from 'react';

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import Highcharts from 'highcharts';
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from 'highcharts-react-official';



import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from 'utils/utils';
import GridPedidos from './GridPedidos';


class Semaforo extends React.Component {
  constructor(props) {
    super(props);

    highchartsMore(Highcharts);
    solidGauge(Highcharts);

    this.graficaVerdeRef = React.createRef();
    this.graficaMarilloRef = React.createRef();
    this.graficaRojoRef = React.createRef();

    this.state = {
      totales: { total: null, totalInv: null, totalViajes: null, totalTraspasos: null, totalProduccion: null, verde: null, amarillo: null, rojo: null },
      optionsVerde: null,
      optionsAmarillo: this.getChartAmarillo(),
      optionsRojo: this.getChartRojo(),
      data: null

    };

  }

  getPedidos(filtros) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const urlService = `${process.env.REACT_APP_URL_API}Semaforo/Busqueda`;


    const params = {
      ClaDireccion: filtros.claDireccion,
      ClaSubdireccion: filtros.claSubdireccion,
      ClaZona: filtros.claZona,
      ClaAgente: filtros.claAgente,
      ClaUbicacion: filtros.claPlanta,
      ClaFamilia: filtros.claFamilia,
      ClaSubfamilia: filtros.claSubfamilia,
      ClaProducto: filtros.claProducto,
      ClaCliente: filtros.claCliente,
      ClaPedido: filtros.numPedido !== null ? filtros.numPedido : -1,
      TipoFecha: filtros.tipoFecha,
      FechaInicio: filtros.fechaInicio,
      FechaFin: filtros.fechaFin,
      PendFechaPromesa: filtros.pendienteFechaPromesa === true ? 1 : 0,
      CanceladoSurtido: filtros.canceladosSurtidos === true ? 1 : 0,
      PedidoVencidos: filtros.pedidoVencidos === true ? 1 : 0
    };


    callApi(urlService, "POST", params, (res) => {
      this.setState({
        data: res.detalle,
        totales: res.totales,
        optionsVerde: this.getChartVerde(res.totales.verde),
        optionsAmarillo: this.getChartAmarillo(res.totales.amarillo),
        optionsRojo: this.getChartRojo(res.totales.rojo)
      });
    });
  }


  getChartVerde(valor) {
    const options = this.getChart();
    options.pane.background[0].backgroundColor = Highcharts.color("#34b53a").setOpacity(0.3).get();
    options.series[0].data[0].color = Highcharts.color("#34b53a").get();
    options.series[0].data[0].y = valor !== undefined ? valor : 0;

    return options;
  }

  getChartAmarillo(valor) {
    const options = this.getChart();
    options.pane.background[0].backgroundColor = Highcharts.color("#ffb200")
      .setOpacity(0.3)
      .get();

    options.series[0].data[0].color = Highcharts.color("#ffb200").get();
    options.series[0].data[0].y = valor !== undefined ? valor : 0;

    return options;
  }

  getChartRojo(valor) {
    const options = this.getChart();
    options.pane.background[0].backgroundColor = Highcharts.color("#ff3a29")
      .setOpacity(0.3)
      .get();

    options.series[0].data[0].color = Highcharts.color("#ff3a29").get();
    options.series[0].data[0].y = valor !== undefined ? valor : 0;


    return options;
  }

  getChart() {

    return {
      chart: {
        type: 'solidgauge',
        height: '150',
        backgroundColor: 'rgba(0,0,0,0)',
        events: {
          /// render: renderIcons
        }
      },

      title: {
        text: '',
        style: {
          fontSize: '24px'
        }
      },

      tooltip: {
        enabled: false,
      },

      pane: {
        startAngle: 0,
        endAngle: 360,
        background: [{ // Track for Move
          outerRadius: '0%',
          innerRadius: '88%',
          backgroundColor: Highcharts.color("#34b53a")
            .setOpacity(0.3)
            .get(),
          borderWidth: 0
        }]
      },

      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: []
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            enabled: true,
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: false
        }
      },

      series: [{
        name: 'Move',
        data: [{
          color: "#34b53a",
          radius: '100%',
          innerRadius: '88%',
          y: 80
        }],
        dataLabels: {
          format: `<div style="text-align:center"><span style="font-size:25px;color:"${((Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black')}">{y}%</span></div>`
        }
      }],
      credits: { enabled: false },
    };
  }

  componentDidMount() {
    this.getPedidos(this.props.filtros);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
      this.getPedidos(this.props.filtros);
    }
  }

  render() {


    const contenido = (
      <>
        <Row>
          <Col className="col-12">
            <Row>
              <Col className="col-4">
                <Card className="m-1 border-5 border-top border-bottom border-right border-left border-dark">
                  <CardBody className="p-1 align-items-center">
                    <Row>
                      <Col className="d-flex justify-content-center border-dark"> <h2>{this.state.totales.total}</h2> </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center"> <h5>Pedidos calculados con fecha</h5></Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col-8">
                <Card className="m-1 border-top border-bottom border-right border-left border-dark">
                  <CardBody className="p-1">
                    <Row>
                      <Col className="col-3">
                        <Row>
                          <Col className=" d-flex justify-content-center"><h2>{this.state.totales.totalInv}</h2></Col>
                        </Row>
                        <Row>
                          <Col className=" d-flex justify-content-center"><h5>Inventario</h5></Col>
                        </Row>
                      </Col>
                      <Col className="col-3">
                        <Row>
                          <Col className=" d-flex justify-content-center"><h2>{this.state.totales.totalViajes}</h2></Col>
                        </Row>
                        <Row>
                          <Col className=" d-flex justify-content-center"><h5>Viajes</h5></Col>
                        </Row>

                      </Col>
                      <Col className="col-3">
                        <Row>
                          <Col className=" d-flex justify-content-center"><h2>{this.state.totales.totalTraspasos}</h2></Col>
                        </Row>
                        <Row>
                          <Col className=" d-flex justify-content-center"><h5>Traspasos</h5></Col>
                        </Row>
                      </Col>
                      <Col className="col-3">
                        <Row>
                          <Col className=" d-flex justify-content-center"><h2>{this.state.totales.totalProduccion}</h2></Col>
                        </Row>
                        <Row>
                          <Col className=" d-flex justify-content-center"><h5>Producción</h5></Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="col-4">
            <Card className="m-1 border-top border-bottom border-right border-left border-dark">
              <CardBody className="p-1">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.optionsVerde}
                  containerProps={{ style: { width: '100%', height: '90%' } }}
                  ref={this.graficaVerdeRef}
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="col-4">
            <Card className="m-1 border-top border-bottom border-right border-left border-dark">
              <CardBody className="p-1">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.optionsAmarillo}
                  containerProps={{ style: { width: '100%', height: '90%' } }}
                  ref={this.graficaAmarilloRef}
                />
              </CardBody>
            </Card>
          </Col>
          <Col className="col-4">
            <Card className="m-1 border-top border-bottom border-right border-left border-dark">
              <CardBody className="p-1">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={this.state.optionsRojo}
                  containerProps={{ style: { width: '100%', height: '90%' } }}
                  ref={this.graficaRojoRef}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );



    return (
      <>
        <div className="content mb-5">
          {contenido}
          <Row>
            <Col className="col-12">
              <GridPedidos filtros={this.props.filtros} data={this.state.data} onClick={this.props.onClick}></GridPedidos>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Semaforo;