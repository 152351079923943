/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, Scrolling, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';


export default class ProduccionGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;
        this.state = {
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);        
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
 
        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Motivos Vencimiento Partidas.xlsx');
                });
        });
        e.cancel = true;
    }
    
    componentDidMount() {
    }
  
    render() {
        return (
            <>
                <DataGrid
                    id="gridProduccion"
                    ref={(ref) => this.dataGrid = ref}
                    dataSource={this.props.data}
                    key={'idPlan'}
                    width="100%"
                    height={600}
                    selection={{ mode: 'single' }}
                    showBorders={false}
                    columnAutoWidth={false}
                    showColumnHeaders
                    showColumnLines={false}
                    showRowLines
                    noDataText="Sin Registros"
                    wordWrapEnabled
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    onExporting={this.onExporting}                    
                    //columnWidth={100}
                >
                    <FilterRow
                        visible={true}
                        applyFilter={true}
                    />
                    <HeaderFilter visible={true} />             

                    <Column dataField="FechaEvaluacion" caption="Fecha Evaluación" alignment="center" width = '100'
                        dataType={"date"} 
                        format={"dd/MM/yyyy"}
                    ></Column>
                    <Column width = '150' dataField="NombreUbicacion" caption="Ubicación"/>
                    <Column width = '10' dataField="FechaPromesaActual" caption="Fecha Embarque"
                        dataType={"date"} 
                        format={"dd/MM/yyyy"}
                    ></Column>
                    <Column alignment= "center" width = '100' dataField="ClaPedido" caption="Pedido" allowResizing={false}/>
                    <Column alignment= "center" width = '100' dataField="IdFabricacionDet" caption="Renglón" allowResizing={false}/>
                    <Column alignment= "center" width = '100' dataField="ClaveArticulo" caption="Clave Artículo" allowResizing={false}/>
                    <Column alignment= "left"   width = '100' dataField="NomFamilia" caption="Familia" allowResizing={false}/>
                    <Column alignment= "left"   width = '100' dataField="NombreSubFamilia" caption="SubFamilia" allowResizing={false}/>
                    <Column alignment= "left"   width = '100' dataField="MotivoVencimiento" caption="Motivo Vencimiento" allowResizing={false}/>
                    <Column alignment= "center" width = '100' dataField="NomArea" caption="Área" allowResizing={false}/>
                    <Column alignment= "left"   width = '150' dataField="NombreGrupoEstadistico1" caption="Gpo Estadístico 1" allowResizing={false}/>
                    <Column alignment= "center" width = '100' dataField="Clasificacion" caption="Clasificación" allowResizing={false}/>
                    <Column alignment= "center" width = '300' dataField="Observaciones" caption="Observaciones"allowResizing={false} />
                    
                    <Scrolling columnRenderingMode="virtual" />
                    <Paging
                        pageSize={30}
                        pageIndex={this.props.pageIndex}
                    />
                    
                    <Export enabled={true} allowExportSelectedData={false} />
                </DataGrid>
            </>
        );
    }
}