/*eslint-disable*/
import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import PedidosGridDet from './PedidosGridDet'

class PedidosMasterDetail extends React.Component {
    constructor(props) {
        super(props);

        this.renderTabPedidoDetalle = this.renderTabPedidoDetalle.bind(this);
    }

    componentDidMount() { 
    }

    renderTabPedidoDetalle() {
        console.log(this.props.data.data.idPlan)

        return <PedidosGridDet claPedido={this.props.data.data.claPedido} 
        fechaCalculo={this.props.data.data.fechaCalculo} 
        modoVista={this.props.data.data.modoVista}
        />;
    }


  render() {
      return (
        <TabPanel>
          <Item title="Detalle" render={this.renderTabPedidoDetalle} />
        </TabPanel>
      );
  }
}

export default PedidosMasterDetail;