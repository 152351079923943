/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup, Row, Col } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import CtrlAutoComplete from '../../Controles/CtrlAutoComplete';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlNumerico from '../../Controles/CtrlNumerico';

export default class Template4Alta extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ubicacionesData: [],
            familiasData:[],
            subFamiliasData:[],
            productosData:[],
            claSubFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            claUbicacion: null,
            claMolino: null
        }

        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getProductos = this.getProductos.bind(this);
        this.getFamilias = this.getFamilias.bind(this);
        this.getSubfamilias = this.getSubfamilias.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.onProductoSelected = this.onProductoSelected.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
    
        this.refNumProducto = this.refNumProducto.bind(this);
        // this.refNumProducto = React.createRef();
        this.refCmbUbicacion = React.createRef();

        this.autoCompleteInstance = null;
    }

    refNumProducto(e){
        this.autoCompleteInstance = e.component;
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            
            let MyA = res.Result0.filter(u => !u.Nombre.includes('Acería'));

            this.setState({
                ubicacionesData: MyA
            })
        });
    }

    getProductos(claUbicacion) {
    
        const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/GetCatProductosMyALoteminimo?ClaUbicacion=${claUbicacion}`;

        callApi(urlApiService, 'GET', null, (result) => {
            // console.log(result);
            this.setState({
            productosData: result
            })
        });
    }

    getFamilias(){
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaNivel: 20,
        };

        callKrakenApi(25, 1, params, 1, (res) => {
            // return res.Result0;
            this.setState({
                familiasData: res.Result0
            })
        });
    }

    getSubfamilias(familia){
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaFamilia: familia.ClaFamilia,
        };

        callKrakenApi(25, 2, params, 1, (res) => {
            this.setState({
                subFamiliasData: res.Result0,
                claSubFamilia: familia.ClaSubFamilia,
                claFamilia: familia.ClaFamilia,
                claProducto : familia.ClaProducto
            });
        });
    }

    limpiar(){
        if(this.autoCompleteInstance !== null)
        {this.autoCompleteInstance.reset();}
        
        this.setState({
            claUbicacion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto : null,
            claMolino: null,
            subFamiliasData:[],
            productosData:[],
        })
    }

    onChangeCmbUbicacion(object){
        if(object.value !== null){            
            this.setState({
                claUbicacion: object.value
            })
            this.getProductos(object.value)
        }
        else{
            this.limpiar();
        }
    }

    btnGuardarHandleSubmit() {
    
      const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/InsertTemplate4`;

      const body = {
        idConfiguracion: this.props.idConfiguracion,
        claUbicacion: this.state.claUbicacion,
        claFamilia: this.state.claFamilia,
        claSubFamilia: this.state.claSubFamilia,
        claProducto: this.state.claProducto
      };
      
      callApi(urlApiService, 'POST', body, (res) => {
        //   showNotify('Usuario guardado correctamente', notifyPosition.centerTop, notifyType.success);
        this.props.handler(false);
        window.location.reload();
      });
    }

    btnCancelarHandle(){
        this.props.handler(false);
        this.limpiar();
    }

    onProductoSelected(object){
        
        if(object.selectedItem !== null)
        {
            
            this.setState({
                txtLoteMinimo: object.selectedItem.LoteMinimo
            })
            this.autoCompleteInstance = object.component;
            const data = {claProducto : object.selectedItem.ClaArticulo};

            const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/GetFamiliasSubFamilias`;
            callApi(urlApiService, 'POST', data, (res) => {
                console.log(res)
                this.getSubfamilias(res[0]);
            });
            
        }
        else{
            this.setState({
                claFamilia: null,
                claSubFamilia: null,
                claProducto : null,
                claMolino : null,
                subFamiliasData:[],
            })
        }
    }

    componentDidMount(){
        this.getUbicaciones();
        this.getFamilias();
    }

    render(){
        return(
            <div>
                <Row>
                    <Col>
                        <CtrlComboBox
                            etiqueta="Ubicacion"
                            onChange={this.onChangeCmbUbicacion}
                            data={this.state.ubicacionesData}
                            value={this.state.claUbicacion}
                            reference={this.refCmbUbicacion}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CtrlAutoComplete
                            etiqueta="Producto"
                            placeholder="Clave o Nombre del producto..."
                            valueExpr="Nombre"
                            displayExpr="Nombre"
                            keyValue="Clave"
                            claFamilia={this.state.claSubFamilia}
                            claSubfamilia={this.state.claSubFamilia}
                            dataSource={this.state.productosData}
                            onChange={this.onProductoSelected}
                            value={this.state.claProducto}
                            // reference={this.refNumProducto}
                            onInitialized={this.refNumProducto}
                            disabled={this.state.claUbicacion === null}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CtrlComboBox
                            etiqueta="Familia"
                            onChange={this.props.onChangeClaFamilia}
                            data={this.state.familiasData}
                            value={this.state.claSubFamilia}
                            reference={this.refCmbFamilia}
                            disabled={true}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CtrlComboBox
                            etiqueta="SubFamilia"
                            onChange={this.props.onChangeClaSubFamilia}
                            data={this.state.subFamiliasData}
                            value={this.state.claSubFamilia}
                            reference={this.refCmbSubFamilia}
                            disabled={true}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CtrlNumerico
                            etiqueta="Lote mínimo"
                            value={this.state.txtLoteMinimo}
                            disabled={true}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col>
                        <Button
                            id="btnUsersGuardar"
                            text="Guardar"
                            onClick={this.btnGuardarHandleSubmit}
                            className="btn btn-primary btn-round"
                        />
                    </Col>
                    <Col>
                        <Button
                            id="btnUsersCancelar"
                            text="Cancelar"
                            onClick={this.btnCancelarHandle}
                            className="btn btn-primary btn-round"
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}