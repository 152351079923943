import React, { Component } from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { config } from 'utils/config';
import { callApi, showNotify, notifyType, notifyPosition, callKrakenApi } from 'utils/utils';

class HorizonteAlta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buscar: false,
      objTraspaso: {
        ClaveUbicacion: 0,
        ClaveGrupoEstadistico: 0,
        ClaveTipoInventario: 0,
        DiasVencido: 0,
        DiasFuturo: 0,
      },
    };

    // this.btnBuscarHandleSubmit = this.btnBuscarHandleSubmit.bind(this);
    this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
    this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
    this.refForm = React.createRef();
  }

  /**
   * Regresa el objeto del formulario
   */
  get formUser() {
    return this.refForm.current ? this.refForm.current.instance : null;
  }

  /**
   * Funcion que se ejecuta al dar clic en el boton Guardar
   */
  btnGuardarHandleSubmit() {
    const dataForm = this.formUser.option('formData');

    if (!dataForm.ClaveUbicacion || 
        // !dataForm.ClaveGrupoEstadistico || 
      !dataForm.ClaveTipoInventario || dataForm.DiasVencido <= 0 || dataForm.DiasFuturo <= 0) {
      showNotify(
        'Llenar todos los campos',
        notifyPosition.centerTop,
        notifyType.error
      );
      return;
    }

    const body = {
      ClaUbicacion: dataForm.ClaveUbicacion,
      ClaGrupoEstadistico: dataForm.ClaveGrupoEstadistico,
      ClaTipoInventario: dataForm.ClaveTipoInventario,
      DiasVencido: dataForm.DiasVencido,
      DiasFuturo: dataForm.DiasFuturo
    };

    // console.log(body)
    const urlApiService = `${process.env.REACT_APP_URL_API}Catalogo/HorizontesIns`;

    // const body = {
    //   ClaUsuario: dataForm.ClaveUsuario,
    //   BajaLogica: 0,
    //   ClaRol: dataForm.claRol,
    // };

    callApi(urlApiService, 'POST', body, (res) => {
      showNotify('Configuración guardada correctamente', notifyPosition.centerTop, notifyType.success);
      this.props.handler(false);
      this.refForm.current.instance._resetValues()
      // window.location.reload();
    });
  }

  /**
   * Función del botón para cancelar la operación y ocultar el modal
   */
  btnCancelarHandle() {
    this.props.handler(false);
  }

  render() {
    return (
      <div className='m-0 p-0'>
        <Card className='mt0'>
          <CardBody cssClass='NoPadding'>
            <Form
              ref={this.refForm}
              key="formUser"
              formData={this.state.objTraspaso}
              colCount={1}
              showColonAfterLabel={true}
              className="formUser"
              id="FormAltaUsuario"
              scrollingEnabled={false}
              cssClass="NoPadding"
            >
              <Item dataField="ClaveUsuario" visible={false} />
              <Item
                dataField="ClaveUbicacion"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: this.props.dataUbicaciones,
                  searchEnabled: true,
                  valueExpr: 'Clave',
                  displayExpr: 'Nombre',
                  placeholder: 'Selecciona una ubicacion',
                }}
              />
              <Item
                dataField="ClaveTipoInventario"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: this.props.dataTipoInventario,
                  searchEnabled: true,
                  valueExpr: 'Clave',
                  displayExpr: 'Nombre',
                  placeholder: 'Selecciona un Tipo Inventario',
                }}
              />
              <Item
                dataField="ClaveGrupoEstadistico"
                editorType="dxSelectBox"
                editorOptions={{
                  dataSource: this.props.dataGpoEstadisticos1,
                  searchEnabled: true,
                  valueExpr: 'Clave',
                  displayExpr: 'Nombre',
                  placeholder: 'Selecciona un Grupo Estadístico',
                }}
              />
              <GroupItem colSpan={2} colCount={2}>
                <Item
                  dataField="DiasVencido"
                  editorType="dxNumberBox"
                />
                <Item
                  dataField="DiasFuturo"
                  editorType="dxNumberBox"
                />
              </GroupItem>

            </Form>
            <FormGroup className="text-right">
              <Button
                id="btnUsersGuardar"
                text="Guardar"
                onClick={this.btnGuardarHandleSubmit}
                className="btn btn-primary btn-round"
              />
              <Button
                id="btnUsersCancelar"
                text="Cancelar"
                onClick={this.btnCancelarHandle}
                className="btn btn-primary btn-round"
              />
            </FormGroup>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default HorizonteAlta;
