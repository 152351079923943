/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export } from 'devextreme-react/data-grid';

export default class CapacidadDispGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pageIndexTab3: 0
        };

        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);   
    }
    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndexTab3') {
          this.props.onPage(e.value);
        }
    }
    
    render() {
        const gridContent = (
            <Row>
                <Col>
                    <Row>    
                        <Col>
                            <DataGrid
                                id="gridCapacidadDisp"
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.props.capacidadDispData}
                                key={'ClaUbicacion'}
                                width="100%"
                                height={600}
                                selection={{ mode: 'single' }}
                                showBorders={false}
                                columnHidingEnabled
                                columnAutoWidth={false}
                                showColumnHeaders
                                showColumnLines={false}
                                showRowLines
                                noDataText="Sin Registros"
                                wordWrapEnabled
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                onOptionChanged={this.handleOptionChange}
                            >

                                <Column dataField="NomUbicacion" caption="Ubicación" width={40}/>
                                <Column dataField="NomZona" caption="Zona" width={40}/>
                                <Column dataField="CapacidadCargaUbi" caption="Capacidad" width={20}/>
                                <Column dataField="CapDispCargaLunes" caption="Lunes" width={20}/>
                                <Column dataField="CapDispCargaMartes" caption="Martes" width={20}/>
                                <Column dataField="CapDispCargaMiercoles" caption="Miércoles" width={20}/>
                                <Column dataField="CapDispCargaJueves" caption="Jueves" width={20}/>
                                <Column dataField="CapDispCargaViernes" caption="Viernes" width={20}/>
                                <Column dataField="CapDispCargaSabado" caption="Sábado" width={20}/>
                                <Column dataField="CapDispCargaDomingo" caption="Domingo" width={20}/>
                                <Column width={1}/>

                                <Paging
                                    pageSize={30}
                                    pageIndexTab3={this.props.pageIndexTab3}
                                />
                            </DataGrid>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

        return (
        <>
            {gridContent}
        </>
        );
    }
}