/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import CtrlComboBox from '../../../Controles/CtrlComboBox';

export default class FiltroFechas extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <CtrlComboBox
                        etiqueta="Ubicación"
                        onChange={this.props.onChangeCmbUbicacion}
                        data={this.props.dataUbicaciones}
                        value={this.props.filtros.claUbicacion}
                        reference={this.props.refCmbUbicacion}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <CtrlComboBox
                        etiqueta="Planta TE"
                        onChange={this.props.onChangeCmbPlantaTE}
                        data={this.props.dataUbicaciones}
                        value={this.props.filtros.claPlantaTE}
                        reference={this.props.refCmbPlantaTE}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <CtrlComboBox
                        etiqueta="Ubicación Elabora"
                        onChange={this.props.onChangeCmbUbicacionElabora}
                        data={this.props.dataUbicaciones}
                        value={this.props.filtros.claUbicacionElabora}
                        reference={this.props.refCmbUbicacionElabora}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}