/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Scrolling } from 'devextreme-react/data-grid';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';

import InfoPedido from './InfoPedido';
import InfoCalculo from './InfoCalculo';
import Trace from './Trace';

import { config } from '../../../utils/config';
import { callApi, semaforoColor } from '../../../utils/utils';

import 'assets/css/pedidotrace.css';
import 'assets/css/semaforo.css';

export default class PedidoDetalle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      datosEnc: {
        ClaPedido: null,
        NomCliente: '',
        NomUbicacion: '',
        FechaCaptura: '',
        Direccion: '',
        SubDireccion: '',
        FechaDeseada: '',
      },
      datosDet: [],
      datosRow: {},
      datosCant: [],
      datoTrace: { inventarios: [], traspasos: [], viaje: [] },
      datosFecha: [],
      fechaCalculo: null,
    };

    this.onContentReady = this.onContentReady.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onFechaCalculo = this.onFechaCalculo.bind(this);
    this.cellIconoRender = this.cellIconoRender.bind(this);
  }

  getData(fechaCalculo) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    // const urlService = `${config.KrakenService}/13/9`;
    const urlService = `${process.env.REACT_APP_URL_API}Pedido/Detalle`;

    const params = {
      ClaPedido: this.props.claPedido,
      FechaCalculo: fechaCalculo,
    };
    callApi(urlService, 'POST', params, (res) => {
      this.setState({
        datosEnc: res.encabezado,
        datosDet: res.detalle,
        datosCant: res.cantidades,
        datosRow: res.detalle[0],
      });
    });
  }

  getTrace(fechaCalculo) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    // const urlService = `${config.KrakenService}/13/9`;
    const urlService = `${process.env.REACT_APP_URL_API}Pedido/Trace`;
    console.log('url', urlService);
    const params = {
      ClaPedido: this.props.claPedido,
      FechaCalculo: fechaCalculo,
    };

    callApi(urlService, 'POST', params, (res) => {
      this.setState({ datoTrace: res });
    });
  }

  getTraceFecha(fechaCalculo) {
    const urlService = `${process.env.REACT_APP_URL_API}Pedido/TraceFecha`;
    console.log('url', urlService);
    const params = {
      ClaPedido: this.props.claPedido,
      FechaCalculo: fechaCalculo,
    };

    callApi(urlService, 'POST', params, (res) => {
      this.setState({ datosFecha: res });
    });
  }

  onContentReady(e) {
    const selectedRows = e.component.getSelectedRowsData();

    // Selecciona el primer row del grid.
    if (selectedRows.length === 0) {
      const listaKeys = [];
      if (this.state.datosDet.length > 0) {
        listaKeys.push(this.state.datosDet[0].idRenglon);
        e.component.selectRows(listaKeys, true);
      }
    }
  }

  onSelectionChanged(selectedItem) {
    this.setState({ datosRow: selectedItem.selectedRowsData[0] });
  }

  cellIconoRender(data) {
    const iconoColor = semaforoColor(data.data.claSemaforo);
    const icono = (
      <div>
        <i
          className={`mb-0 icon icon-shape rounded-circle shadow ${iconoColor}`}
          title={data.data.nomSemaforoEstatus}
          style={{ width: 30, height: 30, padding: '5px', cursor: 'pointer' }}
          // onClick={this.descargaDirecta.bind(this, data.ClaTipoDocumento, idDocumento, tipoArchivo)}
        ></i>
      </div>
    );

    return icono;
  }

  onFechaCalculo(fechaCalculo) {
    this.getData(fechaCalculo);
    this.getTrace(fechaCalculo);
    this.getTraceFecha(fechaCalculo);
  }

  componentDidMount() {
    this.getData(this.props.fechaCalculo);
    this.getTrace(this.props.fechaCalculo);
    this.getTraceFecha(this.props.fechaCalculo);
  }

  render() {
    const gridContent = (
      <div>
        <DataGrid
          id="gridDetalle"
          dataSource={this.state.datosDet}
          keyExpr="idRenglon"
          height={600}
          width="100%"
          selection={{ mode: 'single' }}
          showBorders={false}
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onContentReady={this.onContentReady}
          onSelectionChanged={this.onSelectionChanged}
        >
          <Column dataField="idRenglon" caption="#" width={50} alignment="right" />
          <Column dataField="producto" caption="Producto" width={200} />
          <Column dataField="deuda" caption="Cant" width={50} format="#,##0" />
          <Column dataField="unidad" caption="UM" width={50} />
          <Column dataField="kilosSolicitados" caption="Cant Kgs" width={50} format="#,##0" />
          <Column dataField="kilosSurtidos" caption="Surt. Kgs" width={50} format="#,##0" />
          <Column dataField="estatus" caption="Estatus" width={70} />
          <Column dataField="fechaEmbarque" caption="Fecha Embarque" width={120} />
          <Column dataField="servicio" caption="Servicio" width={120} />
          <Column
            dataField="claSemaforoEstatus"
            caption="Semaforo"
            width={80}
            cellRender={this.cellIconoRender}
          />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </div>
    );

    const semaforo =
      this.state.datosRow !== null && this.state.datosRow !== undefined ? (
        <h5>
          Semaforo: {this.state.datosRow.length > 0 ? this.state.datosRow.nomSemaforoEstatus : ''}{' '}
        </h5>
      ) : (
        <></>
      );
    return (
      <>
        <InfoPedido
          data={this.state.datosEnc}
          claPedido={this.props.claPedido}
          onBack={this.props.onBack}
          releaseState={this.props.releaseState}
          toggleReleaseState={this.props.toggleReleaseState}
        ></InfoPedido>
        <InfoCalculo
          data={this.state.datosEnc}
          dataFecha={this.state.datosFecha}
          fechaCalculo={this.props.fechaCalculo}
          onFechaCalculo={this.onFechaCalculo}
        ></InfoCalculo>
        <Row>
          <Col md={{ size: 8 }}>{gridContent}</Col>
          <Col md={{ size: 4 }} className="align-self-start">
            <Card key={this.props.claUbicacion} className="m-1">
              <CardBody className="p-1 bgTrace">
                <h5>
                  Renglon:{' '}
                  {this.state.datosRow !== undefined ? this.state.datosRow.idRenglon : null}{' '}
                </h5>
                {semaforo}
              </CardBody>
            </Card>
            <Trace
              claPedido={this.props.claPedido}
              data={this.state.datosRow}
              datosCant={this.state.datosCant}
              tracedata={this.state.datoTrace}
            ></Trace>
            <Card key={this.props.claUbicacion} className="m-1">
              <CardBody className="p-1 bgTrace">
                <h5>
                  Toneladas Asignadas de Fila de Reparto:{' '}
                  {this.state.datosRow !== undefined ? this.state.datosRow.invAsignadoTon : null}{' '}
                </h5>
                <h5>
                  Unidades Asignadas de Fila de Reparto:{' '}
                  {this.state.datosRow !== undefined ? this.state.datosRow.invAsignadoUni : null}{' '}
                </h5>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
