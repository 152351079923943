/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';

export default class FactorConversionFiltros extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            dataFactorConversion : []
        };

        this.onBtnBuscar = this.onBtnBuscar.bind(this);
        this.onBtnLimpiar = this.onBtnLimpiar.bind(this);
    }

    onBtnBuscar() {
        this.props.getFactorConversionData(this.props.filtros);
    }

    onBtnLimpiar() {
        this.props.onLimpiar();
    }

    componentDidMount() { }

    render() {

        const spinner =
        this.props.showSpin === true ? (
            <Loader type="Puff" color="#00BFFF" height={40} width={40} />
        ) : (<></>);

        return (
        
        <div className="content">
            <Card>
                <CardHeader>
                    <CardTitle>Factor de Conversión</CardTitle>
                </CardHeader>
            </Card>  
            <CardBody>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <CtrlDatePicker
                                    etiqueta="Fecha Cálculo"
                                    defaultValue={this.now}
                                    onChange={this.props.onChangeFechaCalculo}
                                    value={this.props.fechaCalculoValue}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Ubicacion"
                                    onChange={this.props.onChangeCmbUbicacion}
                                    data={this.props.ubicacionesData}
                                    value={this.props.claUbicacion}
                                    reference={this.props.refCmbUbicacion}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Área"
                                    onChange={this.props.onChangeCmbAreas}
                                    data={this.props.areasData}
                                    value={this.props.claArea}
                                    reference={this.props.refCmbAreas}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Producto"
                                    onChange={this.props.onChangeCmbProductos}
                                    onEnterKey={this.props.onEnterKey}
                                    data={this.props.productosData}
                                    value={this.props.claProducto}
                                    reference={this.props.refCmbProductos}
                                    searchExpr={['Nombre', 'Clave2']}
                                />
                            </Col>
                            <Col>
                                <CtrlComboBox
                                    etiqueta="Grupo Máquina"
                                    onChange={this.props.onChangeCmbGpoMaquinas}
                                    data={this.props.gpoMaquinasData}
                                    value={this.props.claGrupoMaquina}
                                    reference={this.props.refCmbGpoMaquinas}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                                Buscar
                            </Button>
                            {spinner}
                            <Button type="button" onClick={this.onBtnLimpiar}>
                                Limpiar
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </CardBody>          
        </div>
        );
    }
}