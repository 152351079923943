/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Scrolling } from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';

export default class CapacidadGridDet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pageIndex: 0,
      dataDetalle: [],
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.getProduccionData = this.getProduccionData.bind(this);
    this.onGridPage = this.onGridPage.bind(this);

    this.getProduccionData();
  }

  getProduccionData() {
    const filtros = {
      fechaCalculo: this.props.fechaCalculo,
      idPlan: this.props.idPlan,
      claProducto: null,
      claArea: null,
      claGrupoMaquina: null,
    };

    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Busqueda`;
    console.log('urlApiService', urlApiService);

    callApi(urlApiService, 'POST', filtros, (result) => {
      console.log(result);
      this.setState({
        dataDetalle: result[1],
      });
    });
  }

  onGridPage(pageIndex) {
    this.setState({ pageIndex });
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.onGridPage(e.value);
    }
  }

  render() {
    const gridContent = (
      <div>
        <DataGrid
          dataSource={this.state.dataDetalle}
          height={600}
          selection={{ mode: 'single' }}
          showBorders={false}
          columnHidingEnabled
          columnAutoWidth={true}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onOptionChanged={this.handleOptionChange}
        >
          <Column dataField="idPlan" caption="Id Plan" width={100} visible={false} />
          <Column dataField="idPlanDet" caption="Id Plan Det" width={100} visible={false} />
          <Column dataField="idPlanEnc" caption="Plan Enc" width={100} visible={false} />
          <Column dataField="idPadre" caption="Id Padre" width={100} visible={false} />
          <Column dataField="claUbicacion" caption="Ubicacion" width={100} />
          <Column dataField="claAreaProduccion" caption="Area Producción" width={200} />
          <Column dataField="claGrupoMaquinas" caption="Grupo Máquina" width={200} />
          <Column dataField="claCampo" caption="Segmentación" width={100} />
          <Column
            dataField="porcentaje"
            caption="Porcentaje"
            width={50}
            alignment={'left'}
            format="#0.00'%'"
          />
          <Column
            dataField="totalHrs"
            caption="Total Hrs"
            width={50}
            dataType={'number'}
            alignment={'left'}
            format="#,###.##"
          />
          <Column
            dataField="disponibleHrs"
            caption="Horas Disponbles"
            dataType={'number'}
            alignment={'left'}
            format="#,###.##"
          />
          <Column
            dataField="reservadoHrs"
            caption="Horas Reservadas"
            dataType={'number'}
            alignment={'left'}
            format="#,###.##"
          />
          <Column dataField="claPeriodo" caption="Periodo" width={50} />

          <Paging pageSize={30} pageIndex={this.props.pageIndex} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </div>
    );

    return <>{gridContent}</>;
  }
}
