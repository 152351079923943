import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import ArticulosTab from './GridArticulos';

class GridTabs extends React.Component {
  constructor(props) {
    super(props);
    this.renderArticulosTab = this.renderArticulosTab.bind(this);
  }

  render() {
    return (
      <TabPanel>
        <Item title="Detalle" render={this.renderArticulosTab} />
      </TabPanel>
    );
  }

  renderArticulosTab() {
    return <ArticulosTab ClaPedido={this.props.data.key} />;
  }

}

export default GridTabs;
