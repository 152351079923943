/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, {
  MasterDetail,
  Column,
  Paging,
  Format,
  Export,
  Scrolling,
} from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';

export default class FactorConversionGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.props.onPage(e.value);
    }
  }

  //Comentario

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Factor de conversión.xlsx'
        );
      });
    });
    e.cancel = true;
  }

  componentDidMount() {
    console.log(this.props.data);
  }

  render() {
    const visible = this.props.esModal != undefined ? false : true;
    const gridHeight = this.props.esModal != undefined ? 600 : 600;

    const gridContent = (
      <div className="content">
        <DataGrid
          id="gridFactorConversion"
          dataSource={this.props.data}
          key={'idPlan'}
          width="100%"
          height={gridHeight}
          selection={{ mode: 'single' }}
          showBorders={false}
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onOptionChanged={this.handleOptionChange}
          onExporting={this.onExporting}
        >
          <Column
            dataField="fechaCalculo"
            caption="Fecha Calculo"
            width={100}
            alignment="center"
            dataType={'date'}
            format={'shortDate'}
            visible={visible}
          />
          <Column dataField="claProducto" caption="Producto" width={130} visible={visible} />
          <Column dataField="claAreaProduccion" caption="Area Producción" width={100} />
          <Column dataField="claGrupoMaquinas" caption="Grupo Maquinas" width={130} />
          <Column dataField="preferencia" caption="Preferencia" width={100} />
          <Column
            dataField="capacidadProduccion"
            caption="Capacidad Producción En Kgs"
            width={100}
          />
          <Column
            dataField="factorCapacidadProduccion"
            caption="Factor Capacidad de Producción"
            width={100}
            dataType={'number'}
          >
            <Format type="fixedPoint" precision={6} />
          </Column>
          <Column dataField="esEgeColaborado" caption="Es EGE Colaborado" width={80} />
          <Column dataField="ege" caption="EGE" width={70} dataType={'number'}>
            <Format type="percent" precision={2} />
          </Column>
          <Column dataField="claUbicacion" caption="Ubicacion" width={100} visible={visible} />
          <Column dataField="anchoBancada" caption="Ancho Bancada" width={100} dataType={'number'}>
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column dataField="altoMts" caption="Alto (Mts)" width={100} dataType={'number'}>
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column dataField="factorPlanta" caption="Factor Planta" width={100} dataType={'number'}>
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column
            dataField="numProdBancada"
            caption="NumProdBancada"
            width={120}
            dataType={'number'}
          >
            <Format type="fixedPoint" precision={2} />
          </Column>
          <Column dataField="esDeudaKgs" caption="Es Deuda Kgs" width={100} dataType={'number'}>
            <Format type="fixedPoint" precision={0} />
          </Column>
          <Paging pageSize={30} pageIndex={this.props.pageIndex} />
          <Scrolling columnRenderingMode="virtual" />
          <Export enabled={visible} allowExportSelectedData={false} />
        </DataGrid>
      </div>
    );

    return <>{gridContent}</>;
  }
}
