/*eslint-disable*/
import React from 'react';
import { TabPanel, Item } from 'devextreme-react/tab-panel';

import { config } from '../../../../utils/config';
import ContratosTabProductos from './ContratosTabProductos'
import ContratosTabReservaCap from './ContratosTabReservaCap'

class ContratosMasterDetail extends React.Component {
    constructor(props) {
        super(props);

        this.renderTabProductos = this.renderTabProductos.bind(this);
        this.renderTabReservaCap = this.renderTabReservaCap.bind(this);
    }
    
    componentDidMount() { 
    }

    renderTabProductos() {
      console.log(this.props.data.data.idPlan)
      return <ContratosTabProductos claPedido={this.props.data.data.claPedido} 
          fechaCalculo={this.props.data.data.fechaCalculo} 
          modoVista={this.props.data.data.modoVista}
        />;
  }

  renderTabReservaCap() {
    console.log(this.props.data.data.idPlan)
    return <ContratosTabReservaCap claPedido={this.props.data.data.claPedido} 
        fechaCalculo={this.props.data.data.fechaCalculo} 
        modoVista={this.props.data.data.modoVista}
      />;
  }

    render() {
        return (
          <TabPanel>
            <Item title="Productos" render={this.renderTabProductos} />
            <Item title="Detalle" render={this.renderTabReservaCap} />
        </TabPanel>
        );
    }
}

export default ContratosMasterDetail;