
/*eslint-disable*/
import React from 'react';
import AppContext from "../../../../context/AppContext";

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";
import { Switch } from "devextreme-react/switch";
import RadioGroup from 'devextreme-react/radio-group';
import Tabs from 'devextreme-react/tabs';
import CapacidadFiltros from './CapacidadFiltros';
import CapacidadGrid from './CapacidadGrid';
import CapacidadPlanoGrid from './CapacidadPlanoGrid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';

export default class Capacidad extends React.Component {
    constructor(props) {
        super(props);

        locale('en');

        const d = new Date();
        const filtros = {            
            fechaCalculo: new Date(),
            claUbicacion: null,
            claArea: null,
            claGrupoMaquina: null,
            periodoInicio: null,
            periodoFin: null
        };
        
        const produccionTabs = [
            {
                id: 0,
                text: 'Agrupados',
                content: 'Agrupados',
            },
            {
                id: 1,
                text: 'Detalle',
                content: 'Detalle',
            }
        ];

        this.state = {
            dataProduccion: [],
            dataProduccionPlano:[],
            filtros,
            fechaCalculo: new Date(),
            pageIndex: 0,
            planoPageIndex: 0,
            ubicacionesData: [],
            periodosData: [],
            produccionTabs,
            showSpin: false,
            produccionSelectedIndex: 0,
            agrupadosVisibility: "",
            detallesVisibility: "none",
        }
        
        this.getProduccionData = this.getProduccionData.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.onGridPage = this.onGridPage.bind(this);
        this.onGridPlanoPage = this.onGridPlanoPage.bind(this);
        this.onChangeCmbPeriodoInicio = this.onChangeCmbPeriodoInicio.bind(this);
        this.onChangeCmbPeriodoFin = this.onChangeCmbPeriodoFin.bind(this);        
        this.onLimpiar = this.onLimpiar.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeCmbAreas = this.onChangeCmbAreas.bind(this);
        this.onChangeCmbGpoMaquinas = this.onChangeCmbGpoMaquinas.bind(this);
        this.onProduccionTabsSelectionChanged = this.onProduccionTabsSelectionChanged.bind(this);
        this.onChangeFechaCalculo = this.onChangeFechaCalculo.bind(this);

        this.refCmbUbicacion = React.createRef();
        this.refCmbPeriodoInicio = React.createRef();
        this.refCmbPeriodoFin = React.createRef();
        this.refCmbAreas = React.createRef();
        this.refCmbGpoMaquinas = React.createRef();
    }

    getProduccionData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Busqueda`;
        
        this.setState({ showSpin: true });

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            var newPeriodos = [];
            result[2].forEach((e, i) => { newPeriodos.push({"Clave": e.claPeriodo, "Nombre": e.claPeriodo})})
            this.setState({
                dataProduccion: result,
                dataProduccionPlano: result[1],
                periodosData: newPeriodos,
                showSpin: false
            });
        });
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
        
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onGridPlanoPage(planoPageIndex) {
        this.setState({ planoPageIndex });
    }

    onChangeFechaCalculo(selectedDate){
        var newFiltro = this.state.filtros;
        newFiltro.fechaCalculo = selectedDate.value;

        this.setState({
            filtros: newFiltro,
        })
    }

    onChangeCmbUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro,
        });
        
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {

            var areasArr = [];

            result.areasProduccion.forEach(e => {
                areasArr.push({'Clave': e.ClaAreaProduccion, 'Nombre': e.NomAreaProduccion})
            })

            this.setState({
                areasData: areasArr
            });
        });
    }

    onChangeCmbAreas(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claArea = selectedItem.value;

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {

            var maqArr = [];

            result.gpoMaquinas.forEach(e => {
                maqArr.push({'Clave': e.ClaGrupoMaquina, 'Nombre': e.NomGrupoMaquina})
            })

            this.setState({
                gruposMaquinas: maqArr
            });
        });

        this.setState({ 
            filtros : newFiltro,
        }); 
    }

    onChangeCmbGpoMaquinas(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claGrupoMaquina = selectedItem.value;        

        this.setState({ 
            filtros : newFiltro,
        }); 
    }

    onChangeCmbPeriodoInicio(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.periodoInicio = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro
        }); 
    }

    onChangeCmbPeriodoFin(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.periodoFin = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro
        }); 
    }

    onProduccionTabsSelectionChanged(e){
        if (e.name === 'selectedIndex') {
            if(e.value === 0){   
                this.setState({
                    produccionSelectedIndex: e.value,
                    agrupadosVisibility: "",
                    detallesVisibility: "none",
                });      
            }
            if(e.value === 1){      
                this.setState({
                    produccionSelectedIndex: e.value,
                    agrupadosVisibility: "none",
                    detallesVisibility: "",
                });   
            }
        }
    }

    onLimpiar() {
        const d = new Date();
        const filtros = {            
            fechaCalculo: new Date(d.getFullYear, d.getMonth, d.getDay),
            claUbicacion: null,
            claArea: null,
            claGrupoMaquina: null,
            periodoInicio: null,
            periodoFin: null
        };

        this.setState({
            filtros: filtros,
        })
    }

    componentDidMount(){
        this.getUbicaciones();
    }

    render(){
        let filtro = (
            <CapacidadFiltros
                onLimpiar={this.onLimpiar}

                getProduccionData={this.getProduccionData}

                onChangeFechaCalculo={this.onChangeFechaCalculo}
                fechaCalculoValue={this.state.filtros.fechaCalculo}
                
                ubicacionesData={this.state.ubicacionesData}
                onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                refCmbUbicacion={this.refCmbUbicacion}
                claUbicacion={this.state.filtros.claUbicacion}

                areasData={this.state.areasData}
                onChangeCmbAreas={this.onChangeCmbAreas}
                refCmbAreas={this.refCmbAreas}
                claArea={this.state.filtros.claArea}

                gpoMaquinasData={this.state.gruposMaquinas}
                onChangeCmbGpoMaquinas={this.onChangeCmbGpoMaquinas}
                refCmbGpoMaquinas={this.refCmbGpoMaquinas}
                claGrupoMaquina={this.state.filtros.claGrupoMaquina}

                periodosData={this.state.periodosData}
                onChangeCmbPeriodoInicio={this.onChangeCmbPeriodoInicio}
                refCmbPeriodoInicio={this.refCmbPeriodoInicio}
                periodoInicio={this.state.filtros.periodoInicio}
                onChangeCmbPeriodoFin={this.onChangeCmbPeriodoFin}
                refCmbPeriodoFin={this.refCmbPeriodoFin}
                periodoFin={this.state.filtros.periodoFin}
                
                filtros={this.state.filtros}                
                showSpin={this.state.showSpin}
            >
            </CapacidadFiltros>
        );

        let grid=(
            <>
                <Tabs
                    dataSource={this.state.produccionTabs}
                    selectedIndex={this.state.produccionSelectedIndex}
                    onOptionChanged={this.onProduccionTabsSelectionChanged}
                />
                <div style={{"display": this.state.agrupadosVisibility}}>
                    <CapacidadGrid 
                        data={this.state.dataProduccion} 
                        onPage={this.onGridPage} 
                        pageIndex={this.state.pageIndex}
                        filtros={this.state.filtros}
                    >
                    </CapacidadGrid>
                </div>
                <div style={{"display": this.state.detallesVisibility}}>
                    <CapacidadPlanoGrid
                        data={this.state.dataProduccionPlano} 
                        onPage={this.onGridPlanoPage} 
                        pageIndex={this.state.planoPageIndex}
                    >                        
                    </CapacidadPlanoGrid>
                </div>
            </>
        );

        return(
            <>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <CardTitle>Capacidad</CardTitle>
                            </CardHeader>
                        </Card>  
                        <CardBody>
                            {filtro}
                        </CardBody> 
                    </Col>
                </Row>
                <Row >
                    <Col>
                        {grid}
                    </Col>
                </Row>
            </>
        );
    }
}