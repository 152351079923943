import React from 'react';
import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap';
import CtrlComboBox from '../Controles/CtrlComboBox';
import CtrlAutoComplete from '../Controles/CtrlAutoComplete';
import CtrlDatePicker from '../Controles/CtrlDatePicker';
import CtrlCheckbox from '../Controles/CtrlCheckbox';

import { callApi, callKrakenApi } from '../../utils/utils';

export default class JeimmyPrueba extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  getUbicaciones() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 231, params, 1, (res) => {
      this.setState({ data: res.Result0 });
    });
  }

  componentDidMount() {
    this.getUbicaciones();
  }


  render() {

    return (
      <>
        <Row>
          <Col>
            <Card className="m-1">
              <CardHeader>
                <CardTitle>Pedidos</CardTitle>
              </CardHeader>
              <CardBody className="p-1">
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Direccion"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Familia"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Fitrar por Fecha"
                      data={this.state.data}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="SubDireccion"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="SubFamilia"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <Row>
                      <Col className="col-6">
                        <CtrlDatePicker
                          etiqueta="Inicio"
                          data={this.state.data}
                        />
                      </Col>
                      <Col className="col-6">
                        <CtrlDatePicker
                          etiqueta="Fin"
                          data={this.state.data}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Zona"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Producto"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlCheckbox
                      etiqueta="Producto"
                      data={this.state.data}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Agente"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlAutoComplete
                      etiqueta="Cliente"
                      placeholder="Clave o Nombre del cliente..."
                      valueExpr="Nombre"
                      displayExpr="Nombre"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="col-3">
                    <CtrlComboBox
                      etiqueta="Planta"
                      data={this.state.data}
                    />
                  </Col>
                  <Col className="col-3">
                    <CtrlAutoComplete
                      etiqueta="Cliente"
                      placeholder="Clave o Nombre del cliente..."
                      valueExpr="Nombre"
                      displayExpr="Nombre"
                    />
                  </Col>
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col className="col-6"> Inducción con React
          </Col>
        </Row>


        <Row>
          <Col md={{ size: 5 }} style={{ padding: 0, margin: 0 }}>
          </Col>
        </Row>

      </>
    );
  }
}
