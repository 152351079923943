import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import TraceArticulo from './TraceArticulo';

export default class Trace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
  }


  render() {
    let idRenglon = 0;
    let claArticulo = 0;
    let ubicaciones = [];

    if (this.props.data !== undefined) {

      idRenglon = this.props.data !== undefined && this.props.data.idRenglon !== undefined ? this.props.data.idRenglon : 0;
      claArticulo= this.props.data !== undefined && this.props.data.claArticulo !== undefined ? this.props.data.claArticulo : 0;

      if (this.props.data.ubicaciones !== undefined && this.props.data.ubicaciones != null)
        ubicaciones = this.props.data.ubicaciones.split(',');

    }


    const list = []
    let indice = 0;

    ubicaciones.forEach((ubicacion) => {
      const articuloUbic = this.props.datosCant.filter((item) => item.idRenglon === idRenglon && item.claArticulo === claArticulo);
      const claUbicacion = parseInt(ubicacion, 10);
      list.push(

        articuloUbic
          .filter(item => item.claUbicacion === claUbicacion)
          .map((itemCant, index) => {
            const id = `IdArtUbic-${index}`;
            return (
              <Row key={id}>
                <Col>
                  <TraceArticulo claPedido={this.props.claPedido} claUbicacion={claUbicacion} data={articuloUbic[indice]} tracedata={this.props.tracedata} index={indice}></TraceArticulo>
                </Col>
              </Row>
            );
          })

      );

      indice++;
    });


    return (
      <>
        {list}
      </>
    );
  }
}
