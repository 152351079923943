/*eslint-disable*/
import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import AppContext from "../../context/AppContext";
import { callApi, callKrakenApi } from '../../utils/utils';
import Tabs from 'devextreme-react/tabs';
import { config } from '../../utils/config';
import Capacidad from './Capacidad/Capacidad';
import Deuda from './Deuda/Deuda';
import CapacidadDisp from './CapacidadDisp/CapacidadDisp';

export default class Embarque extends React.Component {
    constructor(props) {
        super(props);

        const tabs = [
            {
                id: 0,
                text: 'Saturación de Embarque',
                content: 'Saturación de Embarque'
            },
            {
                id: 1,
                text: 'Deuda de Embarque',
                content: 'Deuda de Embarque'
            },
            {
                id: 2,
                text: 'Capacidad de Carga/Descarga',
                content: 'Capacidad de Carga/Descarga'
            }
        ];

        this.state = {
            selectedIndex: 0,
            tabs,
            capacidadVisible: "",
            deudaVisible: "none",
            capacidadDispVisible: "none",
         };

         this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    }

    onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            if(args.value === 0){ 
                this.setState({
                  selectedIndex: args.value,
                  capacidadVisible: "",
                  deudaVisible: "none",
                  capacidadDispVisible: "none",
                });
            }
            if(args.value === 1){ 
                this.setState({
                  selectedIndex: args.value,
                  capacidadVisible: "none",
                  deudaVisible: "",
                  capacidadDispVisible: "none",
                });
            }
            if(args.value === 2){ 
                this.setState({
                  selectedIndex: args.value,
                  capacidadVisible: "none",
                  deudaVisible: "none",
                  capacidadDispVisible: "",
                });
            }

        }
    }

    componentDidMount(){        
    }

    render() {
        const content = (
            <>
                <div style={{"display": this.state.capacidadVisible}}>
                    <Capacidad/>
                </div>
                <div style={{"display": this.state.deudaVisible}}>
                    <Deuda/>
                </div>
                <div style={{"display": this.state.capacidadDispVisible}}>
                    <CapacidadDisp/>
                </div>
            </>
        );

        return (
            <>
                <Tabs
                    dataSource={this.state.tabs}
                    selectedIndex={this.state.selectedIndex}
                    onOptionChanged={this.onTabsSelectionChanged}
                />
                <div className="content mb-5">
                    {content}
                </div>
            </>
        );
    }
}