/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup, Row, Col } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi, showNotify, notifyType, notifyPosition } from '../../../utils/utils';
import CtrlAutoComplete from '../../Controles/CtrlAutoComplete';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlNumerico from 'views/Controles/CtrlNumerico';
import { TextBox } from 'devextreme-react';

export default class Template_UbiProdAceLote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ubicacionesData: [],
            familiasData:'',
            subFamiliasData:'',
            productosData:[],
            claSubFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            claUbicacion: null,
        }

        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getProductos = this.getProductos.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.onProductoSelected = this.onProductoSelected.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
    
        this.refNumProducto = this.refNumProducto.bind(this);
        // this.refNumProducto = React.createRef();
        this.refCmbUbicacion = React.createRef();

        this.autoCompleteInstance = null;
    }

    refNumProducto(e){
        this.autoCompleteInstance = e.component;
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            
            let acerias = res.Result0.filter(u => u.Nombre.includes('Acería'));

            this.setState({
                ubicacionesData: acerias
            })
        });
    }

    getProductos(claUbicacion) {
    
        const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/GetCatProductosAceriaLoteminimo?ClaUbicacion=${claUbicacion}`;

        callApi(urlApiService, 'GET', null, (result) => {
            // console.log(result);
            this.setState({
                productosData: result
            })
        });
    }

    limpiar(){
        if(this.autoCompleteInstance !== null)
        {this.autoCompleteInstance.reset();}
        
        this.setState({
            claUbicacion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto : null,
            subFamiliasData:'',
            familiasData: '',
            productosData:[],
            txtLoteMinimo: ''
        })
    }

    onChangeCmbUbicacion(object){
        if(object.value !== null){            
            this.setState({
                claUbicacion: object.value
            })
            this.getProductos(object.value)
        }
        else{
            this.limpiar();
        }
    }

    btnGuardarHandleSubmit() {
    
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneralInsert`;
        
        const body = {
            idConfiguracion: this.props.idConfiguracion,
            claUbicacion: this.state.claUbicacion,
            claFamilia: this.state.claFamilia,
            claSubFamilia: this.state.claSubFamilia,
            claProducto: this.state.claProducto,
            valorEntero1: parseInt(this.state.claMolino)
        };
    
        if(body.claUbicacion === null || body.claProducto === null){
            showNotify('Campo obligatorio vacío.', notifyPosition.centerTop, notifyType.error);
            return;
        }

        callApi(urlApiService, 'POST', body, (res) => {
            // showNotify('Configuración guardada correctamente', notifyPosition.centerTop, notifyType.success);
            this.props.handler(false);
          
            this.props.getConfiguraciones()
            this.limpiar();
        });
    }

    btnCancelarHandle(){
        this.limpiar();
        this.props.handler(false);
    }

    onProductoSelected(object){
        
        if(object.selectedItem !== null)
        {
            
            this.setState({
                txtLoteMinimo: String(object.selectedItem.LoteMinimo),
                txtCalibre: String(object.selectedItem.NombreCalibre)
            })
            this.autoCompleteInstance = object.component;
            const data = {
                claProducto : object.selectedItem.Clave,
                claMolino: object.selectedItem.Clave2
            };

            let configLote = this.state.productosData.filter(x => x.Clave == object.selectedItem.Clave)[0];

            const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/GetFamiliasSubFamilias`;
            callApi(urlApiService, 'POST', data, (res) => {
                
                this.setState({
                    familiasData: res[0].NomFamilia,
                    subFamiliasData: res[0].NomSubFamilia
                })

            });

            this.setState({
                claProducto: data.claProducto,
                cantidadLote: configLote.LoteMinimo,
                claFamilia: configLote.ClaFamilia,
                claMolino: data.claMolino
            })

        }
        else{
            this.setState({
                claFamilia: null,
                claSubFamilia: null,
                claProducto : null,
                subFamiliasData:'',
                familiasData:'',
                txtLoteMinimo: ''
            })
        }
    }

    componentDidMount(){
        this.getUbicaciones();
        // this.getFamilias();
    }

    render(){
        return(
            <div>
                <Row>
                    <Col>
                        <CtrlComboBox
                            etiqueta="Ubicacion"
                            onChange={this.onChangeCmbUbicacion}
                            data={this.state.ubicacionesData}
                            value={this.state.claUbicacion}
                            reference={this.refCmbUbicacion}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CtrlAutoComplete
                            etiqueta="Producto"
                            placeholder="Clave o Nombre del producto..."
                            valueExpr="Nombre"
                            displayExpr="Nombre"
                            keyValue="Clave"
                            claFamilia={this.state.claSubFamilia}
                            claSubfamilia={this.state.claSubFamilia}
                            dataSource={this.state.productosData}
                            onChange={this.onProductoSelected}
                            value={this.state.claProducto}
                            // reference={this.refNumProducto}
                            onInitialized={this.refNumProducto}
                            disabled={this.state.claUbicacion === null}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Familia:
                        <br/>
                        <TextBox
                            value={this.state.familiasData}
                            disabled={true}
                        />
                        <br/>
                    </Col>
                    <Col>
                        SubFamilia:
                        <br/>
                        <TextBox
                            value={this.state.subFamiliasData}
                            disabled={true}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Lote Mínimo:
                        <br/>
                        <TextBox
                            value={this.state.txtLoteMinimo}
                            disabled={true}
                            mask={'000,000'}
                        />
                        <br/>
                    </Col>
                    <Col>
                        Calibre:
                        <br/>
                        <TextBox
                            value={this.state.txtCalibre}
                            disabled={true}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col className='justify-content-center'>
                        <Button
                            id="btnUsersGuardar"
                            text="Guardar"
                            onClick={this.btnGuardarHandleSubmit}
                            className="btn btn-primary btn-round"
                        />
                    </Col>
                    <Col className='justify-content-center'>
                        <Button
                            id="btnUsersCancelar"
                            text="Cancelar"
                            onClick={this.btnCancelarHandle}
                            className="btn btn-primary btn-round"
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}