/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Paging, Export } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';

import 'assets/css/popup.css';

export default class ProduccionGridDet extends React.Component {
    constructor(props) {
      super(props);
    
      const TimerTiempo=2000;
      const TimerId=0;
      this.gridRef = React.createRef();

      this.state = {
        data:[],
        enc:{NomAreaProduccion:null,NomGrupoMaquina:null,direccion:null},
        datasegmentacion:[]
      };

      this.onCellPrepared = this.onCellPrepared.bind(this);
      this.onRowPrepared = this.onRowPrepared.bind(this);
      this.onExporting = this.onExporting.bind(this);
      this.onExported = this.onExported.bind(this);
      
      this.onTimer=this.onTimer.bind(this);
      
    }

    getProduccionData = () => {
      const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/ReservaDet`;
      
      const p =this.props.paramService;
      const params = {
        claUbicacion: p.claUbicacion,
        claArea: p.claArea,
        claGrupoMaquina: p.claGrupoMaquina,
        claDireccion: p.claDireccion,
        claPeriodo: p.claPeriodo,
        fechaCalculo: p.fechaCalculo,
      };

      callApi(urlApiService, 'POST', params, (result) => {
          this.setState({data: result[1], enc:result[0][0]})
          this.TimerId=setInterval(this.onTimer, 2000);
      });
  }

  onCellPrepared(e){

    if (e.rowType == 'data' && e.data.TotalHrs===0){
      e.cellElement.style.color ="red"
    }
    
    if (e.rowType == 'data' && e.columnIndex===4 && e.data.DisponibleHrs===0){
      e.cellElement.style.color ="red"
    }

  }

  onRowPrepared(e){

    if (e.rowType == 'data' && e.data.ClaPedido===this.props.paramService.claPedido){
      e.rowElement.classList.add('rowSelected');  
    }

  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    worksheet.columns = [  
      { width: 25 }, { width: 20 }, { width: 20 },{ width: 25 }, { width: 20 }, { width: 20 }, { width: 30 }, { width: 15 }, { width: 90 }, { width: 90 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 }
    ];  
    e.component.beginUpdate(); 
    e.component.columnOption("FechaEmbarque", "visible", true);  
    e.component.columnOption("FechaPedido", "visible", true);  
    e.component.columnOption("IdRenglon", "visible", true);  
    e.component.columnOption("NomProducto", "visible", true);  
    e.component.columnOption("NomComposicion", "visible", true);  
    e.component.columnOption("NomAreaProduccion", "visible", true);  
    e.component.columnOption("NomGrupoMaquina", "visible", true);  
    e.component.columnOption("NomDireccion", "visible", true);  
    e.component.columnOption("NivelComposicion", "visible", true);  

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: false,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PedidosKayak.xlsx');
      });
    });
    e.cancel = true;
  }

  onExported(e){
    e.component.columnOption("FechaEmbarque", "visible", false);  
    e.component.columnOption("FechaPedido", "visible", false);  
    e.component.columnOption("IdRenglon", "visible", false);  
    e.component.columnOption("NomProducto", "visible", false);  
    e.component.columnOption("NomComposicion", "visible", false);  
    e.component.columnOption("NomAreaProduccion", "visible", false);  
    e.component.columnOption("NomGrupoMaquina", "visible", false);  
    e.component.columnOption("NomDireccion", "visible", false);  
    e.component.columnOption("NivelComposicion", "visible", false);  
    

    
    e.component.endUpdate();
  }

  onTimer(){
    clearInterval(this.TimerId);

    if(this.gridRef.current===null)
      return;

    if(this.gridRef.current.instance===null)
      return;

    this.gridRef.current.instance.navigateToRow(this.props.paramService.claPedido);
  }

  componentDidMount() {
    this.getProduccionData();
  }
  
  render() {
    const gridContent = (
      <DataGrid
        id="gridPedidos"
        keyExpr="ClaPedido"
        ref={this.gridRef}
        dataSource={this.state.data}
        height={450}
        width="95%"
        selection={{ mode: 'single' }}
        showBorders={false}
        columnHidingEnabled
        columnAutoWidth={false}
        showColumnHeaders
        showColumnLines={false}
        showRowLines
        noDataText="Sin Registros"
        wordWrapEnabled
        rowAlternationEnabled={false}
        scrollByContent={true}
        onCellPrepared={this.onCellPrepared} 
        onRowPrepared={this.onRowPrepared} 
        onExporting={this.onExporting}
        onExported={this.onExported}
        
        className="connect-datagrid-default" 
      >
        <Column dataField="NomAreaProduccion" caption="AreaProduccion" width={550} visible={false}/>
        <Column dataField="NomGrupoMaquina" caption="GrupoMaquina" width={550} visible={false}/>
        <Column dataField="NomDireccion" caption="Direccion" width={550} visible={false}/>

        <Column dataField="ClaPeriodo" caption="Periodo" width={30}/>
        <Column dataField="ClaPedido" caption="Pedido" width={30}/>
        <Column dataField="FechaPedido" caption="FechaPedido" width={50} visible={false}/>
        <Column dataField="FechaEmbarque" caption="FechaEmbarque" width={30} visible={false}/>
        <Column dataField="IdRenglon" caption="IdRenglon" width={30} visible={false}/>
        <Column dataField="NomProducto" caption="Producto" width={30} visible={false}/>
        <Column dataField="NomComposicion" caption="Composicion" width={30} visible={false}/>
        <Column dataField="NivelComposicion" caption="Nivel" width={30} visible={false}/>
        
        <Column dataField="TotalHrs" caption="Disp." width={25} />
        <Column dataField="ReservadoHrs" caption="Reservado" width={32}/>
        <Column dataField="DisponibleHrs" caption="Resto" width={30}/>
        <Paging pageSize={60} />
        <Export enabled={true} />
      </DataGrid>
    );

  
  return (
          <div className='col-12'>
          <Row className="mb-0 pb-0 mt--3">
            <Col className='col-12 m-0 p-0'>
                Area Producción: <b>{this.state.enc.NomAreaProduccion}</b>
            </Col>
            <Col className='col-12 m-0 p-0'>
                Gpo Maquinas: <b>{this.state.enc.NomGrupoMaquina}</b>
            </Col>
            <Col className='col-12 m-0 p-0'>
              Dirección: <b>{this.state.enc.NomDireccion}</b>
            </Col>
          </Row>
          <Row className="mb-0 pb-0 mt--1">
            <Col className='col-12 m-0 p-0'>
              {gridContent}
            </Col>
          </Row>
        </div>
        );
    }
}
