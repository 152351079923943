import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../Controles/CtrlDatePicker';
import CtrlNumerico from '../../Controles/CtrlNumerico';
import CtrlCheckbox from '../../Controles/CtrlCheckbox';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class FiltroFechas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataTipoFecha: [{ Clave: 1, Nombre: "Fecha Pedido" }, { Clave: 2, Nombre: "Fecha Embarque" }, { Clave: 3, Nombre: "Fecha Entrega" }],
      dataProcesos:[]
    };
  }

  getProcesos() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    // const urlService = `${config.KrakenService}/13/9`;
    const urlService = `${process.env.REACT_APP_URL_API}Catalogo/Procesos`;
    const params = {
    };

    callApi(urlService, "POST", params, (res) => {
      this.setState({ dataProcesos: res[0] });
    });
  }

  componentDidMount() {
    this.getProcesos();
  }

  render() {

    return (
      <>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Filtrar por Fecha"
              onChange={this.props.onChangeCmbTipoFecha}
              data={this.state.dataTipoFecha}
              value={this.props.tipoFecha}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <Row>
              <Col>
                <CtrlDatePicker
                  etiqueta="Inicio"
                  defaultValue={this.now}
                  onChange={this.props.onChangeFechaInicio}
                  value={this.props.fechaInicio}
                />
              </Col>
              <Col>
                <CtrlDatePicker
                  etiqueta="Fin"
                  defaultValue={this.now}
                  onChange={this.props.onChangeFechaFin}
                  value={this.props.fechaFin}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ padding: 0, margin: 0, marginTop: 1, alignItems: "center" }} className="mt-1">
          <CtrlCheckbox etiqueta="Pendientes de Fecha Promesa de Embarque" onChange={this.props.onChangePendienteFechaPromesa} value={this.props.pendienteFechaPromesa}></CtrlCheckbox>
        </Row>
        <Row>
          <Col>
            <CtrlCheckbox etiqueta="Cancelados/Surtidos" onChange={this.props.onChangeCanceladosSurtidos} value={this.props.canceladosSurtidos} />
          </Col>
          <Col>
            <CtrlCheckbox etiqueta="Con Pedidos Vencidos" onChange={this.props.onChangePedidosVencidos} value={this.props.pedidoVencidos} />
          </Col>
        </Row>
        <Row>
          <Col className='col-8'>
            <CtrlComboBox
              etiqueta="Proceso"
              valueExpr="ClaProceso"
              displayExpr="NomProceso"
              onChange={this.props.onChangeProceso}
              data={this.state.dataProcesos}
              value={this.props.claProceso}
            />
          </Col>
        </Row>
      </>
    );
  }
}

