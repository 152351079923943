/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export } from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';
import EntregaFiltros from './EntregaFiltros';
import EntregaGrid from './EntregaGrid';

export default class Entrega extends React.Component {
    constructor(props) {
        super(props);

        const filtros = {
            fechaEmbarqueIniValue: new Date(),
            fechaEmbarqueFinValue: new Date(),
            claEstadistico: null,
            claUbicacion: null,
            claMotivoVencimiento: null,
            claArea: null,
            idTab: 2,
            esTraspasos: 0,
            esClientes: 0,
            claPedido: null
        }

        this.state ={
            filtros,
            fechaEmbarqueIniValue: new Date(),
            fechaEmbarqueFinValue: new Date(),
            claEstadistico: null,
            claUbicacion: null,
            claMotivo: null,
            motivoData: [],
            entregaVencidasData: [],
            pageIndex: 0,
            showSpin: false,
            esTraspasos: 0,
            esClientes: 0,
            claPedido: null
        }
        
        this.refCmbUbicacion = React.createRef();
        this.refCmbEstadistico = React.createRef();
        this.refCmbMotivo = React.createRef();

        this.onChangeCmbEstadistico = this.onChangeCmbEstadistico.bind(this);
        this.onChangeFechaEmbarqueIni = this.onChangeFechaEmbarqueIni.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeFechaEmbarqueFin = this.onChangeFechaEmbarqueFin.bind(this);
        this.onChangeCmbMotivo = this.onChangeCmbMotivo.bind(this);
        this.onChangeCmArea = this.onChangeCmArea.bind(this);

        this.onChangeEsTraspasos = this.onChangeEsTraspasos.bind(this);
        this.onChangeEsClientes = this.onChangeEsClientes.bind(this);
        this.onChangeNumPedido = this.onChangeNumPedido.bind(this);

        this.getEntregaData = this.getEntregaData.bind(this);

        this.onGridPage = this.onGridPage.bind(this);
    }

    getEntregaData(){
        
        this.setState({ showSpin: true });

        const urlApiService = `${process.env.REACT_APP_URL_API}Vencimiento/Busqueda`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            console.log(result);
            this.setState({
                entregaVencidasData: result,
                showSpin: false
            });
        });
    }

    onChangeEsTraspasos(e) {
        var newFiltros = this.state.filtros;
        newFiltros.esTraspasos = e.value === true ? 1 : 0  
        this.setState ({
            filtros : newFiltros,
            esTraspasos : e.value  
        })
        console.log("fin de on change traspasos");
        console.log(this.state);
    }
    
    onChangeEsClientes(e) {
        var newFiltros = this.state.filtros;
        newFiltros.esClientes = e.value === true ? 1 : 0 
        this.setState ({
            filtros : newFiltros,
            esClientes : e.value  
        })
    }

    onChangeNumPedido (e) {
        var newFiltros = this.state.filtros;
        newFiltros.claPedido = e.value
        this.setState ({
            filtros : newFiltros,
            claPedido : e.value
        })
    }

    onLimpiarPartidas(){
        const filtros = {
            fechaEmbarqueIniValue: this.now,
            fechaEmbarqueFinValue: this.now,
            claEstadistico: null,
            claUbicacion: null,
            claMotivoVencimiento: null,
            claArea: null,
            idTab: 2,
            // claPedido: null,
            // esClientes: 0,
            // esTraspasos: 0,
        }

        this.setState({
            filtros
        })
    }

    onGridPage(pageIndex){
        this.setState({ pageIndex });
    }

    onChangeCmbEstadistico(e){
        var newFiltros = this.state.filtros;
        newFiltros.claEstadistico = e.value
        this.setState({
            filtros: newFiltros,
            claEstadistico: e.value
        })
    }

    onChangeFechaEmbarqueIni = (e) =>{
        var newFiltros = this.state.filtros;
        newFiltros.fechaEmbarqueIniValue = e.value
        this.setState({
            filtros: newFiltros,
            fechaEmbarqueIniValue: e.value
        })
    }

    onChangeFechaEmbarqueFin = (e) => {
        var newFiltros = this.state.filtros;
        newFiltros.fechaEmbarqueFinValue = e.value
        this.setState({
            filtros: newFiltros,
            fechaEmbarqueFinValue: e.value
        })
    }

    onChangeCmbUbicacion(e){
        var newFiltros = this.state.filtros;
        newFiltros.claUbicacion = e.value
        this.setState({
            filtros: newFiltros,
            claUbicacion: e.value
        })
    }

    onChangeCmbMotivo(e){
        var newFiltros = this.state.filtros;
        newFiltros.claMotivoVencimiento = e.value
        this.setState({
            filtros: newFiltros,
            claMotivo: e.value
        })
    }

    onChangeCmArea(e){
        var newFiltros = this.state.filtros;
        newFiltros.claArea = e.value
        this.setState({
            filtros: newFiltros,
            claArea: e.value
        })
        
        this.getMotivos();
    }

    getMotivos = () => {
        const urlApiService = `${process.env.REACT_APP_URL_API}Vencimiento/GetMotivos`;
        // const _param = {'claArea': this.state.claArea}
        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            console.log(result);
            this.setState({
                motivoData: result
            });
        });
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <EntregaFiltros 
                            getEntregaData={this.getEntregaData}
                            onLimpiarPartidas={this.onLimpiarPartidas}

                            onChangeFechaEmbarqueIni={this.onChangeFechaEmbarqueIni}
                            fechaEmbarqueIniValue={this.state.fechaEmbarqueIniValue}

                            onChangeFechaEmbarqueFin={this.onChangeFechaEmbarqueFin}
                            fechaEmbarqueFinValue={this.state.fechaEmbarqueFinValue}

                            onChangeCmbEstadistico={this.onChangeCmbEstadistico}
                            estadisticosData={this.props.estadisticosData}
                            claEstadistico={this.state.claEstadistico}
                            refCmbEstadistico={this.refCmbEstadistico}

                            onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                            ubicacionesData={this.props.ubicacionesData}
                            claUbicacion={this.state.claUbicacion}
                            refCmbUbicacion={this.refCmbUbicacion}

                            onChangeCmbMotivo={this.onChangeCmbMotivo}
                            motivoData={this.props.motivoData}
                            claMotivo={this.state.claMotivo}
                            refCmbMotivo={this.refCmbMotivo}
                            
                            onChangeCmArea={this.onChangeCmArea}
                            areaData={this.props.areasData}
                            claArea={this.state.claArea}
                            refCmbArea={this.refCmbArea}

                            onChangeNumPedido={this.onChangeNumPedido}
                            claPedido = {this.state.claPedido}

                            onChangeEsTraspasos={this.onChangeEsTraspasos}
                            esTraspasos = {this.state.esTraspasos}

                            onChangeEsClientes={this.onChangeEsClientes}
                            esClientes = {this.state.esClientes}

                            showSpin={this.state.showSpin}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <EntregaGrid 
                            data={this.state.entregaVencidasData}
                            onPage={this.onGridPage} 
                            pageIndex={this.state.pageIndex}
                        />
                    </Col>
                </Row>
            </>
        );
    }
}