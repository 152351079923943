import React from 'react';
import AppContext from "../context/AppContext";

// react library for routing
import { Route, Switch, Redirect } from 'react-router-dom';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import AdminFooter from 'components/Footers/AdminFooter.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import routes from 'routes.js';
import { config } from '../utils/config';
import { callApi } from '../utils/utils';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredRoutes: [],
      sidenavOpen: false,
      usuarioValido: false,
      msg: "Verificando seguridad...",
      roles: [],
      routesStates: {},
      refreshRoutesStates: false
    };

    this.toggleRoutesStates = this.toggleRoutesStates.bind(this);
  }


  getRoutes = (routes) =>

    routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }

      if (prop.layout === '/layout') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      }
      return null;
    });

  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return 'Brand';
  };

  // toggles collapse between mini sidenav and normal
  toggleSidenav = (e) => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };

  getNavbarTheme = () =>
    this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';

  getUrlParams() {
    let param = this.props.location.search;
    const { setIdPedido } = this.context;

    if (param === "") {
      setIdPedido(null);
      return;
    }

    param = param.substring(1, param.length);
    const listParam = param.split('&');

    if (listParam.length > 0) {
      let i = 0;
      for (i = 0; i < listParam.length; i++) {
        const params = listParam[i].split('=');
        if (params.length > 0 && params[0] === "idpedido") {
          const index = 1;
          setIdPedido(params[1]);
          break;
        }
      }
    }

  }

  componentDidMount() {
    const urlApiService = `${process.env.REACT_APP_URL_API}usuario/validar`;
    this.getUrlParams();

    callApi(urlApiService, 'GET', {}, (result) => {
      this.setState({
        usuarioValido: result.data, msg: result.data ? "" : "Favor solicitar permisos al administrador."
      });
    });
    
    callApi(`${process.env.REACT_APP_URL_API}usuario/permisos`, 'POST', {}, (result) => {
      // this.setState({
      //   roles: result.data
      // })

      
      let filteredRoutes;
      // eslint-disable-next-line prefer-const
      // eslint-disable-next-line no-var
      var newFilters = [];

      if (window.location.hostname !== "localhost"){
        // newFilters = routes.filter(item => !item.path.includes("Pruebas"));
        newFilters = routes;
      }
      else{
        filteredRoutes = routes;
        
        filteredRoutes.forEach((element,index) => {
          // if(this !== undefined){
            if(result.data !== 2 && element.name === "Configuración")
            {
              // newFilters.push(element)
            }
            else{
              newFilters.push(element)
            }
          // }
        })
        // newFilters = filteredRoutes;
      }

      this.setState({
        filteredRoutes: newFilters
      })
    });

    // Menu minizado al iniciar la pantalla
    this.toggleSidenav();
  }

  toggleRoutesStates(key){
    const newCollapseState = this.state.routesStates;
    newCollapseState[key] = !this.state.routesStates[key];
    
    this.setState({
      routesStates: newCollapseState,
      refreshRoutesStates: true
    })
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname && this.state.usuarioValido) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  render() {

    // let filteredRoutes;
    // eslint-disable-next-line prefer-const
    // let newFilters = [];

    // if (window.location.hostname !== "localhost"){
    //   newFilters = routes.filter(item => !item.path.includes("Pruebas"));
    // }
    // else{
    //   filteredRoutes = routes;
      
    //   filteredRoutes.forEach((element,index) => {
    //     if(this !== undefined){
    //       if(this.state.roles !== 2 && element.name !== "Configuración")
    //       {
    //         newFilters.push(element)
    //       }
    //     }
    //   })
    //   // newFilters = filteredRoutes;
    // }
    if(this.state.refreshRoutesStates === false){
      const _routesStates = {}
      this.state.filteredRoutes.forEach((element, index) => {
        if(element.collapse === true){
          _routesStates[index] = element.state
          }
      });
      this.state.routesStates = _routesStates;
    }
    

    if (this.state.usuarioValido) {
      return (
        <>
          <div>
            <Sidebar
              {...this.props}
              routes={this.state.filteredRoutes}
              toggleSidenav={this.toggleSidenav}
              sidenavOpen={this.state.sidenavOpen}
              routesStates={this.state.routesStates}
              toggleRoutesStates={this.toggleRoutesStates}
            />
            <div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
              <AdminNavbar {...this.props} />
              <Switch>
                {this.getRoutes(this.state.filteredRoutes, this.state.idPedido)}
                <Redirect from="Configuracion" to="/layout/Configuracion" />
                <Redirect from="*" to="/layout/Pedidos" />
              </Switch>
              <AdminFooter />
            </div>
            {this.state.sidenavOpen ? (
              <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
            ) : null}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
          <AdminNavbar {...this.props} />
          <div className="content">
            <Row className="col-12 d-flex justify-content-center">
              <Card>
                <CardBody>
                  <h3>{this.state.msg}</h3>
                </CardBody>
              </Card>
            </Row>
          </div>
          <AdminFooter />
        </div>
      </>
    );
  }
}

Admin.contextType = AppContext;
export default Admin;
