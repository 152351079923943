/*eslint-disable*/
import React from 'react';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { locale } from "devextreme/localization";
import Tabs from 'devextreme-react/tabs';
import ContratosFiltro from './ContratosFiltro';
import ContratosGrid from './ContratosGrid';

export default class Pedidos extends React.Component {
    constructor(props) {
        super(props);

        const filtros = {            
            fechaCalculo: new Date(),
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claArea: null,
            claGrupoMaquina: null,
            modoVista: 2,
            claPedido: null
        };

        this.state = {
            filtros,
            pedidosPageIndex: 0,
            ubicacionesData: [],
            direccionesData: [],
            subDireccionesData: [],
            areasData: [],
            gruposMaquinas: [],
            productoData: [],
            tipoProductoData:  [
                {"Clave": 1, "Nombre": "MTO"},
                {"Clave": 2, "Nombre": "MTS"}
            ],
            getProduccionPedidosData: [],
            periodosPedidosData: [],
        };

        this.onChangeFechaCalculo = this.onChangeFechaCalculo.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeCmbGpoMaquinas = this.onChangeCmbGpoMaquinas.bind(this);
        this.onChangeCmbAreas = this.onChangeCmbAreas.bind(this);
        this.getProduccionPedidosData = this.getProduccionPedidosData.bind(this);
        this.onGridPedidosPage = this.onGridPedidosPage.bind(this);
        this.onChangeCmbPeriodoPedidoInicio = this.onChangeCmbPeriodoPedidoInicio.bind(this);
        this.onChangeCmbPeriodoPedidoFin = this.onChangeCmbPeriodoPedidoFin.bind(this);
        this.onChangeCmbDireccion = this.onChangeCmbDireccion.bind(this);
        this.onChangeCmbSubDireccion = this.onChangeCmbSubDireccion.bind(this);
        this.onChangeCmbTipoProducto = this.onChangeCmbTipoProducto.bind(this);
        this.onProductoSelected = this.onProductoSelected.bind(this);
        
        this.refCmbUbicacion = React.createRef();
        this.refCmbAreas = React.createRef();
        this.refCmbGpoMaquinas = React.createRef();
        this.refCmbPedidoPeriodoInicio = React.createRef();
        this.refCmbPedidoPeriodoFin = React.createRef();
        this.refCmbDireccion = React.createRef();
    }

    getProduccionPedidosData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;
        
        this.setState({ showSpin: true });

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            var newPeriodos = [];
            result[1].forEach((e, i) => { newPeriodos.push({"Clave": e.ClaPeriodo, "Nombre": e.ClaPeriodo})})
            this.setState({
                dataProduccionPedidos: result[0],
                periodosPedidosData: newPeriodos,
                showSpin: false
            });
        });
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    getDirecciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 2,
            IncluirTodosSN: 0,
            ClaNivel: 20,
          };
      
        callKrakenApi(23, 18, params, 1, (res) => {
            const data = res.Result0;
            var newDirecciones = [];
            data.forEach((e, i) => { newDirecciones.push({"Clave": e.ClaOrganizacion, "Nombre": e.NomOrganizacion})});
            this.setState({
                direccionesData: newDirecciones
            });
        });
    }

    onChangeFechaCalculo(selectedDate){
        var newPedidoFiltro = this.state.filtros;
        newPedidoFiltro.fechaCalculo = selectedDate.value;

        this.setState({
            filtros: newPedidoFiltro
        })
    }

    onChangeCmbUbicacion(selectedItem){
        var newPedidoFiltro = this.state.filtros;
        newPedidoFiltro.claUbicacion = selectedItem.value;
        
        this.setState({ 
            filtros : newPedidoFiltro
        });
        
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

        callApi(urlApiService, 'POST', newPedidoFiltro, (result) => {

            var areasArr = [];
            // var gpoMaquArr = [];

            result.areasProduccion.forEach(e => {
                areasArr.push({'Clave': e.ClaAreaProduccion, 'Nombre': e.NomAreaProduccion})
            });

            // result.gpoMauinas.forEach(e => {
            //     gpoMaquArr.push({'Clave': e.ClaGrupoMaquina, 'Nombre': e.NomGrupoMaquina})
            // })

            this.setState({
                areasData: areasArr,
                // gpoMaquinasData: gpoMaquArr,
            });
        });
    }

    onChangeCmbAreas(selectedItem){
        var newFiltroPedidos = this.state.filtros;
        newFiltroPedidos.claArea = selectedItem.value;

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {

            var maqArr = [];

            result.gpoMaquinas.forEach(e => {
                maqArr.push({'Clave': e.ClaGrupoMaquina, 'Nombre': e.NomGrupoMaquina})
            })

            this.setState({
                gruposMaquinas: maqArr
            });
        });

        this.setState({ 
            filtros : newFiltroPedidos
        }); 
    }

    onChangeCmbGpoMaquinas(selectedItem){
        var newFiltroPedidos = this.state.filtros;
        newFiltroPedidos.claGrupoMaquina = selectedItem.value;

        this.setState({ 
            filtros : newFiltroPedidos
        }); 
    }

    onChangeCmbPeriodoPedidoInicio(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.periodoInicio = selectedItem.value;
        
        this.setState({ 
            filtros: newFiltro
        }); 
    }

    onChangeCmbPeriodoPedidoFin(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.periodoFin = selectedItem.value;
        
        this.setState({ 
            filtros: newFiltro
        }); 
    }
    
    onChangeCmbDireccion(e){
        var newFiltro = this.state.filtros;
        newFiltro.claDireccion = e.value;

        this.setState({
            filtros: newFiltro
        });

        if(e.value === null){
            this.setState({
                subDireccionesData: []
            });
        }
        else{
            const params = {
                Valor: '',
                Tipo: 1,
                ModoSel: 2,
                IncluirTodosSN: 0,
                ClaNivel: 30,
                ClaDireccion: e.value,
            };
        
            callKrakenApi(23, 18, params, 1, (res) => {
                const data = res.Result0;
                var newDirecciones = [];
                data.forEach((e, i) => { newDirecciones.push({"Clave": e.ClaOrganizacion, "Nombre": e.NomOrganizacion})});
                this.setState({
                    subDireccionesData: newDirecciones
                });
            });
        }
    }

    onChangeCmbSubDireccion(e){
        var newFiltro = this.state.filtros;
        newFiltro.claSubDireccion = e.value;
        
        this.setState({ 
            filtros: newFiltro
        });
    }

    onChangeCmbTipoProducto(e){
        var newFiltro = this.state.filtros;
        newFiltro.claTipoProducto = e.value;
        
        this.setState({ 
            filtros: newFiltro
        });
    }

    onProductoSelected(object){
        var newFiltro = this.state.filtros;
        newFiltro.claProducto = object.selectedItem.Clave;
        
        this.setState({ 
            filtros: newFiltro
        });
    }

    onGridPedidosPage(pedidosPageIndex){
        this.setState({ pedidosPageIndex });
    }

    onLimpiar(){
        const filtros = {            
            fechaCalculo: new Date(),
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claArea: null,
            claGrupoMaquina: null,
            modoVista: 1,
            claPedido: null
        };

        this.setState({
            filtros
        })
    }

    componentDidMount(){ 
        this.getDirecciones(); 
        this.getUbicaciones();
    }

    render(){
        let filtro = (
            <ContratosFiltro
                onLimpiar={this.onLimpiar}

                getProduccionPedidosData={this.getProduccionPedidosData}

                onChangeFechaCalculo={this.onChangeFechaCalculo}
                fechaCalculoValue={this.state.filtros.fechaCalculo}

                periodosData={this.state.periodosPedidosData}
                onChangeCmbPeriodoInicio={this.onChangeCmbPeriodoPedidoInicio}
                refCmbPeriodoInicio={this.refCmbPedidoPeriodoInicio}
                periodoInicio={this.state.filtros.periodoInicio}

                // periodosData={this.state.periodosData}
                onChangeCmbPeriodoFin={this.onChangeCmbPeriodoPedidoFin}
                refCmbPeriodoFin={this.refCmbPedidoPeriodoFin}
                periodoFin={this.state.filtros.periodoFin}
                
                onChangeCmbDireccion={this.onChangeCmbDireccion}
                onChangeCmbSubDireccion={this.onChangeCmbSubDireccion}
                direccionesData={this.state.direccionesData}
                subDireccionesData={this.state.subDireccionesData}
                direccion={this.state.filtros.claDireccion}
                subDireccion={this.state.filtros.claSubDireccion}
                refCmbDireccion={this.refCmbDireccion}
                refCmbSubDireccion={this.refCmbSubDireccion}

                onChangeCmbTipoProducto={this.onChangeCmbTipoProducto}
                tipoProductoData={this.state.tipoProductoData}
                tipoProducto={this.state.filtros.claTipoProducto}
                refCmbTipoProducto={this.refCmbTipoProducto}
                
                // onChangeCmbProducto={this.onChangeCmbProducto}
                onProductoSelected={this.onProductoSelected}
                productoData={this.state.productoData}
                producto={this.state.filtros.claProducto}
                refCmbProducto={this.refCmbProducto}

                ubicacionesData={this.state.ubicacionesData}
                onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                refCmbUbicacion={this.refCmbUbicacion}
                claUbicacion={this.state.filtros.claUbicacion}
                
                areasData={this.state.areasData}
                onChangeCmbAreas={this.onChangeCmbAreas}
                refCmbAreas={this.refCmbAreas}
                claArea={this.state.filtros.claArea}

                gpoMaquinasData={this.state.gruposMaquinas}
                onChangeCmbGpoMaquinas={this.onChangeCmbGpoMaquinas}
                refCmbGpoMaquinas={this.refCmbGpoMaquinas}
                claGrupoMaquina={this.state.filtros.claGrupoMaquina}

                showSpin={this.state.showSpin}
            >
            </ContratosFiltro>
        );

        let grid=(
            <ContratosGrid 
                data={this.state.dataProduccionPedidos} 
                onPage={this.onGridPedidosPage} 
                pageIndex={this.state.pedidosPageIndex}
                filtros={this.state.filtros}
                produccionModo={this.state.produccionModo}
            >
            </ContratosGrid>
        );

        return(
            <>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <CardTitle>Contratos</CardTitle>
                            </CardHeader>
                        </Card>  
                        <CardBody>
                            {filtro}
                        </CardBody> 
                    </Col>
                </Row>
                <Row >
                    <Col>
                        {grid}
                    </Col>
                </Row>
            </>
        );
    }
}