/*eslint-disable*/
import React from 'react';
import DataGrid, { Column, Paging, Format } from 'devextreme-react/data-grid';
import { Col, Row } from 'reactstrap';
import { config } from 'utils/config';
import { callApi, fechaStr } from 'utils/utils';
import CtrlComboBox from 'views/Controles/CtrlComboBox';

import 'assets/css/popup.css';

export default class ProduccionAceGridDet extends React.Component {
  constructor(props) {
    super(props);

    const TimerTiempo = 2000;
    const TimerId = 0;
    this.gridRef = React.createRef();

    this.state = {
      data: [],
      enc: { NomGrupoProduccion: null, NomCalibre: null, direccion: null },
      claSegmentacion: this.props.paramService.claDireccion,
      datasegmentacion: [],
      paramService: {},
      showSegementacion: props.showSegementacion == 0 ? false : true,
    };

    this.onCellPrepared = this.onCellPrepared.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.cellDateFormatRender = this.cellDateFormatRender.bind(this);
    this.onTimer = this.onTimer.bind(this);
    this.onChangeCmbSegmentacion = this.onChangeCmbSegmentacion.bind(this);
  }

  getProduccionData = () => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/DetalleAce`;

    const p = this.props.paramService;
    const params = {
      claUbicacion: p.claUbicacion,
      claGrupoProduccion: p.claGrupoProduccion,
      claCalibre: p.claCalibre,
      fechaCalculo: p.fechaCalculo,
      claDireccion: this.state.claSegmentacion,
    };

    callApi(urlApiService, 'POST', params, (result) => {
      this.setState({ data: result.detalle, enc: result.encabezado[0] });

      this.TimerId = setInterval(this.onTimer, 2000);
    });
  };

  getSegmentacionData = () => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Segmentacion`;

    const p = this.props.paramService;

    const params = {
      claUbicacion: p.claUbicacion,
      //claArea: p.claArea,
      //claGrupoMaquina: p.claGrupoMaquina,
      fechaCalculo: p.fechaCalculo,
      ClaGrupoProd: p.ClaGrupoProd,
      ClaCalibre: p.ClaCalibre,
    };

    callApi(urlApiService, 'POST', params, (result) => {
      this.setState({ datasegmentacion: result[0] });
    });
  };

  onChangeCmbSegmentacion(selectedItem) {
    this.setState({ claSegmentacion: selectedItem.value }, () => {
      this.getProduccionData();
    });
  }

  onClickModal() {
    const p = this.props.paramService;
    const params = {
      claPedido: p.claPedido,
      claUbicacion: p.claUbicacion,
      //claArea: p.claArea,
      //claGrupoMaquina: p.claGrupoMaquina,
      fechaCalculo: p.fechaCalculo,
      claDireccion: this.state.claSegmentacion,
    };

    this.setState({ openModal: true, paramService: params });
  }

  onCellPrepared(e) {
    if (e.rowType == 'data' && e.data.TotalProduccion === 0) {
      e.cellElement.style.color = 'red';
    }

    if (e.rowType == 'data' && e.columnIndex === 3 && e.data.Disponibles === 0) {
      e.cellElement.style.color = 'red';
    }
  }

  onRowPrepared(e) {
    if (e.rowType == 'data' && e.data.Fecha === this.props.paramService.fechaEstimadaEmbarque) {
      e.rowElement.classList.add('rowSelected');
    }
  }

  cellDateFormatRender(data, index) {
    const fechaobj = data.values[data.columnIndex];
    const fecha = fechaStr(fechaobj);
    const fechaJsx = <span>{fecha}</span>;

    return fechaJsx;
  }

  onTimer() {
    clearInterval(this.TimerId);

    if (this.gridRef.current === null) return;

    if (this.gridRef.current.instance === null) return;

    this.gridRef.current.instance.navigateToRow(this.props.paramService.fechaEstimadaEmbarque);
  }

  componentDidMount() {
    this.getProduccionData();
    this.getSegmentacionData();
  }

  render() {
    const gridContent = (
      <DataGrid
        id="gridPedidos"
        ref={this.gridRef}
        dataSource={this.state.data}
        height={400}
        width="95%"
        selection={{ mode: 'single' }}
        showBorders={false}
        columnHidingEnabled
        columnAutoWidth={false}
        showColumnHeaders
        showColumnLines={false}
        showRowLines
        noDataText="Sin Registros"
        wordWrapEnabled
        rowAlternationEnabled={false}
        scrollByContent={true}
        onOptionChanged={this.handleOptionChange}
        onCellPrepared={this.onCellPrepared}
        onRowPrepared={this.onRowPrepared}
        className="connect-datagrid-default"
      >
        <Column
          dataField="Fecha"
          caption="Fecha"
          width={40}
          dataType="date"
          cellRender={this.cellDateFormatRender}
        />
        {
          <Column
            dataField="Porcentaje"
            caption="Seg."
            width={30}
            format="#0.#'%'"
            visible={this.state.showSegementacion}
          />
        }
        <Column dataField="TotalProduccion" caption="Total" width={30} format="#0.#" />
        <Column dataField="Reservados" caption="Reservado" width={30} />
        <Column dataField="Disponibles" caption="Disp." width={30} />
        <Paging pageSize={60} />
      </DataGrid>
    );
    const direccion = (
      <>
        <Col className="col-2 m-0 p-0">Dirección:</Col>
        <Col className="col-10 m-0 p-0">
          <CtrlComboBox
            etiqueta="Segmentación"
            valueExpr="ClaDireccion"
            displayExpr="NomDireccion"
            onChange={this.onChangeCmbSegmentacion}
            data={this.state.datasegmentacion}
            value={this.state.claSegmentacion}
            tipo={2}
          />
        </Col>
      </>
    );
    return (
      <div className="col-12">
        <Row className="mb-0 pb-0 mt--3">
          <Col className="col-12 m-0 p-0">
            Grupo Producción: <b>{this.state.enc.NomGrupoProduccion}</b>
          </Col>
          <Col className="col-12 m-0 p-0">
            Calibre: <b>{this.state.enc.NomCalibre}</b>
          </Col>
          {this.state.showSegementacion && direccion}
        </Row>
        <Row className="mb-0 pb-0 mt--1">
          <Col className="col-12 m-0 p-0">{gridContent}</Col>
        </Row>
      </div>
    );
  }
}
