/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, {
  MasterDetail,
  Column,
  Paging,
  Format,
  Export,
  Scrolling,
} from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import CapaidadMasterDetail from './CapacidadMasterDetail';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';

export default class CapacidadGrid extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = null;
    this.state = {};

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.onExporting = this.onExporting.bind(this);
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.props.onPage(e.value);
    }
  }

  onExporting(e) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      workbook.xlsx.writeBuffer().then(function (buffer) {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Producción.xlsx');
      });
    });

    e.cancel = true;
  }

  render() {
    const gridContent = (
      <DataGrid
        id="gridProduccion"
        ref={(ref) => (this.dataGrid = ref)}
        dataSource={this.props.data}
        key={'idPlan'}
        width="100%"
        height={600}
        selection={{ mode: 'single' }}
        showBorders={false}
        columnAutoWidth={false}
        showColumnHeaders
        showColumnLines={false}
        showRowLines
        noDataText="Sin Registros"
        wordWrapEnabled
        rowAlternationEnabled={false}
        onOptionChanged={this.handleOptionChange}
        onExporting={this.onExporting}
      >
        <Column dataField="idPlan" caption="Plan" width={100} visible={false} />
        <Column dataField="idPlanDet" caption="Plan detalle" width={100} visible={false} />
        <Column dataField="idPlanEnc" caption="Plan del Encabezado" width={100} visible={false} />
        <Column dataField="idPadre" caption="Padre" width={100} visible={false} />
        <Column dataField="claUbicacion" caption="Ubicacion" width={100} />
        <Column dataField="claAreaProduccion" caption="Area Producción" width={200} />
        <Column dataField="claGrupoMaquinas" caption="Grupo Máquina" width={200} />
        <Column dataField="claCampo" caption="Segmentación" width={100} />
        <Column
          dataField="porcentaje"
          caption="Porcentaje"
          width={100}
          dataType={'number'}
          alignment={'left'}
          format="#0.00'%'"
        />
        <Column
          dataField="totalHrs"
          caption="Total Hrs"
          width={50}
          dataType={'number'}
          alignment={'left'}
          format="#,###.##"
        />
        <Column
          dataField="disponibleHrs"
          caption="Horas Disponbles"
          width={50}
          dataType={'number'}
          alignment={'left'}
          format="#,###.##"
        />
        <Column
          dataField="reservadoHrs"
          caption="Horas Reservadas"
          width={50}
          dataType={'number'}
          alignment={'left'}
          format="#,###.##"
        />
        <Column dataField="claPeriodo" caption="Periodo" width={50} />

        <Paging pageSize={30} pageIndex={this.props.pageIndex} />
        <Scrolling columnRenderingMode="virtual" />
        <Export enabled={true} allowExportSelectedData={false} />
      </DataGrid>
    );

    return <>{gridContent}</>;
  }
}
