import React from "react";
import { Row, Col } from "reactstrap";

const TraceInventarioAceria = props => {
  const { data, idRenglon, claUbicacion } = props;

  const lista = data
    .filter((item) => item.IdRenglon === idRenglon && item.ClaUbicacion === claUbicacion)
    .map((invent, index) => {
      const id = `Id-${index}`;

      const diaComprado = invent.DiasProductoComprado > 0 ? <Row><Col><b>Dias Producto Comprado:</b> {invent.DiasProductoComprado} </Col></Row> : <></>;
      const diasDesviacion = invent.DiasDesviacion > 0 ? <Row><Col><b>Dias Desviacición:</b> {invent.DiasDesviacion} </Col></Row> : <></>;

      return (
        <Row key={id}>
          <Col>
            <Row>
              <Col className="col-6">
                <h5 className="mb-0">Producto Interno</h5>
              </Col>
              <Col className="col-3">
                <h5 className="mb-0">Inventario Total</h5>
              </Col>
              <Col className="col-3">
                <h5 className="mb-0">Asignación a Inventario</h5>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                {invent.ArticuloAceria}
              </Col>
              <Col className="col-3">
                <p>{invent.Total} Kgs</p>
              </Col>
              <Col className="col-3">
                {invent.CantReservadaKgs}
              </Col>
            </Row>
            {diaComprado}
            {diasDesviacion}
          </Col>
        </Row>
      );
    });

  return lista;
};


export default TraceInventarioAceria;