/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup, Row, Col } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi, showNotify, notifyType, notifyPosition, } from '../../../utils/utils';
import CtrlAutoComplete from '../../Controles/CtrlAutoComplete';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import CtrlNumerico from '../../Controles/CtrlNumerico';
import { TextBox } from 'devextreme-react';

export default class Template_UbiCalAceLote extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ubicacionesData: [],
            calibresData:[],
            claCalibre: null,
            claUbicacion: null,
        }

        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getCalibres = this.getCalibres.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.onCalibreSelected = this.onCalibreSelected.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.limpiar = this.limpiar.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);
    
        this.refNumLote = React.createRef();
        this.refCmbUbicacion = React.createRef();

        this.autoCompleteInstance = null;
    }


    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            
            let acerias = res.Result0.filter(u => u.Nombre.includes('Acería'));

            this.setState({
                ubicacionesData: acerias
            })
        });
    }

    getCalibres(claUbicacion) {
        const urlApiService = `${process.env.REACT_APP_URL_API}LoteMinimo/GetCatCalibresAceriaLoteminimo?ClaUbicacion=${claUbicacion}`;

        callApi(urlApiService, 'GET', null, (result) => {
            // console.log(result);
            this.setState({
                calibresData: result
            })
        });
    }

    limpiar(){
        if(this.autoCompleteInstance !== null)
        {this.autoCompleteInstance.reset();}
        
        this.setState({
            claUbicacion: null,
            claCalibre : null,
            txtLoteMinimo: null,
            calibresData:[],
        })
    }

    onChangeCmbUbicacion(object){
        if(object.value !== null){            
            this.setState({
                claUbicacion: object.value
            })
            this.getCalibres(object.value)
        }
        else{
            this.limpiar();
        }
    }

    btnGuardarHandleSubmit() {
    
        if(this.state.claCalibre === null || this.state.claCalibre === undefined){
            showNotify('Error: Campo obligatorio vacío', notifyPosition.centerTop, notifyType.error);
            return;
        }

        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneralInsert`;
    
        const body = {
            idConfiguracion: this.props.idConfiguracion,
            claUbicacion: this.state.claUbicacion,
            valorEntero1: this.state.claCalibre
        };
        console.log(body);
    
        callApi(urlApiService, 'POST', body, (res) => {
        //   showNotify('Usuario guardado correctamente', notifyPosition.centerTop, notifyType.success);
            this.props.handler(false);
          
            this.props.getConfiguraciones()
            this.limpiar();
        });
    }

    btnCancelarHandle(){
        this.limpiar();
        this.props.handler(false);
    }

    onCalibreSelected(object){
        
        if(object.selectedItem !== null)
        {
            this.autoCompleteInstance = object.component;
            this.setState({
                txtLoteMinimo: String(object.selectedItem.LoteMinimo),
                claCalibre: object.selectedItem.Clave
            })
        }
        else{
            this.setState({
                claCalibre : null,
            })
        }
    }

    componentDidMount(){
        this.getUbicaciones();
    }

    render(){
        return(
            <div>
                <Row>
                    <Col>
                        <CtrlComboBox
                            etiqueta="Ubicacion"
                            onChange={this.onChangeCmbUbicacion}
                            data={this.state.ubicacionesData}
                            value={this.state.claUbicacion}
                            reference={this.refCmbUbicacion}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CtrlAutoComplete
                            etiqueta="Calibre"
                            placeholder="Nombre del calibre..."
                            valueExpr="Nombre"
                            displayExpr="Nombre"
                            keyValue="Clave"
                            dataSource={this.state.calibresData}
                            onChange={this.onCalibreSelected}
                            value={this.state.claCalibre}
                            minSearchLength = {1}
                            // reference={this.refNumProducto}
                            onInitialized={this.refNumProducto}
                            disabled={this.state.claUbicacion === null}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextBox
                            etiqueta="Lote mínimo"
                            value={this.state.txtLoteMinimo}
                            disabled={true}
                            mask={'000,000'}
                        />
                        <br/>
                    </Col>
                </Row>
                <Row className='justify-content-center'>
                    <Col>
                        <Button
                            id="btnUsersGuardar"
                            text="Guardar"
                            onClick={this.btnGuardarHandleSubmit}
                            className="btn btn-primary btn-round"
                        />
                    </Col>
                    <Col>
                        <Button
                            id="btnUsersCancelar"
                            text="Cancelar"
                            onClick={this.btnCancelarHandle}
                            className="btn btn-primary btn-round"
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}