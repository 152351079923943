/*eslint-disable*/
import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import AppContext from "../../../context/AppContext";
import { callApi, callKrakenApi } from '../../../utils/utils';
import Tabs from 'devextreme-react/tabs';
import CapacidadFiltros from './CapacidadFiltros';
import { config } from '../../../utils/config';
import CapacidadCharts from './CapacidadCharts';
import CapacidadGrid from './CapacidadGrid';

export default class Capacidad extends React.Component {
    constructor(props) {
        super(props);
        
        const tabs = [
            {
                id: 0,
                text: 'Gráficas',
                content: 'Gráficas'
            },
            {
                id: 1,
                text: 'Detalle',
                content: 'Detalle'
            }
        ];

        const filtros = {
            claUbicacion: null,
            claZona: null,
            fechaEmbarque: new Date(),
        }

        this.state = {
            ubicacionesData: [],
            capacidadData: [],
            filtros,
            showSpin: false,
            tabs,
            graficaVisible: "",
            detalleVisible: "none",
            pageIndex: 0,
            capacidadVisible: "",
            deudaVisible: "none",
            selectedIndex: 0,
            requiredMessage: "hidden"
        };

        this.getEmbarques = this.getEmbarques.bind(this);
        
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeFechaEmbarque = this.onChangeFechaEmbarque.bind(this);
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

        this.onLimpiar = this.onLimpiar.bind(this);

        this.refCmbUbicacion = React.createRef();     
    }

        
    getUbicaciones = () => {
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    getEmbarques(){
        if(this.state.filtros.claUbicacion === null){
            this.setState({
                showSpin: false,
                requiredMessage: "visible"
            })
        }
        else{                
            const urlApiService = `${process.env.REACT_APP_URL_API}Embarque/BusquedaCapacidad`;
            
            this.setState({ showSpin: true });

            callApi(urlApiService, 'POST', this.state.filtros, (result) => {
                console.log(result);
                this.setState({
                    capacidadData: result,
                    showSpin: false,
                    requiredMessage: "hidden"
                })
            });
        }
    }
    
    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }
    
    onChangeCmbUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro,
            requiredMessage: "hidden",
            capacidadData: []
        });
    }

    onChangeFechaEmbarque(selectedDate){
        var newFiltro = this.state.filtros;
        newFiltro.fechaEmbarque = selectedDate.value;

        this.setState({
            filtros: newFiltro,
        })
    }
    
    onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            if(args.value === 0){ 
                this.setState({
                  selectedIndex: args.value,
                  graficaVisible: "",
                  detalleVisible: "none",
                });
            }
            if(args.value === 1){ 
                this.setState({
                  selectedIndex: args.value,
                  graficaVisible: "none",
                  detalleVisible: "",
                });
            }
        }
    }

    onLimpiar(){        
        const filtros = {
            claUbicacion: null,
            claZona: null,
            fechaEmbarque: new Date(),
        }
        
        this.setState({
            filtros: filtros
        })
    }

    componentDidMount(){       
        this.getUbicaciones();
    }
    
    render() {
        return (
            <div className="content mb-5">
                <Row>
                    <Col>
                        <CapacidadFiltros
                            onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                            onChangeFechaEmbarque={this.onChangeFechaEmbarque}

                            getEmbarques={this.getEmbarques}
                            onLimpiar={this.onLimpiar}

                            ubicacionesData={this.state.ubicacionesData}

                            refCmbUbicacion={this.refCmbUbicacion}
                            
                            filtros={this.state.filtros}
                            showSpin={this.state.showSpin}

                            requiredMessage={this.state.requiredMessage}
                        >
                        </CapacidadFiltros>
                    </Col>
                </Row>
                <Row>
                    <Col>                    
                        <Tabs
                            dataSource={this.state.tabs}
                            selectedIndex={this.state.selectedIndex}
                            onOptionChanged={this.onTabsSelectionChanged}
                        />
                                
                        <div style={{"display": this.state.graficaVisible}}>                            
                            <CapacidadCharts
                                capacidadData={this.state.capacidadData}
                            />
                        </div>
                        <div style={{"display": this.state.detalleVisible}}>
                            <CapacidadGrid
                                capacidadData={this.state.capacidadData[2]}
                                onPage={this.onGridPage} 
                                pageIndex={this.state.pageIndex}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}