import React from "react";
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import { Row, Col } from "reactstrap";

import FaltantesGridDet from './FaltantesGridDet';
import 'assets/css/popup.css';

const ModalPreferencias = props => {
  const { visible, onClosePopup, paramService } = props;
  
  if(visible===false)
    return (<></>);

  const onContentReady = (args) => {
    // const html = args.component.content();  
    const html = args.component;  
    // $(html).css("padding", "0");
    // html.ElementAttr('padding', '0');
  }

  return (
    <Popup
      visible={visible}
      dragEnabled={false}
      closeOnOutsideClick={false}
      onHiding={onClosePopup}
      onContentReady={onContentReady}
      showCloseButton={true}
      showTitle={true}
      title={`Preferencias: ${paramService.nomArticulo}`}
      container=".dx-viewport"
      width={900}
      height={580}
    >
      <Row>
        <FaltantesGridDet paramService={paramService}></FaltantesGridDet>
      </Row>
      
    </Popup>      
  );
};


export default ModalPreferencias;