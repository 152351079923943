import React from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import CtrlComboBox from '../Controles/CtrlComboBox';
import { callApi, callKrakenApi } from '../../utils/utils';

export default class LuisPrueba extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.onChange = this.onChange.bind(this);
  }


  getUbicaciones() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 231, params, 1, (res) => {
      this.setState({ data: res.Result0 });
     
    });
  }

  onChange(selectedItem) {
    const claUbicacion = selectedItem.value != null ? selectedItem.value : -1;
    this.setState({claUbicacion});
  }

  componentDidMount() {
    this.getUbicaciones();
  }


  render() {

    return (
      <>
        <Row>
          <Col> HOLA Luis!!!!
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 3 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Ubicaciones"
              data={this.state.data}
              onChange={this.onChange}
              value={this.state.claUbicacion}
            />
          </Col>
        </Row>
      </>
    );
  }
}
