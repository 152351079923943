/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column, Paging, Editing, Lookup, Export, FilterRow, HeaderFilter} from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';

export default class Template8Grid extends React.Component {
    constructor(props) {
        super(props);

        this.dataGrid = null;

        this.handleOptionChange = this.handleOptionChange.bind(this);
    }

    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function(options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            } 
        }).then(function() {
            workbook.xlsx.writeBuffer()
                .then(function(buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Configuración.xlsx');
                });
        });
        e.cancel = true;
    }

    componentDidMount(){

    }

    componentDidUpdate(){

    }

    render(){
        const gridContent = (
            <DataGrid
                id="gridConfiguracion"
                ref={(ref) => this.dataGrid = ref}
                dataSource={this.props.configData}
                key={'IdCfgConfiguracionGeneral'}
                width="100%"
                height={600}
                selection={{ mode: 'single' }}
                showBorders={false}
                columnHidingEnabled
                columnAutoWidth={false}
                showColumnHeaders
                showColumnLines={false}
                showRowLines
                noDataText="Sin Registros"
                wordWrapEnabled
                rowAlternationEnabled={false}
                onOptionChanged={this.handleOptionChange}
                remoteOperations={true}
                repaintChangesOnly={true}
                onRowRemoving={this.props.validateRemove}   
                onExporting={this.onExporting}        
            >
                <Editing                
                    mode="single"
                    allowAdding={false}
                    allowDeleting={true}
                    allowUpdating={false}
                />

                <FilterRow
                    visible={true}
                    applyFilter={true}
                />
                
                <HeaderFilter visible={true} width={"400"}/> 

                <Column dataField="IdCfgConfiguracionGeneral" caption="IdCfgConfiguracionGeneral" width={100} visible={false} />
                <Column dataField="IdConfiguracion" caption="IdConfiguracion" width={150} visible={false} />
                <Column dataField="ClaUbicacion" caption="IdConfiguracion" width={150} visible={false} />
                <Column dataField="NombreUbicacion" caption="Ubicacion" width={150} visible={true} />

                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />

                <Export enabled={true} allowExportSelectedData={false} />
                    
            </DataGrid>
        );

        return(
            <div className="content mb-5">
                {gridContent}
            </div>
        )
    }
}