/*eslint-disable*/
import React from 'react';
import { Card, CardBody, FormGroup } from 'reactstrap';
import { Button } from 'devextreme-react/button';
import Form, { Item, GroupItem, ButtonItem } from 'devextreme-react/form';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class Template9Alta extends React.Component {
    constructor(props) {
        super(props);
        
        const objTemplate9 = {
            Ubicacion : null,
            Familia: null,
            SubFamilia: null
        }

        this.state = {
            objTemplate9,
            ubicacionesData: [],
            familiasData: [],
            subFamiliasData: [],
        }

        this.onFieldDataChanged = this.onFieldDataChanged.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getFamilias = this.getFamilias.bind(this);
        this.getSubfamilias = this.getSubfamilias.bind(this);
        this.btnGuardarHandleSubmit = this.btnGuardarHandleSubmit.bind(this);
        this.btnCancelarHandle = this.btnCancelarHandle.bind(this);

        this.refForm = React.createRef();
    }

    get formUser() {
        return this.refForm.current ? this.refForm.current.instance : null;
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    getFamilias() {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaNivel: 20,
        };

        callKrakenApi(25, 1, params, 1, (res) => {
            // return res.Result0;
            this.setState({
                familiasData: res.Result0
            })
        });
    }

    getSubfamilias(claFamilia) {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaFamilia: claFamilia,
        };

        callKrakenApi(25, 2, params, 1, (res) => {
            this.setState({
                subFamiliasData: res.Result0
            })
        });
    }

    onFieldDataChanged(e){
        if(e.dataField == "Familia"){
            this.getSubfamilias(e.value);
        }
    }

    btnCancelarHandle() {
        this.props.handler(false);
    }

    btnGuardarHandleSubmit() {
        const dataForm = this.formUser.option('formData');
    
        if (dataForm.Ubicacion === null || dataForm.Familia === null || dataForm.SubFamilia === null) {
        //   showNotify(
        //     'Favor de capturar una Clave de Ubicacion y Producto',
        //     notifyPosition.centerTop,
        //     notifyType.error
        //   );
          return;
        }
    
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/InsertTemplate9`;
    
        const body = {
            idConfiguracion: this.props.idConfiguracion,
            claUbicacion: dataForm.Ubicacion,
            claFamilia: dataForm.Familia,
            claSubFamilia: dataForm.SubFamilia
        };
    
        callApi(urlApiService, 'POST', body, (res) => {
        //   showNotify('Usuario guardado correctamente', notifyPosition.centerTop, notifyType.success);
          this.props.handler(false);
        //   window.location.reload();
        });
    }

    componentDidMount(){
        this.getUbicaciones();
        this.getFamilias();
        // this.getAreaProduccion();
    }

    render(){
        return(
            <div>
                <Form
                    ref={this.refForm}
                    key="formUser"
                    formData={this.state.objTemplate9}
                    colCount={1}
                    showColonAfterLabel={true}
                    className="formUser"
                    id="FormAltaUsuario"
                    onFieldDataChanged={this.onFieldDataChanged}
                >
                <Item
                    dataField="Ubicacion"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.ubicacionesData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
                <Item
                    dataField="Familia"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.familiasData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
                <Item
                    dataField="SubFamilia"
                    editorType="dxSelectBox"
                    editorOptions={{
                        dataSource: this.state.subFamiliasData,
                        searchEnabled: true,
                        valueExpr: 'Clave',
                        displayExpr: 'Nombre',
                    }}
                />
            </Form>
            <FormGroup className="text-right">
                <Button
                    id="btnUsersGuardar"
                    text="Guardar"
                    onClick={this.btnGuardarHandleSubmit}
                    className="btn btn-primary btn-round"
                />
                <Button
                    id="btnUsersCancelar"
                    text="Cancelar"
                    onClick={this.btnCancelarHandle}
                    className="btn btn-primary btn-round"
                />
            </FormGroup>
            </div>
        )
    }
}