
/*eslint-disable*/
import React from 'react';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";
import { Switch } from "devextreme-react/switch";
import RadioGroup from 'devextreme-react/radio-group';
import Tabs from 'devextreme-react/tabs';
import PedidosFiltro from './PedidosFiltro';
import PedidosGrid from './PedidosGrid';
import PedidosPlanoGrid from './PedidosPlanoGrid';

export default class Pedidos extends React.Component {
    constructor(props) {
        super(props);

        locale('en');
        
        const filtrosPedidos = {            
            fechaCalculo: new Date(),
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claArea: null,
            claGrupoMaquina: null,
            modoVista: 1,
            claPedido: null
        };

        const produccionTabs = [
            {
                id: 0,
                text: 'Agrupados',
                content: 'Agrupados',
            },
            {
                id: 1,
                text: 'Detalle',
                content: 'Detalle',
            }
        ];

        this.state = {
            produccionTabs,
            filtrosPedidos,
            dataProduccionPlano:[],
            dataProduccionPedidos: [],
            pedidosPageIndex: 0,
            periodosPedidosData: [],
            ubicacionesData: [],
            areasData: [],
            gpoMaquinasData: [],
            direccionesData: [],
            subDireccionesData: [],
            productoData: [],
            showSpin: false,
            tipoProductoData: [
                {"Clave": 1, "Nombre": "MTO"},
                {"Clave": 2, "Nombre": "MTS"}
            ],
            produccionSelectedIndex: 0,
            agrupadosVisibility: "",
            detallesVisibility: "none",
        }

        this.onProduccionTabsSelectionChanged = this.onProduccionTabsSelectionChanged.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onChangeCmbGpoMaquinas = this.onChangeCmbGpoMaquinas.bind(this);
        this.onChangeCmbAreas = this.onChangeCmbAreas.bind(this);
        this.getProduccionPedidosData = this.getProduccionPedidosData.bind(this);
        this.onGridPedidosPage = this.onGridPedidosPage.bind(this);
        this.onChangeCmbPeriodoPedidoInicio = this.onChangeCmbPeriodoPedidoInicio.bind(this);
        this.onChangeCmbPeriodoPedidoFin = this.onChangeCmbPeriodoPedidoFin.bind(this);
        this.onChangeCmbDireccion = this.onChangeCmbDireccion.bind(this);
        this.onChangeCmbSubDireccion = this.onChangeCmbSubDireccion.bind(this);
        this.onChangeCmbTipoProducto = this.onChangeCmbTipoProducto.bind(this);
        this.onChangeCmbProducto = this.onChangeCmbProducto.bind(this);
        this.onProductoSelected = this.onProductoSelected.bind(this);
        this.onLimpiar = this.onLimpiar.bind(this);
        
        this.refCmbUbicacion = React.createRef();
        this.refCmbAreas = React.createRef();
        this.refCmbGpoMaquinas = React.createRef();
        this.refCmbPedidoPeriodoInicio = React.createRef();
        this.refCmbPedidoPeriodoFin = React.createRef();
        this.refCmbDireccion = React.createRef();
        this.refCmbSubDireccion = React.createRef();
        this.refCmbTipoProducto = React.createRef();
        this.refCmbProducto = React.createRef();
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    getDirecciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 2,
            IncluirTodosSN: 0,
            ClaNivel: 20,
          };
      
        callKrakenApi(23, 18, params, 1, (res) => {
            const data = res.Result0;
            var newDirecciones = [];
            data.forEach((e, i) => { newDirecciones.push({"Clave": e.ClaOrganizacion, "Nombre": e.NomOrganizacion})});
            this.setState({
                direccionesData: newDirecciones
            });
        });
    }

    getProductos(){
        var params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
        };
    
        callKrakenApi(25, 3, params, 1, (res) => {
            const data = res.Result0;
            
            this.setState({
                productoData: data
            });
        });
    }

    getProduccionPedidosData(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;
        
        this.setState({ showSpin: true });

        callApi(urlApiService, 'POST', this.state.filtrosPedidos, (result) => {
            var newPeriodos = [];
            if(result[1] !== undefined){
                result[1].forEach((e, i) => { newPeriodos.push({"Clave": e.ClaPeriodo, "Nombre": e.ClaPeriodo})})
                this.setState({
                    dataProduccionPedidos: result[0],
                    periodosPedidosData: newPeriodos,
                    // showSpin: false
                }); 
            }
            
        });

        const urlService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;
        
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.modoVista = 5;

        callApi(urlService, 'POST', newFiltro, (result) => {
            this.setState({
                dataProduccionPlano: result[0],
                showSpin: false
            });
        });
    }

    onGridPedidosPage(pedidosPageIndex){
        this.setState({ pedidosPageIndex });
    }

    onChangeFechaCalculo = selectedDate => {
        var newPedidoFiltro = this.state.filtrosPedidos;
        newPedidoFiltro.fechaCalculo = selectedDate.value;

        this.setState({
            filtrosPedidos: newPedidoFiltro
        })
    }

    onChangeCmbUbicacion(selectedItem){
        var newPedidoFiltro = this.state.filtrosPedidos;
        newPedidoFiltro.claUbicacion = selectedItem.value;
        
        this.setState({ 
            filtrosPedidos : newPedidoFiltro
        });
        
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

        callApi(urlApiService, 'POST', newPedidoFiltro, (result) => {

            var areasArr = [];
            // var gpoMaquArr = [];

            result.areasProduccion.forEach(e => {
                areasArr.push({'Clave': e.ClaAreaProduccion, 'Nombre': e.NomAreaProduccion})
            });

            // result.gpoMauinas.forEach(e => {
            //     gpoMaquArr.push({'Clave': e.ClaGrupoMaquina, 'Nombre': e.NomGrupoMaquina})
            // })

            this.setState({
                areasData: areasArr,
                // gpoMaquinasData: gpoMaquArr,
            });
        });
    }

    onChangeCmbAreas(selectedItem){
        var newFiltroPedidos = this.state.filtrosPedidos;
        newFiltroPedidos.claArea = selectedItem.value;

        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Catalogos`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {

            var maqArr = [];

            result.gpoMaquinas.forEach(e => {
                maqArr.push({'Clave': e.ClaGrupoMaquina, 'Nombre': e.NomGrupoMaquina})
            })

            this.setState({
                gpoMaquinasData: maqArr
            });
        });

        this.setState({ 
            filtrosPedidos : newFiltroPedidos
        }); 
    }

    onChangeCmbGpoMaquinas(selectedItem){
        var newFiltroPedidos = this.state.filtrosPedidos;
        newFiltroPedidos.claGrupoMaquina = selectedItem.value;

        this.setState({ 
            filtrosPedidos : newFiltroPedidos
        }); 
    }

    onChangeCmbPeriodoPedidoInicio(selectedItem){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.periodoInicio = selectedItem.value;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        }); 
    }

    onChangeCmbPeriodoPedidoFin(selectedItem){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.periodoFin = selectedItem.value;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        }); 
    }
    
    onChangeCmbDireccion(e){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.claDireccion = e.value;

        this.setState({
            filtrosPedidos: newFiltro
        });

        if(e.value === null){
            this.setState({
                subDireccionesData: []
            });
        }
        else{
            const params = {
                Valor: '',
                Tipo: 1,
                ModoSel: 2,
                IncluirTodosSN: 0,
                ClaNivel: 30,
                ClaDireccion: e.value,
            };
        
            callKrakenApi(23, 18, params, 1, (res) => {
                const data = res.Result0;
                var newDirecciones = [];
                data.forEach((e, i) => { newDirecciones.push({"Clave": e.ClaOrganizacion, "Nombre": e.NomOrganizacion})});
                this.setState({
                    subDireccionesData: newDirecciones
                });
            });
        }
    }

    onChangeCmbSubDireccion(e){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.claSubDireccion = e.value;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        });
    }

    onChangeCmbTipoProducto(e){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.claTipoProducto = e.value;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        });
    }

    onChangeCmbProducto(e){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.claProducto = e.value;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        });
    }

    onProductoSelected(object){
        var newFiltro = this.state.filtrosPedidos;
        newFiltro.claProducto = object.selectedItem.Clave;
        
        this.setState({ 
            filtrosPedidos: newFiltro
        });
    }

    onProduccionTabsSelectionChanged(e){
        if (e.name === 'selectedIndex') {
            if(e.value === 0){   
                this.setState({
                    produccionSelectedIndex: e.value,
                    agrupadosVisibility: "",
                    detallesVisibility: "none",
                });      
            }
            if(e.value === 1){      
                this.setState({
                    produccionSelectedIndex: e.value,
                    agrupadosVisibility: "none",
                    detallesVisibility: "",
                });   
            }
        }
    }

    onLimpiar(){
        const filtrosPedidos = {            
            fechaCalculo: new Date(),
            claDireccion: null,
            claSubDireccion: null,
            claTipoProducto: null,
            claProducto: null,
            periodoInicio: null,
            periodoFin: null,
            claArea: null,
            claGrupoMaquina: null,
            modoVista: 1,
            claPedido: null
        };

        this.setState({
            filtrosPedidos
        })
    }

    componentDidMount(){        
        // this.getProductos();  
        this.getDirecciones(); 
        this.getUbicaciones();
    }

    render(){
        let filtro = (
            <PedidosFiltro
                onLimpiar={this.onLimpiar}

                getProduccionPedidosData={this.getProduccionPedidosData}
                
                onChangeFechaCalculo={this.onChangeFechaCalculo}
                fechaCalculoValue={this.state.filtrosPedidos.fechaCalculo}

                periodosData={this.state.periodosPedidosData}
                onChangeCmbPeriodoInicio={this.onChangeCmbPeriodoPedidoInicio}
                refCmbPeriodoInicio={this.refCmbPedidoPeriodoInicio}
                periodoInicio={this.state.filtrosPedidos.periodoInicio}

                // periodosData={this.state.periodosData}
                onChangeCmbPeriodoFin={this.onChangeCmbPeriodoPedidoFin}
                refCmbPeriodoFin={this.refCmbPedidoPeriodoFin}
                periodoFin={this.state.filtrosPedidos.periodoFin}
                
                onChangeCmbDireccion={this.onChangeCmbDireccion}
                onChangeCmbSubDireccion={this.onChangeCmbSubDireccion}
                direccionesData={this.state.direccionesData}
                subDireccionesData={this.state.subDireccionesData}
                direccion={this.state.filtrosPedidos.claDireccion}
                subDireccion={this.state.filtrosPedidos.claSubDireccion}
                refCmbDireccion={this.refCmbDireccion}
                refCmbSubDireccion={this.refCmbSubDireccion}

                onChangeCmbTipoProducto={this.onChangeCmbTipoProducto}
                tipoProductoData={this.state.tipoProductoData}
                tipoProducto={this.state.filtrosPedidos.claTipoProducto}
                refCmbTipoProducto={this.refCmbTipoProducto}
                
                onChangeCmbProducto={this.onChangeCmbProducto}
                onProductoSelected={this.onProductoSelected}
                productoData={this.state.productoData}
                producto={this.state.filtrosPedidos.claProducto}
                refCmbProducto={this.refCmbProducto}

                ubicacionesData={this.state.ubicacionesData}
                onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                refCmbUbicacion={this.refCmbUbicacion}
                claUbicacion={this.state.filtrosPedidos.claUbicacion}
                
                areasData={this.state.areasData}
                onChangeCmbAreas={this.onChangeCmbAreas}
                refCmbAreas={this.refCmbAreas}
                claArea={this.state.filtrosPedidos.claArea}

                gpoMaquinasData={this.state.gpoMaquinasData}
                onChangeCmbGpoMaquinas={this.onChangeCmbGpoMaquinas}
                refCmbGpoMaquinas={this.refCmbGpoMaquinas}
                claGrupoMaquina={this.state.filtrosPedidos.claGrupoMaquina}

                showSpin={this.state.showSpin}
            >
            </PedidosFiltro>
        );

        let grid = (
            <>
                <Tabs
                    dataSource={this.state.produccionTabs}
                    selectedIndex={this.state.produccionSelectedIndex}
                    onOptionChanged={this.onProduccionTabsSelectionChanged}
                />
                <div style={{"display": this.state.agrupadosVisibility}}>
                    <PedidosGrid 
                        data={this.state.dataProduccionPedidos} 
                        onPage={this.onGridPedidosPage} 
                        pageIndex={this.state.pedidosPageIndex}
                        filtros={this.state.filtrosPedidos}
                        produccionModo={this.state.produccionModo}
                    >
                    </PedidosGrid>
                </div>
                <div style={{"display": this.state.detallesVisibility}}>
                    <PedidosPlanoGrid 
                        data={this.state.dataProduccionPlano} 
                        onPage={this.onGridPlanoPage} 
                        pageIndex={this.state.planoPageIndex}
                    />
                </div>
            </>
        );

        return(
            <>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <CardTitle>Pedidos</CardTitle>
                            </CardHeader>
                        </Card>  
                        <CardBody>
                            {filtro}
                        </CardBody> 
                    </Col>
                </Row>
                <Row >
                    <Col>
                        {grid}
                    </Col>
                </Row>
            </>
        );
    }
}