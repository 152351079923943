/*eslint-disable*/
import React from 'react';
import { Card, CardBody, Row, Col, CardHeader } from 'reactstrap';
import TracePopupOver from './TracePopupOver';
import DataGrid, { Column, Paging } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { config } from '../../../utils/config';
import { callApi } from '../../../utils/utils';

export default class InfoCalculoDet extends React.Component {
  constructor(props) {
    super(props);
    const filtroSeg = {
      ClaUbicacion: 7,
    };

    this.state = {
      datosCompetenciaTE: [],
      showPopup: false,
      showSegementacion: 0,
      filtroSeg,
    };

    this.onClickCompetencia = this.onClickCompetencia.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.getConfiguracion = this.getConfiguracion.bind(this);

    this.getConfiguracion();
  }

  getDetalleCompetenciaTE(fechaCalculo) {
    const urlService = `${process.env.REACT_APP_URL_API}Pedido/DetalleCompetenciaTE`;
    const params = {
      ClaPedido: this.props.claPedido,
      IdRenglon: this.props.data.idRenglon, // MoverIDRenglon
      FechaCalculo: fechaCalculo,
    };

    callApi(urlService, 'POST', params, (res) => {
      this.setState({ datosCompetenciaTE: res.detalleResultadosCompetenciaTE, showPopup: true });
    });
  }
  getConfiguracion() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/ConfiguracionSegmentacion`;

    callApi(urlApiService, 'POST', this.state.filtroSeg, (result) => {
      this.setState({
        showSegementacion: result[0][0].SegmentacionActivada,
      });
    });
  }

  onClickCompetencia() {
    this.getDetalleCompetenciaTE(this.props.fechaCalculo);
    // this.setState({ showPopup: true });
  }

  onPopupClose() {
    this.setState({ showPopup: false });
  }

  componentDidMount() {}

  render() {
    const claseBase = 'mb-0 icon icon-shape text-white rounded-circle shadow ';
    const produccion = this.props.data.produccion > 0 ? 'bg-warning' : 'bg-info';
    const classProduccion = claseBase + produccion;

    const valorInvent = this.props.data.inventario !== undefined ? this.props.data.inventario : 0;
    const valorTraspaso = this.props.data.traspasos !== undefined ? this.props.data.traspasos : 0;
    const valorViaje = this.props.data.viaje !== undefined ? this.props.data.viaje : 0;
    const valorProduccion =
      this.props.data.produccion !== undefined ? this.props.data.produccion : 0;
    const idRenglon = this.props.data.idRenglon !== undefined ? this.props.data.idRenglon : 0;

    let traspasosTE = [];
    let valorTraspasoTE = 0;
    if (this.props.tracedata.traspasosTE !== undefined)
      traspasosTE = this.props.tracedata.traspasosTE.filter(
        (item) =>
          item.IdRenglon === this.props.data.idRenglon &&
          item.ClaProducto === this.props.data.claArticulo &&
          item.IdPrioridadOrden === this.props.index
      );
    if (traspasosTE.length > 0) valorTraspasoTE = traspasosTE[0].ClaPedidoTraspaso;

    const te =
      this.props.data.esTE === 1 ? (
        <h5 className="mb-1">
          <b>Tecnología Exclusiva:</b>
        </h5>
      ) : (
        <></>
      );
    const fechaRecepcion =
      this.props.data.fechaRecepcion !== null ? (
        <h5 className="mb-0">Recepción: {this.props.data.fechaRecepcion}</h5>
      ) : null;
    const fechaEmbarque =
      this.props.data.fechaEmbarque != null ? (
        <h5 className="mb-0">Embarque: {this.props.data.fechaEmbarque}</h5>
      ) : null;
    const fechas =
      fechaRecepcion !== null && fechaEmbarque !== null ? (
        <Row>
          <Col>{fechaRecepcion}</Col>
          <Col>{fechaEmbarque}</Col>
        </Row>
      ) : fechaRecepcion !== null ? (
        fechaRecepcion
      ) : (
        fechaEmbarque
      );

    const diaComprado =
      this.props.data.diasProductoComprado > 0 ? (
        <Row>
          <Col>
            <h5 className="mb-0">
              <b>Producto Comprado:</b> {this.props.data.diasProductoComprado} días
            </h5>{' '}
          </Col>
        </Row>
      ) : (
        <></>
      );
    const diasDesviacion =
      this.props.data.diasDesviacion > 0 ? (
        <Row>
          <Col>
            <b>Dias Desviación:</b> {this.props.data.diasDesviacion}{' '}
          </Col>
        </Row>
      ) : (
        <></>
      );
    const competencia =
      this.props.data.esCompetencia > 0 ? (
        <Row>
          <Col>
            <i onClick={this.onClickCompetencia}>
              <h5 className="mb-0 link">
                <b>Ver Competencia</b>
              </h5>
            </i>
          </Col>
        </Row>
      ) : (
        <></>
      );

    const traspasote =
      valorTraspasoTE > 0 ? (
        <Row>
          <Col>
            <TracePopupOver
              key={`TraspTeUbic${this.props.claUbicacion}`}
              value="TraspasoTE"
              data={traspasosTE}
              tipo={5}
              idRenglon={idRenglon}
              claUbicacion={this.props.claUbicacion}
            ></TracePopupOver>
          </Col>
        </Row>
      ) : (
        <></>
      );

    const competenciaPopup = (
      <Popup
        key="popUpVisorPDF"
        id="popUpVisorPDF"
        visible={true}
        onHiding={this.onPopupClose}
        dragEnabled={false}
        closeOnOutsideClick={true}
        showTitle={true}
        title={this.props.titulo}
        width="70%"
        height="100%"
      >
        <Row>
          <DataGrid
            id="gridPedidos"
            dataSource={this.state.datosCompetenciaTE}
            keyExpr=""
            height={900}
            selection={{ mode: 'single' }}
            showBorders={false}
            columnHidingEnabled
            columnAutoWidth={false}
            showColumnHeaders
            showColumnLines={false}
            showRowLines
            noDataText="Sin Registros"
            wordWrapEnabled
            rowAlternationEnabled={false}
            onContentReady={this.onContentReady}
            onOptionChanged={this.handleOptionChange}
          >
            <Column dataField="IdPedidoCalculado" caption="No Pedido" width={150} />
            <Column dataField="IdRenglon" caption="Renglon" width={100} />
            <Column dataField="Prioridad" caption="Prioridad" width={100} />
            <Column dataField="IdPrioridadOrden" caption="Nivel TE" width={100} />
            <Column dataField="EsGanoCompetencia" caption="Gano Competencia" width={200} />
            <Column dataField="FechaPromesaEmbarque" caption="Fecha Embarque" width={350} />
            <Column dataField="FechaRecepcion" caption="Fecha Recepción" width={350} />
            <Column dataField="FechaCalculo" caption="Fecha Cálculo" width={380} />
            <Column dataField="CantidadDeuda" caption="Deuda" width={100} />
            <Column dataField="ClaUbicacion" caption="Ubicación" width={100} />
            <Column dataField="ClaUbicacionElabora" caption="Ubicación Elabora" width={100} />
            <Column
              dataField="CantidadInventarioAsignado"
              caption="Inventario Asignado"
              width={100}
            />
            <Column dataField="CantidadInvAsignadoViaje" caption="Viaje Asignado" width={100} />
            <Column
              dataField="CantidadInvAsignadoTraspaso"
              caption="Traspaso Asignado"
              width={100}
            />
            <Column
              dataField="CantidadCapacidadAsignada"
              caption="Capacidad Asignada"
              width={100}
            />
            <Column dataField="CantidadDeudaPendiente" caption="Deuda Pendiente" width={100} />
            <Column dataField="CantidadDeudaKgs" caption="Deuda Kgs" width={100} format="#,##0" />
            <Column
              dataField="CantidadDeudaPendienteKgs"
              caption="Deuda Pendiente Kgs"
              width={100}
              format="#,##0"
            />
          </DataGrid>
        </Row>
      </Popup>
    );

    const contentPopup = this.state.showPopup ? competenciaPopup : <></>;

    return (
      <>
        <Row>
          <Col md={{ size: 12 }}>
            <Card key={this.props.claUbicacion} className="m-1">
              <CardBody className="p-1 bgTrace">
                <Row>
                  <Col className="col-12 m-0 pb-0">{te}</Col>
                </Row>
                <Row className="mb-0 pb-0">
                  <Col className="col-3 m-0 pb-0 d-flex justify-content-center">
                    <TracePopupOver
                      key={`InventUbic${this.props.claUbicacion}`}
                      value={valorInvent}
                      data={this.props.tracedata}
                      tipo={1}
                      idRenglon={idRenglon}
                      claUbicacion={this.props.claUbicacion}
                    ></TracePopupOver>
                  </Col>
                  <Col className="col-3 m-0 d-flex justify-content-center">
                    <TracePopupOver
                      key={`ViajeUbic${this.props.claUbicacion}`}
                      value={valorViaje}
                      data={this.props.tracedata}
                      tipo={3}
                      idRenglon={idRenglon}
                      claUbicacion={this.props.claUbicacion}
                    ></TracePopupOver>
                  </Col>
                  <Col className="col-3 m-0 d-flex justify-content-center">
                    <TracePopupOver
                      key={`TraspUbic${this.props.claUbicacion}`}
                      value={valorTraspaso}
                      data={this.props.tracedata}
                      tipo={2}
                      idRenglon={idRenglon}
                      claUbicacion={this.props.claUbicacion}
                    ></TracePopupOver>
                  </Col>
                  <Col className="col-3 m-0 d-flex justify-content-center">
                    <TracePopupOver
                      key={`TraspUbic${this.props.claUbicacion}`}
                      value={valorProduccion}
                      data={this.props.tracedata}
                      tipo={4}
                      idRenglon={idRenglon}
                      claUbicacion={this.props.claUbicacion}
                      showSegementacion={this.state.showSegementacion}
                    ></TracePopupOver>
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }} className="align-self-start">
                  <Col className="col-3 m-0 d-flex justify-content-center align-self-start">
                    <h5 className="mb-0">Inventario</h5>
                  </Col>
                  <Col className="col-3 m-0 d-flex justify-content-center align-self-start">
                    <h5 className="mb-0">Viajes</h5>
                  </Col>
                  <Col className="col-3 m-0 d-flex justify-content-center align-self-start">
                    <h5 className="mb-0">Traspasos PI:</h5>
                  </Col>
                  <Col className="col-3 mb-0 d-flex justify-content-center text-center">
                    <div className="d-flex justify-content-center">
                      <h5 className="mb-0">Capacidad producción</h5>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <h5 className="mb-0">Planta: {this.props.data.nomUbicacion}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-6">
                    <h5 className="mb-0">Deuda: {this.props.data.deuda}</h5>
                  </Col>
                  <Col className="col-6">
                    <h5 className="mb-0">Deuda Pendiente: {this.props.data.deudaPendiente}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">{fechas}</Col>
                </Row>
                {traspasote}
                {diaComprado}
                {diasDesviacion}
                {competencia}
                {contentPopup}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
