/*eslint-disable*/
import React from 'react';

import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import Loader from 'react-loader-spinner';

import { config } from 'utils/config';
import { callApi, callKrakenApi } from 'utils/utils';

import  CtrlComboBox from 'views/Controles/CtrlComboBox'
import  CtrlDatePicker from 'views/Controles/CtrlDatePicker'
import  FaltantesGrid from './FaltantesGrid'
import  ModalPreferencias from './ModalPreferencias'
import  ModalProduccionMyA from 'views/Produccion/ModalCapacidad/ModalProduccionMyA'


export default class Faltantes extends React.Component {
  constructor(props) {
    super(props);

       this.state = {
        showSpin:false,
        showModal:false,
        showModalCap:false,
        filtros:{claUbicacion:3, fechaCalculo:new Date()},
        dataUbicacion:[],
        data:[[],[]]
    };

    this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    this.onChangeFechaInicio = this.onChangeFechaInicio.bind(this);
    this.onBtnBuscar = this.onBtnBuscar.bind(this);
    this.onClosePopup = this.onClosePopup.bind(this);
    this.onClosePopupCap = this.onClosePopupCap.bind(this);
    this.onClickProducto = this.onClickProducto.bind(this);
    this.onClickCapacidad = this.onClickCapacidad.bind(this);
  }

  getUbicaciones() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/FaltantesUbicaciones`;
    const params={}

    callApi(urlApiService, 'POST', params, (res) => {
      this.setState({ dataUbicacion: res });
    });
  }

  getData(){
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/Faltantes`;

    callApi(urlApiService, 'POST', this.state.filtros, (resp) => {
        this.setState({
            data: resp, showSpin:false
        });
    });
  }

  onChangeCmbUbicacion(selectedItem){
    var newFiltro = this.state.filtros;
    newFiltro.claUbicacion = selectedItem.value;
    
    this.setState({ 
        filtros : newFiltro
    });
  }

  onChangeFechaInicio(selectedItem){
    var filtros = this.state.filtros;
    filtros.fechaCalculo = selectedItem.value;
    
    this.setState({ 
        filtros 
    });
  }
  
  onBtnBuscar(){

    this.setState({showSpin:true},
       ()=>{this.getData();}
    );
  }

  onClosePopup(){
    this.setState({ showModal:false });
  }

  onClosePopupCap(){
    this.setState({ showModalCap:false });
  }

  onClickCapacidad(FechaCalculo,ClaUbicacion, ClaAreaProduccion, ClaGrupoMaquina, ClaArticulo, ClaDireccion, ClaPeriodo){
    this.setState({ 
        showModalCap:true,
        paramServiceCap:{ claUbicacion:ClaUbicacion
                      ,claArea:ClaAreaProduccion
                      ,claGrupoMaquina:ClaGrupoMaquina
                      ,claArticulo:ClaArticulo
                      ,claDireccion: ClaDireccion
                      ,claPeriodo: ClaPeriodo
                      ,fechaCalculo:FechaCalculo
                    }});
  }

  onClickProducto(ClaUbicacion, ClaArticulo, NomArticulo){
    this.setState({ 
        showModal:true,
        paramService:{fechaCalculo:this.state.filtros.fechaCalculo
                      ,claUbicacion:ClaUbicacion
                      ,claArticulo:ClaArticulo
                      ,nomArticulo:NomArticulo}});
  }

  componentDidMount() {
    this.getUbicaciones();
  }

  render(){

    const spinner =
      this.state.showSpin === true ? (
        <Loader type="Puff" color="#00BFFF" height={40} width={40} />
      ) : (<></>);

    return(
      <div className="content mb-5">
        <Row>
          <Col className='col-4' style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Ubicacion"
              valueExpr="clave"
              displayExpr="nombre"
              onChange={this.onChangeCmbUbicacion}
              data={this.state.dataUbicacion}
              value={this.state.filtros.claUbicacion}
            />
          </Col>
          <Col>
            <CtrlDatePicker
              etiqueta="Inicio"
              defaultValue={this.now}
              onChange={this.onChangeFechaInicio}
              value={this.state.filtros.fechaCalculo}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Button type="button" color="warning" onClick={this.onBtnBuscar}>
                Buscar
              </Button>
              {spinner}
            </Row>
          </Col>
        </Row>   
        <Row>
          <Col className="col-12">
            <FaltantesGrid 
                data={this.state.data} 
                onClickProducto={this.onClickProducto}
                onClickCapacidad={this.onClickCapacidad}
                onPage={this.onGridPage} 
                pageIndex={this.state.pageIndex}
                filtros={this.state.filtros}>
            </FaltantesGrid>
          </Col>
        </Row>
        <Row>
            <Col className='col-12'>
              <ModalPreferencias visible={this.state.showModal} onClosePopup={this.onClosePopup} paramService={this.state.paramService}>
              </ModalPreferencias>
            </Col>
        </Row>
        <Row>
          <Col>
            <ModalProduccionMyA visible={this.state.showModalCap} onClosePopup={this.onClosePopupCap} paramService={this.state.paramServiceCap}>
            </ModalProduccionMyA>
          </Col>
        </Row>
      </div>
    )
  };
}
