/*eslint-disable*/
import React from 'react';
import { Autocomplete } from 'devextreme-react/autocomplete';
import { Row, Col } from 'reactstrap';
import { callKrakenApi } from '../../utils/utils';

function CtrlAutoComplete(props) {
  const dataSource = {
    key: props.keyValue,
    keyType: 'Int32',
    perf1: '',
    claUsuario: 0,
    load: async (loadOptions) => {
      let data = [];

      if (loadOptions.searchValue === null) return;

      let params = {};
      if (props.claFamilia === undefined) {
        params = {
          Valor: loadOptions.searchValue,
          Tipo: 3,
          IncluirTodosSN: 0,
        };
      } else {
        params = {
          Valor: loadOptions.searchValue,
          Tipo: 3,
          IncluirTodosSN: 0,
          ClaFamilia: props.claFamilia,
          ClaSubFamilia: props.claSubfamilia,
          Top: 50,
        };
      }
      await callKrakenApi(props.claProducto, props.idEntidad, params, 1, (res) => {
        data = res.Result0;
      });

      return data;
    },
  };

  function onChange(obj) {
    const Valor = '';
    if (obj.component._changedValue === '') {
      const object = { selectedItem: null };
      props.onChange(object);
    }
  }

  return (
    <Row style={{ padding: 0, margin: 0, marginBottom: 1, alignItems: 'center' }} className="mb-1">
      <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
        <Row style={{ height: '24px' }}>
          <Col md={{ size: 12 }}>
            <div className="pl-xl-2 subtitle-1">{props.etiqueta}</div>
          </Col>
        </Row>
        <Row style={{ height: '32px' }}>
          <Col md={{ size: 12 }}>
            <Autocomplete
              ref={props.reference}
              dataSource={props.dataSource === undefined ? dataSource : props.dataSource}
              placeholder={props.placeholder}
              valueExpr={props.valueExpr}
              displayExpr={props.displayExpr}
              showClearButton={true}
              minSearchLength={props.minSearchLength === undefined ? 3 : props.minSearchLength}
              searchTimeout={500}
              onSelectionChanged={props.onChange}
              onInput={onChange}
              disabled={props.disabled}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default CtrlAutoComplete;
