/*eslint-disable*/
import React from 'react';
import AppContext from "../../context/AppContext";

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";

import { config } from '../../utils/config';
import { callApi, callKrakenApi } from '../../utils/utils';

import ConfiguracionCatalogos from './ConfiguracionCatalogo.js';
import Template1 from './Template1/Template1';
import Template2 from './Template2/Template2';
import Template3 from './Template3/Template3';
import Template4 from './Template4/Template4';
import Template5 from './Template5/Template5';
import Template6 from './Template6/Template6';
import Template7 from './Template7/Template7';
import Template8 from './Template8/Template8';
import Template9 from './Template9/Template9';
import Template_UbiProdAceLote from './Template_UbiProdAceLote/Template_UbiProdAceLote';
import Template_UbiCalAceLote from './Template_UbiCalAceLote/Template_UbiCalAceLote';

export default class Coniguraciones extends React.Component {
    constructor(props) {
        super(props);

        locale('en');

        this.state = {
            configCatData: [],
            configData: [],
            configuracionValue: null,
        }


        this.onChangeConfiguracion = this.onChangeConfiguracion.bind(this);
        this.getConfiguracionesCatalogos = this.getConfiguracionesCatalogos.bind(this);
       
        this.refCmbConfiguracion = React.createRef();
    }

    getConfiguracionesCatalogos(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/CatalogosConfiguracion`;
        
        callApi(urlApiService, 'POST', null , (result) => {
            this.setState({
                configCatData: result
            });
        });
    }

    onChangeConfiguracion = selectedItem =>{
        var selectedCat = this.state.configCatData[this.state.configCatData.findIndex((x) => x.Clave == selectedItem.value)]
        
        this.setState({
            configuracionValue: selectedItem.value,
            selectedTemplate: selectedCat.ClaTemplate,
        })
    }

    componentDidMount(){
        this.getConfiguracionesCatalogos();
    }

    render(){
        var content = null;

        if(this.state.selectedTemplate == 1)
        {
            content = (
                <Col>
                    <Template1
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>   
            );
        }
        else if(this.state.selectedTemplate == 2){

            content = (
                <Col>
                    <Template2
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 3){
            content = (
                <Col>
                    <Template3
                        configuracionValue={this.state.configuracionValue}
                        template={this.state.selectedTemplate}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 4){
            content = (
                <Col>
                    <Template4
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 5){
            content = (
                <Col>
                    <Template5
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 6){
            content = (
                <Col>
                    <Template6
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 7){
            content = (
                <Col>
                    <Template7
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 8){
            content = (
                <Col>
                    <Template8
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        else if(this.state.selectedTemplate == 9){
            content = (
                <Col>
                    <Template9
                        configuracionValue={this.state.configuracionValue}
                    />
                </Col>
            );
        }
        // else if(this.state.selectedTemplate == 10){
        //     content = (
        //         <Col>
        //             <Template_UbiProdAceLote
        //                 configuracionValue={this.state.configuracionValue}
        //             />
        //         </Col>
        //     );
        // }
        // else if(this.state.selectedTemplate == 11){
        //     content = (
        //         <Col>
        //             <Template_UbiCalAceLote
        //                 configuracionValue={this.state.configuracionValue}
        //             />
        //         </Col>
        //     );
        // }

        return(
            <div className="content">
                <Row>
                    <Col>
                        <ConfiguracionCatalogos 
                            onChangeConfiguracion={this.onChangeConfiguracion}
                            configData={this.state.configCatData}
                            configuracion={this.state.configuracionValue}
                            refCmbConfiguracion={this.refCmbConfiguracion}
                        />
                    </Col>
                </Row>         
                <Row>
                    {content}              
                </Row>
            </div>
        )
    };
}