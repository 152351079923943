/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DataGrid, {
  MasterDetail,
  Column,
  Paging,
  Format,
  Export,
  Scrolling,
} from 'devextreme-react/data-grid';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';
import AceriasMasterDetail from './AceriasMasterDetail';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';
import { isNumber } from 'highcharts';

export default class CapacidadGrid extends React.Component {
  constructor(props) {
    super(props);

    this.dataGrid = null;

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.onExporting = this.onExporting.bind(this);
  }

  getDetalle() {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaAceria`;
    /*export de excel */
    callApi(urlApiService, 'POST', this.props.filtros, (response) => {
      this.Exporting(response[3]);
    });
  }

  Exporting(detalle) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');
    const grid = this.dataGrid.instance;
    const masterRows = [];
    const items = [];

    worksheet.columns = [
      { width: 20 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ];
    exportDataGrid({
      component: grid,
      worksheet,
      keepColumnWidths: false,
      topLeftCell: { row: 1, column: 1 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'data') {
          // Valida que no se repita la llave
          if (items.indexOf(gridCell.data.IdProduccion) < 0) {
            items.push(gridCell.data.IdProduccion);
            masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
          }
        }

        // Formato a la celdas si es Group Row
        /* if (gridCell.rowType === 'group') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
            }
            */

        // Formato a la celda de Datos
        if (gridCell.rowType === 'data') {
          excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
          excelCell.font = { bold: true };
        }

        // Formato a la celdas si es Total Footer Row
        if (gridCell.rowType === 'totalFooter' && excelCell.value) {
          excelCell.font.italic = true;
        }
      },
    })
      .then((cellRange) => {
        // GENERAR UNA SECCION DE DETALLE

        const encabezados = [
          'Id Producción',
          'IdProducciónDet',
          'ClaDireccion',
          'NombreDireccion',
          'IdRenglon',
          'Pedido',
          'Artículo',
          'Kilos Disponibles',
          'Kilos Reservados',
        ];
        const columns = [
          'IdProduccion',
          'IdProduccionDet',
          'ClaDireccion',
          'NombreDireccion',
          'Porcentaje',
          'IdRenglon',
          'ClaPedido',
          'NomArticulo',
          'KilosDisponibles',
          'KilosReservados',
        ];
        const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
        const columnIndex = cellRange.from.column;

        const insertRow = function (index, offset, outlineLevel) {
          const currentIndex = index + offset;
          const row = worksheet.insertRow(currentIndex, [], 'n');

          for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
            worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
          }

          row.outlineLevel = outlineLevel;
          return row;
        };

        let totalOffset = 0;

        for (let i = 0; i < masterRows.length; i++) {
          let offset = 0;
          offset = totalOffset;

          // ** Cambio: Llave encabezado
          // const { ClaArticulo } = masterRows[i].data;
          const { IdProduccion } = masterRows[i].data;
          // Agrega un Row con los Encabezados del Detalle...
          const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
          encabezados.forEach((header, currentColumnIndex) => {
            Object.assign(row.getCell(columnIndex + currentColumnIndex), {
              value: header,
              fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } },
              font: { bold: true },
              border: {
                bottom: borderStyle,
                left: borderStyle,
                right: borderStyle,
                top: borderStyle,
              },
              alignment: { horizontal: 'center' },
            });
          });

          // ** Cambio : Filtro Detalle
          // const pedidoPartidas = detalle.filter((partida) => partida.ClaProductoOrig == ClaArticulo);
          const pedidoPartidas = detalle.filter((partida) => partida.IdProduccion == IdProduccion);
          pedidoPartidas.forEach((partida) => {
            const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

            columns.forEach((columnName, currentColumnIndex) => {
              const alinear =
                currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';
              // Imprime cada celda del detalle..
              Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                value: partida[columnName],
                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F9FE' } },
                border: {
                  bottom: borderStyle,
                  left: borderStyle,
                  right: borderStyle,
                  top: borderStyle,
                },
                alignment: { horizontal: alinear },
              });
            });
          });
          offset--;
          totalOffset = offset;
        }
      })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Producción.xlsx');
        });
      });
    grid.cancel = true;
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.props.onPage(e.value);
    }
  }

  onExporting(e) {
    this.getDetalle();
    e.cancel = true;
  }

  componentDidMount() {}

  render() {
    const gridContent = (
      <Row>
        <Col>
          <DataGrid
            id="gridProduccion"
            ref={(ref) => (this.dataGrid = ref)}
            dataSource={this.props.dataSource}
            key={'idProduccion'}
            width="100%"
            height={600}
            selection={{ mode: 'single' }}
            showBorders={false}
            columnHidingEnabled
            columnAutoWidth={false}
            showColumnHeaders
            showColumnLines={false}
            showRowLines
            noDataText="Sin Registros"
            wordWrapEnabled
            rowAlternationEnabled={false}
            onOptionChanged={this.handleOptionChange}
            onExporting={this.onExporting}
          >
            <MasterDetail
              enabled={true}
              component={AceriasMasterDetail}
              filtros={this.props.filtros}
              showSegementacion={1}
            />

            <Column
              dataField="FechaPlanCampana"
              caption="Fecha Campaña"
              width={70}
              dataType={'date'}
              format={'dd/MM/yyyy'}
            ></Column>
            <Column dataField="IdProduccion" caption="Id" width={50} visible={false} />
            {
              <Column
                dataField="IdProduccionDet"
                caption="IdProduccionDet"
                width={50}
                visible={false}
              />
            }
            <Column dataField="NomUbicacion" caption="Ubicación" width={100} />
            <Column dataField="ClaDireccion" caption="ClaDireccion" width={100} visible={false} />
            <Column
              dataField="NombreDireccion"
              caption="Dirección"
              width={100}
              visible={this.props.showSegementacion}
            />
            <Column
              dataField="Porcentaje"
              caption="Porcentaje"
              width={50}
              format="#.##'%'"
              visible={this.props.showSegementacion}
            />
            <Column
              dataField="KilosDisponibles"
              caption="Kgs Disponibles"
              width={100}
              dataType="string"
              format="#,###.##"
            />
            <Column
              dataField="KilosPlanProduccion"
              caption="Plan Producción Kgs"
              width={100}
              dataType="string"
              format="#,###.##"
            />
            <Column
              dataField="KilosReservados"
              caption="Kgs Reservados"
              width={100}
              dataType="string"
              format="#,###.##"
            />

            <Column dataField="NomGrupoProduccion" caption="Gpo Producción" width={100} />
            <Column dataField="NomCalibre" caption="Calibre" width={60} />
            <Paging pageSize={30} pageIndex={this.props.pageIndex} />

            <Scrolling columnRenderingMode="virtual" />

            <Export enabled={true} allowExportSelectedData={false} />
          </DataGrid>
        </Col>
      </Row>
    );
    return <>{gridContent}</>;
  }
}
