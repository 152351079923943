/*eslint-disable*/
import React from 'react';
import DataGrid, { Paging, Column, Summary, TotalItem, HeaderFilter, FilterRow, GroupPanel, Grouping, Export } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { config } from 'utils/config';
import { callApi, callKrakenApi } from 'utils/utils';

export default class FaltantesGrid extends React.Component {
    constructor(props) {
        super(props);
    
        this.applyFilterTypes = [{key: 'auto',name: 'Immediately'}
                                ,{key: 'onClick',name: 'On Button Click'}];
    
        this.dataGrid = null;

        this.state = {
          showFilterRow: true,
          showHeaderFilter: true,
          currentFilter: this.applyFilterTypes[0].key,
          autoExpandAll: false,
        };
    
    
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.onRowPrepared = this.onRowPrepared.bind(this);
        this.onCellPrepared = this.onCellPrepared.bind(this);
        this.cellRenderLnkComp = this.cellRenderLnkComp.bind(this);
        this.cellRenderLnkCap = this.cellRenderLnkCap.bind(this);
        
        this.onClickProducto = this.onClickProducto.bind(this);
        this.onClickCapacidad = this.onClickCapacidad.bind(this);
        
        this.onExporting= this.onExporting.bind(this);
    }

    getDetalle() {
      const urlService = `${process.env.REACT_APP_URL_API}Produccion/FaltantesDet`;
  
      const { filtros } = this.props;
  
      const params = {
        FechaCalculo: filtros.fechaCalculo,
        ClaUbicacion: filtros.claUbicacion,
        ClaArticulo: null
      };
  
      callApi(urlService, 'POST', params, (response) => {
        this.Exporting(response[0])
      });
    }

    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndex') {
          this.props.onPage(e.value);
        }
    }

    onRowPrepared(e){

      if (e.rowType == 'data'){
        // e.rowElement.classList.add('rowSelected');  
      }
    }

    onCellPrepared(e){

      if (e.column.type == 'detailExpand' || e.column.type == 'groupExpand'){
         e.column.width=30;
      }
    }

    cellRenderLnkComp(cellData) {
      return (
        <div
          className="link"
          onClick={this.onClickProducto.bind(this, cellData.data)}
        >
          Ver
        </div>
      );
    }

    cellRenderLnkCap(cellData) {
      return (
        <div
          className="link"
          onClick={this.onClickCapacidad.bind(this, cellData.data)}
        >
          Ver
        </div>
      );
    }

    onClickProducto(data) {
      const {ClaUbicacion, ClaArticulo, NomArticulo }= data;
      this.props.onClickProducto(ClaUbicacion, ClaArticulo, NomArticulo);
    }

    onClickCapacidad(data) {
      const {FechaCalculo, ClaUbicacion, ClaAreaProduccion, ClaGrupoMaquina, ClaArticulo, ClaDireccion, ClaPeriodo }= data;
      this.props.onClickCapacidad(FechaCalculo, ClaUbicacion, ClaAreaProduccion, ClaGrupoMaquina, ClaArticulo, ClaDireccion, ClaPeriodo);
    }

    onExporting(e) {
      this.getDetalle();
      e.cancel = true;
    }

    /* Exporting(datos) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      const e = this.dataGrid.instance;
      const masterRows = [];
  
      exportDataGrid({
        component: e,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PedidosKayak.xlsx');
        });
      });
      e.cancel = true;
    }
*/
    Exporting(detalle) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Main sheet');
      const grid = this.dataGrid.instance;
      const masterRows = [];
      const items = [];
  
      worksheet.columns = [
        { width: 80 }, { width: 15 }, { width: 20 }, { width: 60 }, { width: 25 }, { width: 40 }, { width: 15 }, { width: 25 }, { width: 80 }
      ];
      exportDataGrid({
        component: grid,
        worksheet,
        keepColumnWidths: false,
        topLeftCell: { row: 1, column: 1 },
        customizeCell: ({ gridCell, excelCell }) => {
          if (gridCell.rowType === 'data') {
            // Valida que no se repita la llave
            if(items.indexOf(gridCell.data.ClaArticulo)<0){
              items.push(gridCell.data.ClaArticulo);
              masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
            }
          }
  
          // Formato a la celdas si es Group Row
          /* if (gridCell.rowType === 'group') {
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
          }
          */

          // Formato a la celda de Datos
          if (gridCell.rowType === 'data') {
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
            excelCell.font =  { bold: true };
          }

          // Formato a la celdas si es Total Footer Row
          if (gridCell.rowType === 'totalFooter' && excelCell.value) {
            excelCell.font.italic = true;
          }
        }
  
      }).then((cellRange) => {
        // GENERAR UNA SECCION DE DETALLE
        const encabezados = ["Componente", "Nivel", "Composicion", "Area Produccion", "Grupo Maquina", "Preferencia"];
        const columns = ["NomProducto", "Nivel", "PorcComposicion","NomAreaProduccion", "NomGrupoMaquina", "Preferencia"];
        const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
        const columnIndex = cellRange.from.column;
  
        const insertRow = function (index, offset, outlineLevel) {
          const currentIndex = index + offset;
          const row = worksheet.insertRow(currentIndex, [], 'n');
  
          for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
            worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
          }
  
          row.outlineLevel = outlineLevel;
          return row;
        }
  
        let totalOffset = 0;
  
        for (let i = 0; i < masterRows.length; i++) {
          let offset = 0;
          offset = totalOffset;
  
          // ** Cambio: Llave encabezado
          const { ClaArticulo } = masterRows[i].data;
          // Agrega un Row con los Encabezados del Detalle...
          const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
          encabezados.forEach((header, currentColumnIndex) => {
            Object.assign(row.getCell(columnIndex + currentColumnIndex), {
              value: header,
              fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } },
              font: { bold: true },
              border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
              alignment: { horizontal: 'center' }
            });
          });
  
          // ** Cambio : Filtro Detalle
          const pedidoPartidas = detalle.filter((partida) => partida.ClaProductoOrig == ClaArticulo);
          pedidoPartidas.forEach((partida) => {
            const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
  
            columns.forEach((columnName, currentColumnIndex) => {
              const alinear = currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';
                // Imprime cada celda del detalle..
                Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                  value: partida[columnName],
                  fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F9FE' } },
                  border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                  alignment: { horizontal: alinear }
                });
            });
          });
          offset--;
          totalOffset = offset;
        }
  
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PrefProduccion.xlsx');
        });
      });
      grid.cancel = true;
    }
  

    componentDidMount() {
    }

    render() {
      return (
        <DataGrid
          id="gridFaltante"
          ref={(ref) => this.dataGrid = ref}
          dataSource={this.props.data[0]}
          showBorders={true}
          width="100%"
          height={600}
          columnHidingEnabled
          columnAutoWidth={true}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onRowPrepared={this.onRowPrepared}
          onCellPrepared={this.onCellPrepared}
          onExporting={this.onExporting}
          >

          <FilterRow
            visible={this.state.showFilterRow}
            applyFilter={this.state.currentFilter}
          />
          <HeaderFilter visible={this.state.showHeaderFilter} />
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={this.state.autoExpandAll} />

          <Column dataField="Estatus" caption="Estatus" width={180} groupIndex={0}/>
          <Column dataField="NomArticulo" caption="Producto" width={550} />
          <Column dataField="ClaEstatus" caption="Composicion" width={90}  cellRender={this.cellRenderLnkComp}/>
          <Column dataField="ClaEstatus" caption="Capacidad" width={90}  cellRender={this.cellRenderLnkCap}/>
          
          <Column dataField="ClaPedido" caption="Pedido" width={90} alignment={"left"}/>
          <Column dataField="ClaPeriodo" caption="Periodo" width={90} />
          <Column dataField="NomAreaProduccion" caption="Area Produccion"  groupIndex={0}/>
          <Column dataField="NomGrupoMaquina" caption="Grupo Maquina"  groupIndex={0}/>
          <Column dataField="NomDireccion" caption="Dirección"/>
          
          <Column dataField="ClaArticulo" caption="ClaArticulo" visible={false} />
          <Column dataField="ClaUbicacion" caption="Ubicacion" visible={false} />

          <Paging enabled={false} />

          <Summary>
            <TotalItem column="ClaPedido" summaryType="count" displayFormat="Total: {0}" />
          </Summary>

          <Export enabled={true} allowExportSelectedData={false} />
        </DataGrid>
      );

    }
}
