/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import CtrlComboBox from '../../../Controles/CtrlComboBox';
import CtrlDatePicker from '../../../Controles/CtrlDatePicker';
import CtrlNumerico from '../../../Controles/CtrlNumerico';

export default class FiltroFechas extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataTipoFecha: [
                { Clave: 0, Nombre: "Pedidos Activos"},
                { Clave: 1, Nombre: "Fecha Pedido" }, 
                // { Clave: 2, Nombre: "Fecha Estimada" }, 
                // { Clave: 3, Nombre: "Fecha Recibe" },
            ],
          };
    }

    render() {
        return (
            <>
                <Row>
                    <Col md={{ size: 12 }}>
                        <CtrlNumerico
                        etiqueta="Número Pedido"
                        onChange={this.props.onChangeNumPedido}
                        value={this.props.filtros.claPedido}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <CtrlComboBox
                            etiqueta="Filtrar por Fecha"
                            onChange={this.props.onChangeCmbTipoFecha}
                            data={this.state.dataTipoFecha}
                            value={this.props.filtros.selectedTipoFecha}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
                        <Row>
                            <Col>
                                <CtrlDatePicker
                                disabled={this.props.disabled}
                                etiqueta="Inicio"
                                defaultValue={this.now}
                                onChange={this.props.onChangeFechaInicio}
                                value={this.props.filtros.fechaInicio}
                                />
                            </Col>
                            <Col>
                                <CtrlDatePicker
                                disabled={this.props.disabled}
                                etiqueta="Fin"
                                defaultValue={this.now}
                                onChange={this.props.onChangeFechaFin}
                                value={this.props.filtros.fechaFin}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}