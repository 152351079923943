/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Popup, Position, Offset } from 'devextreme-react/popup';
import FactorConversionGrid from './FactorConversionGrid';
import 'assets/css/popup.css';
import { Row, Col } from 'reactstrap';
import { config } from 'utils/config';
import { callApi } from 'utils/utils';

export default class FactorConversionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataFactorConversion: [],
      positionOf: '',
    };

    this.target = window;
  }

  getFactorConversionData = () => {
    const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaFactor`;
    const { claUbicacion, claArea, claGrupoMaquina, claProducto, fechaCalculo } =
      this.props.paramService;

    const filtros = {
      fechaCalculo,
      claUbicacion,
      claArea,
      claGrupoMaquina,
      claProducto,
      periodoInicio: null,
      periodoFin: null,
    };

    this.setState({ showSpin: true });

    callApi(urlApiService, 'POST', filtros, (result) => {
      console.log(result);
      this.setState({
        dataFactorConversion: result,
        showSpin: false,
      });
    });
  };

  componentDidMount() {
    this.getFactorConversionData();
  }

  render() {
    const titulo = `Factor de Conversion`;

    return (
      <Popup
        visible={this.props.visible}
        resizeEnabled={false}
        closeOnOutsideClick={true}
        onHiding={this.props.onClosePopup}
        showCloseButton={true}
        showTitle={true}
        title={titulo}
        container=".dx-viewport"
        height={350}
        dragEnabled={true}
      >
        <div>
          <FactorConversionGrid
            data={this.state.dataFactorConversion}
            esModal="true"
          ></FactorConversionGrid>
        </div>
      </Popup>
    );
  }
}
