/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Paging, Format, Export, Scrolling, FilterRow, HeaderFilter } from 'devextreme-react/data-grid';
import { config } from '../../../../utils/config';
import { callApi, callKrakenApi } from '../../../../utils/utils';
import PedidoMasterDetail from './PedidosMasterDetail';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import ExcelJS from 'exceljs';

export default class PedidosGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;
    
        this.state = {
        };     

        this.onExporting= this.onExporting.bind(this);
    }

    getDetalle() {
        const urlService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaPedido`;
        
        var newFiltro = this.props.filtros;
        newFiltro.modoVista = 5;

        callApi(urlService, 'POST', newFiltro, (response) => {
            this.Exporting(response[0])
        });
    }

    onExporting(e) {
        this.getDetalle();
        e.cancel = true;
    }

    Exporting(detalle) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');
        const grid = this.dataGrid.instance;
        const masterRows = [];
        const items = [];
    
        worksheet.columns = [
          { width: 20 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }
        ];
        exportDataGrid({
            component: grid,
            worksheet,
            keepColumnWidths: false,
            topLeftCell: { row: 1, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'data') {
                // Valida que no se repita la llave
                if(items.indexOf(gridCell.data.claPedido)<0){
                    items.push(gridCell.data.claPedido);
                    masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
                }
            }

            // Formato a la celdas si es Group Row
            /* if (gridCell.rowType === 'group') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
            }
            */

            // Formato a la celda de Datos
            if (gridCell.rowType === 'data') {
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F3F2F1' } };
                excelCell.font =  { bold: true };
            }

            // Formato a la celdas si es Total Footer Row
            if (gridCell.rowType === 'totalFooter' && excelCell.value) {
                excelCell.font.italic = true;
            }
            }
    
        })
        .then((cellRange) => {
          // GENERAR UNA SECCION DE DETALLE
            const encabezados = [
                // "Fecha Cálculo",                
                "Deuda Original Composicion",
                "Capacidad Disponible",
                "Horas Reservados",
                "Horas Disponibles",               
                "Deuda Pendiente",
                "Pedido",
                "Renglón",
                "Nivel Composicion",                
                "Área Producción",
                "Gpo Máquinas",
                "Tipo Producto",
                "Producto",
                "Articulo Comp",
                "Periodo",
            ];
            const columns = [
                // "fechaCalculo",
                "deudaOriginalComposicion",
                "capacidadDisponible",
                "horasReservados",
                "horasDisponibles",    
                "deudaPendiente",
                "claPedido",
                "idRenglon",
                "nivelComposicion",                
                "NomAreaProduccion",
                "NomGrupoMaquina",
                "tipoProducto",
                "claProductoAto",
                "claArticuloComp",
                "claPeriodo"
                
            ];
            const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            const columnIndex = cellRange.from.column;

            const insertRow = function (index, offset, outlineLevel) {
                const currentIndex = index + offset;
                const row = worksheet.insertRow(currentIndex, [], 'n');

                for (let j = worksheet.rowCount + 1; j > currentIndex; j--) {
                    worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
                }

                row.outlineLevel = outlineLevel;
                return row;
            }

            let totalOffset = 0;

            for (let i = 0; i < masterRows.length; i++) {
                let offset = 0;
                offset = totalOffset;

                // ** Cambio: Llave encabezado
                // const { ClaArticulo } = masterRows[i].data;
                const { fechaCalculo, claPedido } = masterRows[i].data;
                // Agrega un Row con los Encabezados del Detalle...
                const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                encabezados.forEach((header, currentColumnIndex) => {
                    Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                    value: header,
                    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E6E6E6' } },
                    font: { bold: true },
                    border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                    alignment: { horizontal: 'center' }
                    });
                });

                // ** Cambio : Filtro Detalle
                // const pedidoPartidas = detalle.filter((partida) => partida.ClaProductoOrig == ClaArticulo);
                const pedidoPartidas = detalle.filter((partida) => partida.claPedido == claPedido);
                pedidoPartidas.forEach((partida) => {
                    const row = insertRow(masterRows[i].rowIndex + i, offset++, 2);

                    columns.forEach((columnName, currentColumnIndex) => {
                    const alinear = currentColumnIndex === 1 || currentColumnIndex === 6 ? 'left' : 'center';
                        // Imprime cada celda del detalle..
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                        value: partida[columnName],
                        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'F8F9FE' } },
                        border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle },
                        alignment: { horizontal: alinear }
                        });
                    });
                });
                offset--;
                totalOffset = offset;
            }
    
        })
        .then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ProduccionPedidos.xlsx');
            });
        });
        grid.cancel = true;
    }

    render() {
        const renderContratos = this.props.produccionModo == 'Vista Contratos' ? false :  true
        const renderColumnaContratos = this.props.produccionModo == 'Vista Contratos' ? "Contratos" : "Pedido"
        const gridContent = (
                <DataGrid
                    id="gridProduccionPedidos"
                    ref={(ref) => this.dataGrid = ref}
                    dataSource={this.props.data}
                    width="100%"
                    height={600}
                    selection={{ mode: 'single' }}
                    showBorders={false}
                    columnAutoWidth={false}
                    showColumnHeaders
                    showColumnLines={false}
                    showRowLines
                    noDataText="Sin Registros"
                    wordWrapEnabled
                    rowAlternationEnabled={false}
                    onOptionChanged={this.handleOptionChange}
                    onExporting={this.onExporting}
                >
                
                <MasterDetail 
                    enabled={true} 
                    component={PedidoMasterDetail} 
                />

                <FilterRow
                    visible={true}
                    applyFilter={true}
                />
                <HeaderFilter visible={true} /> 

                <Column dataField="fechaCalculo" caption="Fecha Calculo" width={60}
                    dataType={"date"} 
                    format={"dd/MM/yyyy"}
                >
                </Column>
                <Column dataField="claPedido" caption={renderColumnaContratos} width={70} alignment="left"/>
                <Column dataField="claUbicacion" caption="Ubicación" width={100} />
                <Column dataField="nomCliente" caption="Cliente" width={100}/>
                <Column dataField="IdRenglon" caption="Renglón" width={70} alignment="left"/>
                <Column dataField="fechaEmbarque" caption="Fecha Embarque" width={100}
                    dataType={"date"} 
                    format={"shortDate"}
                >
                </Column>
                <Column dataField="fechaEntrega" caption="Fecha Entrega" width={100}
                    dataType={"date"} 
                    format={"shortDate"}
                    visible={renderContratos}
                >
                </Column>
                <Column dataField="modoVista" caption="modovista" width={100} dataType="number" visible={false}/>
                <Paging
                    pageSize={30}
                    pageIndex={this.props.pageIndex}
                />
                
                <Export enabled={true} allowExportSelectedData={false} />
                <Scrolling columnRenderingMode="virtual" />
            </DataGrid>
        );

        return (
        <>
            {gridContent}
        </>
        );
    }
}