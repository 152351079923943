import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import CtrlComboBox from '../../Controles/CtrlComboBox';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class FiltroOrganizacion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataDireccion: [],
      dataSubdireccion: [],
      dataZona: [],
      dataAgente: [],
      dataUbicaciones: [],
    };

    this.dataLoad = this.dataLoad.bind(this);
    this.onChangeCmbDireccion = this.onChangeCmbDireccion.bind(this);
    this.onChangeCmbSubdireccion = this.onChangeCmbSubdireccion.bind(this);
    this.onChangeCmbZona = this.onChangeCmbZona.bind(this);
  }

  getDireccion() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 2,
      IncluirTodosSN: 0,
      ClaNivel: 20,
    };

    callKrakenApi(23, 18, params, 1, (res) => {
      const data = res.Result0;
      if (this.props.claSubdireccion !== -1)
        this.getSubdireccion(this.props.claDireccion);

      this.dataLoad(data, this.props.data.subdireccion, this.props.data.zona, this.props.data.agente, this.props.data.planta);
      // this.setState({ dataDireccion: data });
    });
  }

  getSubdireccion(claDireccion) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 2,
      IncluirTodosSN: 0,
      ClaNivel: 30,
      ClaDireccion: claDireccion,
    };

    callKrakenApi(23, 18, params, 1, (res) => {
      const data = res.Result0;
      if (this.props.claZona !== -1)
        this.getZonas(this.props.claDireccion, this.props.claSubdireccion);

      this.dataLoad(this.props.data.direccion, res.Result0, this.props.data.zona, this.props.data.agente, this.props.data.planta);
      // this.setState({ dataSubdireccion: res.Result0 });
    });
  }

  getZonas(claDireccion, claSubdireccion) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      ModoSel: 2,
      IncluirTodosSN: 0,
      ClaNivel: 50,
      ClaDireccion: claDireccion,
      ClaSubDireccion: claSubdireccion,
    };

    callKrakenApi(23, 18, params, 1, (res) => {
      const data = res.Result0;
      if (this.props.claAgente !== -1)
        this.getAgentes(this.props.claZona);

      // this.setState({ dataZona: res.Result0 });
      this.dataLoad(this.props.data.direccion, this.props.data.subdireccion, res.Result0, this.props.data.agente, this.props.data.planta);
    });
  }

  getAgentes(claZona) {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
      ClaZona: claZona,
    };

    callKrakenApi(23, 16, params, 1, (res) => {
      // this.setState({ dataAgente: res.Result0 });
      this.dataLoad(this.props.data.direccion, this.props.data.subdireccion, this.props.data.zona, res.Result0, this.props.data.planta);
    });
  }

  getUbicaciones() {
    // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
    const params = {
      Valor: '',
      Tipo: 1,
      IncluirTodosSN: 0,
    };

    callKrakenApi(8, 258, params, 1, (res) => {
      // this.setState({ dataUbicaciones: res.Result0 });
      this.dataLoad(this.props.data.direccion, this.props.data.subdireccion, this.props.data.zona, this.props.data.agente, res.Result0);
    });
  }

  dataLoad(direccion, subdireccion, zona, agente, planta) {

    const dataOrganizacion = {
      direccion,
      subdireccion,
      zona,
      agente,
      planta
    };

    this.props.onData(dataOrganizacion);
  }

  onChangeCmbDireccion(selectedItem) {
    const claDireccion = selectedItem.value != null ? selectedItem.value : -1;

    if (claDireccion !== -1) {
      this.getSubdireccion(claDireccion);
    }

    this.props.onChangeCmbDireccion(claDireccion);
  }

  onChangeCmbSubdireccion(selectedItem) {
    const claSubdireccion = selectedItem.value != null ? selectedItem.value : -1;
    this.getZonas(this.props.claDireccion, claSubdireccion);

    this.props.onChangeCmbSubdireccion(claSubdireccion);
  }

  onChangeCmbZona(selectedItem) {
    const claZona = selectedItem.value != null ? selectedItem.value : -1;
    this.getAgentes(claZona);

    this.props.onChangeCmbZona(claZona);
  }


  componentDidMount() {
    this.getDireccion();
    this.getUbicaciones();
  }

  render() {
    return (
      <>
        <Row style={{ marginleft: 0, marginright: 0 }}>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Dirección"
              valueExpr="ClaOrganizacion"
              displayExpr="NomOrganizacion"
              onChange={this.onChangeCmbDireccion}
              data={this.props.data.direccion}
              value={this.props.claDireccion}
              reference={this.props.refCmbDireccion}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Subdirección"
              valueExpr="ClaOrganizacion"
              displayExpr="NomOrganizacion"
              onChange={this.onChangeCmbSubdireccion}
              data={this.props.data.subdireccion}
              value={this.props.claSubdireccion}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Zona"
              valueExpr="ClaOrganizacion"
              displayExpr="NomOrganizacion"
              onChange={this.onChangeCmbZona}
              data={this.props.data.zona}
              value={this.props.claZona}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Agente"
              valueExpr="ClaAgente"
              displayExpr="NomAgente"
              onChange={this.props.onChangeCmbAgente}
              data={this.props.data.agente}
              value={this.props.claAgente}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }} style={{ padding: 0, margin: 0 }}>
            <CtrlComboBox
              etiqueta="Planta Asignada"
              onChange={this.props.onChangeCmbPlanta}
              data={this.props.data.planta}
              value={this.props.claPlanta}
              reference={this.props.refCmbPlanta}
            />
          </Col>
        </Row>
      </>
    );
  }
}
