import React from 'react';
import { CheckBox } from 'devextreme-react/check-box';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import { Popup } from 'devextreme-react/popup';
import HistorialReclaculos from './HistorialRecalculos';
import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class InfoPedido extends React.Component {
  constructor(props) {
    super(props);

    this.state = {      
      popupVisible: false,
      historialData: []
    };

    this.getHistorialRecalculos = this.getHistorialRecalculos.bind(this);
    this.onBack = this.onBack.bind(this);
    this.hidePopUp = this.hidePopUp.bind(this);
    this.handler = this.handler.bind(this);
    this.onOpenHistorial = this.onOpenHistorial.bind(this);
    this.onOpenPedidoMaster = this.onOpenPedidoMaster.bind(this);
  }

  getHistorialRecalculos(){
    const body = {
      ClaPedido: this.props.claPedido,
      IdRenglon: 0,
      FechaCalculo: ''
    }

    const urlApiService = `${process.env.REACT_APP_URL_API}Pedido/GetHistorialRecalculos`;
    callApi(urlApiService, 'POST', body, (res) => {
        this.setState({
          historialData: res
        })
    });
  }

  handler(val) {
    this.setState({
        popupVisible: val,
    });
  }

  hidePopUp() {
      this.setState({
          popupVisible: false,
      });
  }

  onBack() {
    this.props.onBack();
  }

  onOpenHistorial(){
    this.getHistorialRecalculos();
    this.handler(true);
  }

  onOpenPedidoMaster(){
    this.props.toggleReleaseState();
    // this.props.releaseState = true
  }

  componentDidMount() {
  }

  render() {

    const fechaDesea = this.props.data.esFechaDesea === 1 ? <h4><b>Fecha embarque influenciada por Fecha Desea !.</b></h4> : <></>;
    const popupTittle = `Historial de Recálculos Pedido: ${this.props.claPedido}`
    const botonRelease = this.props.data.claPedidoOriginal !== 0 && this.props.data.claPedidoOriginal !== null ? (       
      <Col md={{ size: 3 }} style={{ margin: 0 }}>
        <i className="fas fa-search-plus" onClick={this.onOpenPedidoMaster} style={{ cursor: "pointer" }}>Pedido Master</i>
        {/* <Button type="button" className="btn btn-secundary" onClick={this.onOpenPedidoMaster}>
          Pedido Master
        </Button> */}
      </Col>
    ) :
    (
      <></>
    )
    return (
      <>
        <Row>
          <Col md={{ size: 12 }}>
            <Card>
              <CardHeader>
                <CardTitle>PEDIDO</CardTitle>
              </CardHeader>
              <CardBody>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 3 }} style={{ margin: 0 }}>
                    <h5>Pedido: {this.props.claPedido}</h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>Cliente: {this.props.data.nomCliente}</h5>
                  </Col>
                  <Col md={{ size: 1 }} style={{ margin: 0 }}>
                    <Row>
                      <CheckBox value={this.props.data.esAceptaPedidoAntes === 1} disabled={true} />
                      <span className="ml-2 mt-2"><h5>Acepta Antes</h5></span>
                    </Row>
                  </Col>
                  <Col md={{ size: 1 }} style={{ margin: 0 }}>
                    <Row>
                      <CheckBox value={this.props.data.esAceptaParcialidad > 0} disabled={true} />
                      <span className="ml-2 mt-2"><h5>Acepta Parcial</h5></span>
                    </Row>
                  </Col>
                  <Col md={{ size: 1 }} style={{ margin: 0 }}>
                    <Row>
                      <CheckBox value={this.props.data.esFranquicia > 0} disabled={true} />
                      <span className="ml-2 mt-2"><h5>Franquicia</h5></span>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ size: 3 }} style={{ margin: 0 }}>
                    <h5>Fecha Pedido: {this.props.data.fechaCaptura}</h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>Fecha Desea: {this.props.data.fechaDeseada}</h5>
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 3 }} style={{ margin: 0 }}>
                    <h5>Dirección: {this.props.data.direccion}</h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>Subdirección: {this.props.data.subDireccion}</h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>Zona: {this.props.data.zona}</h5>
                  </Col>
                </Row>
                <Row style={{ marginleft: 0, marginright: 0 }}>
                  <Col md={{ size: 3 }} style={{ margin: 0 }}>
                    <h5>Bussines Unit: {this.props.data.businessUnit}</h5>
                  </Col>
                  <Col md={{ size: 4 }} style={{ margin: 0 }}>
                    <h5>Modelo de Atención: {this.props.data.modeloAtencion}</h5>
                  </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col md={{ size: 3 }} style={{ margin: 0 }}>
                    <Button type="button" className="btn btn-primario" onClick={this.onBack}>
                      Regresar
                    </Button>
                  </Col>
                  <Col md={{ size: 3 }} style={{ margin: 0 }}>
                    <Button type="button" className="btn btn-secundary" onClick={this.onOpenHistorial}>
                      Historial de Recálculos
                    </Button>
                  </Col>     
                  {botonRelease}
                  <Col md={{ size: 5 }} style={{ margin: 0 }}>
                    {fechaDesea}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Popup
              key="popupHistorial"
              id="popupHistorial"
              visible={this.state.popupVisible}
              onHiding={this.hidePopUp}
              dragEnabled={false}
              closeOnOutsideClick={true}
              showTitle={true}
              title={popupTittle}
              // width={1500}
              // height={600}              
              container=".dx-viewport"
            >
              <HistorialReclaculos 
                claPedido={this.props.claPedido}
                handler={this.handler}
                data={this.state.historialData}
              />
            </Popup>
          </Col>
        </Row>
      </>
    );
  }
}
