/*eslint-disable*/
import React from 'react';
import AppContext from "../../../context/AppContext";

import { Popup } from 'devextreme-react/popup';
import { Button, Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { locale } from "devextreme/localization";

import { config } from '../../../utils/config';
import { callApi, callKrakenApi } from '../../../utils/utils';

import Template1Filtros from './Template1Filtros';
import Template_UbiProdAceLoteGrid from './Template_UbiProdAceLoteGrid';
import Template_UbiProdAceLoteAlta from './Template_UbiProdAceLoteAlta';

export default class Template_UbiProdAceLote extends React.Component {
    constructor(props) {
        super(props);

        const filtros = {
            claUbicacion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            idConfiguracion: this.props.configuracionValue,
            claTemplate: 10
        };
        
        this.state = {
            showSpin: false,
            pageIndex: 0,
            filtros,            
            familiasData: [],
            subFamiliasData: [],
            ubicacionesData: [],
            productosData: [],
               
            filtrosfamiliasData: [],
            filtrossubFamiliasData: [],
            filtrosubicacionesData: [],
            filtrosproductosData: [],

            selectedFamiliaValue: null,
            
            popupVisible: false,
        }
        
        this.onDeleting = this.onDeleting.bind(this);
        this.onGridPage = this.onGridPage.bind(this); 
        this.hidePopUp = this.hidePopUp.bind(this);
        this.handler = this.handler.bind(this);
        this.onChangeCmbFamilia = this.onChangeCmbFamilia.bind(this);
        this.onChangeCmbSubfamilia = this.onChangeCmbSubfamilia.bind(this);
        this.onChangeCmbProducto = this.onChangeCmbProducto.bind(this);
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);

        this.getConfiguraciones = this.getConfiguraciones.bind(this);
        this.onLimpiar = this.onLimpiar.bind(this);
        this.onDeleting = this.onDeleting.bind(this);

        this.onClickAgregar = this.onClickAgregar.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getFamilias = this.getFamilias.bind(this);
        this.getSubfamilias = this.getSubfamilias.bind(this);
        this.getProductos = this.getProductos.bind(this);

        this.refCmbUbicacion = React.createRef();
        this.refCmbFamilia = React.createRef();
        this.refCmbSubFamilia = React.createRef();
        this.refCmbProducto = React.createRef();
    }
    
    getConfiguraciones(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneral`;

        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            this.setState({
                configData: result,
            });
        });
    }

    getUbicaciones() {
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    getFamilias() {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaNivel: 20,
        };

        callKrakenApi(25, 1, params, 1, (res) => {
            // return res.Result0;
            this.setState({
                familiasData: res.Result0
            })
        });
    }

    getSubfamilias(claFamilia) {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaFamilia: claFamilia,
        };

        callKrakenApi(25, 2, params, 1, (res) => {
            this.setState({
                subFamiliasData: res.Result0
            })
        });
    }

    getProductos(claFamilia, claSubfamilia) {
        // reactContext 3: se extrae el action setClienteInfo del contexto, este guardara la informacion del cliente
        const params = {
            Valor: '',
            Tipo: 1,
            ModoSel: 1,
            IncluirTodosSN: 0,
            ClaFamilia: claFamilia,
            ClaSubFamilia: claSubfamilia,
        };

        callKrakenApi(25, 3, params, 1, (res) => {
            const data = res.Result0;
            
            this.setState({
                productosData: data
            })

        });
    }
///////// POPUP
    handler(val) {
        this.setState({
        popupVisible: val,
        });
    }

    hidePopUp() {
        this.setState({
        popupVisible: false,
        });
    }
////////
    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onDeleting(e){
        e.cancel = true;
        
        const urlApiService = `${process.env.REACT_APP_URL_API}Configuraciones/ConfiguracionGeneralDelete`;
        this.setState({ showSpin: true });

        const data = {
            idConfiguracionGeneral: e.data.IdCfgConfiguracionGeneral
        }
        callApi(urlApiService, 'POST', data, (result) => {
        });

        this.getConfiguraciones();
    }

    onChangeCmbFamilia(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claFamilia = selectedItem.value;

        this.setState({
            filtros: newFiltro
        })
        this.getSubfamilias(selectedItem.value);
    }

    onChangeCmbSubfamilia(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claSubFamilia = selectedItem.value;

        this.setState({
            filtros: newFiltro
        })

        this.getProductos(this.state.filtros.claFamilia, selectedItem.value);
    }

    onChangeCmbProducto(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claProducto = selectedItem.value;

        this.setState({
            filtros: newFiltro
        })
    }

    onChangeCmbUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;

        this.setState({
            filtros: newFiltro
        })
    }

    onRefreshData(){
        this.getConfiguraciones();
    }

    onClickAgregar(e) {
        this.setState({
          popupVisible: true,
        });
    
        e.stopPropagation();
    }

    onLimpiar() {
        const filtros = {
            claUbicacion: null,
            claFamilia: null,
            claSubFamilia: null,
            claProducto: null,
            claConfiguracion: 310
        };

        this.setState({
            filtros,
            subFamiliasData: [],
            productosData: [],
               
            filtrossubFamiliasData: [],
            filtrosproductosData: [],
        })
    }

    componentDidMount(){
        this.getConfiguraciones();

        this.getFamilias();
        this.getUbicaciones();
    }

    componentDidUpdate(){
        if(this.state.filtros.idConfiguracion !== this.props.configuracionValue)
        {
            this.getConfiguraciones();
        }
    }

    render(){
        return(
            <div className="content">
                {/* <Row>
                    <Col>
                        <Template1Filtros 
                            
                            onLimpiar={this.onLimpiar}
                            getConfiguraciones={this.getConfiguraciones}
                            
                            onChangeClaFamilia={this.onChangeCmbFamilia}
                            onChangeClaSubFamilia={this.onChangeCmbSubfamilia}
                            onChangeCmbProductos={this.onChangeCmbProducto}
                            onChangeCmbUbicacion={this.onChangeCmbUbicacion}

                            familiaData={this.state.familiasData}
                            subFamiliaData={this.state.subFamiliasData}
                            productosData={this.state.productosData}
                            ubicacionesData={this.state.ubicacionesData}

                            ubicacionValue={this.state.filtros.claUbicacion}
                            productosValue={this.state.filtros.claProducto}
                            familiaValue={this.state.filtros.claFamilia}
                            subFamiliaValue={this.state.filtros.claSubFamilia}

                            refCmbFamilia={this.refCmbFamilia}
                            refCmbSubFamilia={this.refCmbSubFamilia}
                            refCmbProducto={this.refCmbProducto}
                            refCmbUbicacion={this.refCmbUbicacion}
                        />
                    </Col>
                </Row> */}
                <Row>
                    <Col md={{ size: 1, offset: 11 }} style={{ textAlign: 'center' }}>
                        <Button
                            id="btnNuevo"
                            onClick={this.onClickAgregar}
                            size="md"
                            className="btn btn-primary btn-round btn-icon btn-custom mb-1"
                        >
                            <i className="fa fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Template_UbiProdAceLoteGrid 
                            data={this.state.configData}
                            onDeleting={this.onDeleting}
                            onPage={this.onGridPage} 
                            pageIndex={this.state.pageIndex}
                        />   
                    </Col>
                </Row>
                <Popup
                    key="popupConfig"
                    id="popupConfig"
                    visible={this.state.popupVisible}
                    onHiding={this.hidePopUp}
                    dragEnabled={false}
                    closeOnOutsideClick={true}
                    showTitle={true}
                    title="Alta de Configuración"
                    width={"50%"}
                    height={600}
                    >
                    <Template_UbiProdAceLoteAlta 
                        idConfiguracion={this.props.configuracionValue}
                        handler={this.handler}
                        getConfiguraciones={this.getConfiguraciones}
                    />
                </Popup>
            </div>
        );
    }

}
