import React from 'react';
import DataGrid, { Column, Paging, Editing, FilterRow, HeaderFilter, Export, Scrolling, Texts } from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import 'assets/css/semaforo.css';
import CtrlCheckbox from 'views/Controles/CtrlCheckbox';

export default class HorizonteGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data:[],
      claPermiso: null
    };

    this.onRowUpdated = this.onRowUpdated.bind(this);
    this.onRowRemoved = this.onRowRemoved.bind(this);
    this.cellRenderCheckbox = this.cellRenderCheckbox.bind(this);
    this.onExporting = this.onExporting.bind(this);
  }

  onRowUpdated(objeto) {
    this.props.updHorizonteCfg(objeto.data);
  }

  onRowRemoved(objeto) {
    this.props.delHorizonteCfg(objeto.data);
  }

  cellRenderCheckbox(cellData) {
        
    const value= cellData.data.BajaLogica === 1;

    const ctrl=  <CtrlCheckbox etiqueta="" value={value}></CtrlCheckbox>;
    return ctrl;
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'TraspasoHorizontes.xlsx');
      });
    });
    e.cancel = true;
  }


  componentDidMount() {
  }

  render() {

    const permisos =this.props.claPermiso===1 || this.props.claPermiso===2;
    const gridContent = (
      <div>
        <DataGrid
          dataSource={this.props.data}
          showRowLines={true}
          showColumnLines={false}
          onRowUpdated={this.onRowUpdated}
          onRowRemoved={this.onRowRemoved}
          noDataText="Sin Registros"
          width="100%"
        >
          <Scrolling showScrollbar="never" />
          <FilterRow visible={true} showAllText="Todos" />
          <HeaderFilter visible={true} />
          <Paging defaultPageSize={20} />
          <Editing 
            mode="row" 
            allowUpdating={permisos} 
            allowDeleting={permisos} 
            allowAdding={false} 
          >
            <Texts confirmDeleteMessage="¿Desea eliminar la configuración?" />
          </Editing>
          <Export enabled={true} allowExportSelectedData={true} />
          

          <Column dataField="Id" caption="Id" allowEditing={false} alignment="center" visible={false} />
          <Column dataField="TipoUbicacion" caption="Tipo Ubicacion" allowEditing={false} alignment="center" width='10%'  />
          <Column dataField="Ubicacion" caption="Ubicacion" allowEditing={false} alignment="center" width='10%' />
          <Column dataField="TipoInventario" caption="Tipo Inventario" allowEditing={false} width='10%' />
          <Column dataField="GpoEstadistico1" caption="GpoEstadistico1" allowEditing={false} alignment="center" width='10%' />
          <Column dataField="DiasVencido" caption="Dias Vencido" allowEditing={true} width='10%' />
          <Column dataField="DiasFuturo" caption="Dias Futuro" allowEditing={true} width='10%' />
          <Column dataField="BajaLogica" caption="BajaLogica" allowEditing={false} cellRender={this.cellRenderCheckbox} width='10%' />
          <Column dataField="Usuario" caption="Usuario" allowEditing={false} width='20%' />
          <Column dataField="FechaUltimaMod" caption="FechaUltimaMod" visible={true} allowEditing={false} width='10%' />
        </DataGrid>
      </div>
    );


    return (
      <>
        {gridContent}
      </>
    );
  }
}
