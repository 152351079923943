/*eslint-disable*/
import React from 'react';
// import AppContext from "../../context/AppContext";

import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import { locale } from "devextreme/localization";

import ContratosFiltros from './ContratosFiltros';
import ContratosGrid from './ContratosGrid';

import { config } from 'utils/config';
import { callApi, callKrakenApi } from 'utils/utils';

export default class Contratos extends React.Component {
    constructor(props) {
        super(props);

        locale('en');
        
        const filtros = {            
            fechaCalculo: new Date(),
            claUbicacion: null,
            periodoInicio: null,
            periodoFin: null,
            idContrato: null
        };

        this.state = {
            filtros,
            pageIndex: 0,
            showSpin: false,
            fechaCalculo: new Date(),
            ubicacionesData: [],
            periodosData: [],
            dataProduccion: [],
        }

        this.getProduccionData = this.getProduccionData.bind(this);
        this.getUbicaciones = this.getUbicaciones.bind(this);
        this.getHorizontePeriodos = this.getHorizontePeriodos.bind(this);
        this.onChangeCmbPeriodoInicio = this.onChangeCmbPeriodoInicio.bind(this);
        this.onChangeCmbPeriodoFin = this.onChangeCmbPeriodoFin.bind(this);
        this.onChangeIdContrato = this.onChangeIdContrato.bind(this);

        this.onGridPage = this.onGridPage.bind(this);
        this.onLimpiar = this.onLimpiar.bind(this);

        this.refCmbUbicacion = React.createRef();
        this.refCmbPeriodoInicio = React.createRef();
        this.refCmbPeriodoFin = React.createRef();

        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
    }

    getUbicaciones(){
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 0,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }

    getProduccionData = () => {
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaContrato`;
        
        this.setState({ showSpin: true });
        
        callApi(urlApiService, 'POST', this.state.filtros, (result) => {
            this.setState({
                dataProduccion: result,
                showSpin: false
            });
        });
    }

    getHorizontePeriodos(){
        const urlApiService = `${process.env.REACT_APP_URL_API}Produccion/BusquedaContrato`;
        
        
        const filtros = {            
            fechaCalculo: new Date(),
            claUbicacion: null,
            periodoInicio: null,
            periodoFin: null,
            idContrato: 0
        };


        callApi(urlApiService, 'POST', filtros, (result) => {
            this.setState({
                dataProduccion: result,
                periodosData: result[2],
                showSpin: false
            });
        });
    }

    onGridPage(pageIndex) {
        this.setState({ pageIndex });
    }

    onChangeFechaCalculo = selectedDate => {
        var newFiltro = this.state.filtros;
        newFiltro.fechaCalculo = selectedDate.value;

        this.setState({
            filtros: newFiltro,
            filtrosPedidos: newFiltro
        })
    }

    onChangeCmbUbicacion = selectedItem => {
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;

        this.setState({ 
            filtros : newFiltro
        });
    }
    
    onChangeCmbPeriodoInicio = selectedItem => {
        var newFiltro = this.state.filtros;
        newFiltro.periodoInicio = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro
        }); 
    }

    onChangeCmbPeriodoFin = selectedItem => {
        var newFiltro = this.state.filtros;
        newFiltro.periodoFin = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro
        }); 
    }

    onChangeIdContrato(e){        
        var newFiltro = this.state.filtros;
        newFiltro.idContrato = e.value;

        this.setState({ 
            filtros: newFiltro
        });
    }
    
    onLimpiar() {
        const filtros = {            
            fechaCalculo: new Date(),
            claUbicacion: null,
            claArea: null,
            claGrupoMaquina: null,
            periodoInicio: null,
            periodoFin: null,
            idContrato: null
        };

        this.setState({
            filtros
        });
    }

    componentDidMount(){
        this.getHorizontePeriodos();
        this.getUbicaciones();
    }

    render(){
        return(
            <div className="content mb-5">
                <Row>
                    <ContratosFiltros
                        onLimpiar={this.onLimpiar}

                        getProduccionData={this.getProduccionData}

                        onChangeFechaCalculo={this.onChangeFechaCalculo}
                        fechaCalculoValue={this.state.filtros.fechaCalculo}

                        ubicacionesData={this.state.ubicacionesData}
                        onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                        refCmbUbicacion={this.refCmbUbicacion}
                        claUbicacion={this.state.filtros.claUbicacion}

                        periodosData={this.state.periodosData}
                        onChangeCmbPeriodoInicio={this.onChangeCmbPeriodoInicio}
                        refCmbPeriodoInicio={this.refCmbPeriodoInicio}
                        periodoInicio={this.state.filtros.periodoInicio}
                        onChangeCmbPeriodoFin={this.onChangeCmbPeriodoFin}
                        refCmbPeriodoFin={this.refCmbPeriodoFin}
                        periodoFin={this.state.filtros.periodoFin}

                        onChangeIdContrato={this.onChangeIdContrato}
                        idContrato={this.state.filtros.idContrato}

                        showSpin={this.state.showSpin}
                    />
                </Row>
                <Row>
                    <ContratosGrid
                        data={this.state.dataProduccion} 
                        onPage={this.onGridPage} 
                        pageIndex={this.state.pageIndex}
                        filtros={this.state.filtros}
                    />
                </Row>
            </div>
        )
    };
}