/*eslint-disable*/
import React from 'react'
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import AppContext from "../../../context/AppContext";
import { callApi, callKrakenApi } from '../../../utils/utils';
import Tabs from 'devextreme-react/tabs';
import CapacidadDispFiltros from './CapacidadDispFiltros';
import { config } from '../../../utils/config';
import CapacidadConfigGrid from './CapacidadConfigGrid';
import CapacidadConfigDescargaGrid from './CapacidadConfigDescargaGrid';
import CapacidadDispGrid from './CapacidadDispGrid';
import CapacidadDispDescGrid from './CapacidadDispDescGrid';

export default class CapacidadDisp extends React.Component {
    constructor(props) {
        super(props);
        
        const tabs = [
            {
                id: 0,
                text: 'Porcentaje Disponible de Carga',
                content: 'Porcentaje Disponible de Carga'
            },
            {
                id: 1,
                text: 'Porcentaje Disponible de Descarga',
                content: 'Porcentaje Disponible de Descarga'
            },
            {
                id: 2,
                text: 'Capacidad Disponible Carga',
                content: 'Capacidad Disponible Carga'
            },
            {
                id: 3,
                text: 'Capacidad Disponible Descarga',
                content: 'Capacidad Disponible Descarga'
            }
        ];

        const filtros = {
            claUbicacion: -1,
            tipoVista: 1
        }

        this.state = {
            ubicacionesData: [],
            capacidadConfigData: [],
            capacidadConfigDescargaData: [],
            capacidadDispData: [],
            capacidadDispDescData: [],
            filtros,
            showSpin: false,
            tabs,
            porcentajeVisible: "",
            porcentajeDescargaVisible: "none",
            detalleVisible: "none",
            detalleDescVisible: "none",
            pageIndexTab1: 0,
            pageIndexTab2: 0,
            pageIndexTab3: 0,
            pageIndexTab4: 0,
            selectedIndex: 0,
            requiredMessage: "hidden"
        };

        this.getEmbarques = this.getEmbarques.bind(this);
        
        this.onChangeCmbUbicacion = this.onChangeCmbUbicacion.bind(this);
        this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);

        this.onLimpiar = this.onLimpiar.bind(this);

        this.refCmbUbicacion = React.createRef();     
    }

        
    getUbicaciones = () => {
        var params = {
            Valor: '',
            Tipo: 1,
            IncluirTodosSN: 1,
        };
      
        callKrakenApi(8, 258, params, 1, (res) => {
            this.setState({
                ubicacionesData: res.Result0
            })
        });
    }
    
    getEmbarques(){
        if(this.state.filtros.claUbicacion === null){
            this.setState({
                //showSpin: true,
                requiredMessage: "visible"
            })
        }
        else{                
            const urlApiService = `${process.env.REACT_APP_URL_API}Embarque/BusquedaCapDispEmb`;
            
            this.setState({ showSpin: true });

            if (this.state.filtros.tipoVista == 1) {
                callApi(urlApiService, 'POST', this.state.filtros, (result) => {
                    console.log(result);
                    this.setState({
                        capacidadConfigData: result,
                        showSpin: false,
                        requiredMessage: "hidden"
                    })
                });
            }

            if (this.state.filtros.tipoVista == 2) {
                callApi(urlApiService, 'POST', this.state.filtros, (result) => {
                    console.log(result);
                    this.setState({
                        capacidadConfigDescargaData: result,
                        showSpin: false,
                        requiredMessage: "hidden"
                    })
                });
            }

            if (this.state.filtros.tipoVista == 3) {
                callApi(urlApiService, 'POST', this.state.filtros, (result) => {
                    console.log(result);
                    this.setState({
                        capacidadDispData: result,
                        showSpin: false,
                        requiredMessage: "hidden"
                    })
                });
            }
            if (this.state.filtros.tipoVista == 4) {
                callApi(urlApiService, 'POST', this.state.filtros, (result) => {
                    console.log(result);
                    this.setState({
                        capacidadDispDescData: result,
                        showSpin: false,
                        requiredMessage: "hidden"
                    })
                });
            }
        }
    }
    
    onGridPageTab1(pageIndexTab1) {
        this.setState({ pageIndexTab1 });
    }
    
    onGridPageTab2(pageIndexTab2) {
        this.setState({ pageIndexTab2 });
    }

    onGridPageTab3(pageIndexTab3) {
        this.setState({ pageIndexTab3 });
    }

    onGridPageTab4(pageIndexTab4) {
        this.setState({ pageIndexTab4 });
    }

    onChangeCmbUbicacion(selectedItem){
        var newFiltro = this.state.filtros;
        newFiltro.claUbicacion = selectedItem.value;
        
        this.setState({ 
            filtros : newFiltro,
            requiredMessage: "visible",
            capacidadConfigData: [],
            capacidadConfigDescargaData: [],
            capacidadDispData: [],
            capacidadDispDescData: []
        });
    }

        
    onTabsSelectionChanged(args) {
        if (args.name === 'selectedIndex') {
            if(args.value === 0){ 
                this.state.filtros.tipoVista = 1;
                this.setState({
                  selectedIndex: args.value,
                  porcentajeVisible: "",
                  porcentajeDescargaVisible: "none",
                  detalleVisible: "none",
                  detalleDescVisible: "none",
                });
            }
            if(args.value === 1){ 
                this.state.filtros.tipoVista = 2;
                this.setState({
                  selectedIndex: args.value,
                  porcentajeVisible: "none",
                  porcentajeDescargaVisible: "",
                  detalleVisible: "none",
                  detalleDescVisible: "none",
                });
            }
            if(args.value === 2){ 
                this.state.filtros.tipoVista = 3;
                this.setState({
                  selectedIndex: args.value,
                  porcentajeVisible: "none",
                  porcentajeDescargaVisible: "none",
                  detalleVisible: "",
                  detalleDescVisible: "none",
                });
            }
            if(args.value === 3){ 
                this.state.filtros.tipoVista = 4;
                this.setState({
                  selectedIndex: args.value,
                  porcentajeVisible: "none",
                  porcentajeDescargaVisible: "none",
                  detalleVisible: "none",
                  detalleDescVisible: "",
                });
            }
        }
    }

    onLimpiar(){        
        const filtros = {
            claUbicacion: -1,
            tipoVista: this.state.filtros.tipoVista
        }
        
        this.setState({
            filtros: filtros
        })
    }

    componentDidMount(){       
        this.getUbicaciones();
        
    }
    
    render() {
        return (
            <div className="content mb-5">
                <Row>
                    <Col>
                        <CapacidadDispFiltros
                            onChangeCmbUbicacion={this.onChangeCmbUbicacion}
                            
                            getEmbarques={this.getEmbarques}
                            onLimpiar={this.onLimpiar}

                            ubicacionesData={this.state.ubicacionesData}

                            refCmbUbicacion={this.refCmbUbicacion}
                            
                            filtros={this.state.filtros}
                            showSpin={this.state.showSpin}

                            requiredMessage={this.state.requiredMessage}
                        >
                        </CapacidadDispFiltros>
                    </Col>
                </Row>
                <Row>
                    <Col>                    
                        <Tabs
                            dataSource={this.state.tabs}
                            selectedIndex={this.state.selectedIndex}
                            onOptionChanged={this.onTabsSelectionChanged}
                        />

                        <div style={{"display": this.state.porcentajeVisible}}>
                            <CapacidadConfigGrid
                                capacidadConfigData={this.state.capacidadConfigData[0]}
                                onPage={this.onGridPageTab1} 
                                pageIndexTab1={this.state.pageIndexTab1}
                            />
                        </div>        

                        <div style={{"display": this.state.porcentajeDescargaVisible}}>
                            <CapacidadConfigDescargaGrid
                                capacidadConfigDescargaData={this.state.capacidadConfigDescargaData[0]}
                                onPage={this.onGridPageTab2} 
                                pageIndexTab2={this.state.pageIndexTab2}
                            />
                        </div>    
                        
                        <div style={{"display": this.state.detalleVisible}}>
                            <CapacidadDispGrid
                                capacidadDispData={this.state.capacidadDispData[0]}
                                onPage={this.onGridPageTab3} 
                                pageIndexTab3={this.state.pageIndexTab3}
                            />
                        </div>

                        <div style={{"display": this.state.detalleDescVisible}}>
                            <CapacidadDispDescGrid
                                capacidadDispDescData={this.state.capacidadDispDescData[0]}
                                onPage={this.onGridPageTab4} 
                                pageIndexTab4={this.state.pageIndexTab4}
                            />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}