/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button, Spinner, Label } from 'reactstrap';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default class CapacidadCharts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: this.getChart(),
        }

        this.getChart = this.getChart.bind(this);
    }

    getChart(){
        const options = {
                chart: {
                  type: 'column',
                },      
                title: {
                  text: 'Saturación de Embarque',
                  style: {
                    fontSize: '24px'
                  }
                },      
                tooltip: {
                  enabled: false,
                },
                xAxis: {
                    categories: this.props.capacidadData[0],
                    title: {
                        text: 'Zonas'
                    }
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: ''
                  }
                },
                plotOptions: {
                    column: {
                      stacking: 'percent'
                    }
                },      
                series: this.props.capacidadData[1],
                tooltip: {
                  pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y} Toneladas</b> ({point.percentage:.0f}%)<br/>',
                  shared: true
                },
                credits: { enabled: false },
            };
        
            return options;
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){        
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {            
            console.log(this.props.capacidadData)
            this.setState({                
                options: this.getChart()
            })
        }
    }

    render() {
        return (
            <div className="content">
                <Row>
                    <Col>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={this.state.options}
                            allowChartUpdate={true}
                            containerProps={{ style: { width: '100%', height: '90%' } }}
                        >                            
                        </HighchartsReact>
                    </Col>
                </Row>
            </div>
        );
    }
}