/*eslint-disable*/
import React from 'react';
import { Card, CardHeader, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import DataGrid, { MasterDetail, Column, Editing, Paging, Format, Export } from 'devextreme-react/data-grid';
import { callApi, callKrakenApi } from '../../../utils/utils';

export default class CapacidadConfigDescargaGrid extends React.Component {
    constructor(props) {
        super(props);
        this.dataGrid = null;

        this.state = {
            usuarios: [],
            roles: [],
            claPermiso:null,
          };

          this.onRowUpdated = this.onRowUpdated.bind(this);
          //this.updateUsuario = this.updateUsuario.bind(this);

          this.handleOptionChange = this.handleOptionChange.bind(this);   
    }
    
    handleOptionChange(e) {
        if (e.fullName === 'paging.pageIndexTab2') {
          this.props.onPage(e.value);
        }
    }


    
  getPermiso(){
    callApi(`${process.env.REACT_APP_URL_API}usuario/permisos`, 'POST', {}, (result) => {
      this.setState({
        claPermiso: result.data
      })
    });

  }


  /**
   * Funcion que actualiza el usuario del grid
   */
  onRowUpdated(objeto) {
    this.updateRegistro(objeto);
  }

  /**
   * Funcion que realiza la peticion al servicio
   */
  updateRegistro(objeto) {
    if (objeto.data) {
      const urlApiService = `${process.env.REACT_APP_URL_API}Embarque/ActualizaCapDispDescarga`;

      const body = {
        ClaUbicacion: objeto.data.ClaUbicacion,
        PctLunCapDescarga: objeto.data.PctLunCapDescarga,
        PctMarCapDescarga: objeto.data.PctMarCapDescarga,
        PctMieCapDescarga: objeto.data.PctMieCapDescarga,
        PctJueCapDescarga: objeto.data.PctJueCapDescarga,
        PctVieCapDescarga: objeto.data.PctVieCapDescarga,
        PctSabCapDescarga: objeto.data.PctSabCapDescarga,
        PctDomCapDescarga: objeto.data.PctDomCapDescarga,
      };

      callApi(urlApiService, 'POST', body, (res) => { });

    }
  }

  componentDidMount() {
    this.getPermiso();
  }
    
    render() {
        const permisos =this.state.claPermiso===1 || this.state.claPermiso===2;

        const gridContent = (
            <Row>
                <Col>
                    <Row>    
                        <Col>
                            <DataGrid
                                id="gridCapacidadConfigDescarga"
                                ref={(ref) => this.dataGrid = ref}
                                dataSource={this.props.capacidadConfigDescargaData}
                                key={'ClaUbicacion'}
                                width="100%"
                                height={600}
                                selection={{ mode: 'single' }}
                                showBorders={false}
                                columnHidingEnabled
                                columnAutoWidth={true}
                                showColumnHeaders
                                showColumnLines={false}
                                showRowLines={true}
                                noDataText="Sin Registros"
                                wordWrapEnabled
                                rowAlternationEnabled={false}
                                onOptionChanged={this.handleOptionChange}
                                onRowUpdated={this.onRowUpdated}
                            >
 
                         <Editing
                                mode="row"
                                allowUpdating={permisos}
                                allowDeleting={false}
                                allowAdding={false}
                                width={100}
                                >
                            </Editing>   

                                <Column dataField="ClaUbicacion" caption="Clave Ubicación" allowEditing={false} visible={false} width={30}/>
                                <Column dataField="NomUbicacion" caption="Ubicación" allowEditing={false} width={120}/>
                                <Column dataField="PctLunCapDescarga" caption="% Lun" allowEditing={true} width={30}/>
                                <Column dataField="PctMarCapDescarga" caption="% Mar" allowEditing={true} width={30}/>
                                <Column dataField="PctMieCapDescarga" caption="% Mié" allowEditing={true} width={30}/>
                                <Column dataField="PctJueCapDescarga" caption="% Jue" allowEditing={true} width={30}/>
                                <Column dataField="PctVieCapDescarga" caption="% Vie" allowEditing={true} width={30}/>
                                <Column dataField="PctSabCapDescarga" caption="% Sáb" allowEditing={true} width={30}/>
                                <Column dataField="PctDomCapDescarga" caption="% Dom" allowEditing={true} width={30}/>
                                <Column width={30}/>

                                <Paging
                                    pageSize={30}
                                    pageIndexTab2={this.props.pageIndexTab2}
                                />
                            </DataGrid>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );

        const GridConfigCapDescargaServicio = () => {
            return <div>{gridContent}</div>;
          };
      

    //    return (
    //     <>
    //         {gridContent}
    //     </>
    //     );

        return (
            <>
              <div className="content">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <CardTitle tag="h4">Configuración % Capacidad Descarga Disponible</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <GridConfigCapDescargaServicio claPermiso={this.state.claPermiso} />
                  </CardBody>
                </Card>
              </div>
            </>
          );
      
    }
}