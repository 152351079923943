/*eslint-disable*/
import React from 'react';
import DataGrid, {
  Column,
  Paging,
  Summary,
  TotalItem,
  Export,
  Scrolling,
} from 'devextreme-react/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { semaforoColor } from 'utils/utils';
import 'assets/css/semaforo.css';
import { config } from '../../utils/config';
import { callApi, fechaStr } from '../../utils/utils';

export default class PedidoGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claPermiso: null,
    };

    this.cellLinkRender = this.cellLinkRender.bind(this);
    this.onClickPedido = this.onClickPedido.bind(this);
    this.onContentReady = this.onContentReady.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.cellIconoRender = this.cellIconoRender.bind(this);
    this.cellDateFormatRender = this.cellDateFormatRender.bind(this);
    this.onExporting = this.onExporting.bind(this);
    this.onCellPrepared = this.onCellPrepared.bind(this);
  }

  onClickPedido(ClaPedido) {
    this.props.onClick(ClaPedido);
  }

  cellLinkRender(cellData) {
    if (this.state.claPermiso !== 2) {
      // eslint-disable-next-line no-lone-blocks
      if (cellData.data.esFechaKayak === 0) {
        const _url = `http://logisticaofi:2001/Pages/AG_CU121_Pag1.aspx?ClaPedido=${cellData.text}&DireccionFiltro=&SubDireccionFiltro=-1&ZonaFiltro=-1&AgenteVentaFiltro=-1&PlantaAsignadaFiltro=-1&FamiliaFiltro=-1&SubFamiliaFiltro=-1&ProductoFiltro=-1&ClienteFiltro=-1&PedidoFiltro=-1&PedidoRbtnFiltro=True&EstimadaRbtnFiltro=False&AsignadorRbtnFiltro=False&FechaRbtnFiltro=False&FechaInicioFiltro=21/03/2022%2012:00:00%20a.%20m.&FechaFinFiltro=22/03/2022%2012:00:00%20a.%20m.&PendientesAutorizarFiltro=0&PendientesFechaFiltro=False&PedidosVencidosFiltro=False`;
        return (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={_url} target="_blank">
            {cellData.text}
          </a>
        );
      }
    }

    return (
      <div className="link" onClick={this.onClickPedido.bind(this, cellData.data.claPedido)}>
        {console.log('CLICK', cellData.data.claPedido)}
        {cellData.text}
      </div>
    );
  }

  onContentReady(e) {
    const selectedRows = e.component.getSelectedRowsData();

    // Selecciona el primer row del grid.
    if (this.props.idPedido === 0 || this.props.idPedido === null) return;

    if (selectedRows.length === 0) {
      const listaKeys = [];
      if (this.props.data.length > 0) {
        listaKeys.push(this.props.idPedido);
        e.component.selectRows(listaKeys, true);
      }
    }
  }

  handleOptionChange(e) {
    if (e.fullName === 'paging.pageIndex') {
      this.props.onPage(e.value);
    }

    if (e.fullName === 'columns[5].sortOrder') {
      this.props.onSort(e.value);
    }
  }

  cellIconoRender(data) {
    const iconoColor = semaforoColor(data.data.claSemaforo);

    const icono = (
      <i
        className={`mb-0 icon icon-shape text-white rounded-circle shadow ${iconoColor}`}
        title={data.data.nomSemaforoEsatstus}
        style={{ width: 25, height: 25, padding: '5px', cursor: 'pointer' }}
        // onClick={this.descargaDirecta.bind(this, data.ClaTipoDocumento, idDocumento, tipoArchivo)}
      ></i>
    );

    return icono;
  }

  cellDateFormatRender(data, index) {
    const fechaobj = data.values[data.columnIndex];
    const fecha = fechaStr(fechaobj);
    const fechaJsx = <span>{fecha}</span>;

    return fechaJsx;
  }

  countFechaEmbarque(options) {
    switch (options.summaryProcess) {
      case 'start':
        // Initializing "totalValue" here
        options.totalValue = 0;
        break;
      case 'calculate':
        // Modifying "totalValue" here
        if (options.value.fechaEstimadaEmbarque !== null && options.name === 'fechaEmbarque') {
          options.totalValue += 1;
        }
        if (options.value.fechaEmbarqueAG !== null && options.name === 'fechaEmbarqueAG') {
          options.totalValue += 1;
        }
        if (
          options.value.fechaEstimadaEmbarque !== null &&
          options.name === 'fechasKayak' &&
          options.value.esFechaKayak === 1
        ) {
          options.totalValue += 1;
        }
        break;
      default:
      case 'finalize':
        // Assigning the final value to "totalValue" here
        break;
    }
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Main sheet');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'PedidosKayak.xlsx');
      });
    });
    e.cancel = true;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.columnIndex === 3 && e.data.esFechaKayak === 1) {
      e.cellElement.style.color = 'green';
      e.cellElement.style.fontWeight = 'bold';
      return;
    }

    if (e.rowType === 'data' && e.columnIndex === 4 && e.data.esFechaKayak === 0) {
      e.cellElement.style.color = 'green';
      e.cellElement.style.fontWeight = 'bold';
    }
  }

  componentDidMount() {
    callApi(`${process.env.REACT_APP_URL_API}usuario/permisos`, 'POST', {}, (result) => {
      this.setState({
        claPermiso: result.data,
      });
    });
  }

  render() {
    const gridContent = (
      <div>
        <DataGrid
          id="gridPedidos"
          dataSource={this.props.data}
          keyExpr="claPedido"
          height={600}
          columnWidth={100}
          selection={{ mode: 'single' }}
          showBorders={false}
          columnAutoWidth={false}
          showColumnHeaders
          showColumnLines={false}
          showRowLines
          noDataText="Sin Registros"
          wordWrapEnabled
          rowAlternationEnabled={false}
          onContentReady={this.onContentReady}
          onOptionChanged={this.handleOptionChange}
          onCellPrepared={this.onCellPrepared}
          onExporting={this.onExporting}
        >
          <Column
            dataField="claPedido"
            caption="Pedido"
            width={100}
            alignment="center"
            cellRender={this.cellLinkRender}
          />
          <Column dataField="nomUbicacion" caption="Planta Asignada" width={200} />
          <Column dataField="fechaCaptura" caption="Fecha Pedido" width={150} />
          <Column dataField="fechaDeseada" caption="Fecha Desea" width={150} />
          <Column dataField="nomCliente" caption="Cliente" width={300} />
          <Column dataField="estatus" caption="Estatus" width={150} />
          <Column
            dataField="fechaEstimadaEmbarque"
            caption="Embarque"
            dataType="date"
            width={130}
            cellRender={this.cellDateFormatRender}
            sortOrder={this.props.sortorder.col5}
          />
          <Column
            dataField="fechaEstimadaEntrega"
            caption="Entrega"
            dataType="date"
            format="dd-MM-yyyy"
            width={130}
            cellRender={this.cellDateFormatRender}
          />
          <Column
            dataField="claSemaforoEstatus"
            caption=""
            width={80}
            cellRender={this.cellIconoRender}
          />
          <Summary calculateCustomSummary={this.countFechaEmbarque}>
            <TotalItem column="claPedido" summaryType="count" displayFormat="Total: {0}" />
            <TotalItem
              name="fechaEmbarque"
              summaryType="custom"
              displayFormat="Kayak: {0}"
              showInColumn="fechaEstimadaEmbarque"
            />
            <TotalItem
              name="fechasKayak"
              summaryType="custom"
              displayFormat="Fechas Kayak: {0}"
              showInColumn="nomCliente"
            />
          </Summary>
          <Paging enabled={false} pageSize={30} pageIndex={this.props.pageIndex} />
          <Export enabled={true} allowExportSelectedData={true} />
          <Scrolling columnRenderingMode="virtual" />
        </DataGrid>
      </div>
    );

    return <>{gridContent}</>;
  }
}
